import { values } from "lodash";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";

export const CollabContext = createContext({
    liveBrief: {},
    setLiveBrief: () => {}
});

const CollabProvider = ({
    ...props
  }) => {
  // const { children } = props;

  const [liveBrief, setLiveBrief] = useState({});

  const value = { liveBrief, setLiveBrief }

  return (
    <CollabContext.Provider value={value}>
      {props.children}
    </CollabContext.Provider> 
  );
};

export default CollabProvider;