import React, { useState, useContext } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import DatePicker from 'react-date-picker';
import { useInterval } from 'usehooks-ts';
import { RoomProvider } from "./liveblocks.config";
import { ClientSideSuspense } from "@liveblocks/react";
import { Loading } from "./components/Loading";
import { EditProfileLoader } from "../../../components/common/loader";
import { RoomAudience } from './RoomAudience';
import { RoomNotes } from './RoomNotes';
import { RoomKpis } from './RoomKpis';
import { RoomBg } from './RoomBg';
import { RoomBrief } from './RoomBrief';
import { RoomGoal } from './RoomGoal';
import { RoomExp } from './RoomExp';
import { RoomBrand } from './RoomBrand';
import './globals.css';
import AddDeliverableDivider from '../../../components/Brief/AddDeliverableDivider';
import { CollaborativeEditor } from './components/CollaborativeEditor';
// import { syncLiveblocks } from './../../../apis';
import { CollabContext } from './providers/CollabProvider';
import { addDeliverableApi, removeRoleFromDeliverableApi, removeDeliverableApi, addContributorsV2, getUserApi, removeSubcultureV2, removeAudienceV2 } from '../../../apis/index.js';
import SimpleTextAndTitle from '../../../components/text-boxes/SimpleTextAndTitle.js';
import calendarIcon from '../../../Icons/calendarIcon.svg';
// import DateRange from '../../../components/Brief/DateRange';
import editIcon from '../../../Icons/editIconSimple.svg';
import SimpleModal from '../../../components/Modals/SimpleModal';
import EditName from './components/EditName';
import plusIconBlack from '../../../Icons/plusIconBlack.svg';
import plusIconGray from '../../../Icons/plusIconGray.svg';
import closeCrossIcon from '../../../Icons/closeCrossIcon.svg';
import trashIcon from '../../../Icons/trachIcon.svg';
import AddRole from './components/AddRole';
import { getLastElementId } from '../../../utils/helperFunctions.js';
import AddTag from './components/AddTag';
import AddAudience from './components/AddAudience';
import { LoadingIndicator } from 'react-select/dist/declarations/src/components/indicators';

const addDivider: string = require('../../../Icons/addDivider.svg').default

export default function EditBriefForm({briefDetails, refreshBrief}) {

  const { liveBrief, setLiveBrief } = useContext<any>(CollabContext);

  // TODO: populate collabText with data from briefDetails when model is updated
  const [collabText, setCollabText] = useState<any>({});
  const [collabUpdate, setCollabUpdate] = useState<any>({
    audience: false,
    kpis: false
  });
  const [editName, setEditName] = useState(false);
  const [addRole, setAddRole] = useState(false);
  const [addTag, setAddTag] = useState(false);
  const [addAudience, setAddAudience] = useState(false);
  const [audienceSelected, setAudienceSelected] = useState<any>();
  // const [removeAudience, setRemoveAudience] = useState(false);
  const [tagType, setTagType] = useState('');
  const [subcultureSelected, setSubcultureSelected] = useState<any>();
  const [removeTag, setRemoveTag] = useState(false);
  const [removeRole, setRemoveRole] = useState(false);
  const [currentDeliverable, setCurrentDeliverable] = useState<any>();
  const [removeDeliverable, setRemoveDeliverable] = useState(false);
  const {deliverables} = briefDetails;
  const currDate = moment().format('YYYY-MM-DD');
  const [deliverableLoading, setDeliverableLoading] = useState(false);
  const [removeRoleLoading, setRemoveRoleLoading] = useState(false);
  const [removeTagLoading, setRemoveTagLoading] = useState(false);
  const [roleSelected, setRoleSelected] = useState<any>();
  const [addContributorLoading, setAddContributorLoading] = useState(false);

  const addDeliverable = async () => {
    setDeliverableLoading(true);
    const res = await addDeliverableApi({ id: briefDetails?._id});
    setDeliverableLoading(false);
    
    if (res?.data?.deliverableId) {
      refreshBrief();
    }
  }
  
  const handleRemoveTag = async (params) => {
    setRemoveTagLoading(true);
    const res = await removeSubcultureV2({ 
      id: briefDetails?._id,
      subcultureId: params.subcultureId
    });
    setRemoveTagLoading(false);
    
    if (res?.data?.briefId) {
      toast.success(res?.data?.message, { id: '001' });  
      refreshBrief();
    }
    setRemoveTag(false);
  };

  const handleRemoveAudience = async (params) => {
    setRemoveTagLoading(true);
    const res = await removeAudienceV2({ 
      id: briefDetails?._id,
      countryId: params.countryId
    });
    setRemoveTagLoading(false);
    
    if (res?.data?.briefId) {
      toast.success(res?.data?.message, { id: '001' });  
      refreshBrief();
    }
    setRemoveTag(false);
  };
  
  const handleRemoveRole = async (params) => {
    setRemoveRoleLoading(true);
    const res = await removeRoleFromDeliverableApi({ 
      id: briefDetails?._id,
      roleId: params.roleId,
      deliverableId: params.deliverableId
    });
    setRemoveRoleLoading(false);
    
    if (res?.data?.deliverableId) {
      toast.success(res?.data?.message, { id: '001' });  
      refreshBrief();
    }
    setRemoveRole(false);
  };

  const handleRemoveDeliverable = async (params) => {
    setRemoveRoleLoading(true);
    const res = await removeDeliverableApi({ 
      id: briefDetails?._id,
      deliverableId: params.deliverableId
    });
    setRemoveRoleLoading(false); // uses same modal buttons anyway
    
    if (res?.data?.deliverableId) {
      toast.success(res?.data?.message, { id: '001' });  
      refreshBrief();
    }
    setRemoveDeliverable(false);
  };

  const handleAddContributor = async () => {
    if (addContributorLoading === false) {
      setAddContributorLoading(true);
      const userRes = await getUserApi();
      const addRes = await addContributorsV2({
        briefId: getLastElementId(),
        userId: userRes?.data?.user[0]._id,
        firstName: userRes?.data?.user[0].firstName,
        lastName: userRes?.data?.user[0].lastName
      });
      setAddContributorLoading(false);
    }
  }

  return (
    <>
      <form className='briefs-edit-form'>
        <div className='row'>
        <div className='col-md-12 brief-collaborative-container'>
            <label className='form-label text-[24px]'>HQ Brief Notes</label>
            
            <RoomNotes>
              <CollaborativeEditor startedTyping={handleAddContributor} updateForm={(text) => {
                    setLiveBrief(liveBrief => ({
                      ...liveBrief,
                      briefNotes: text
                    }));
                  }} />
            </RoomNotes>
            {/* <InputErrorMessage error={formik2.touched.background && formik2.errors.background} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 brief-collaborative-container'>
            <label className='form-label text-[24px]'>How did this brief come about?</label>
            
            <RoomBg>
              <CollaborativeEditor startedTyping={handleAddContributor} updateForm={(text) => {
                    setLiveBrief(liveBrief => ({
                      ...liveBrief,
                      howDidThisBriefComeAbout: text
                    }));
                  }} />
            </RoomBg>
            {/* <InputErrorMessage error={formik2.touched.background && formik2.errors.background} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 brief-collaborative-container'>
            <label className='form-label text-[24px]'>What's the brief? Briefly.</label>
            <RoomBrief>
              <CollaborativeEditor startedTyping={handleAddContributor} updateForm={(text) => {           
                    setLiveBrief(liveBrief => ({
                      ...liveBrief,
                      whatsTheBrief: text
                    }));
                  }} />
            </RoomBrief>
            {/* <InputErrorMessage error={formik2.touched.inBrief && formik2.errors.inBrief} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 brief-collaborative-container'>
            <label className='form-label text-[24px]'>What challenges do we need to overcome?</label>
            
            <RoomGoal>
              <CollaborativeEditor startedTyping={handleAddContributor} updateForm={(text) => {
                    setLiveBrief(liveBrief => ({
                      ...liveBrief,
                      whatChallengesDoWeNeedToOvercome: text
                    }));
                  }} />
            </RoomGoal>
            {/* <InputErrorMessage error={formik2.touched.projectAmbition && formik2.errors.projectAmbition} marginBottom={-5} /> */}
            {/* <span className='d-block mt-2 text-sm text-muted text-end'>Maximum 20 characters</span> */}
          </div>
          <div className='col-md-12 brief-collaborative-container'>
            <label className='form-label text-[24px]'>What are we trying to achieve?</label>
                
            <RoomKpis>
              <CollaborativeEditor startedTyping={handleAddContributor} updateForm={(text) => {
                    setLiveBrief(liveBrief => ({
                      ...liveBrief,
                      whatAreWeTryingToAchieve: text
                    }));
                  }} />
            </RoomKpis>
            {/* <InputErrorMessage error={formik2.touched.kpis && formik2.errors.kpis} marginBottom={-5} /> */}
          </div>          
          <div className='col-md-12 brief-collaborative-container'>
            <label className='form-label text-[24px]'>Who are we trying to reach?</label>
            
            <RoomAudience>
              <CollaborativeEditor startedTyping={handleAddContributor} updateForm={(text) => {
                    setLiveBrief(liveBrief => ({
                      ...liveBrief,
                      whoAreWeTryingToReach: text
                    }));
                  }} />
            </RoomAudience>
          </div>
          <div className='brief-tag-content'>
            <h4>Subculture</h4>
            <div className='brief-tag-list'>
              
            {briefDetails.subculture?.map((culture, index) => {
              return (
                <div className='brief-badge' key={index}>
                  <p style={{marginRight: '8px', height: '20px'}}>{culture.subcultureName}</p>   
                  <img src={closeCrossIcon} onClick={() => {
                    setTagType('Subculture');
                    setSubcultureSelected({
                      subcultureId: culture.subcultureId
                    });
                    setRemoveTag(true);
                  }} />
                </div>
              )
            })}
            </div>
            <img src={plusIconGray} onClick={() => {
                setTagType('Subculture');
                setAddTag(true);
              }} />
          </div>
          <div className='brief-tag-content'>
            <h4>Audience Region</h4>
            <div className='brief-tag-list'>
              
            {briefDetails.audienceCountry ?.map((audience, index) => {
              return (
                <div className='brief-badge' key={index}>
                  <p style={{marginRight: '8px', height: '20px'}}>{audience.countryName}</p>   
                  <img src={closeCrossIcon} onClick={() => {
                    setTagType('Audience Region');
                    setAudienceSelected({
                      countryId: audience.countryId
                    });
                    setRemoveTag(true);
                  }} />
                </div>
              )
            })}
            </div>
            <img src={plusIconGray} onClick={() => {
                setTagType('Audience Region');
                setAddAudience(true);
              }} />
          </div>
          <div className='col-md-12 brief-collaborative-container'>
            <label className='form-label text-[24px]'>What’s the special sauce of this brand or product?</label>
            
            <RoomExp>
              <CollaborativeEditor startedTyping={handleAddContributor} updateForm={(text) => {
                    setLiveBrief(liveBrief => ({
                      ...liveBrief,
                      experience: text
                    }));
                  }} />
            </RoomExp>
            {/* <InputErrorMessage error={formik2.touched.experience && formik2.errors.experience} marginBottom={-5} /> */}
          </div>
          <h2 className='brief-deliverables-header'>Deliverables</h2>
         
          {deliverables && 
            
            deliverables.map((deliverable, i) => {

              function useOverrideRoomId() {
                const url = window.location.href;
                const urlArray = url.split("/");
                const room = urlArray[5]+`-${deliverable.deliverableId}`;
                return room;
              }

              const deliverableRoomId = useOverrideRoomId();

              let durationStartDate = deliverable.projectDeliverableStartDate ? new Date(moment(deliverable.projectDeliverableStartDate).format('YYYY-MM-DD')) : new Date(moment().format('YYYY-MM-DD'));
              let durationEndDate = deliverable.projectDeliverableDueDate ? new Date(moment(deliverable.projectDeliverableDueDate).format('YYYY-MM-DD')) : new Date(moment().add(1, 'd').format('YYYY-MM-DD'));;

              let displayStartDate = moment(deliverable?.projectDeliverableStartDate).format('MMM DD YYYY') || currDate;
              let displayEndDate = moment(deliverable?.projectDeliverableDueDate).format('MMM DD YYYY') || currDate;

              displayStartDate = displayStartDate.toUpperCase();
              displayEndDate = displayEndDate.toUpperCase();
              
              return (
                <div key={deliverable.deliverableId } className='col-md-12 mb-3'>
                  <div className='brief-deliverables-title'>
                    <div className='brief-deliverables-title-label'>
                      <label className='form-label text-[24px]'>{deliverable.deliverableName || 'New Deliverable' }</label>
                        <img src={editIcon} onClick={() => {
                          setCurrentDeliverable(deliverables[i]);
                          setEditName(true);
                        }} />
                      </div>
                      <div className='brief-deliverable-action-container'>
                        <img src={trashIcon} onClick={() => {
                          setCurrentDeliverable(deliverables[i]);
                          setRemoveDeliverable(true);
                        }} />
                      </div>
                  </div>
                  <div className='brief-deliverables-description'>
                    <RoomProvider
                      id={deliverableRoomId}
                      initialPresence={{
                        cursor: null,
                      }}
                    >
                      <ClientSideSuspense fallback={<Loading />}>
                        {() => <CollaborativeEditor startedTyping={handleAddContributor} updateForm={(text) => {
                            setLiveBrief(liveBrief => ({
                              ...liveBrief,
                              deliverables: deliverables.map((del => {
                                if (del.deliverableId === deliverable.deliverableId) {
                                  del.deliverableDescription = text;
                                }

                                return del;
                              }))
                            }));
                          }} />}
                      </ClientSideSuspense>
                    </RoomProvider>
                  </div>
                  
                  <div className='brief-deliverables-roles'>
                      <h3>Roles</h3>
                      <div className='brief-tag-list'>
                        {deliverable.role?.map((i, index) => {
                          return (
                            <div className='brief-badge' key={index}>
                              <p style={{marginRight: '4px', height: '20px'}}>{i?.roleName}</p>   
                              <img src={closeCrossIcon} id={i?.id} onClick={() => {
                                setRoleSelected({
                                  roleId: i.roleId,
                                  deliverableId: deliverable.deliverableId
                                });
                                setRemoveRole(true);
                              }}/>
                            </div>
                          );
                        })}
                      </div>
                      {
                        <img src={plusIconBlack} onClick={() => {
                          setCurrentDeliverable(deliverables[i]);
                          setAddRole(true);
                        }} />
                      }
                  </div>
                </div>
              )
            })
          }
        <AddDeliverableDivider loading={deliverableLoading} addDeliverable={addDeliverable}/>
        </div>
      </form>
      {editName &&  
        <SimpleModal title='Edit Delivery Name' closeModal={() => {setEditName(false)}} >
          <EditName briefId={briefDetails?._id} deliverable={currentDeliverable} closeModal={() => {
            setEditName(false);
            refreshBrief();
          }} />
        </SimpleModal>
      }
      {addRole &&  
        <SimpleModal title='Add Role' closeModal={() => {setAddRole(false)}} >
          <AddRole briefId={briefDetails?._id} deliverable={currentDeliverable} closeModal={() => {
            setAddRole(false);
            refreshBrief();
          }} />
        </SimpleModal>
      }
      {addTag &&  
        <SimpleModal title={`Add ${tagType}`} closeModal={() => {setAddTag(false)}} >
          <AddTag briefId={briefDetails?._id} tagType={tagType} closeModal={() => {
            setAddTag(false);
            refreshBrief();
          }} />
        </SimpleModal>
      }
      {addAudience &&  
        <SimpleModal title={`Add ${tagType}`} closeModal={() => {setAddAudience(false)}} >
          <AddAudience briefId={briefDetails?._id} closeModal={() => {
            setAddAudience(false);
            refreshBrief();
          }} />
        </SimpleModal>
      }
      
      {removeTag &&  
        <SimpleModal title={`Delete ${tagType}`} isWarning={true} noScroll={true} closeModal={() => {setRemoveTag(false)}} >
          <div className='row'>
            <div>
              <label className='form-label' style={{textAlign: 'center', width: '100%'}}>
                Are you sure you want to delete this item? This action cannot be undone.
              </label>
            </div>
            <div className='brief-button-container'>
              <button type='button' className='button-close' style={{width: '100%'}} onClick={() => {setRemoveTag(false)}}>
                Cancel
              </button>
              <button onClick={() => {
                if (tagType === 'Subculture') {
                  handleRemoveTag({
                    subcultureId: subcultureSelected.subcultureId
                  });
                } else {
                  handleRemoveAudience({
                    countryId: audienceSelected.countryId
                  });
                }
              }} className='button-warning ' disabled={removeTagLoading} style={{width: '100%'}}>
                {removeTagLoading ? (
                  <div className='text-xs text-center'>
                    <EditProfileLoader style={{}}/>
                  </div>
                ) : (
                  'Delete'
                )}
              </button>
            </div>
          </div>
        </SimpleModal>
      }
      {removeRole &&  
        <SimpleModal title='Delete Role' isWarning={true} noScroll={true} closeModal={() => {setRemoveRole(false)}} >
          <div className='row'>
            <div>
              <label className='form-label' style={{textAlign: 'center', width: '100%'}}>
                Are you sure you want to delete this item? This action cannot be undone.
              </label>
            </div>
            <div className='brief-button-container'>
              <button type='button' className='button-close' style={{width: '100%'}} onClick={() => {setRemoveRole(false)}}>
                Cancel
              </button>
              <button onClick={() => {
                handleRemoveRole({
                  roleId: roleSelected.roleId,
                  deliverableId: roleSelected.deliverableId
                });
              }} className='button-warning ' disabled={removeRoleLoading} style={{width: '100%'}}>
                {removeRoleLoading ? (
                  <div className='text-xs text-center'>
                    <EditProfileLoader style={{}}/>
                  </div>
                ) : (
                  'Delete'
                )}
              </button>
            </div>
          </div>
        </SimpleModal>
      }
      {removeDeliverable &&  
        <SimpleModal title='Delete Deliverable' isWarning={true} noScroll={true} closeModal={() => {setRemoveDeliverable(false)}} >
          <div className='row'>
            <div>
              <label className='form-label' style={{textAlign: 'center', width: '100%'}}>
                Are you sure you want to delete this item? This action cannot be undone.
              </label>
            </div>
            <div className='brief-button-container'>
              <button type='button' className='button-close' style={{width: '100%'}} onClick={() => {setRemoveDeliverable(false)}}>
                Cancel
              </button>
              <button onClick={() => {
                handleRemoveDeliverable({
                  deliverableId: currentDeliverable.deliverableId
                });
              }} className='button-warning ' disabled={removeRoleLoading} style={{width: '100%'}}>
                {removeRoleLoading ? (
                  <div className='text-xs text-center'>
                    <EditProfileLoader style={{}}/>
                  </div>
                ) : (
                  'Delete'
                )}
              </button>
            </div>
          </div>
        </SimpleModal>
      }
    </>
  );
}
