import React, { createContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { editBriefValidation, editBriefValidation2, editBriefValidation3 } from '../../../components/Validation';
import { v4 as uuidv4 } from 'uuid';
import {
  editBriefApi,
  getAllBriefApi,
  getBriefByIdApi,
  fetchCategoryApi,
  fetchClientApi,
  fetchEngagementTypeApi,
  fetchIndustryApi,
  fetchRegionApi,
  getMasherShareApi,
  getProjectByIdApi,
  editProjectApi,
} from '../../../apis/index.js';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import EditProject1 from './EditProject1.js';
import EditProject2 from './EditProject2.js';
import EditProject3 from './EditProject3.js';
import AdminLayout from '../../../components/admin-layout/index.js';
import { allClientData, getLastElementId } from '../../../utils/helperFunctions.js';
import { EditProjectContext } from '../../../appContext';

const EditBrief = () => {
  const [projectDetails, setprojectDetails] = useState({ loading: false, data: '' });

  const [step, setStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [briefData, setBriefData] = useState({ data: '', loading: false });
  const currDate = moment().format('YYYY-MM-DD');
  const [formSelects, setformSelects] = useState({ region: '', industry: '', engagementType: '', category: '', client: '', loading: false });
  const [masherData, setMasherData] = useState({ data: null, loading: false });
  const [brief, setBrief] = useState({ data: null, loading: false });
  const [value, setValue] = useState('');
  const [client, setClient] = useState({ data: null, loading: false });

  const searchFilter = { search: '', skills: '', minExperience: '', maxExperience: '', location: '', page: 1, limit: '', status: 'approved' };
  const projectId = getLastElementId();
  const navgate = useNavigate();
  const briefDetails = briefData?.data;

  const getAllBrief = async (search, startDate, dueDate, page, limit) => {
    try {
      const res = await getAllBriefApi(search, startDate, dueDate, page, limit);
      if (res.status === 200) {
        setBrief({ data: res.data.allBriefs });
      }
    } catch (error) {
      toast.error(error?.response);
    }
  };
  const getBriefById = async (id) => {
    setLoader(true);
    try {
      const res = await getBriefByIdApi(id);
      if (res.status === 200) {
        setLoader(false);
        setBriefData({ data: res.data.brief });
      }
    } catch (error) {
      setLoader(false);

      return error;
    }
  };
  const formik = useFormik({
    initialValues: {
      clientName: '',
      briefName: briefDetails.briefName,
      logo: briefDetails.logo,
      industry: briefDetails.industry,
      region: briefDetails.region,
      clientType: briefDetails.clientType,
      category: briefDetails.category,
      engagementStructure: briefDetails.engagementStructure,
    },
    validationSchema: editBriefValidation,
    onSubmit: (values) => {
      setStep(1);
    },
  });
  const formik2 = useFormik({
    initialValues: {
      inBrief: '',
      background: '',
      projectAmbition: '',
      brand: '',
      audience: '',
      kpis: '',
      experience: '',
    },
    validationSchema: editBriefValidation2,
    onSubmit: (values) => {
      setStep(2);
    },
  });
  const formik3 = useFormik({
    initialValues: {
      deliverableDetails: [
        { id: uuidv4(), name: '', startDate: currDate, dueDate: currDate, masherRole: '', delieveryFormat: '', order: '', description: '' },
      ],
      websiteLink: [''],
      budget: '',
      attachments: [{ name: '', file: '' }],
      currency: '',
      fileName: '',
      mashManager: '',
      id: '',
      projectFee: '',
      projectId: '',
      projectCurrency: '',
      team: [{ id: uuidv4(), masherName: '', currency: '', fees: '', userId: '' }],
    },
    validationSchema: editBriefValidation3,
    onSubmit: async (values) => {
      let alldata = { ...values, ...formik2.values, ...formik.values };
      const { value, label } = alldata.clientName;
      alldata.clientId = value;
      alldata.clientName = label;
      const {
        team,
        projectId,
        clientId,
        clientName,
        briefName,
        logo,
        industry,
        region,
        clientType,
        category,
        engagementStructure,
        inBrief,
        background,
        projectAmbition,
        brand,
        audience,
        kpis,
        experience,
        deliverableDetails,
        websiteLink,
        budget,
        attachments,
        currency,
        fileName,
        mashManager,
        id,
      } = alldata;

      const briefData = {
        team,
        id,
        clientId,
        clientName,
        briefName,
        logo,
        industry,
        region,
        clientType,
        category,
        engagementStructure,
        inBrief,
        background,
        projectAmbition,
        brand,
        audience,
        kpis,
        experience,
        deliverableDetails,
        websiteLink,
        budget,
        attachments,
        currency,
        fileName,
      };

      const projectData = { id: projectId, team, mashManager, projectFee: budget, projectCurrency: currency };

      try {
        setLoader(true);
        const projectApi = await editProjectApi(projectData);
        const briefApi = await editBriefApi(briefData);

        if (projectApi.status === 200 || projectApi.status === 201) {
          toast.success(projectApi.data.message, { id: '001' });
          setLoader(false);
          navgate('/');
        }

        if (briefApi.status === 200 || 201) {
          setLoader(false);
          navgate('/');
        }
      } catch (error) {
        setLoader(false);
      }
    },
  });

  const allMasherData = async (masherPayload) => {
    setMasherData({ loading: true });
    try {
      const res = await getMasherShareApi(masherPayload);
      if (res.status === 200) {
        setMasherData({ data: res.data.data, loading: false });
      }
    } catch (error) {
      setMasherData({ loading: false });
    }
  };

  const fetchSelectRegion = async () => {
    try {
      setformSelects({ loading: true });
      const selectResponse = {
        regionRes: await fetchRegionApi(),
        industryRes: await fetchIndustryApi(),
        engagementRes: await fetchEngagementTypeApi(),
        cateRes: await fetchCategoryApi(),
        clientRes: await fetchClientApi(),
      };

      setformSelects({
        loading: false,
        region: selectResponse?.regionRes.data,
        industry: selectResponse?.industryRes.data,
        engagementType: selectResponse?.engagementRes.data,
        category: selectResponse?.cateRes.data,
        client: selectResponse?.clientRes.data,
      });
    } catch (error) {
      toast.error(error.response.data.message, { id: 'error' });
    }
  };

  const fetchProjectDetailsById = async (id) => {
    try {
      setprojectDetails((v) => ({ ...v, loading: true, data: '' }));
      const res = await getProjectByIdApi(id);
      if (res.status === 200) {
        setprojectDetails((v) => ({ ...v, loading: false, data: res?.data }));
      } else {
        setprojectDetails((v) => ({ ...v, loading: false }));
        toast.error('Something went wrong.', { id: 'err01' });
      }
    } catch (error) {
      const message = error.response?.data?.message || error.response.statusText;
      toast.error(message, { id: 'err01' });
      setprojectDetails((v) => ({ ...v, loading: false }));
    }
  };

  useEffect(() => {
    formik.setValues({
      clientName: { value: briefDetails.clientId, label: briefDetails.clientName },
      briefName: briefDetails.briefName,
      logo: briefDetails.logo,
      industry: briefDetails.industry,
      region: briefDetails.region,
      clientType: briefDetails.clientType,
      category: briefDetails.category,
      engagementStructure: briefDetails.engagementStructure,
    });
    formik2.setValues({
      inBrief: briefDetails.inBrief,
      experience: briefDetails.experience,
      background: briefDetails.background,
      projectAmbition: briefDetails.projectAmbition,
      brand: briefDetails.brand,
      audience: briefDetails.audience,
      kpis: briefDetails.kpis,
    });
    const deliverableDetails = briefDetails.deliverableDetails ? briefDetails.deliverableDetails.map(e=>{
        e.masherRole = typeof (e.masherRole) == 'string' ? null : (e.masherRole);
      return e;
    }) : briefDetails.deliverableDetails;
    formik3.setValues({
      mashManager: projectDetails?.data?.project?.mashManager,
      projectFee: projectDetails?.data?.project?.projectFee,
      projectCurrency: projectDetails?.data?.project?.projectCurrency,
      team: projectDetails?.data?.project?.team,
      deliverableDetails: deliverableDetails,
      websiteLink: briefDetails.websiteLink,
      budget: briefDetails.budget,
      currency: briefDetails.currency,
      attachments: briefDetails?.attachments,
      id: briefDetails?._id,
      projectId: projectDetails?.data?.project?._id,
      fileName: briefDetails.fileName,
      // userId :
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [briefDetails, projectDetails]);

  useEffect(() => {
    if (projectDetails?.data?.project?.briefId?._id) {
      setValue({ value: projectDetails?.data?.project?.briefId?._id, label: projectDetails?.data?.project?.briefId?.briefName });
      getAllBrief();
      getBriefById(projectDetails?.data?.project?.briefId?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetails?.data?.project]);

  useEffect(() => {
    getAllBrief();
    if(value?.value){
      getBriefById(value?.value);
    }
  }, [value]);

  useEffect(() => {
    allMasherData('approved');
    fetchSelectRegion();
    allClientData({ ...searchFilter }, setClient);
    fetchProjectDetailsById(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <EditProjectContext.Provider
        value={{
          client,
          formik,
          formik2,
          formik3,
          setStep,
          loader,
          value,
          setValue,
          brief,
          formSelects,
          masherData,
          projectDetails,
          getAllBrief,
          getBriefById,
        }}>
        <AdminLayout>
          <div className='container create-brief'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/' className='blackOnHover'>
                    Home
                  </Link>
                </li>

                <li className='breadcrumb-item active' aria-current='page'>
                  Edit Project
                </li>
              </ol>
            </nav>

            {step === 0 && <EditProject1 />}
            {step === 1 && <EditProject2 />}
            {step === 2 && <EditProject3 />}
          </div>
        </AdminLayout>
      </EditProjectContext.Provider>
    </>
  );
};

export default EditBrief;
