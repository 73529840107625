import { Link } from 'react-router-dom';
import { useState } from 'react';
import ShareBriefModal from '../Popups/ShareBriefModal';
import { Highlight} from 'react-instantsearch';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import moment from 'moment';
import toast from 'react-hot-toast';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import CountryBadge from '../badges/CountryBadge';
import Tag from '../tags/Tag';
import ProfilePreview from '../../Pages/MasherProfileView';
import { ThreeDots,PencilSquare,Trash3,ArrowRepeat } from 'react-bootstrap-icons';
import { MasherStatusChangeApi } from '../../apis';
import { EditProfileLoader } from '../common/loader';

export default function MashCard({ hit,currentid, setcurrentid,refreshmasher }) {
  const {skills} = hit;
  const isNewMasher = moment(hit.createdAt) > new Date(new Date().setDate(- 30));
  const [openBriefModal, setOpenBriefModal] = useState(false);
  const handleModal = () => {
    setOpenBriefModal(!openBriefModal);
  };

  // const getRoleLabels = (roles) => {
  //   let roleLabelsArray = [];
  //   if (roles) {
  //     roles?.map(role => {
  //       roleLabelsArray.push(role.label);
  //     });
  //   } else {
  //     roleLabelsArray.push('No roles selected by Masher');
  //   }
  //   return roleLabelsArray.join(' / ');
  // };
  const[statusload,setstatusload]=useState(false);
  const statuschange=async(masherid,status)=>{
    try{
      setstatusload(true)
      const res = await MasherStatusChangeApi({
          "status": status,
          "masherId":masherid
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message, { id: '001' }); 
        //refreshmasher();
        location.reload();
      } else {
        console.log('Retrying..');      
        toast.error(res?.data?.message, { id: '001' }); 
      }
    } catch(e) {
      toast.error(e?.response?.data?.error, { id: '001' }); 
      console.log('Retrying..');      
    }
    finally{
      setstatusload(false);
    }
  }
  const getRoleLabels = (roles) => {
    let roleLabelsSet = new Set();
    if (roles) {
      roles.forEach(role => {
        roleLabelsSet.add(role.label);
      });
    } else {
      roleLabelsSet.add('No roles selected by Masher');
    }
    return Array.from(roleLabelsSet).join(' / ');
  };
  const [editpopup, seteditpopup] = useState(false);
  return (
    <div className='search-card-list-items'>
     <div className="absolute flex justify-end text-black right-corner cursor-pointer" style={{ zIndex: 999 }}
          onClick={() => { seteditpopup(!editpopup) }}
        >
          <ThreeDots size={20} />

        </div>
        
       
        {
          editpopup &&
          <div className="absolute flex justify-end right-corner cursor-pointer">
            <div className='popupcontainer shadow-md rounded-sm p-2'>
              {
                (hit?.status==="approved") ? 
                (
                  <>
                  {
                    (statusload) ? (
                      <div className='flex items-center justify-center gap-2' onClick={() => { statuschange(hit._id,'archived') }}>
                      <div className='text-sm font-medium text-gray-700'><EditProfileLoader /></div>
                    </div>
                    ):(
                      <div className='flex items-center justify-start gap-2' onClick={() => { statuschange(hit._id,'archived') }}>
                  {/* <div><Trash3 /></div> */}
                  <div className='text-sm font-medium text-gray-700'>Archive</div>
                </div>
                    )
                  }
                </> 
                ):(
                  <>
                  {
                    (statusload) ? (
                      <div className='flex items-center justify-center gap-2' onClick={() => { statuschange(hit._id,'archived') }}>
                      <div className='text-sm font-medium text-gray-700'><EditProfileLoader /></div>
                    </div>
                    ):(
                      <div className='flex items-center justify-start gap-2' onClick={() => { statuschange(hit._id,'unarchived') }}>
                      {/* <div><ArrowRepeat /></div> */}
                      <div className='text-sm font-medium text-gray-700'>Restore</div>
                    </div>
                    )
                  }
                </> 
                   
                )
              }
             
               
            </div>
          </div>
        }
      <div className="search-card-user-container" onClick={()=>{seteditpopup(false)}}>
        <div className="search-card-user-header">
          <button 
            data-bs-toggle="modal"
            data-bs-target="#profilePreview"
            id="profilePreview_open"
            onClick={()=>{setcurrentid(hit._id);}}
          >
            <div className="search-card-image-container">
              <p
                style={{backgroundImage: `url(${hit.userId?.profilePic?.split(' ').join('%20') || hit.profilePic?.split(' ').join('%20') || ProfileDefault})`, backgroundSize: 'cover'}}
                className="search-card-image"
              ></p>
            </div>
          </button>
        </div>
        <div className="search-card-masher-details">
          <div className="search-card-masher-line-one">
            <span>
              <button 
                data-bs-toggle="modal"
                data-bs-target="#profilePreview"
                id="profilePreview_open"
                onClick={()=>{setcurrentid(hit._id);}}
                className='search-card-masher-name'
              >{hit.userId?.firstName ? 
                  `${hit.userId.firstName} ${hit.userId.lastName}` : 
                  <>
                    <Highlight classNames={{ highlighted: 'search-highlighted' }} attribute="firstName" style={{marginRight: '5px'}} hit={hit} /> 
                    <Highlight classNames={{ highlighted: 'search-highlighted' }} attribute="lastName" hit={hit} />
                  </>} 
              </button>
            </span>
            {isNewMasher && <span className="search-card-new-masher">NEW</span>}
          </div>
          { <div className='search-card-masher-roles'>{getRoleLabels(hit.roles)}</div>}
          <div className="search-card-masher-line-two">
            { hit.country &&<CountryBadge backgroundColor={'#F0F9FF'} title={hit.country}/>}
          </div>
        </div>
        <div className='search-card-masher-footer'>
          {!skills ?
            ''
            : hit.skills?.map((skill, i) => {
              return (
                <Tag title={skill.label} key={i}/>
              );
            })
          }
        </div>
      </div>

      <div className="search-card-right" >
        {openBriefModal && <ShareBriefModal masher={hit} handleModal={handleModal} />}
      </div>
    </div>
  );
}