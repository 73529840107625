"use client";
import * as React from 'react';
import { ReactNode } from "react";
import { RoomProvider } from "./liveblocks.config";
import { ClientSideSuspense } from "@liveblocks/react";
import { Loading } from "./components/Loading";

export function RoomBg({ children }: { children: ReactNode }) {
  const roomId = useOverrideRoomId();
  
  return (
  <>
    <RoomProvider
      id={roomId}
      initialPresence={{
        cursor: null,
      }}
    >
      <ClientSideSuspense fallback={<Loading />}>
        {() => children}
      </ClientSideSuspense>
    </RoomProvider>
	</>
  );
}

/**
 * This function is used when deploying an example on liveblocks.io.
 * You can ignore it completely if you run the example locally.
 */
function useOverrideRoomId() {
  const url = window.location.href;
  const urlArray = url.split("/");
  const room = urlArray[5]+"-bg";
  return room;
}
