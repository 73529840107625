import React, { createContext, useContext,useEffect, useState } from 'react';
import { AppContext } from './../../appContext';
import { WeavyClient, WeavyProvider, Chat, MessengerContext, MessengerProvider, ConversationList, Conversation } from '@weavy/uikit-react';
import axios from 'axios';
import { Loader } from '../../components/common/loader';
import moment from 'moment';
import '@weavy/uikit-react/dist/css/weavy.css';
import { weavyTokenApi } from '../../apis';
import { Link } from 'react-router-dom';
import Notification from './Notification';

const NotificationListV2 = () => {
  let fname, lname,username,notiCount,weavyClient,briefdetails;
  const accessToken = process.env.REACT_APP_WEAVY_API_KEY;
  const tsKey = process.env.REACT_APP_TYPESENSE_API_KEY;
  const tsHost = process.env.REACT_APP_HOST_NAME;
  const weavyUrl = process.env.REACT_APP_WEAVY_URL;
  const [notifications, setNotifications] = useState([]);
  const [usertoken, setToken] = useState('');
  const [notificationsLoader, setNotiLoader] = useState(true);
  const [notificationsEmpty, setNotiEmpty] = useState(false);
  const [briefLink, setBriefLink] = useState([]);
  const appContext = useContext(AppContext);
  const state = appContext;
  const { loggedInUser } = state;
  fname = loggedInUser?.data[0]?.firstName;
  lname = loggedInUser?.data[0]?.lastName;
  username = fname+'-'+lname;
  username = username.replace(/\s+/g, '');
  const getWeavyToken = async (userfname) =>{
    try {
      const values = {firstname: userfname};
      const res = await weavyTokenApi(values);
      if (res.status === 200 || 201) {
        console.log(res);
        return res.data;
      }
      if(userfname === undefined){
        
        console.log('user name undefined');
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getNotifications = async (userfname) =>{
    try {
      const token = await getWeavyToken(userfname);
      setToken(token);
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: weavyUrl+'/api/notifications?order_by=id+desc&unread=true',
        headers: { 
          'Authorization': 'Bearer '+token,
          'Content-Type': 'application/json',
        }
      };
      axios.request(config)
        .then(response => {
          notiCount=response.data.count;
          if(notiCount == 0){
            setNotiEmpty(true);
            setNotiLoader(false);
          }
          setNotifications(response.data.data);
          setNotiLoader(false);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getNotifications(username);
  },[username]);
  return(
    <>
      <div className='card'>
        {notificationsLoader &&
        <div className='card-header'>
          <div className='d-flex align-items-center'>
            <div className='text-center align-items-center p-4 pt-12'>
              <Loader />
            </div>
          </div>
        </div>
        }
        <div className='card-body py-0 position-relative scrollable-y' style={{ maxHeight: '50vh' }}>
          <div className='list-group list-group-flush'>
            {notifications && notifications.length > 0 && notifications.map((n: any) => {                        
                        return (
                            <Notification key={`notification-${n.id}`} id={n.id} text={n.plain} entityId={n.link.id} usertoken={usertoken}/>
                            
                        )
                    })}
                    {(notifications && notifications.length === 0 || !notifications) &&
                        <p className="text-muted text-center my-3"><small>No unread notifications</small></p>
                    }
          </div>
        </div>
      </div>
      <br/>
    </>
  );
};

export default NotificationListV2;