import React from 'react';
import DashboardSidenav from '../Sidebar/Sidebar';

const AdminLayout = ({ children }) => {
  const urlPathLocation = window.location.pathname;
  return (
    <div className='d-flex flex-column flex-lg-row h-lg-full'>
      <DashboardSidenav />
      <div className='flex-lg-1 h-screen overflow-y-lg-auto'>
        {
          urlPathLocation === '/briefs/create-new-briefs' ||
          urlPathLocation === '/briefs/create-new-briefs-next'
        }
        <div className={'content !mt-16 md:!mt-0 overflow-hidden'}>{children}</div>
      </div>

    </div>
  );
};
export default AdminLayout;
