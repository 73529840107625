import React, { useEffect, useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { SearchBox, InstantSearch, Hits, useInstantSearch, useSearchBox, Configure } from 'react-instantsearch';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Avatar from '../../../../components/Avatar/Avatar';
import { Loader } from '../../../../components/common/loader';
import { InputErrorMessage } from '../../../../components/errorMessages';
import { assignMasherToRoleApi } from '../../../../apis/index';
import { EditProfileLoader } from '../../../../components/common/loader';
import chevronDown from '../../../../Icons/chevronDown.svg';
import tickGreen from '../../../../Icons/tickGreen.svg';

export default function AssignMasherToRole({ briefId, roleId, deliverable, closeModal, searchClient, related }) {
  const [updating, setUpdating] = useState(false);
  const { deliverableId } = deliverable;
  const [loading, setLoading] = useState(false);
  const [resultsOpen, setResultsOpen] = useState(false);
  const [assigned, setAssigned] = useState<any>([]);
  useEffect(() => {
    if (!deliverable.role[deliverable.role.findIndex((r) => r.roleId === roleId)].Masher) {
      return;
    }
    const existing = deliverable.role[deliverable.role.findIndex((r) => r.roleId === roleId)].Masher?.map((m) => {
      return {
        _id: m.masherId,
        firstName: m.masherName,
        lastName: '',
        exStatus:m.status
      };
    });
     setAssigned(existing);
    
  }, []);

  const formik = useFormik({
    initialValues: {
      roleId: '',
    },
    // validationSchema: addRoleSchema,
    onSubmit: async (_v, {setErrors}) => {
      try {
        setUpdating(true);
        if (assigned.length < 1 ) {
          setErrors({
            roleId: 'Must be at least 1 masher.'
          });
          setUpdating(false);
          return;
        }

        const res = await assignMasherToRoleApi({
          deliverableId,
          id: briefId,
          roleId,
          // masher: {
          //   masherId: assigned[0]._id,
          //   masherName: `${assigned[0].firstName} ${assigned[0].lastName}`
          // }
          mashers: assigned
        });
        setUpdating(false);

        if (res.status === 200 || 201) {
          toast.success(res?.data?.message, { id: '001' });          
          closeModal();
        } else {

        }
      } catch(e) {
        closeModal();
      }
    },
  });

  const onAddToAll = async () => {
    console.log("Add to all")
    try {
      setUpdating(true);
      if (assigned.length < 1) {
        formik.setErrors({
          roleId: 'Must be at least 1 masher.'
        });
        setUpdating(false);
        return;
      }
  
      // Include the current deliverable in the list of updates\
      const toUpdate = [...related]
      toUpdate.push(deliverable);
      console.log(toUpdate)
  
      // Prepare data for API call
      const updates = toUpdate.map(item => {
        // Extract masher IDs already assigned to roles in this item
        const existingMashers = item.role?.flatMap(role => role.Masher?.map(m => m.masherId));
        // Map assigned to masherIds for comparison and filter out already assigned
        const newAssignedIds = assigned
                                ?.map(a => a._id) // Extract masherId from assigned
                                .filter(masherId => !existingMashers.includes(masherId));
  
        // Filter assigned array to include only new mashers
        const newAssigned = assigned.filter(a => newAssignedIds.includes(a._id));
  
        return {
          deliverableId: item.deliverableId,
          id: briefId,
          roleId,
          mashers: newAssigned // Pass the filtered list of new mashers
        };
      }).filter(update => update.mashers.length > 0);
      // Execute API calls for each related deliverable with a delay
      console.log("updates",updates)
      for (const update of updates) {
        await assignMasherToRoleApi(update);
        await delay(1000); // Delay of 1000 ms (1 second) between requests
      }
  
      setUpdating(false);
  
      // Check if all updates were successful (you might need to adjust this logic)
      toast.success('Roles successfully assigned to all related deliverables', { id: '001' });
      closeModal();
  
    } catch (e) {
      console.log(e)
      setUpdating(false);
      closeModal();
    }
  }

  const SearchResults = () => {    
    const { results, error } = useInstantSearch();
    // setResultsOpen(results.hits?.length > 0 && results.query !== '*');
    return (
      <div className='assign-masher-to-role-results-container'>
        {
          results.hits?.length > 0 && results.query !== '*' ?          
          <div className='assign-masher-to-role-results'>
            {
              results.hits?.map((hit, i) => {            
                const fullName = `${hit.firstName} ${hit.lastName}`;
                const exists = assigned.find(a => results?.hits[i]._id === a._id);
                return (
                    <div className='assign-masher-to-role-results-row' id={hit._id}>
                      <div className='assign-masher-to-role-results-item'>
                        <div className='assign-masher-to-role-results-checkbox'>
                          <input type='checkbox' checked={exists} onClick={(e) => {                          
                            if (e.currentTarget.checked) {
                              setAssigned((oldAssigned) => {
                                oldAssigned.push(results?.hits[i]);
                                return oldAssigned;
                              });
                            } else {
                              setAssigned((oldAssigned) => {
                                const filtered = oldAssigned.filter((masher) => {
                                  return masher._id !== results?.hits[i]._id;
                                });
                                return filtered;
                              });
                            }
                          }} />
                        </div>
                        <div className='assign-masher-to-role-results-profilepic'>
                        {
                          hit.profilePic && 
                            <div className='avatar-container' style={{border: `'2px' solid 'white'`, backgroundColor: 'white', minWidth: '40px'}}>
                              <div className='avatar-url'>
                                {/* <img src={avatarUrl}/> */}
                                <p
                                  style={{backgroundImage: `url(${hit.profilePic?.split(' ').join('%20')})`, backgroundSize: 'cover'}}
                                  className="search-card-image"
                                ></p>
                              </div>
                            </div>
                        }
                        {
                          !hit?.profilePic && 
                          <Avatar name={fullName} id={hit._id}/>
                        }
                        </div>
                        <div className='assign-masher-to-role-results-name'>
                          {fullName}
                        </div>
                      </div>
                      <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {hit?.skills && 
                          hit?.roles?.map((role, i) => <p key={i} className='assign-masher-to-role-results-skills'>{role.label}</p>)}
                        {hit?.country && <p className='assign-masher-to-role-results-country'>
                          {hit.country}
                        </p>}
                      </div>
                    </div>
                );
              })
            }
          </div> :
          // <p style={{ textAlign: 'center'}}>No results</p>
            <></>
        }
      </div>
    );
  }

  const MasherSearchBox = (props) => {
    const {
      query,
      refine,
      clear
    } = useSearchBox(props);

    const inputRef = useRef(null);

    const [inputValue, setInputValue] = useState(query);
  
    function setQuery(newQuery) {
      setInputValue(newQuery);
  
      refine(newQuery);
    }
    return (
      <div className='assign-masher-to-role-search-box'>
        <form
          className={`assign-masher-to-role-search-box-form${inputValue ? '-opened' : ''}`}
          onReset={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setQuery('');
            setAssigned((assigned) => assigned);
            if (inputRef.current) {
              // inputRef?.current?.focus();
            }
          }}
          >
            <div className='assign-masher-to-role-search-info'>
              <div style={{display: 'flex'}}>

              {assigned.map(i => {
                return (
                  <div className='search-badge' key={i._id}>{`${i.firstName} ${i.lastName}`}</div>
                );
              })}
              </div>
              <div className='assign-masher-to-role-search-box-chevron'>
                {
                  inputValue ? <button type='reset' onClick={() => setQuery('')}><img src={tickGreen} /></button> : <img src={chevronDown} />
                }
              </div>
            </div>
          <input
            ref={inputRef}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            type='search'
            name='roleId'
            className='assign-masher-to-role-search-box-custom-search'
            value={inputValue}
            onChange={(e) => {
              setQuery(e.currentTarget.value);
            }} />             
        </form>
      </div>
    );
  }

  return (
    <>    
    {loading ? (
        <div className='text-xs text-center'>
          <Loader style={{}}/>
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className='assign-masher-to-role-search-container'>
            <InstantSearch indexName="masherWithUser" searchClient={searchClient}>
              <div className='assign-masher-to-role-search-box'>
                <MasherSearchBox />
              </div>
              <SearchResults />
            </InstantSearch>
          </div>
          <InputErrorMessage error={formik.touched.roleId && formik.errors.roleId} marginBottom={-5} />
          {related.length > 0 &&
            <div className="flex justify-between w-full items-end">
              <div className="">            
                <p className="font-bold">Related deliverables with same role:</p>
                <ul className="pl-4" style={{listStyleType: 'disc'}}>
                  {related.map((deliverable, i) => <li key={i}>{deliverable.deliverableName}</li>)}
                </ul>
              </div>

              <div>
                <button  disabled={updating} className="h-4 py-0" onClick={(e) => {e.preventDefault(); onAddToAll()}}>
                  {updating ? (
                    <div className='text-xs text-center'>
                      <EditProfileLoader />
                    </div>
                  ) : (
                    'Add to all'
                  )}
                </button>
              </div>
            </div>
          }
          <div className='brief-button-container'>
            <button type='button' className='button-close' style={{width: '100%'}} onClick={closeModal}>
              Cancel
            </button>
            <button type='submit' className='button-primary' disabled={updating} style={{width: '100%'}}>
              {updating ? (
                <div className='text-xs text-center'>
                  <EditProfileLoader />
                </div>
              ) : (
                'Add'
              )}
            </button>
           
          </div>
          
        </form>
      )
    }
    </>
  );
}

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));