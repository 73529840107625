import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { referMasherApi } from '../../apis';
import { InputErrorMessage } from '../../components/errorMessages';
import { InviteMasherValidation } from '../../components/Validation';
import { LIVE_BASE_URL } from '../../utils/baseurl';

export default function InviteSharePopup({ userId }) {
  let invitation = 'Hey, you are invited to register as a Masher ';
  let modal = document.querySelector('#modalExport');
  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    validationSchema: InviteMasherValidation,
    onSubmit: async (values) => {
      try {
        const res = await referMasherApi({ ...values, msg: invitation + values.msg });
        if (res.status === 200) {
          toast.success(res?.data?.message, { id: '001' });
          formik?.resetForm();
          modal.click();
          
        }
      } catch (error) {
        toast.error(error.response.data.message, { id: '002' });
      }
    },
  });
  useEffect(() => {
    userId && formik.setFieldValue('msg', `${LIVE_BASE_URL}/sign-up?ref=${userId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleChange = (e) => {
    formik.handleChange(e);
  };

  return (
    <>
      <section className=''>
        <div className='modal fade' id='modalExport' tabIndex={-1} aria-labelledby='modalExport' aria-hidden='true'>
          <div className='modal-dialog vh-100'>
            <form onSubmit={formik.handleSubmit} className='justify-content-center align-items-center d-flex vh-100'>
              <div className='modal-content shadow-3'>
                <div className='modal-header'>
                  <div className='icon icon-shape bg-white rounded-3 bg-soft-primary text-primary text-lg me-4 '>
                    <big>
                      <big>👋</big>
                    </big>
                  </div>
                  <div>
                    <h5 className='mb-1'>Invite New Masher</h5>
                    <small className='d-block text-xs text-muted'>Publish and share link</small>
                  </div>
                  <div className='ms-auto'>
                    <i className='bi bi-x-lg cursor-pointer' data-bs-dismiss='modal'></i>
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='mb-4'>
                    {/* <label className='form-label fs-12 mb-4'>Enter their email address/s below</label> */}

                    <div className=' mb-5'>
                      <div className='input-group input-group-inline'>
                        <input
                          onChange={handleChange}
                          name='msg'
                          value={formik.values.msg}
                          type='text'
                          className='form-control'
                          placeholder='Message'
                          disabled
                        />

                        <span className='input-group-text cursor-pointer'>
                          <CopyToClipboard text={formik.values.msg} onCopy={() => toast.success('Copied Successfully!')}>
                            <i className='bi bi-clipboard' />
                          </CopyToClipboard>
                        </span>
                      </div>
                      <InputErrorMessage error={formik.touched.msg && formik.errors.msg} marginBottom={-5} />
                    </div>
                    <label className='form-label'>First Name</label>
                    <div className='input-group '>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter first name'
                        name='firstName'
                        value={formik.values.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <InputErrorMessage error={formik.touched.firstName && formik.errors.firstName} marginBottom={-5} />
                    <label className='form-label'>Last Name</label>
                    <div className='input-group '>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter last name'
                        name='lastName'
                        value={formik.values.lastName}
                        onChange={handleChange}
                      />
                    </div>
                    <InputErrorMessage error={formik.touched.lastName && formik.errors.lastName} marginBottom={-5} />
                    <label className='form-label'>Email</label>
                    <div className='input-group '>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter email'
                        name='email'
                        value={formik.values.email}
                        onChange={handleChange}
                      />
                    </div>
                    <InputErrorMessage error={formik.touched.email && formik.errors.email} marginBottom={-5} />
                  </div>
                </div>
                <div className='modal-footer justify-content-center'>
                  <button type='submit' className='btn btn_primary_black px-10'>
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
