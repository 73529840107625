// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basic-input {
  font-family: 'Open Sans', sans-serif;
}
.basic-input-form-label {
  font-weight: 600;
  font-size: 13px;
  color: #667085;
  line-height: 19.07px;
  margin-bottom: 8px
}
.basic-input-form-input {
  width: 100%;
  height: 38px;
  border: 1px solid #EDEAE1;
  padding: 8px;
  border-radius: 4px;
  color: #667085;
  background-color: transparent;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/inputs/basicInput.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,oBAAoB;EACpB;AACF;AACA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,6BAA6B;EAC7B,eAAe;AACjB","sourcesContent":[".basic-input {\n  font-family: 'Open Sans', sans-serif;\n}\n.basic-input-form-label {\n  font-weight: 600;\n  font-size: 13px;\n  color: #667085;\n  line-height: 19.07px;\n  margin-bottom: 8px\n}\n.basic-input-form-input {\n  width: 100%;\n  height: 38px;\n  border: 1px solid #EDEAE1;\n  padding: 8px;\n  border-radius: 4px;\n  color: #667085;\n  background-color: transparent;\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
