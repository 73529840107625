
import React from 'react';
import './pageHeader.css';

function PageHeaderAvatar({userAvatar, title, buttonsArray, description}) {
  return (
    <div className='page-header flex-wrap md:!flex-nowrap gap-4'>
      <div className='page-header-avatar-container'>
        {userAvatar ? 
          <label className='page-header-avatar' 
            style={{
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage:`${`url(${userAvatar})`}`
            }}></label> 
          : null
        }
      </div>  
      <div className='page-header-right'>
        
        <div>
          <h2 style={{fontSize: '32px'}}>{title}</h2>
          <p>{description}</p>

        </div>

      </div>
      {buttonsArray && 
      <div className='page-header-left'>
        {buttonsArray?.map((link, i) => {
          return <div key={i}>{link}</div>;
        })}
      </div>
      }
    </div>
  );
}

export default PageHeaderAvatar;