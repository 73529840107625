import React from 'react';

const OtherContactsModal = ({ tableData = [] }) => {
  return (
    <div className='modal fade' id='other_contacts' tabIndex={-1} aria-labelledby='otherContacts' aria-hidden='true'>
      <div className='modal-dialog modal-lg vh-100'>
        <div className='modal-content shadow-3'>
          <div className='modal-header '>
            <div>
              <h5 className='mb-1'>Other Contacts</h5>
            </div>
            <div className='ms-auto'>
              <i className='bi bi-x-lg cursor-pointer' data-bs-dismiss='modal'></i>
            </div>
          </div>
          <div className='modal-body'>
            <div className='table-responsive'>
              <table className='table table-hover table-nowrap'>
                <thead className='table_gray_light'>
                  <tr>
                    <th scope='col' >
           Name
                    </th>
                    <th scope='col' >
           Email
                    </th>
                    <th scope='col' >
           Designation
                    </th>
                    {/* <th scope='col' >
           PhoneCode
          </th> */}
                    <th scope='col' >
           Phone Number
                    </th>
                    <th scope='col'></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((el, i) => {
                    return (
                      <tr key={'tr_'+i}>
                        <td>{el?.clientName || '--'}</td>
                        <td>{el?.clientEmail || '--'}</td>
                        <td>{el?.clientDesignation || '--'}</td>
                        <td>{ (el?.clientPhoneCode?.value || '') + ' ' + (el?.clientPhoneNo || '--')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' data-bs-dismiss='modal' className='btn btn-sm btn_primary_black' onClick={() => { }}>
       Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherContactsModal;
