import React, { useState } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { InputErrorMessage } from '../../../../components/errorMessages';
import { editDeliverableNameApi } from '../../../../apis/index';
import { EditProfileLoader } from '../../../../components/common/loader';

export default function EditName({ briefId, deliverable, closeModal }) {
  const [updating, setUpdating] = useState(false);
  const { deliverableName, deliverableId } = deliverable;
  
  const deliveryNameSchema = yup.object({
    deliverableName: yup.string().required()
  });

  const formik = useFormik({
    initialValues: {
      deliverableName: deliverableName || '',
    },
    validationSchema: deliveryNameSchema,
    onSubmit: async (values) => {
      try {
        setUpdating(true);
        const res = await editDeliverableNameApi({
          deliverableId,
          id: briefId,
          deliverableName: values.deliverableName
        });
        setUpdating(false);

        if (res.status === 200 || 201) {
          toast.success(res?.data?.message, { id: '001' });          
          closeModal();
        } else {

        }
      } catch(e) {
        closeModal();
      }
    },
  });
  
  const handleChange = (e) => {
    formik.handleChange(e);
  };

  return (    
    <form onSubmit={formik.handleSubmit}>
      <div className='row'>
        <div>
          <label className='form-label'>
            Deliverable Name
          </label>
          <input
            type='text'
            className={Boolean(formik.touched?.deliverableName) && Boolean(formik.errors?.deliverableName) ? 'form-control border-danger' : 'form-control '}
            placeholder='Edit Deliverable Name'
            name='deliverableName'
            value={formik.values.deliverableName}
            onChange={handleChange}
          />
          <InputErrorMessage error={formik.touched.deliverableName && formik.errors.deliverableName} marginBottom={-5} />
        </div>
        <div className='brief-button-container'>
          <button type='button' className='button-close' style={{width: '100%'}} onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' className='button-primary' disabled={updating} style={{width: '100%'}}>
            {updating ? (
              <div className='text-xs text-center'>
                <EditProfileLoader />
              </div>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
    </form>
  );
}
