import React, { useContext, useEffect, useRef, useState } from 'react';
import AdminLayout from '../../components/admin-layout';
import UserDefault from '../../Assets/img/covers/userDefault.png';
import PageHeader from '../../components/pageElements/pageHeader/PageHeader';
import { AppContext } from '../../appContext';
import { useFormik } from 'formik';
import { updateUserApi, uploadProfileImgApi } from '../../apis';
import { updateUserValidation } from '../../components/Validation';
import { InputErrorMessage } from '../../components/errorMessages';
import toast from 'react-hot-toast';
import { EditProfileLoader } from '../../components/common/loader';
import { assignBucketUrl } from '../../utils/helperFunctions';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';

export default function EditProfile() {
  const [loader, setLoader] = useState(false);
  const appContext = useContext(AppContext);
  const { state, fetchUserProfile } = appContext;
  const { loggedInUser } = state;
  const [profile, setProfile] = useState('');
  const [, setImage] = useState('');
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleFilechange = async (e) => {
    const loadingToast = toast.loading('File Uploading...');

    let file = e.target.files[0];
    const type = file.type.split('/')[1];
    const imageType = ['jpeg', 'jpg', 'png'];
    const validImageType = imageType.includes(type);

    if (!validImageType) {
      formik.setFieldError('profilePic', 'Please upload a valid image : jpeg, png, jpg');
      toast.dismiss(loadingToast);
    } else {
      if (file.size > 5000001) {
        formik.setFieldError('profilePic', 'image size is more than 5 MB');
        toast.dismiss(loadingToast);
        setProfile('');
      } else {
        let fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = function () {
          setProfile(fr.result);
        };
      }
    }
    const formdata = new FormData();
    formdata.append('file', file);
    try {
      const response = await uploadProfileImgApi(formdata);
      if (response?.status === 200) {
        toast.success(response.data.message, { id: '001' });
        toast.dismiss(loadingToast);
      }
      setImage(response.data.filePath);
      formik.setFieldValue('image', response.data.filePath);
    } catch (err) {
      toast.success(err?.response?.message, { id: '001' });
      toast.dismiss(loadingToast);
    }
  };

  const formik = useFormik({
    initialValues: { name: '', email: '', image: '' },
    validationSchema: updateUserValidation,
    onSubmit: async (values) => {
      setLoader(true);
      try {
        const { name, image } = values;
        const firstName = name?.split(' ')[0] || '';
        const lastName = name?.split(firstName)[1] || '';
        const payload = { firstName, lastName };
        if (image) {
          payload.profilePic = image;
        }
        const res = await updateUserApi(payload);
        if (res.status === 200) {
          toast.success(res.data.message, { id: '001' });
          fetchUserProfile();
          setLoader(false);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, { id: '001' });
        setLoader(false);
      }
    },
  });

  useEffect(() => {
    if (loggedInUser?.data[0]?._id) {
      formik.setValues({
        name: loggedInUser?.data[0]?.firstName + loggedInUser?.data[0]?.lastName,
        email: loggedInUser?.data[0]?.email,
        image: loggedInUser?.data[0]?.filePath,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  return (
    <AdminLayout>
      <div className='px-5 py-5 p-lg-0 min-h-screen '>
        <PageHeader 
          title='Edit Profile'
        />
        <div className='d-flex justify-content-center'>
          <div className='container-fluid'>
            <div className='col-md-12 mb-8'>
              <div className='edit-profile-head'>
               
                <label htmlFor='profile' className='form-label fs-base'>
                  Profile Image{' '}
                  <small className='text-muted '>
                    <br />(<b>OPTIONAL</b>: upload only JPEG, PNG, JPG )
                  </small>
                </label>
              </div>
            </div>
            <div className='col-md-6'>
              <input
                value={formik?.values?.profilePic}
                id='profileImage'
                type='file'
                accept='image/png, image/jpeg, image/jpg'
                name='profile'
                className='form-control'
                onChange={handleFilechange}
                hidden={true}
              />
              <div className='text-danger'>{formik.errors.profilePic}</div>
            </div>
            <div className='center-div-tab'>
              <div className='col-12 m-4'>
                <div className='row'>
                  <div className='d-flex'>
                    <div style={{ width: '100px', height: '100px' }} className='edit-avtar rounded-circle d-inline'>
                      <label
                        className='col-sm-4 bg-repeat-0 bg-position-center bg-size-cover'
                        htmlFor='profileImage'
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          position: 'relative',
                          width: '80px',
                          height: '80px',
                          border: '1px solid rgb(25, 19, 19, 0.15)',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                          backgroundImage: `${
                            profile
                              ? `url(${encodeURI(profile)})`
                              : Boolean(loggedInUser?.data[0]?.profilePic)
                                ? `url(${assignBucketUrl(loggedInUser?.data[0]?.profilePic)})`
                                : `url(${UserDefault})`
                          }`,
                        }}>
                        <div className='edit-pen'>
                          <i className='bi bi-pencil cursor-pointer' onClick={handleClick}></i>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className='col-md-9'>
                    <p>
                      This is your first impression, and the face people are going to look at most. We recommend making it a nice one. A smile always
                      helps.
                    </p>
                  </div>
                </div>

                <form className='edit-profile-form' onSubmit={formik.handleSubmit}>
                  <div className='mb-5'>
                    <label className='form-label' htmlFor='name'>
                      Name<span className='text-danger'> *</span>
                    </label>
                    <input
                      type='text'
                      className={Boolean(formik.touched?.name) && Boolean(formik.errors?.name) ? 'form-control border-danger' : 'form-control'}
                      id='name'
                      name='name'
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    {Boolean(formik.touched?.name) && Boolean(formik.errors?.name) && <InputErrorMessage error={formik.errors?.name} />}
                  </div>
                  <div className='mb-5'>
                    <label className='form-label' htmlFor='email'>
                      Email address<span className='text-danger'> *</span>
                    </label>
                    <input type='text' className='form-control' id='email' value={formik.values.email} disabled={true} />
                  </div>
                  <div style={{float: 'inline-end'}}>
                    {
                      !loader ? <SimpleButton title='Save' type='submit' buttonType='primary'/> : <EditProfileLoader/>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
