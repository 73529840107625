import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar/Avatar';
import './tableList.css';
import Moment from 'react-moment';
import { useInfiniteHits } from "react-instantsearch"

function TableList({ columnWidth, hqUsers, showStatusOptions, showPropDueDateOptions, showBriefOwnerOptions, headers }) {
  const { hits, showMore } = useInfiniteHits()
  const joinName = (owner) => {
    let fullName = `${owner[0].firstName} ${owner[0].lastName}`;
    return fullName;
  };
  const getUserAvatar = (userId) => {
    const avatarUrl = hqUsers?.hits?.find(hqUser => {
      return hqUser?.document?._id === userId;
    });
    return avatarUrl?.document?.profilePic;
  };

  return <>
    <div className='w-full overflow-auto'>
      <table className="table table-hover table-nowrap">
        <thead className="text-white !bg-[#101828]">
          <tr>
            {headers.map(header => <th scope="col">{header.title}</th>)}
          </tr>
        </thead>
        <tbody className='bg-white'>
          {hits.map(hit => <tr>
            <td className='text-left'>
              {hit.refBriefId ? <Link to={`/projects/project-view/${hit._id}`}>{hit?.name} </Link> : <Link to={`/briefs/edit-brief/${hit._id}`}>{hit?.name} </Link>}
            </td>
            <td className='text-left'>
              <span className='text-[#667085]'>{hit.companyName}</span>
            </td>
            <td className='text-left'>
              <span className='text-black'>{hit.companyCurrency} {hit.feeAllocation[0].totalCost}</span>
            </td>
            {showBriefOwnerOptions && <td className='text-left flex items-center text-black'>
              <Avatar name={joinName(hit.owner)} url={getUserAvatar(hit.owner[0]?.userId)} /><span style={{ marginLeft: '6px' }}>{joinName(hit.owner)}</span>
            </td>}
            {showStatusOptions && <td className='text-left'>
              <span className='green-badge'>{hit?.status || 'Pending'}</span>
            </td>}
            <td className='text-left'>
              <Moment format='DD/MM/YYYY' date={hit?.projectExpectedStartDate || hit?.createdAt} />
            </td>
            {showPropDueDateOptions && <td className='text-left'>
              <Moment format='DD/MM/YYYY' date={hit?.projectExpectedEndDate || hit?.briefProposalDueDate} />
            </td>}
          </tr>)}
        </tbody>
      </table>
    </div>
    <button onClick={showMore} className='!w-full'>Show more results</button>
  </>
}

export default TableList;