import React, {useState, useEffect} from 'react';
import toast from 'react-hot-toast';
// import {dummyBriefData as briefData} from './dummyBriefData';
import PricingCard from './PricingCard';
import { Loader, EditProfileLoader } from '../../../components/common/loader';
import './pricingTable.css';
import { saveFeeAllocationsApi } from '../../../apis';

export default function PriceTable({ briefData, closeModal, isConvertToProject = false, refreshBrief }) {
  // const [deliverables, setDeliverables] = useState(briefData.deliverables);
  const [deliverables, setDeliverables] = useState([]);
  const [margin, setMargin] = useState(briefData.feeAllocation ? briefData.feeAllocation[0].markupPercentage : 0);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setDeliverables(briefData.deliverables);
  }, [briefData]);

  function getFlagEmoji(countryCode) {
    if (countryCode) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char =>  127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    }
    return '';
  }

  const getBriefTotal = () => {
    let sum = 0;
    for (let i = 0; i < deliverables.length; i++){
      if (deliverables[i].role) {
        sum += deliverables[i].role.reduce((total, role) => {
          total += parseInt(role.suggestedFeeAllocation);
          return total;
        }, 0);
      }
    }
    let marginL = sum * (margin/100);
    const result = sum + marginL;
    
    return (isNaN(result) ? 0.00 : result );
  };

  const handleMarginChange = (e) => {
    setMargin(e.target.value);
  };

  const updateNewRoleCost = (delId, value, roleIndex) => {
      
    const update = deliverables.map((deliverable) => {
      if( deliverable.deliverableId === delId ) {
        deliverable.role[roleIndex].suggestedFeeAllocation = Math.ceil(value);
      }
      return deliverable;
    });
    setDeliverables(update);
  };

  const handleSave = async () => {
    const reduced = deliverables?.reduce((collected, deliverable) => {

      collected.push({
        deliverableId: deliverable.deliverableId,
        role: deliverable.role
      });

      return collected;
    }, []);

    setSaving(true);
    const res = await saveFeeAllocationsApi({
      deliverables: reduced,
      id: briefData?._id,
      markUp: margin
    });
    setSaving(false);
    if (res.status === 200 || 201) {
      toast.success(res?.data?.message, { id: '001' });   
      if (refreshBrief) {
        refreshBrief();
      }
    } else {

    }
  };

  return (
    !briefData.deliverables ? (
      <div className='text-xs text-center'>
        <Loader />
      </div>
    ) : (
      <div style={{minWidth: '80vw'}}>  
        <div className='project-mashup-table-title-block'>Fee Allocation Per Role for Deliverable</div>        
        <div className='pricing-table-header-row'>
          <p style={{marginRight: '10px'}}>Add suggested pricing below in: </p>
          <div className='pricing-table-badge'>
            <p style={{marginLeft: '5px', fontSize: 'small'}}>{briefData.companyCurrency}</p>
          </div>

        </div>
        <div className='pricing-table-card-container'>
          {
            deliverables?.map((deliverable, i) => {
              if (deliverable.role) {
                return <PricingCard deliverable={deliverable} index={deliverable.deliverableId} updateNewRoleCost={updateNewRoleCost} key={i} />;
              }
              return;
            })
          }
        </div>
        <div className='pricing-table-margin-row'>
          <div className='pricing-table-margin-box'>
            <input className='pricing-table-margin-input' type='text' onChange={handleMarginChange} value={margin}/>
            <p>% Markup</p>
          </div>
        </div>
        <div className='pricing-table-margin-row'>
          <div className='pricing-card-total-cost'>
            <p className='pricing-card-input'>{Math.ceil(parseFloat(getBriefTotal()))}</p>
            <p>.00</p>
          </div>
        </div>
        <div className='brief-button-container'>
          {
            isConvertToProject ?
              '':
              <button type='button' className='button-close' onClick={closeModal} style={{width: '100%'}} >
              Cancel
              </button>
          }
          <button onClick={() => handleSave()} className='button-primary ' style={{width: '100%'}}>
            {
              saving ? <EditProfileLoader /> : 'Update pricing'
            }
          </button>
        </div>
      </div>
    )
    
  );

}