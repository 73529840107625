import React, { useState, useEffect } from 'react';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { assignBucketUrl } from '../../utils/helperFunctions';
import { Loader } from '../../components/common/loader';
import ClientPagination from '../../components/Pagination/ClientPagination';

const ClientTable = ({ client, deleteBrand, handleSortingChange, pageCount, handleChange}) => {
 
  const [tableData, setTableData] = useState([]);
  const handlePageClick = ({ selected }) => {
    handleChange('page', selected + 1);
  };

  useEffect(() => {
    setTableData(client?.data);
  }, [client?.data]);

  return (
    <div className='card'>
      <div className='card-header border-bottom d-flex align-items-center'>
        <h5 className='me-auto'>Active Brands</h5>
      </div>

      <i className='bi bi-airplane'></i>
      <div className='table-responsive client-height'>

        <table className='table table-hover table-nowrap'>
          <thead className='table_gray_light sticky-header'>
            <tr>
              <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('brandName')} data-sort='tables-name'>
                Brands
              </th>
              <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('clientName')} data-sort='tables-name'>
                Client Contact
              </th>
              <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('clientEmail')} data-sort='tables-name'>
                Email
              </th>
              <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('industry')} data-sort='tables-name'>
                Industry
              </th>
              <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('type')} data-sort='tables-name'>
                Client Type
              </th>
              <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('region')} data-sort='tables-name'>
               Region
              </th>
              <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('createdAt')} data-sort='tables-name'>
                create date
              </th>
              <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('live')} data-sort='tables-name'>
                live
              </th>
              <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('converted')} data-sort='tables-name'>
                converted
              </th>
              <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('archived')} data-sort='tables-name'>
                archived
              </th>
              <th scope='col' style={{ cursor: 'pointer' }} onClick={() => handleSortingChange('totalBriefs')} data-sort='tables-name'>
                total Briefs
              </th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((el, i) => {
              return (
                <tr key={i}>
                  <td>
                    <Link to={`/brands/brand-profile/${el?._id}`} className='text-heading font-semibold'>
                      <img
                        onError={(event) => {
                          event.target.src = ProfileDefault;
                          event.onerror = null;
                        }}
                        src={assignBucketUrl(el?.logo)}
                        alt='...'
                        className='avatar avatar-sm rounded-circle me-2'
                      />
                      {el?.brandName || '--'}
                    </Link>
                  </td>
                  <td>{el?.clientName || '--'}</td>
                  <td>{el?.clientEmail || '--'}</td>
                  <td>{(el?.industry ? (Array.isArray(el.industry) ? (el.industry[0].label) : (el.industry.label)) : ('--'))}</td>
                  <td>{(el?.type ? (Array.isArray(el.type) ? (el.type[0].label) : (el.type.label)) : ('--'))}</td>
                  <td>{(el?.region ? (Array.isArray(el.region) ? (el.region[0].label) : (el.region.label)) : ('--'))}</td>
                  <td>
                    <Moment format='DD/MM/YYYY' date={el.createdAt} />
                  </td>
                  <td>{el?.live.length === 0 ? 0 : el?.live.length}</td>
                  <td>{el?.converted.length === 0 ? 0 : el?.converted.length}</td>
                  <td>{el?.archived.length === 0 ? 0 : el?.archived.length}</td>

                  <td>{el?.live.length + el?.converted.length + el?.archived.length || 0}</td>

                  <td className='position-sticky bg-white' style={{ right: 0 }}>
                    <div className='dropdown'>
                      <Link to='' className='text-muted' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                        <i className='bi bi-three-dots-vertical' />
                      </Link>
                      <div className='dropdown-menu'>
                        <Link to={`/brands/brand-profile/${el._id}`} className='dropdown-item'>
                          <i className='bi bi-eye me-3'></i>
                          View Brand Profile
                        </Link>
                        <Link to={`/brands/edit-brand/${el?._id}`} className='dropdown-item'>
                          <i className='bi bi-pencil me-3'></i>
                          Edit Brand Profile
                        </Link>
                        <button onClick={() => deleteBrand({ id: el?._id, isActive: false })} className='dropdown-item downloadPdfBtn'>
                          <i className='bi bi-archive me-3'></i>
                          Archive Brand Profile
                        </button>
                        <Link to={'/briefs/create-brief/'} state={{ value: el?._id, label: el?.brandName }} className='dropdown-item'>
                          <big>
                            <i className='bi bi-plus me-3 text-bold'></i>
                          </big>
                          Create Brief
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {client?.loading ? (
          <div className='text-center p-4'>
            <Loader />
          </div>
        ) : (
          ''
        )}
        {(!client?.data?.length > 0 && !client?.loading) || (client?.data?.[0]?._id === '' && !client?.loading) ? (
          <div className='text-center p-4'>No data found</div>
        ) : (
          ''
        )}
      </div> 
      {Boolean(pageCount) && <ClientPagination pageCount={pageCount} onPageChange={handlePageClick} itemsPerPage={5} />}
    </div>

  );
};

export default ClientTable;
