import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { autoHeightText } from '../../../utils/helperFunctions';
import { BriefFormContext } from '../../../appContext';

export default function CreateBriefsForm2() {
  const BriefContext2 = useContext(BriefFormContext);
  const { formik2, setStep } = BriefContext2;

  const handleChange = (e) => {
    formik2.handleChange(e);
  };

  return (
    <>
      <form onSubmit={formik2.handleSubmit}>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>The Brief</label>
            <input
              type='text'
              className='form-control'
              placeholder='The Brief'
              name='inBrief'
              value={formik2.values.inBrief}
              onChange={handleChange}
            />
            {/* <InputErrorMessage error={formik2.touched.inBrief && formik2.errors.inBrief} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>Project Background</label>
            <textarea
              type='text'
              className='form-control'
              placeholder='Project Background
              '
              name='background'
              value={formik2.values.background}
              onKeyUp={(e) => autoHeightText(e)}
              onChange={handleChange}></textarea>
            {/* <InputErrorMessage error={formik2.touched.background && formik2.errors.background} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>Project Goal</label>
            <textarea
              type='text'
              className={`${
                Boolean(formik2.touched?.projectAmbition) && Boolean(formik2.errors?.projectAmbition) ? ' border-danger ' : ' '
              } form-control `}
              placeholder='what are the business objectives that this brief is attempting to tackle?'
              name='projectAmbition'
              value={formik2.values.projectAmbition}
              onChange={handleChange}
              onKeyUp={(e) => autoHeightText(e)}></textarea>
            {/* <InputErrorMessage error={formik2.touched.projectAmbition && formik2.errors.projectAmbition} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>Product / Experience</label>
            <textarea
              onKeyUp={(e) => autoHeightText(e)}
              type='text'
              className='form-control'
              placeholder='What makes the experience special / how does it fulfill the challenge the audience faces?'
              name='experience'
              value={formik2.values.experience}
              onChange={handleChange}></textarea>
            {/* <InputErrorMessage error={formik2.touched.experience && formik2.errors.experience} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>Brand</label>
            <textarea
              onKeyUp={(e) => autoHeightText(e)}
              type='text'
              className='form-control'
              placeholder='What makes the brand special / how does it fulfill the challenge the audience faces?'
              name='brand'
              value={formik2.values.brand}
              onChange={handleChange}></textarea>
            {/* <InputErrorMessage error={formik2.touched.brand && formik2.errors.brand} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>Audience</label>
            <textarea
              onKeyUp={(e) => autoHeightText(e)}
              className='form-control'
              placeholder='Who are we talking to (demographics, location, age, etc) and what do we know about them that matters most to this brief?'
              name='audience'
              value={formik2.values.audience}
              onChange={handleChange}></textarea>
            {/* <InputErrorMessage error={formik2.touched.audience && formik2.errors.audience} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-3'>
            <label className='form-label'>KPIs</label>
            <textarea
              type='text'
              className='form-control'
              placeholder='How will we measure the success of this project'
              name='kpis'
              value={formik2.values.kpis}
              onKeyUp={(e) => autoHeightText(e)}
              onChange={handleChange}></textarea>
            {/* <InputErrorMessage error={formik2.touched.kpis && formik2.errors.kpis} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 next-btn-cntnr mb-5 mt-5'>
            <Link to='' className='btn btn-sm btn-neutral border-base me-3' onClick={() => setStep((v) => v - 1)}>
              Back
            </Link>
            <button type='submit' className='btn btn-sm btn_primary_black btn-width'>
              Next
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
