import React from 'react';
import { Link } from 'react-router-dom';
import AdminLayout from '../../../components/admin-layout';
import EditForm1 from './EditForm1';
import EditForm2 from './EditForm2';

export default function Index() {
  return (
    <AdminLayout>
      <div className='container'>
        <div className='dashboard-head brief-head'>
          <h1 className='h2 ls-tight'>Edit Profile</h1>
        </div>
        <article>
          <div className='border-bottom pt-6'>
            <ul className='nav nav-tabs overflow-x border-0' id='nav-tab' role='tablist'>
              <li className='nav-item'>
                <Link
                  to=''
                  className='nav-link active'
                  id='nav-home-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-home'
                  type='button'
                  role='tab'
                  aria-controls='nav-home'
                  aria-selected='true'>
                  Profile
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to=''
                  className='nav-link'
                  id='nav-profile-tab'
                  data-bs-toggle='tab'
                  data-bs-target='#nav-profile'
                  type='button'
                  role='tab'
                  aria-controls='nav-profile'
                  aria-selected='false'>
                  Work Details
                </Link>
              </li>
            </ul>
          </div>
          <main className='py-6 '>
            {/* Offcanvas */}

            <div className='tab-content' id='nav-tabContent'>
              <div className='tab-pane fade show active' id='nav-home' role='tabpanel' aria-labelledby='nav-home-tab'>
                <EditForm1 />
              </div>
              <div className='tab-pane fade' id='nav-profile' role='tabpanel' aria-labelledby='nav-profile-tab'>
                <EditForm2 />
              </div>
            </div>
          </main>
        </article>
      </div>
    </AdminLayout>
  );
}
