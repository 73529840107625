import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AdminLayout from '../../components/admin-layout';
import SharePopup from '../../components/Popups/SharePopup';
import { chatMasherApi, duplicateBriefApi, getAllBriefApi, getProjectByIdApi } from '../../apis';
import toast from 'react-hot-toast';
import { assignBucketUrl, getLastElementId, MashersForBriefInvite } from '../../utils/helperFunctions';
import { Loader } from '../../components/common/loader';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import ReactTooltip from 'react-tooltip';
import useCollapse from 'react-collapsed';
import NewChatSection from '../Briefs/BriefChatSection';

export default function ProjectView() {
  const [briefDetails, setBriefDetails] = useState({ loading: false, data: '' });
  const [masherForChat, setMasherForChat] = useState({ loading: false, data: '' });
  const [masherDataShare, setMasherDataShare] = useState({ data: null, loading: false });
  const [brief, setBrief] = useState(1);
  const projectIdd = briefDetails?.data?.project?.briefId?._id;

  const location = useLocation();

  const briefDt = briefDetails?.data?.project;
  const navigate = useNavigate();
  const id = getLastElementId();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const handleDuplicate = async (id) => {
    try {
      const res = await duplicateBriefApi({ id: id });
      if (res.status === 200) {
        toast.success(res?.data?.message, { id: '001' });
        navigate('/briefs');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { id: '001' });
    }
  };

  const fetchProjectDetailsById = async (id) => {
    try {
      const res = await getProjectByIdApi(id);
      if (res.status === 200) {
        setBriefDetails((v) => ({ ...v, loading: true, data: res?.data }));
      } else {
        setBriefDetails((v) => ({ ...v, loading: false }));
        toast.error('Something went wrong.', { id: 'err701' });
      }
    } catch (error) {
      const message = error.response?.data?.message || error.response.statusText;
      toast.error(message, { id: 'err01' });
      setBriefDetails((v) => ({ ...v, loading: false }));
    }
  };

  const chatMasher = async (id) => {
    try {
      const res = await chatMasherApi(id);
      if (res.status === 200) {
        setMasherForChat((v) => ({ ...v, loading: true, data: res?.data }));
      } else {
        setMasherForChat((v) => ({ ...v, loading: false }));
        toast.error('Something went wrong.', { id: 'erru01' });
      }
    } catch (error) {
      const message = error.response?.data?.message || error.response.statusText;
      toast.error(message, { id: 'err091' });
      setBriefDetails((v) => ({ ...v, loading: false }));
    }
  };

  const allBrief = async (search, startDate, dueDate, page, limit) => {
    try {
      const res = await getAllBriefApi(search, startDate, dueDate, page, limit);
      if (res.status === 200) {
        setBrief({ data: res.data.allBriefs, loading: false });
      }
    } catch (error) {
      toast.error(error);
      setBrief({ loading: false });
    }
  };

  useEffect(() => {
    fetchProjectDetailsById(id);
    allBrief();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (briefDetails?.data?.project?.briefId?._id) {
      chatMasher(projectIdd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [briefDetails?.data?.project?.briefId?._id]);

  return (
    <AdminLayout>
      {briefDetails?.loading ? (
        <div className='bg-surface-secondary Project_view' id='testId'>
          <ReactTooltip />

          <div className='d-xl-flex'>
            <div className='min-w-0 flex-xl-fill'>
              <header style={{ background: '#fff' }}>
                <div className='container-fluid'>
                  <div className='pt-6'>
                    <div className='row align-items-center'>
                      <div className='col-sm-8 col'>
                        <div className='d-flex align-items-center mb-3'>
                          <div>
                            <img
                              onError={(event) => {
                                event.target.src = ProfileDefault;
                                event.onerror = null;
                              }}
                              alt='...'
                              src={assignBucketUrl(briefDt?.briefId?.logo)}
                              className='avatar avatar-sm rounded-circle me-2 briefIndi'
                              style={{ border: '1px solid rgba(0,0,0,0.5)' }}
                            />
                          </div>
                          <div className='ps-2'>{briefDt?.briefId?.briefName}</div>
                        </div>
                        <h1 className='h2 ls-tight'>{briefDt?.briefId?.clientName}</h1>
                      </div>
                      <div className='col-sm-4 col text-end'>
                        
                        <div className='dropdown  mt-2 col-md-6'>
                          <button
                            className='btn btn-outline-dark btn-sm dropdown-toggle'
                            type='button'
                            id='dropdownMenuButton1'
                            data-bs-toggle='dropdown'
                            aria-expanded='false'>
                            Action
                          </button>
                          <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                            <li>
                              <Link to={`/projects/edit-project/${id}`} className='dropdown-item'>
                                <i className='bi bi-pencil me-3'></i>
                                Edit Project
                              </Link>
                            </li>
                            <li>
                              
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <nav aria-label='breadcrumb'>
                      <ol className='breadcrumb mb-0 mt-4'>
                        <li className='breadcrumb-item'>
                          <Link to='/' className='blackOnHover'>
                            Home
                          </Link>
                        </li>
                        <li className='breadcrumb-item active' aria-current='page'>
                          <Link to='/briefs' className='blackOnHover'>
                            Briefs
                          </Link>
                        </li>
                        <li className='breadcrumb-item text-muted' aria-current='page'>
                          View Projects
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </header>
              <main className=''>
                <div className='container-fluid view-detail'>
                  <div className='vstack gap-6'>
                    <div className='row mb-2'>
                      <div className='col-12'>
                        <div className='card'>
                          <div className='card-body'>
                            <div className=''>
                              <h4 className='mb-3'>Budget</h4>

                              <div className=''>
                                <h5 className='mb-3 d-inline text-capitalize '>project fee : </h5>
                                <p className='text-sm d-inline '> {briefDt?.projectFee ? (briefDt?.projectFee + ' ' + briefDt?.projectCurrency[0]?.value) : 'Not Available'}</p>
                              </div>
                              <div className='col-md-12'>
                                <h5 className='mb-3 d-inline text-capitalize'>masher fee : </h5>
                                <div className='mt-3'>
                                  <table className='table table-hover table-nowrap '>
                                    <thead>
                                      <tr>
                                        <th>masher name</th>
                                        <th>fees</th>
                                        <th>currency</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        briefDt?.team?.map((el, i) => {
                                          if(el.masherName){
                                            return (
                                              <tr key={i}>
                                                <td> {el.masherName?.label}</td>
                                                <td> {el.fees ? el.fees : 'Not Available'}</td>
                                                <td> {el.currency}</td>
                                              </tr>
                                            );
                                          }
                                        })}
                                    </tbody>
                                  </table>
                                  {briefDt?.team?.filter((el, i) => el.masherName ).length == 0 && (
                                    <div className='text-center p-3'>No Fees</div>
                                  )}
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card mb-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12 mb-5'>
                            <label className='h4'>Team</label>
                            <p className='mb-0 fs-13 preWrap'>
                              {briefDt?.team?.filter((el, i) => el.masherName ).length
                                ? briefDt?.team?.map((el, i) => {
                                  return <div key={i}>{el?.masherName?.label + ', '}</div>;
                                })
                                : 'No Team'}
                             
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card mb-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12 mb-5'>
                            <label className='h4'>Mash Manager</label>
                            <p className='mb-0 fs-13 preWrap'>{ (briefDt?.mashManager ? (typeof(briefDt?.mashManager)=='string' ?( briefDt.mashManager):(briefDt?.mashManager[0].label)):('N/A') )}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card mb-2'>
                      <div className='card-body'>
                        <div>
                          <h4 className='mb-3'>The Brief</h4>

                          <p className='text-sm'>{briefDt?.briefId?.inBrief}</p>
                        </div>
                      </div>
                    </div>
                    <div className='card mb-2 deliTable'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-6 mb-5'>
                            <label className='form-label'>Deliverable Details</label>
                          </div>

                          <div className='table-responsive'>
                            <table className='table table-hover table-nowrap '>
                              <thead>
                                <tr>
                                  <th>order</th>
                                  <th>name</th>
                                  <th>start</th>
                                  <th>due</th>
                                  <th>role</th>
                                  <th>format</th>
                                  <th>description</th>
                                </tr>
                              </thead>
                              <tbody>
                                {briefDt?.briefId?.deliverableDetails
                                  ?.sort((p1, p2) => (p1.order < p2.order ? -1 : p1.order > p2.order ? 1 : 0))
                                  ?.map((el, i) => {
                                    return (
                                      <tr key={i}>
                                        <td className='text-heading font-semibold position-sticky'> {el.order || '--'}</td>
                                        <td> {el.name}</td>
                                        <td> {el.startDate}</td>
                                        <td> {el.dueDate}</td>
                                        <td>{(el?.masherRole ? (Array.isArray(el.masherRole) ? (el.masherRole[0].label):(el.masherRole.label)):('--') )}</td>
                                        <td> {el.delieveryFormat}</td>
                                        <td>
                                          {el.description.length > 50 ? el.description.slice(0, 20) + ' ...' : el.description}
                                          <button className='expandBtn' {...getToggleProps()}>
                                            {isExpanded ? <i className='bi bi-chevron-up'></i> : <i className='bi bi-chevron-down'></i>}
                                          </button>
                                          <p className='expandToggle' {...getCollapseProps()}>
                                            {el.description}
                                          </p>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card mb-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12 mb-5'>
                            <label className='h4'>Project Background</label>
                            <p className='mb-0 fs-13 preWrap'>{briefDt?.briefId?.background || 'N/A'}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card mb-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12 mb-5'>
                            <label className='h4'>Project Goal</label>
                            <p className='mb-0 fs-13 preWrap'>{briefDt?.briefId?.projectAmbition || 'N/A'}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card mb-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12 mb-5'>
                            <label className='h4'>Brand</label>
                            <p className='mb-0 fs-13 preWrap'>{briefDt?.briefId?.brand || 'N/A'}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card mb-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12 mb-5'>
                            <label className='h4'>Audience</label>
                            <p className='mb-0 fs-13 preWrap'>{briefDt?.briefId?.audience || 'N/A'}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card mb-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12 mb-5'>
                            <label className='h4'>Product / Experience</label>
                            <p className='mb-0 fs-13 preWrap'>{briefDt?.briefId?.experience || 'N/A'}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card mb-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12 mb-5'>
                            <label className='h4'>KPIs</label>
                            <p className='mb-0 fs-13 preWrap'>{briefDt?.briefId?.kpis || 'N/A'}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card col-md-12 mb-5 create_brief_links'>
                      <div className='card-body'>
                        <label className='h4'>Client Website / Social / Press Links</label>
                        {(briefDt?.briefId?.websiteLink?.length > 0 &&
                          briefDt?.briefId?.websiteLink.map((el, i) => {
                            return (
                              <a href={`${el?.link}`} target='_blank' rel='noreferrer' className='clientLink' key={i}>
                                <p className='clientLink'>{el.name}</p>
                              </a>
                            );
                          })) || <p className='mb-0 fs-13'>N/A</p>}
                      </div>
                    </div>

                    <div className='card mb-2'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12 mb-5 create_brief_links'>
                            <label className='h4'>Attachments</label>

                            {briefDt?.briefId?.attachement ? (
                              <a href={briefDt?.briefId?.attachement} target='_blank' rel='noreferrer'>
                                <p className='mb-0 fs-13'>{briefDt?.fileName || 'No file name'}</p>
                              </a>
                            ) : (
                              <p className='mb-0 fs-13'>No Attachments</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>

            <div className='flex-xl-none w-xl-96 border-start-xl bg-surface-primary position-sticky top-xl-18'>
              <aside className='p-4 h-full overflow-y-xl-auto'>
                <div className='vstack gap-6'>
                  <div className='brief_chat_wrapper'>
                    <div
                      className='vstack gap-6'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <div className='brief_chat_wrapper w-100 '>
                        {masherForChat?.data?.briefInvitation?.length ? (
                          <NewChatSection masherForChat={masherForChat} isProject={briefDetails} />
                        ) : (
                          <div className='text-center'>
                            <i style={{ color: 'rgba(0,0,0,0.4)', fontWeight: '500', textAlign: 'center' }}>No mashers invited for chat...</i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center p-4'>
          <Loader />
        </div>
      )}

      {/* Share Modal */}
      <SharePopup masherDataShare={masherDataShare} brief={brief} />
    </AdminLayout>
  );
}
