import React, { useContext, useRef } from 'react';
import { Loader } from '../../../components/common/loader';
import { InputErrorMessage } from '../../../components/errorMessages';
import { BriefFormContext } from '../../../appContext';
import Select from 'react-select';
import ProfileDefault from '../../../Assets/img/covers/userDefault.png';
import { assignBucketUrl, brandOptions, helperImageUpload, style } from '../../../utils/helperFunctions';

export default function CreateBriefsForm1() {
  const BriefContext1 = useContext(BriefFormContext);
  const { formik1, setSelect, client, formSelects, getClient } = BriefContext1;
  const inputRef = useRef(null);
  const selectedValues = {
    selectedIndustry: useRef(),
    selectedRegion: useRef(),
    selectedClient: useRef(),
    selectedCate: useRef(),
    selectedEngage: useRef(),
  };

  const handleChange = (e) => {
    formik1.handleChange(e);
    let selectedOptionsText = {
      textSelectOption: selectedValues?.selectedIndustry.current.options[selectedValues?.selectedIndustry.current.selectedIndex].text,
      textSelectOption2: selectedValues?.selectedRegion.current.options[selectedValues?.selectedRegion.current.selectedIndex].text,
      textSelectOption3: selectedValues?.selectedClient.current.options[selectedValues?.selectedClient.current.selectedIndex].text,
      textSelectOption4: selectedValues?.selectedCate.current.options[selectedValues?.selectedCate.current.selectedIndex].text,
      textSelectOption5: selectedValues?.selectedEngage.current.options[selectedValues?.selectedEngage.current.selectedIndex].text,
    };

    setSelect({
      industry: selectedOptionsText?.textSelectOption,
      region: selectedOptionsText?.textSelectOption2,
      client: selectedOptionsText?.textSelectOption3,
      category: selectedOptionsText?.textSelectOption4,
      engage: selectedOptionsText?.textSelectOption5,
    });
  };

  const handleFileUpload = (event) => {
    helperImageUpload(formik1, event);
  };

  const options = () => {
    return client ? brandOptions(client) : false;
  };
  return (
    <>
      {client.loading ? (
        <div className='text-center pt-5'>
          <Loader />
        </div>
      ) : (
        <form onSubmit={formik1.handleSubmit}>
          <div className='row'>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>
                Brands<span className='text-danger'> *</span>
              </label>
              <Select
                styles={style}
                options={options()}
                name={'clientName'}
                className={`${Boolean(formik1.touched?.clientName) && Boolean(formik1.errors?.clientName) ? 'border-danger' : ''} form-control p-015`}
                classNamePrefix='select'
                value={formik1.values.clientName}
                onChange={(e) => {
                  getClient(e, formik1);
                }}
              />
              <InputErrorMessage error={formik1.touched.clientName && formik1.errors.clientName} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-3'>
              <label className='form-label'>
                Brief Name<span className='text-danger'> *</span>
              </label>
              <input
                type='text'
                className={`${
                  Boolean(formik1.touched?.briefName) && Boolean(formik1.errors?.briefName) ? 'form-control border-danger' : 'form-control '
                }`}
                placeholder='Enter Brief Name'
                name='briefName'
                value={formik1.values.briefName}
                onChange={handleChange}
              />
              <InputErrorMessage error={formik1.touched.briefName && formik1.errors.briefName} marginBottom={-5} />
            </div>
            <div className='col-md-6 mb-3 edit-avatar ' style={{ position: 'relative' }}>
              <label className='form-label' style={{ display: 'block' }}>
                Logo Upload<span className='text-danger'> *</span>
              </label>
              <img
                src={formik1?.values?.logo ? assignBucketUrl(formik1?.values?.logo) : assignBucketUrl(ProfileDefault)}
                onError={(event) => {
                  event.target.src = ProfileDefault;
                  event.onerror = null;
                }}
                alt='logo'
                style={{
                  width: '80px',
                  height: '80px',
                  border: '1px solid rgb(25, 19, 19, 0.25)',
                  borderRadius: '50%',
                  boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                  marginRight: '10px',
                }}
              />
              <div className='profileImgUpload d-inline-block  '>
                <label htmlFor='logo'>
                  <div
                    className='changeFileBtn'
                    onClick={() => {
                      inputRef?.current?.click();
                    }}>
                    Choose File
                  </div>
                </label>

                {formik1.values?.logo !== '' ? (
                  <div
                    className='removeFileBtn d-inline-block'
                    onClick={() => {
                      formik1.setFieldValue('logo', '');
                    }}>
                    Remove
                  </div>
                ) : (
                  <div className='d-inline-block'>No Logo Found</div>
                )}
              </div>
              <input type='file' title=' ' className={' d-none edit-pen2 fileInput'} name='logo' id='logo' onChange={handleFileUpload} />
              <InputErrorMessage error={formik1.touched.logo && formik1.errors.logo} marginBottom={-5} />
            </div>

            <div className='col-md-6 mb-3'>
              <label className='form-label'>
                Industry<span className='text-danger'> *</span>
              </label>
              <select
                className={`${Boolean(formik1.touched?.industry) && Boolean(formik1.errors?.industry) ? ' border-danger' : ''} form-select`}
                name='industry'
                placeholder='select'
                ref={selectedValues?.selectedIndustry}
                value={formik1.values.industry}
                onChange={handleChange}>
                <option hidden>Select Industry</option>
                {formSelects?.industry &&
                  formSelects?.industry?.map((el, i) => {
                    return (
                      <option value={el.id} key={i}>
                        {el.name}
                      </option>
                    );
                  })}
              </select>
              <InputErrorMessage error={formik1.touched.industry && formik1.errors.industry} marginBottom={-5} />
            </div>

            <div className='col-md-12 mb-3'>
              <label className='form-label'>
                Region<span className='text-danger'> *</span>
              </label>
              <select
                className={`${Boolean(formik1.touched?.region) && Boolean(formik1.errors?.region) ? ' border-danger' : ''} form-select`}
                name='region'
                ref={selectedValues?.selectedRegion}
                value={formik1.values.region}
                onChange={handleChange}>
                <option hidden>Select Region</option>
                {formSelects?.region &&
                  formSelects?.region?.map((el, i) => {
                    return (
                      <option value={el.id} key={i}>
                        {el.name}
                      </option>
                    );
                  })}
              </select>
              <InputErrorMessage error={formik1.touched.region && formik1.errors.region} marginBottom={-5} />
            </div>

            <div className='col-md-12 mb-3'>
              <label className='form-label'>
                Client Type<span className='text-danger'> *</span>
              </label>
              <select
                className={`${Boolean(formik1.touched?.clientType) && Boolean(formik1.errors?.clientType) ? ' border-danger' : ''} form-select `}
                name='clientType'
                ref={selectedValues?.selectedClient}
                value={formik1.values.clientType}
                onChange={handleChange}>
                <option hidden>Select Client type</option>
                {formSelects?.client &&
                  formSelects?.client?.map((el, i) => {
                    return (
                      <option value={el.id} key={i}>
                        {el.name}
                      </option>
                    );
                  })}
              </select>
              <InputErrorMessage error={formik1.touched.clientType && formik1.errors.clientType} marginBottom={-5} />
            </div>

            <div className='col-md-12 mb-3'>
              <label className='form-label'>
                Category<span className='text-danger'> *</span>
              </label>
              <select
                className={`${Boolean(formik1.touched?.category) && Boolean(formik1.errors?.category) ? ' border-danger' : ''} form-select`}
                name='category'
                ref={selectedValues?.selectedCate}
                value={formik1.values.category}
                onChange={handleChange}>
                <option hidden>Select category</option>
                {formSelects?.category &&
                  formSelects?.category?.map((el, i) => {
                    return (
                      <option value={el.id} key={i}>
                        {el.name}
                      </option>
                    );
                  })}
              </select>
              <InputErrorMessage error={formik1.touched.category && formik1.errors.category} marginBottom={-5} />
            </div>

            <div className='col-md-12 mb-3'>
              <label className='form-label'>
                Engagement Structure<span className='text-danger'> *</span>
              </label>
              <select
                className={`${
                  Boolean(formik1.touched?.engagementStructure) && Boolean(formik1.errors?.engagementStructure) ? ' border-danger' : ''
                } form-select`}
                name='engagementStructure'
                ref={selectedValues?.selectedEngage}
                value={formik1.values.engagementStructure}
                onChange={handleChange}>
                <option hidden> Select Engagement Structure</option>
                {formSelects?.engagementType &&
                  formSelects?.engagementType?.map((el, i) => {
                    return (
                      <option value={el.id} key={i}>
                        {el.name}
                      </option>
                    );
                  })}
              </select>
              <InputErrorMessage error={formik1.touched.engagementStructure && formik1.errors.engagementStructure} marginBottom={-5} />
            </div>
            <div className='col-md-12 mt-3 mb-5'>
              <button type='submit' className='btn btn-sm btn_primary_black btn-width'>
                Next
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
