import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Calendar from 'react-calendar';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import calendarIcon from '../../Icons/calendarIcon.svg';
import editIcon from '../../Icons/editIconSimple.svg';
import { saveDateRangeApiV2, saveProjectDateRangeApi } from '../../apis';

const DateRange = ({ deliverable, briefId, projectId }) => {
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(new Date(moment().format('YYYY-MM-DD')));
  const [endDate, setEndDate] = useState(new Date(moment().add(1, 'd').format('YYYY-MM-DD')));
  const [updating, setUpdating] = useState(false);
  useEffect(() => {
    let durationStartDate = new Date(moment().format('YYYY-MM-DD'));
    let durationEndDate = new Date(moment().add(1, 'd').format('YYYY-MM-DD'));
    if (deliverable) {
      durationStartDate = deliverable.projectDeliverableStartDate ? new Date(moment(deliverable.projectDeliverableStartDate).format('YYYY-MM-DD')) : new Date(moment().format('YYYY-MM-DD'));
      durationEndDate = deliverable.projectDeliverableDueDate ? new Date(moment(deliverable.projectDeliverableDueDate).format('YYYY-MM-DD')) : new Date(moment().add(1, 'd').format('YYYY-MM-DD'));  
    }
    setStartDate(durationStartDate);
    setEndDate(durationEndDate);
  }, []);
  
  const handleOpen = () => {
    setVisible(!visible);
  };

  const handleApi = async (start, end) => {
    if (updating) {
      return;
    }
    setUpdating(true);
    let res;
    if (projectId) {
      res = await saveProjectDateRangeApi({
        id: projectId,
        deliverableId: deliverable.deliverableId,
        startDate: start,
        endDate: end
      });
    } else {
      res = await saveDateRangeApiV2({
        id: briefId,
        deliverableId: deliverable.deliverableId,
        startDate: start,
        endDate: end
      });
    }
    setUpdating(false);
    if (res.status === 200 || 201) {    
      toast.success(`Deliverable dates saved.`, { id: '001' });
    } else { 
    }
  }

  return (
    <div className="brief-date-range-picker-container">
      <img className="calendar-icon" src={calendarIcon} onClick={() => handleOpen()} />
      <p className="brief-textbox">{`${moment(startDate).format('MMM-DD')} - ${moment(endDate).format('MMM-DD')}`}</p>
      <div className={`brief-date-range-subcontainer brief-date-range-picker-${visible ? 'visible' : 'hidden'}`}>
        <Calendar
          selectRange={true}
          defaultActiveStartDate={new Date(moment().format('YYYY-MM-DD'))}
          minDate={new Date(moment().format('YYYY-MM-DD'))}
          maxDate={new Date(moment(new Date()).add(1, 'year').format('YYYY-MM-DD'))}
          clearIcon={null}
          // className='form-control  startFilter '
          showDoubleView={true}
          calendarIcon={<img className='calendar-icon' src={calendarIcon}/>}
          value={[startDate, endDate]}
          onChange={(value) => {                           
            setStartDate(value[0])        
            setEndDate(value[1]);
            handleApi(value[0], value[1]);
            setVisible(false);
          }}
          // className={`brief-date-range-picker-${visible ? 'visible' : 'hidden'}`}
        />
      </div>
      <img className="calendar-icon" src={editIcon} onClick={handleOpen} />
    </div>
  );
}

export default DateRange;