import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useCollapse from 'react-collapsed';
import toast from 'react-hot-toast';
import Moment from 'react-moment';
import { Link, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { getUserApi, chatMasherApi, duplicateBriefApi, extendTimeForBriefApi, getAllBriefApi, getBriefByIdApi } from '../../apis';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import PageHeader from '../../components/pageElements/pageHeader/PageHeader';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import AdminLayout from '../../components/admin-layout';
import { Loader } from '../../components/common/loader';
import SharePopup from '../../components/Popups/SharePopup';
import BriefPDFView from './BriefPDFView';
import SimpleTextAndTitle from '../../components/text-boxes/SimpleTextAndTitle';
import SimplePrintModal from '../../components/Modals/SimplePrintModal';
import pdfIcon from '../../Icons/pdfIcon.svg';
import MasherChat from '../../components/MasherChat';
import { assignBucketUrl, getBriefInvitationTimeRemaining, getLastElementId, MashersForBriefInvite } from '../../utils/helperFunctions';
import NewChatSection from './BriefChatSection';
import ExtendModal from './ExtendModal';

export default function BriefView() {
  const [briefDetails, setBriefDetails] = useState({ loading: false, data: '' });
  const [masherForChat, setMasherForChat] = useState({ loading: false, data: '' });
  const [masherDataShare, setMasherDataShare] = useState({ data: null, loading: false });
  const [extendHours, setExtendHours] = useState({ days: '', hours: '', loading: false });
  const [time, setTime] = useState('');
  const [exportPdf, setExportPdf] = useState(false);
  const [extendTimeLimit, setExtendTimeLimit] = useState(null);
  const [brief, setBrief] = useState(1);
  const [chatBoxViewable, setChatBoxViewable] = useState(false);
  const [currentUser, setCurrentUser] = useState('');

  const briefDt = briefDetails?.data?.brief;
  const navigate = useNavigate();
  const id = getLastElementId();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
 
  const handleDuplicate = async (id) => {
    try {
      const res = await duplicateBriefApi({ id: id });
      if (res.status === 200) {
        toast.success(res?.data?.message, { id: '001' });
        navigate('/briefs');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { id: '001' });
    }
  };

  const fetchBriefDetailsById = async (id) => {
    try {
      const res = await getBriefByIdApi(id);
      if (res.status === 200) {
        setBriefDetails((v) => ({ ...v, loading: true, data: res?.data }));
      } else {
        setBriefDetails((v) => ({ ...v, loading: false }));
        toast.error('Something went wrong.', { id: 'err01' });
      }
    } catch (error) {
      const message = error.response?.data?.message || error.response.statusText;
      toast.error(message, { id: 'err01' });
      setBriefDetails((v) => ({ ...v, loading: false }));
    }
  };

  const chatMasher = async (id) => {
    try {
      setMasherForChat((v) => ({ ...v, loading: true, data: '' }));
      const res = await chatMasherApi(id);
      if (res.status === 200) {
        setExtendTimeLimit(res?.data?.extendTimeLimit);
        if (res?.data?.briefInvitation?.length <= 0) {
          setMasherForChat((v) => ({ ...v, loading: true, data: res?.data }));
        } else {
          setMasherForChat((v) => ({ ...v, loading: false, data: res?.data }));
        }
      } else {
        setMasherForChat((v) => ({ ...v, loading: false }));
        toast.error('Something went wrong.', { id: 'err01' });
      }
    } catch (error) {
      const message = error.response?.data?.message || error.response.statusText;
      toast.error(message, { id: 'err01' });
      setBriefDetails((v) => ({ ...v, loading: false }));
    }
  };

  const allBrief = async (search, startDate, dueDate, page, limit) => {
    try {
      const res = await getAllBriefApi(search, startDate, dueDate, page, limit);
      if (res.status === 200) {
        setBrief({ data: res.data.allBriefs, loading: false });
      }
    } catch (error) {
      toast.error(error);
      setBrief({ loading: false });
    }
  };

  const handleExtendTime1 = async (value) => {
    const validFrom = () => {
      return new Date().toISOString();
    };
    try {
      let daysToHours = extendHours.days * 24 + extendHours.hours;
      let payload = { id: value, validFrom: validFrom(), validTill: daysToHours };
      const res = await extendTimeForBriefApi(payload);
      if (res?.status === 200) {
        toast.success(res?.data?.message, { id: '001' });
        setExtendHours('');
      }
      chatMasher(id);
    } catch (error) {
      toast.error('There\'s something wrong', { id: '001' });
    }
  };


  const formatDiffTimeFromNowHHMMfor48hours = (validFrom, time) => {
    let __time = getBriefInvitationTimeRemaining(validFrom, time);
    if (__time === -1) {
      return '00D // 00H';
    }
    return `${__time?.Days}D // ${__time?.Hours}H`;
  };

  const masherAppFilteredArr = masherForChat?.data?.briefInvitation?.filter(
    (obj, index, self) => index === self.findIndex((t) => t?.masherId?._id === obj?.masherId?._id)
  );

  const currentUserApi = async () => {
    const res = await getUserApi();
    setCurrentUser(res?.data?.user[0]._id);
  };

  const handleChat = () => {
    setChatBoxViewable(!chatBoxViewable);
  };

  const toggleExportPdfModal = () => {
    setExportPdf(!exportPdf);
  };

  const companyIndustries = (industryList) => {
    const companyIndustries = industryList ? industryList?.data?.filter(industry => industry.id === briefDetails.industry): [];
    return companyIndustries; 
  };

  useEffect(() => {
    fetchBriefDetailsById(id);
    chatMasher(id);
    allBrief();
    currentUserApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    <AdminLayout>
      {/* <ReactTooltip /> */}

      {briefDetails?.loading ? (
        <div id='testId'>
          <PageHeader title={briefDt?.briefName} buttonsArray={[
            <SimpleButton
              title='PDF' 
              altText='export pdf'
              buttonType='primaryUnfilled'
              buttonFunction='link'
              onClickEvent={() => {
                toggleExportPdfModal();
              }}
              icon={pdfIcon}
            />,
            <SimpleButton
              title='Share Brief' 
              altText='Share Brief'
              href='#modalExportMasher'
              buttonType='primary'
              buttonFunction='openModal'
              onClickEvent={() => {
                MashersForBriefInvite(setMasherDataShare, 'approved');
              }}
              toggle='modal'
            />
          ]}/>
          <div className='container-fluid'>
            <div className='brief-details'>
              <div className='brief-details-row'>
                <SimpleTextAndTitle title="Company" bodyText={briefDt.clientName} />
                <SimpleTextAndTitle title="Company Region" bodyText={briefDt.clientName} />
                <SimpleTextAndTitle title="Currency" bodyText={briefDt.currency[0]?.value} />
              </div>
              <div className='brief-details-row'>
                <SimpleTextAndTitle title="Expected Start Date" bodyText={briefDt.clientName} />
                <SimpleTextAndTitle title="Expected End Date" bodyText={briefDt.clientName} />
              </div>
            </div>
            <div className='brief-industry-container'>
              <h3 style={{fontSize: '18px', paddingBottom: '10px'}}>Industry</h3>
              {companyIndustries().map(i => {
                return (
                  <div className='brief-badge' key={i}>{i.name}</div>
                );
              })}
            </div> 
            <main style={{display: 'flex', width: '100vw'}}>
              <div style={{width: '65%', marginRight: '20px'}}>
                <div className=''>
                  <div >
                    <div >
                      <div className='card mb-2'>
                        <div className='card-body'>
                          <div className='row'>
                            <h4 className='mb-3'>Budget</h4>
                            <p className='text-sm'>{(briefDt?.budget) + ' ' + (briefDt?.currency ? (typeof (briefDt.currency) == 'string' ? (briefDt.currency) : (briefDt.currency[0].value)) : (''))}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-2'>
                    <div className='card-body'>
                      <div>
                        <h4 className='mb-3'>The Brief</h4>

                        <p className='text-sm'>{briefDt?.inBrief}</p>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-2 deliTable'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-6 mb-5'>
                          <label className='form-label'>Deliverable Details</label>
                        </div>

                        <div className='table-responsive'>
                          <table className='table table-hover table-nowrap '>
                            <thead>
                              <tr>
                                <th>order</th>
                                <th>name</th>
                                <th>start</th>
                                <th>due</th>
                                <th>Lead Mashers</th>
                                <th>description</th>
                                <th>format</th>
                              </tr>
                            </thead>
                            <tbody>
                              {briefDt?.deliverableDetails
                                ?.sort((p1, p2) => (p1.order < p2.order ? -1 : p1.order > p2.order ? 1 : 0))
                                ?.map((el, _i) => {
                                  return (
                                    <tr key={`del-${_i}`}>
                                      <td className='text-heading font-semibold position-sticky'> {el.order || '--'}</td>

                                      <td> {el.name}</td>
                                      <td> {el.startDate}</td>
                                      <td> {el.dueDate}</td>
                                      <td>{(el?.masherRole ? (typeof (el.masherRole) == 'string' ? (el.masherRole) : (el.masherRole.label)) : ('--'))}</td>
                                      <td>
                                        {el.description.length > 50 ? (

                                          <div>
                                            {el.description.slice(0, 70)} <span> ...</span>
                                            <button className='expandBtn' {...getToggleProps()}>
                                              {isExpanded ? <i className='bi bi-chevron-up'></i> : <i className='bi bi-chevron-down'></i>}
                                            </button>
                                            <p className='expandToggle' {...getCollapseProps()}>
                                              {el.description}
                                            </p> </div>
                                        ) : el.description}
                                      </td>
                                      <td style={{ whiteSpace: 'break-spaces' }}> {el.delieveryFormat}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-2'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-12 mb-5'>
                          <label className='h4'>Project Background</label>
                          <p className='mb-0 fs-13 preWrap'>{briefDt?.background || 'N/A'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-2'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-12 mb-5'>
                          <label className='h4'>Project Goal</label>
                          <p className='mb-0 fs-13 preWrap'>{briefDt?.projectAmbition || 'N/A'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-2'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-12 mb-5'>
                          <label className='h4'>Brand</label>
                          <p className='mb-0 fs-13 preWrap'>{briefDt?.brand || 'N/A'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-2'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-12 mb-5'>
                          <label className='h4'>Audience</label>
                          <p className='mb-0 fs-13 preWrap'>{briefDt?.audience || 'N/A'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-2'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-12 mb-5'>
                          <label className='h4'>Product / Experience</label>
                          <p className='mb-0 fs-13 preWrap'>{briefDt?.experience || 'N/A'}</p>
                        </div>
                        <div className='col-md-12 mb-5'>
                          <label className='h4'>KPIs</label>
                          <p className='mb-0 fs-13 preWrap'>{briefDt?.kpis || 'N/A'}</p>
                        </div>
                        <div className='col-md-12 mb-5 create_brief_links'>
                          <label className='h4'>Client Website / Social / Press Links</label>
                          {(briefDt?.websiteLink?.length > 0 &&
                              briefDt?.websiteLink.map((el, _i) => {
                                return (
                                  <a href={`${el?.link}`} target='_blank' rel='noreferrer' className='clientLink' key={_i}>
                                    <p className='clientLink fs-14'>{el.name}</p>
                                  </a>
                                );
                              })) || <p className='mb-0 fs-13'>N/A</p>}
                        </div>
                        <div className='col-md-12 mb-5 create_brief_links'>
                          <label className='h4'>Attachment</label>

                          {briefDt?.attachments &&
                              briefDt?.attachments?.map((el, _i) => {
                                return (
                                  <a href={el?.file} target='_blank' rel='noreferrer'  key={_i}>
                                    <p className='mb-0 fs-14'>{el?.name || 'N/A'} </p>
                                  </a>
                                );
                              })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card mb-2 deliTable'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-6 mb-5'>
                          <label className='form-label'>Masher Applications</label>
                        </div>

                        <div className='table-responsive'>
                          <table className='table table-hover table-nowrap '>
                            <thead>
                              <tr>
                                <th>Masher Name</th>
                                <th>Masher Application Status</th>
                                <th>Brief Application Status</th>
                                <th>Fees</th>
                                <th>deliverable amount</th>
                                <th>Availability</th>
                                <th>Last updated date</th>
                                <th>Time remaining</th>
                                <th></th>
                                {/* <th></th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {masherAppFilteredArr &&
                                  masherAppFilteredArr?.map((el, _i) => {
                                    let totalDelAmt = el?.masherQuotedAmount?.map((ele) => {
                                      return parseInt(ele.amount);
                                    });

                                    let reducedamt = totalDelAmt.length >= 1 && totalDelAmt?.reduce((p, n) => p + n);
                                    let colorForTimer = getBriefInvitationTimeRemaining(el?.validFrom, el?.validTill) === -1 ? 'red' : 'black';

                                    return (
                                      <tr key={_i}>
                                        <td className='text-heading font-semibold'>
                                          <img
                                            src={assignBucketUrl(el?.masherId?.userId?.profilePic)}
                                            onError={(event) => {
                                              event.target.src = ProfileDefault;
                                              event.onerror = null;
                                            }}
                                            alt='...'
                                            className='avatar avatar-sm rounded-circle me-2 briefLogo'
                                          />

                                          {el?.masherId?.userId?.firstName + el?.masherId?.userId?.lastName || '--'}
                                        </td>
                                        <td> {el?.masherId?.status?.charAt(0).toUpperCase() + el.masherId?.status?.slice(1) || '--'}</td>
                                        <td> {masherForChat?.data?.briefInvitation[_i]?.status}</td>
                                        <td> {el?.masherId?.workDetails?.minDayRate + ' ' + el?.masherId?.workDetails?.currency || '--'}</td>

                                        <td>{`${reducedamt || '--'} ${el?.masherId?.workDetails?.currency || '--'}`}</td>
                                        <td> {el?.availability || '--'}</td>

                                        <td>
                                          <Moment format='DD/MM/YYYY' date={el?.masherId?.updatedAt} />
                                        </td>
                                        <td style={{ color: `${colorForTimer}` }}>
                                          {formatDiffTimeFromNowHHMMfor48hours(el?.validFrom, el?.validTill)}
                                          {/* {el?.status === "pending" ? formatDiffTimeFromNowHHMMfor48hours(el?.validTill) : "Applied"} */}
                                        </td>
                                        {/* <td>
                                          <button onClick={() => handleExtendTime({ id: el?._id })}>Reset Invitation validity</button>
                                        </td> */}
                                        <td>
                                          {(extendTimeLimit <= el?.extendTimeAttempts) ?
                                            <button
                                              style={{
                                                boxShadow: 'none',
                                                opacity: '0.65',
                                                border: '1px solid #8080809c',
                                                backgroundColor: 'transparent',
                                                borderRadius: '0.375rem',
                                                color: '#525f7f',
                                                cursor: 'not-allowed',
                                                display: 'inline-block',
                                                fontSize: '1rem',
                                                fontWeight: '500',
                                                lineHight: '1.3',
                                                padding: '0.75rem 1.25rem',
                                                textAlign: 'center',
                                              }}
                                              title="Attempts exceeded!"
                                              disabled={true} >Extend time</button> :
                                            <Link
                                              to=''
                                              className='btn btn-sm btn_primary_black'
                                              onClick={() => setTime(el.validTill)}
                                              data-bs-target={'#resetTime' + _i}
                                              data-bs-toggle='modal'
                                            >
                                              Extend time
                                            </Link>
                                          }

                                          <ExtendModal
                                            extendHours={extendHours}
                                            setExtendHours={setExtendHours}
                                            _i={_i}
                                            handleExtendTime1={handleExtendTime1}
                                            el={el}
                                            time={time}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                            </tbody>
                          </table>
                          {(!masherForChat?.data?.briefInvitation?.length > 0 && !masherForChat?.data?.briefInvitation?.loading) ||
                              (brief?.data?.[0]?._id === '' && !brief?.loading) ? (
                              <div className='text-center p-4'>No data found</div>
                            ) : (
                              ''
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-xl-none w-xl-96 border-start-xl bg-surface-primary position-sticky top-xl-18' style={{width: '25%'}}>
                <div
                  className='p-4 h-full overflow-y-xl-auto'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <div
                    className='vstack gap-6'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <div className='brief_chat_wrapper w-100 '>
                      {masherForChat?.data?.briefInvitation?.length ? (
                        <NewChatSection masherForChat={masherForChat} chatMasher={chatMasher} />
                      ) : (
                        <div className='text-center'>
                          <i style={{ color: 'rgba(0,0,0,0.4)', fontWeight: '500', textAlign: 'center' }}>No mashers invited for chat...</i>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      ) : (
        <div className='text-center p-4'>
          <Loader />
        </div>
      )}

      {/* Share Modal */}
      <SharePopup
        briefDetails={briefDetails}
        masherDataShare={masherDataShare}
        brief={brief}
        invitedMashers={masherForChat?.data?.briefInvitation}
        invMasher={chatMasher}
      />
      {exportPdf && 
        <SimplePrintModal modalWidth='860px' onClickEvent={() => {
          toggleExportPdfModal();
        }}>
          <BriefPDFView brief={briefDetails.data.brief}/>
        </SimplePrintModal>}
    </AdminLayout>
  );
}
