import moment from 'moment';
import React, { useContext, useRef } from 'react';
import DatePicker from 'react-date-picker';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { InputErrorMessage } from '../../../components/errorMessages';
import Currency2 from '../../../utils/Currency.json';
import {
  autoHeightText,
  helperFileUpload,
  style,
  handleAddFields,
  handleRemoveFields,
  helperBriefAttachmentCheck,
} from '../../../utils/helperFunctions';
import { BriefFormContext } from '../../../appContext';

export default function CreateBriefsForm3() {
  const uniqueIds = [];
  const BriefContext3 = useContext(BriefFormContext);
  const inputRef = useRef(null);
  const { formik3, setStep, masherData } = BriefContext3;

  const Currency = Currency2.filter((element) => {
    const isDuplicate = uniqueIds.includes(
      element.name &&
        element.isoAlpha2 &&
        element.isoAlpha3 &&
        element.isoNumeric &&
        element.currency.code &&
        element.currency.name &&
        element.currency.symbol
    );
    if (!isDuplicate) {
      uniqueIds.push(
        element.name,
        element.isoAlpha2,
        element.isoAlpha3,
        element.isoNumeric,
        element.currency.code,
        element.currency.name,
        element.currency.symbol
      );
      return true;
    }
    return false;
  });

  const currencyOptions = Currency?.map((el, i) => {
    const container = {};
    container['value'] = el.currency?.code;
    container['label'] = el?.currency?.name;
    return container;
  });

  const handleChange = (e) => {
    formik3.setFieldValue(e.target.name, e.target.value);
  };

  const handleChangeDatePicker = (name, value) => {
    formik3.setFieldValue(name, moment(value).format('YYYY-MM-DD'));
  };

  const handleAddattachement = () => {
    const attachments = [...formik3.values.attachments, { name: '', file: '' }];
    formik3.setFieldValue('attachments', attachments);
  };
  const handleRemoveattachement = (i) => {
    const id = formik3.values.attachments[i];
    const attachments = formik3.values.attachments.filter((el) => el !== id);
    formik3.setFieldValue('attachments', attachments);
  };

  const masherOptions = masherData?.data?.map((el) => {
    const container = {}; 
    container['value'] = el?.userId?._id;
    container['label'] = el?.userId?.firstName + ' ' + el?.userId?.lastName;
  
    return container;
  });  
  return (
    <>
     
      <form onSubmit={formik3.handleSubmit}>
        {formik3?.values?.deliverableDetails?.map((el, i, self) => {
        
          return (
            <div className='row' key={i}>
              <div className='col-md-6 mb-2'>
                <label className='form-label'>
                  Deliverable Details<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  className={`${
                    Boolean(formik3.touched?.deliverableDetails?.[i]?.name) && Boolean(formik3.errors?.deliverableDetails?.[i]?.name)
                      ? ' border-danger '
                      : ' '
                  } form-control `}
                  placeholder='Name'
                  name={`deliverableDetails.${[i]}.name`}
                  value={formik3?.values?.deliverableDetails?.[i]?.name}
                  onChange={handleChange}
                />
                <InputErrorMessage
                  error={formik3.touched?.deliverableDetails?.[i]?.name && formik3.errors?.deliverableDetails?.[i]?.name}
                  marginBottom={-5}
                />
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-6 mb-2'>
                    <label className='form-label'>
                      Start date<span className='text-danger'> *</span>
                    </label>
                    <DatePicker
                      minDate={new Date(moment().format('YYYY-MM-DD'))}
                      clearIcon={null}
                      className={`${
                        Boolean(formik3.touched?.deliverableDetails?.[i]?.startDate) && Boolean(formik3.errors?.deliverableDetails?.[i]?.startDate)
                          ? ' border-danger'
                          : ''
                      } form-control chatInput `}
                      name={`deliverableDetails.${[i]}.startDate`}
                      value={new Date(formik3.values?.deliverableDetails?.[i]?.startDate)}
                      onChange={(e) => handleChangeDatePicker(`deliverableDetails.${[i]}.startDate`, e)}
                    />
                  </div>
                  <div className='col-md-6 mb-2'>
                    <label className='form-label'>
                      Due date<span className='text-danger'> *</span>
                    </label>
                    <DatePicker
                      minDate={new Date(formik3?.values?.deliverableDetails?.[i]?.startDate)}
                      className={`${
                        Boolean(formik3.touched?.deliverableDetails?.[i]?.dueDate) && Boolean(formik3.errors?.deliverableDetails?.[i]?.dueDate)
                          ? ' border-danger'
                          : ''
                      } form-control chatInput `}
                      clearIcon={null}
                      name={`deliverableDetails.${[i]}.dueDate`}
                      value={new Date(formik3.values?.deliverableDetails?.[i].dueDate)}
                      onChange={(e) => handleChangeDatePicker(`deliverableDetails.${[i]}.dueDate`, e)}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <label className='form-label'>
                  Lead Mashers<span className='text-danger'> *</span>
                </label>
                <Select
                  styles={style}
                  options={masherOptions}
                  name={`deliverableDetails.${[i]}.masherRole`}
                  className={`${
                    Boolean(formik3.touched?.deliverableDetails?.[i]?.masherRole) && Boolean(formik3.errors?.deliverableDetails?.[i]?.masherRole)
                      ? 'form-control border-danger p-015'
                      : 'form-control p-015'
                  }`}
                  classNamePrefix='select'
                  placeholder='Lead Mashers'
                  value={formik3?.values?.deliverableDetails?.[i]?.masherRole}
                  onChange={(e) => {
                    formik3.setFieldValue(`deliverableDetails.${[i]}.masherRole`, e);
                    formik3.setFieldValue(`deliverableDetails.${[i]}.userId`, e?.userId);
                  }}
                />
                <InputErrorMessage
                  error={formik3.touched?.deliverableDetails?.[i]?.masherRole && formik3.errors?.deliverableDetails?.[i]?.masherRole}
                  marginBottom={-5}
                />
              </div>
              <div className='col-md-4 mb-2'>
                <label className='form-label'>
              Delivery Format<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  className={`${
                    Boolean(formik3.touched?.deliverableDetails?.[i]?.delieveryFormat) &&
                    Boolean(formik3.errors?.deliverableDetails?.[i]?.delieveryFormat)
                      ? ' border-danger'
                      : ' '
                  } form-control `}
                  placeholder='Delivery Format'
                  name={`deliverableDetails.${[i]}.delieveryFormat`}
                  value={formik3?.values?.deliverableDetails?.[i]?.delieveryFormat}
                  onChange={handleChange}
                />
                <InputErrorMessage
                  error={formik3.touched.deliverableDetails?.[i]?.delieveryFormat && formik3.errors?.deliverableDetails?.[i]?.delieveryFormat}
                  marginBottom={-5}
                />
              </div>
              <div className='col-md-4 mb-2'>
                <label className='form-label'>
              Order<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  className={`${
                    Boolean(formik3.touched?.deliverableDetails?.[i]?.order) && Boolean(formik3.errors?.deliverableDetails?.[i]?.order)
                      ? ' border-danger'
                      : ' '
                  } form-control`}
                  placeholder='Order'
                  name={`deliverableDetails.${[i]}.order`}
                  value={formik3?.values?.deliverableDetails?.[i]?.order}
                  onChange={handleChange}
                />
                <InputErrorMessage
                  error={formik3.touched.deliverableDetails?.[i]?.order && formik3.errors?.deliverableDetails?.[i]?.order}
                  marginBottom={-5}
                />
              </div>
              <div className='col-md-12 mb-3'>
                <label className='form-label'>
              Description<span className='text-danger'> *</span>
                </label>
                <textarea
                  onKeyUp={(e) => autoHeightText(e)}
                  className={`${
                    Boolean(formik3.touched?.deliverableDetails?.[i]?.description) && Boolean(formik3.errors?.deliverableDetails?.[i]?.description)
                      ? ' border-danger '
                      : ' '
                  } form-control `}
                  placeholder='Description'
                  rows='4'
                  name={`deliverableDetails.${[i]}.description`}
                  value={formik3?.values?.deliverableDetails?.[i]?.description}
                  onChange={handleChange}></textarea>
                <InputErrorMessage
                  error={formik3.touched?.deliverableDetails?.[i]?.description && formik3.errors?.deliverableDetails?.[i]?.description}
                  marginBottom={-5}
                />
              </div>
              <div className='col-md-12 mb-3'>
                {Boolean(formik3?.values?.deliverableDetails?.length - 1 === i) ? (
                  <div className='mb-2 col-2 btn-area-details d-inline mx-1 '>
                    <span
                      className='btn btn-sm btn-neutral border-base py-2 buton-width-del'
                      onClick={() => handleAddFields('deliverableDetails', formik3)}>
                      <i className='bi bi-plus-lg'></i> Add Details
                    </span>
                  </div>
                ) : null}

                {Boolean(self?.length > 1) && (
                  <div className='mb-2 col-2 btn-area-details d-inline mx-1 mb-3'>
                    <span
                      className='btn btn-sm btn-neutral-rmv btn-neutral border-base py-2 buton-width-del   text-danger '
                      onClick={() => handleRemoveFields('deliverableDetails', formik3, i)}>
                      <i className='bi bi-dash-lg text-danger'></i> Remove Details
                    </span>
                  </div>
                )}
                {formik3?.values?.deliverableDetails?.length > 1 ? <hr /> : ''}
              </div>
            </div>
          );
        })}

        <div className='col-md-12 mb-2'>
          <label className='form-label'>Budget</label>
          <div className='row'>
            <div className='col-12 col-sm-6'>
              <Select
                styles={style}
                placeholder={<div>Select Currency...</div>}
                options={currencyOptions}
                name='currency'
                className={'form-control  p-015 '}
                classNamePrefix='select'
                value={formik3.values.currency}
                onChange={(e) => {
                  formik3.setFieldValue('currency', [{ value: e.value, label: e.label }]);
                }}
              />
              {/* <InputErrorMessage error={formik3.touched.currency && formik3.errors.currency} marginBottom={5} /> */}
            </div>
            <div className='col-12 col-sm-6'>
              <input
                type='number'
                className={'  form-control '}
                placeholder='Budget'
                name='budget'
                value={formik3.values.budget}
                onChange={handleChange}
              />
              {/* <InputErrorMessage error={formik3.touched.budget && formik3.errors.budget} marginBottom={-5} /> */}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 my-3'>
            <div className='col-md-12 create_brief_links'>
              <label className='form-label'>Client Website / Social / Press Links</label>
              {formik3.values.websiteLink?.map((el, i, initArr) => {
                 
                return (
                  <div key={i}>
                    <div className='row' style={{ marginBottom: '20px' }}>
                      <div className='col-12 col-sm-6'>
                        <input
                          type='text'
                          className={' form-control'}
                          placeholder='Name here...'
                          name={`websiteLink.${[i]}.name`}
                          value={formik3.values.websiteLink?.[i]?.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='col-12 col-sm-6'>
                        <input
                          type='text'
                          className={' form-control '}
                          placeholder='Links Here...'
                          name={`websiteLink.${[i]}.link`}
                          value={formik3.values.websiteLink?.[i]?.link}
                          onChange={handleChange}
                        />
                        <InputErrorMessage error={formik3.touched.websiteLink?.[i]?.link && formik3.errors.websiteLink?.[i]?.link} marginBottom={10} />
                      </div>
                    </div>
                    {/* <InputErrorMessage error={formik3.touched.websiteLink && formik3.errors.websiteLink[i].link} marginBottom={-5} /> */}
                    {Boolean(formik3.values.websiteLink?.length - 1 === i) ? (
                      <div className='mb-2 col-2 p-0 btn-area-details d-inline mt-5 mx-1 ' style={{ marginLeft: '0px' }}>
                        <span
                          className='btn btn-sm btn-neutral border-base py-2 buton-width-del mb-5 mx-1'
                          onClick={() => handleAddFields('websiteLink', formik3)}>
                          <i className='bi bi-plus-lg'></i> Add More
                        </span>
                      </div>
                    ) : null}
                    {Boolean(initArr?.length > 1) && (
                      <div className='mb-2 col-2 p-0 btn-area-details d-inline mt-5 mx-1'>
                        <span
                          className='btn btn-sm btn-neutral-rmv btn-neutral border-base py-2 buton-width-del mb-5 text-danger  mx-1 '
                          onClick={() => handleRemoveFields('websiteLink', formik3, i)}>
                          <i className='bi bi-dash-lg text-danger'></i> Remove
                        </span>
                      </div>
                    )}
                  </div>
                );
               
              })}
            </div>
            {formik3?.values?.websiteLink?.length > 1 ? <hr /> : ''}
            
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <div className='col-md-12 create_brief_links'>
              <label className='form-label'>Attachment</label>
              {formik3.values.attachments?.map((el, i, initArr) => {
                return (
                  <div key={i}>
                    <div className='row  '>
                      <div className='col-12 col-sm-6'>
                        {helperBriefAttachmentCheck(formik3?.values?.attachments[i]?.file, formik3, i)}
                        <div className='profileImgUpload d-inline-block'>
                          <label htmlFor={`attachment${i}`}>
                            <div
                              className='changeFileBtn'
                              onClick={() => {
                                inputRef?.current?.click();
                              }}>
                              Choose File
                            </div>
                          </label>

                          {formik3.values.attachments[i]?.file ? (
                            <div
                              className='removeFileBtn d-inline-block'
                              onClick={() => {
                                formik3.setFieldValue(`attachments.${[i]}.file`, '');
                              }}>
                              Remove File
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className='col-12 col-sm-6'>
                        <input
                          id={`attachment${i}`}
                          type='file'
                          className='form-control d-none '
                          placeholder='Upload'
                          name={`attachments.${[i]}.file`}
                          onChange={(event) => helperFileUpload(formik3, event)}
                        />
                        <input
                          type='text'
                          className='form-control'
                          placeholder='File Name'
                          name={`attachments.${[i]}.name`}
                          value={formik3.values.attachments?.[i]?.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                      {Boolean(formik3.values.attachments?.length - 1 === i) ? (
                        <div className='mb-2 p-0 btn-area-details d-inline  mx-1 '>
                          <span className='btn btn-sm btn-neutral border-base py-2 buton-width-del mb-5   mx-1 ' onClick={handleAddattachement}>
                            <i className='bi bi-plus-lg m-0'></i>
                          </span>
                        </div>
                      ) : null}
                      {Boolean(initArr?.length > 1) && (
                        <div className='mb-2 p-0 btn-area-details d-inline  mx-1'>
                          <span
                            className=' removeFileBtn btn btn-sm text-white  btn-neutral border-base py-2 buton-width-del mb-5  mx-1 '
                            onClick={() => handleRemoveattachement(i)}>
                            <i className='bi bi-trash  text-white m-0 '></i>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {formik3?.values?.attachments?.length > 1 ? <hr /> : ''}
          </div>
        </div>

        <div className='col-md-12 next-btn-cntnr mb-5 mt-5'>
          <Link to='' className='btn btn-sm btn-neutral border-base me-3' onClick={() => setStep((v) => v - 1)}>
            Back
          </Link>
          <button type='submit' className='btn btn-sm btn_primary_black btn-width'>
            Next
          </button>
        </div>
      </form>
    </>
  );
}
