import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {  EditProfileLoader } from '../../components/common/loader';
import { DashContext } from '../../appContext';
import {getBriefsWithoutProjectsFromTypesense, getProjectsFromTypesense, getMashersFromTypesense, getCompaniesFromTypesense} from '../../apis/typesenseApis';

const DashtotalCounts = () => {

  const [allBriefs, setAllBriefs] = useState();
  const [allProjects, setAllProjects] = useState();
  const [allMashers, setAllMashers] = useState();
  const [allCompanies, setAllCompanies] = useState();
  const [deliverables, setDeliverables] = useState();
  const TableData = useContext(DashContext);
  const { dashState } = TableData;
  

  const getAllBriefs = async () => {
    const briefs = await getBriefsWithoutProjectsFromTypesense();
    setAllBriefs(briefs.data);
  }; 
  const getAllProjects = async () => {
    const projects = await getProjectsFromTypesense();
    setAllProjects(projects.data);
  }; 
  const getAllMashers = async () => {
    const mashers = await getMashersFromTypesense();
    setAllMashers(mashers.data);
  }; 
  const getAllCompanies = async () => {
    const companies = await getCompaniesFromTypesense();
    setAllCompanies(companies.data);
  }; 
  const getDeliverables = async () => {
    let deliverablesCount = 0;
    for (let i = 0; i < allProjects; i++) {
      deliverablesCount += allProjects[i];
    }
  };

  useEffect(()=> {
    getAllBriefs();
    getAllProjects();
    getAllMashers();
    getAllCompanies();
  }, []);

  const getDateComparison = (hits) => {
    let countTotal = 0;
    let now = new Date();

    let last30days = now.setDate(now.getDate() - 30);
    for (let i = 0; i < hits?.length; i++) {
      let hitDate = new Date(hits[i]?.document?.createdAt);
      if( hitDate > last30days){
        countTotal += 1;
      }
    }
    return countTotal;
  };
 
  return (
    <div className='dashboard-cards !justify-center md:!justify-between md:px-12'>
      <div className='dashboard-card-container'>
        <div className='dashboard-card'>
          <div className='dashboard-head'>
            <h4>Briefs </h4>
          </div>
          <div className='dashboard-card-body'>
            {allBriefs ? 
              <>
                <span className='dashboard-card-count'>
                  {allBriefs?.found || 0}
                </span>
                <div className='dashboard-card-stats-container'>
                  <span className='dashbaord-card-stats'> {getDateComparison(allBriefs?.hits)|| 16}</span>
                  <p className='dashboard-card-stats-footer'>  new last month</p>
                </div>
              </>
              :
              <div className='dashboard-card-count-loader'>
                <EditProfileLoader />
              </div>
            }
          </div>
        </div>
      </div>
      <div className='dashboard-card-container'>
        <div className='dashboard-card'>
          <div className='dashboard-head'>
            <h4>Projects</h4>
          </div>
          <div className='dashboard-card-body'>
            <span className='dashboard-card-count'>
              {allProjects?.found || 0}
            </span>
            <div className='dashboard-card-stats-container'>
              <span className='dashbaord-card-stats'>{getDateComparison(allProjects?.hits)|| 0}</span>
              <p className='dashboard-card-stats-footer'>new last month</p>
            </div>
          </div>
        </div>
      </div>
      <div className='dashboard-card-container'>
        <div className='dashboard-card'>
          <div className='dashboard-head'>
            <h4>Mashers</h4>
          </div>
          <div className='dashboard-card-body'>

            <span className='dashboard-card-count'>
              {allMashers ? (
                allMashers?.found || 0
              ) : (
                <div className='dashboard-card-count-loader'>
                  <EditProfileLoader />
                </div>
              )}
            </span>
            <div className='dashboard-card-stats-container'>
              <span className='dashbaord-card-stats'>{getDateComparison(allMashers?.hits) || 7}</span>
              <p className='dashboard-card-stats-footer'>new last month</p>
            </div>
          </div>
        </div>
      </div>
      <div className='dashboard-card-container'>
        <div className='dashboard-card'>
          <div className='dashboard-head'>
            <h4>Companies</h4>
          </div>
          <div className='dashboard-card-body'>
            <span className='dashboard-card-count'>
              {allCompanies ? (allCompanies?.found || 0
              ) : (
                <div className=''>
                  <EditProfileLoader />
                </div>
              )}
            </span>
            <div className='dashboard-card-stats-container'>
              <span className='dashbaord-card-stats'>{getDateComparison(allCompanies?.hits) || 10}</span>
              <p className='dashboard-card-stats-footer'>new last month</p>
            </div>
            <div className='col-auto'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashtotalCounts;
