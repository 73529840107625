import { useFormik } from 'formik';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import Flatpickr from 'react-flatpickr';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_API } from '../../utils/const';
import {
  fetchCountrycodeApi,
  getMasherByIdApi,
  updateMasherProfileApi,
  updateUserByIdApi,
  uploadProfileImgApi,
  getAllClientsApi,
  fetchIndustryApi,
  getOtherSkillsApi,
  getBrandsDataApi,
  getLocationInputApi,
  getTimeZoneLocationApi
} from '../../../apis';
import ProfileDefault from '../../../Assets/img/covers/userDefault.png';
import { EditProfileLoader, Loader } from '../../../components/common/loader';
import { InputErrorMessage } from '../../../components/errorMessages';
import { EditProfileValidation } from '../../../components/Validation';
import { assignBucketUrl, autoHeightText, getLastElementId, helperImageUpload, helperVideoUpload, style } from '../../../utils/helperFunctions';
import { countryNameCode } from '../../../utils/countryCode';
import PhoneInput from 'react-phone-number-input';
import { countryCodeOptions } from './CountryCode';
import LANGUAGES from '../../../Assets/docs/languages.json';

export default function Profile() {
  const [loader, setLoader] = useState(false);
  const [code, setCode] = useState(false);
  const [timezoneArrayD, setTimezoneArrayD] = useState({ data: null, loading: false });

  const [profileData, setProfileData] = useState({ data: null, loading: true });
  const [skills, setSkills] = useState({ data: null, loading: false });
  const masherId = getLastElementId();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [select, setSelect] = useState({ industryType: '', clientsWorkedFor: '' });
  const [selected, setSelected] = useState({ industryType: { value: '', label: '' }, clientsWorkedFor: { value: '', label: '' } });
  const [brands, setBrands] = useState({ data: null, loading: false });
  const [countryCodeArr, setCountryCodeArr] = useState({ data: null });
  const [countryCodeD, setCountryCodeD] = useState({ label: 'Australia (+61)', value: '+61', iso: 'AU' });

  const indOptions =
    select?.industry &&
    select?.industry?.map((el, i) => {
      const container = {};
      container['value'] = el.id;
      container['label'] = el?.name || '--';
      return container;
    });

  const typOptions =
    select?.clientsWorkedFor &&
    select?.clientsWorkedFor?.map((el, i) => {
      const container = {};
      container['value'] = el._id;
      container['label'] = el?.brandName || '--';
      return container;
    });

  const options = skills?.data?.map((el, i) => {
    const container = {};
    container['value'] = el.id;
    container['label'] = el.name;
    return container;
  });

  const brandOptions = brands?.data?.map((el, i) => {
    const container = {};
    container['value'] = el._id;
    container['label'] = el?.brandName || '--';
    container['logo'] = el.logo;
    return container;
  });
  const loveBrandOptions = brands?.data?.map((el, i) => {
    const container = {};
    container['value'] = el._id;
    container['label'] = el?.brandName || '--';
    container['logo'] = el.logo;
    return container;
  });

  const countryCode = countryCodeArr?.data?.countries?.map(el => {
    let container = {};
    container['label'] = `${el?.name} (${el?.code})` || '--';
    container['value'] = el?.code;
    return container;
  });

  const otherSkillsData = async () => {
    const res = await getOtherSkillsApi();
    if (res.status === 200) {
      setSkills({ data: res.data });
    }
  };
  const getAllTimeZones = async (country, latitude, longitude) => {
    const res = await getTimeZoneLocationApi(country, latitude, longitude);
    if (res.status === 201) {
      setTimezoneArrayD({ data: res?.data });
    }
  };

  const handleLocationSet = (e) => {
    formik.setFieldValue('location', e.formatted_address);
    // setDummy({ value: e.formatted_address, label: e.formatted_address })
    getAllTimeZones(e.formatted_address, e.geometry.location.lat(), e.geometry.location.lng());
  };

  const brandsData = async () => {
    const res = await getAllClientsApi();
    if (res.status === 200) {
      setBrands({ data: res.data.allClients });
    }
  };


  const handleChangeInstagram = (e) => {
    if (e.target.value[0] === '@') {
      formik.setFieldValue(e.target.name, e.target.value);

      formik.handleChange(e);
    } else {
      toast('Please include an @ at the start', { icon: '⚠️' });
      // toast.error("Please include an @ at the start", { id: "011" });
      formik.setFieldError('instagram', 'Please include an @ at the start');
    }
  };
  const fetchSelectRegion = async () => {
    const industryRes = await fetchIndustryApi();
    const clientsRes = await getAllClientsApi();
    setSelect({ industry: industryRes.data, clientsWorkedFor: clientsRes.data.allClients });
  };
  // const getAllTimeZones = async (country) => {
  //   const res = await getTimeZoneLocationApi(country);
  //   if (res.status === 201) {
  //     setTimeZoneList({ data: res?.data })
  //   }
  // };

  const handleFileUpload = (event) => {
    helperImageUpload(formik, event);
  };

  const handleCvUpload = async (event) => {
    try {
      const masherCv = event.target.files[0];
      if (!masherCv) {
        return;
      }
      const type = masherCv.type.split('/')[1];
      const cvType = ['pdf', 'docx'];
      const validCv = cvType.includes(type);

      if (!validCv) {
        formik.setErrors({ cv: 'Upload a valid cv : pdf, docx' });
        return;
      }
      let fr = new FileReader();
      fr.readAsDataURL(masherCv);
      fr.onload = function () { };
      formik.setErrors({});

      const formdata = new FormData();
      formdata.append('file', masherCv);
      const resFileUpload = await uploadProfileImgApi(formdata);
      if (resFileUpload.status === 200) {
        formik.setFieldValue('cv', resFileUpload?.data?.filePath);
        toast.success(resFileUpload.data.message, { id: 'succcess' });
      }
    } catch (error) {
      toast('Not able to update CV', { icon: '⚠️' });

      toast.error('Not able to update CV');
    }
  };

  const handleChangeBirth = (e) => {
    formik.setFieldValue('birthDate', new Date(e).toISOString());
  };

  const languagesOptions = LANGUAGES;
  // [
  //   { value: '1', label: 'Arabic' },
  //   { value: '2', label: 'Bahasa' },
  //   { value: '3', label: 'Chinese (simplified)' },
  //   { value: '4', label: 'Chinese (traditional)' },
  //   { value: '5', label: 'Danish' },
  //   { value: '6', label: 'Dutch (Netherlands)' },
  //   { value: '7', label: 'English' },
  //   { value: '8', label: 'Finnish' },
  //   { value: '9', label: 'French' },
  //   { value: '10', label: 'German' },
  //   { value: '11', label: 'Greek' },
  //   { value: '12', label: 'Hindi' },
  //   { value: '13', label: 'Japanese' },
  //   { value: '14', label: 'Korean' },
  //   { value: '15', label: 'Norwegian' },
  //   { value: '16', label: 'Polish' },
  //   { value: '17', label: 'Portuguese' },
  //   { value: '18', label: 'Russian' },
  //   { value: '19', label: 'Spanish' },
  //   { value: '20', label: 'Swedish' },
  //   { value: '21', label: 'Thai' },
  //   { value: '22', label: 'Vietnamese' },
  // ];

  const timezoneOptions = timezoneArrayD?.data?.listedTimeZonesWithTime?.map((el, i) => {
    const container = {};
    container['value'] = el.name;
    container['label'] = el.value;
    return container;
  });
  const visibletoOthers = [{ value: true, label: 'Yes' }, { value: false, label: 'No' }];

  const formik = useFormik({
    initialValues: {
      id: '',
      image: '',
      firstName: '',
      lastName: '',
      email: '',
      cv: '',
      brand: '',
      birthDate: '',
      introVideo: '',
      portfolioVideo: '',
      skills: '',
      language: '',
      location: '',
      phoneNo: '',
      otherLinks: '',
      countryCode: '',
      lovedBrand: '',
      profilePic: '',
      timezone: '',
      linkedin: '',
      facebook: '',
      twitter: '',
      instagram: '',
      tiktok: '',
      websiteUrl: '',
      biography: '',
      industryType: '',
      clientsWorkedFor: '',
      isVisibletoOthers: ''
    },
    validationSchema: EditProfileValidation,
    onSubmit: async (values) => {
      const {
        coverPic,
        profilePic,
        firstName,
        lastName,
        language,
        phoneNo,
        cv,
        otherLinks,
        skills,
        location,
        birthDate,
        introVideo,
        portfolioVideo,
        countryCode,
        lovedBrand,
        brand,
        timezone,
        linkedin,
        facebook,
        twitter,
        instagram,
        tiktok,
        websiteUrl,
        biography,
        industryType,
        clientsWorkedFor,
        isVisibletoOthers
      } = formik.values;

      try {
        setLoader(true);
        const res1 = await updateMasherProfileApi({
          language,
          phoneNo,
          cv,
          otherLinks,
          skills,
          location,
          birthDate,
          introVideo,
          portfolioVideo,
          lovedBrand,
          brand,
          timezone,
          linkedin,
          facebook,
          twitter,
          instagram,
          tiktok,
          websiteUrl,
          countryCode: countryCode?.value,
          id: profileData?.data?._id,
          coverPic,
          biography,
          industryType,
          clientsWorkedFor,
          isVisibletoOthers: isVisibletoOthers?.value
        });
        const payload2 = { firstName, lastName, id: profileData?.data?.userId?._id, profilePic };

        const res2 = await updateUserByIdApi(payload2);

        if (res1.status === 200) {
          setLoader(false);
          toast.success(res1.data.message, { id: 'succcess' });
          navigate(`/mashers/mashers-profile/${masherId}`);
        }
      } catch (error) {
        setLoader(false);
        toast('Something Went Wrong', { icon: '⚠️' });
      }
    },
  });

  const handleChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    e.preventDefault();
    formik.handleChange(e);
  };

  const getMasherProfile = async (id) => {
    setProfileData({ loading: true });
    try {
      const res = await getMasherByIdApi(id);
      if (res.status === 200) {
        setProfileData({ data: res.data.masherProfile, loading: false });
      }
    } catch (error) {
      setProfileData({ data: null, loading: false });
    }
  };

  const fetchCountryCodes = async () => {
    const res = await fetchCountrycodeApi();
    if (res.status === 200) {
      setCountryCodeArr({ data: res?.data });
    }
  };

  // const [dummy, setDummy] = useState({ label: formik.values.location || "", value: formik.values.location || "" })

  const fetchLocationArray = useCallback(
    debounce((inputText, callback) => {
      if (callback) {
        handleLocationChange(inputText).then((options) => callback(options));
      }
    }, 700),
    []
  );
  const handleLocationChange = async (search) => {
    try {
      const res = await getLocationInputApi(search);
      if (res.status === 200) {
        return res?.data?.length ? res.data.map((e) => ({ label: e.formatted_address, value: e.formatted_address, latitude: e.geometry.location.lat, longitude: e.geometry.location.lng })) : null;
      }
    } catch (error) {
      return null;
    }
  };

  const getCountryExist = (location) => {
    let data = countryCodeOptions.find(item => item.label.toLowerCase() == location.trim().toLowerCase());
    return data;
  };
  useEffect(() => {
    if (formik.values.location === '') {
    } else {
      let location = null;
      if (formik.values.location.includes(',') && formik.values.location.split(',').length <= 3) {
        let rec = formik.values.location?.split(',');
        if (rec?.[rec.length - 1]?.trim()?.split(' ')) {
          let loc = rec?.[rec.length - 1]?.trim();
          location = loc.match(/\d/) ? loc.split(' ')?.[0] : loc;
        } else {
          location = formik.values.location?.split(',')[1];
        }
      } else {
        location = formik.values.location?.split(' ')?.length > 2 ? formik.values.location.split(' ')?.[formik.values.location?.split(' ')?.length - 1] : formik.values.location;
      }
      const obj = getCountryExist(location);
      const c_code = {};
      c_code['label'] = obj?.label;
      c_code['value'] = obj?.value;
      c_code['iso'] = obj?.iso;
      if (c_code !== null) {
        let _countrCode = (formik.values.countryCode && formik.values.countryCode.value ? formik.values.countryCode.value : formik.values.countryCode);
        if (formik.values.countryCode && _countrCode != c_code['value']) {
          formik.setFieldValue('phoneNo', '');
          formik.setFieldValue('timezone', '');
        }
        formik.setFieldValue('countryCode', c_code);
        setCountryCodeD(c_code);
      }
    }
  }, [formik.values.location]);
  useEffect(() => {
    getMasherProfile(masherId);
    fetchCountryCodes();
    fetchSelectRegion();
    otherSkillsData();
    brandsData();
    fetchLocationArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const Data = profileData?.data || {};
    formik.setValues({
      id: Data?.userId?._id,
      profilePic: Data?.userId?.profilePic || '',
      coverPic: Data?.coverPic || '',
      firstName: Data?.userId?.firstName || '',
      lastName: Data?.userId?.lastName || '',
      email: Data?.userId?.email || '',
      birthDate: Data?.birthDate || '',
      brand: Data?.brand || '',
      skills: Data?.skills || '',
      language: Data?.language || '',
      cv: Data?.cv || '',
      introVideo: Data?.introVideo || '',
      lovedBrand: Data?.lovedBrand || '',
      location: Data?.location || '',
      phoneNo: Data?.phoneNo || '',
      otherLinks: Data?.otherLinks || '',
      portfolioVideo: Data?.portfolioVideo || '',
      countryCode: Data?.countryCode,
      timezone: Data?.timezone || '',
      linkedin: Data?.linkedin || '',
      facebook: Data?.facebook || '',
      twitter: Data?.twitter || '',
      instagram: Data?.instagram || '',
      tiktok: Data?.tiktok || '',
      websiteUrl: Data?.websiteUrl || '',
      biography: Data?.biography || '',
      industryType: Data?.industryType || '',
      clientsWorkedFor: Data?.clientsWorkedFor || '',
      isVisibletoOthers: { value: !Object.keys(Data).includes('isVisibletoOthers') ? true : Data?.isVisibletoOthers ? true : false, label: !Object.keys(Data).includes('isVisibletoOthers') ? 'Yes' : Data.isVisibletoOthers ? 'Yes' : 'No' } || '',

    });
    // setDummy({ value: Data?.location, label: Data?.location })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  return (
    <>
      {/* Container */}

      {profileData?.loading ? (
        <div className='text-center my-5'>
          <Loader />
        </div>
      ) : (
        <div className=''>
          <div className='col-md-6 d-inline-block edit-avatar ' style={{ position: 'relative' }}>
            <label className='form-label' style={{ display: 'block' }}>
              Profile Image
            </label>

            {formik?.values?.profilePic ? (
              <img
                alt=''
                onError={(event) => {
                  event.target.src = ProfileDefault;
                  event.onerror = null;
                }}
                src={assignBucketUrl(formik?.values?.profilePic)}
                style={{
                  width: '60px',
                  transition: '0.2s all',
                  height: '60px',
                  border: '1px solid rgb(25, 19, 19, 0.25)',
                  borderRadius: '50%',
                  boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                  marginRight: '10px',
                }}
              />
            ) : (
              <img
                alt=''
                onError={(event) => {
                  event.target.src = ProfileDefault;
                  event.onerror = null;
                }}
                src={assignBucketUrl(ProfileDefault)}
                style={{
                  width: '60px',
                  transition: '0.2s all',
                  height: '60px',
                  border: '1px solid rgb(25, 19, 19, 0.25)',
                  borderRadius: '50%',
                  boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                  marginRight: '10px',
                }}
              />
            )}
            <div className='profileImgUpload d-inline-block'>
              <label htmlFor='profile'>
                <div
                  className='changeFileBtn'
                  onClick={() => {
                    inputRef?.current?.click();
                  }}>
                  Choose File
                </div>
              </label>
              {formik.values?.profilePic !== '' ? (
                <div
                  className='removeFileBtn d-inline-block'
                  onClick={() => {
                    formik.setFieldValue('profilePic', '');
                  }}>
                  Remove
                </div>
              ) : (
                ''
              )}
            </div>
            <input
              type='file'
              title=' '
              id='profile'
              className={
                Boolean(formik.touched?.profilePic) && Boolean(formik.errors?.profilePic)
                  ? ' d-none  edit-pen2 fileInput'
                  : 'd-none edit-pen2 fileInput'
              }
              name='profilePic'
              onChange={handleFileUpload}
            />
            <InputErrorMessage error={formik.touched.profilePic && formik.errors.profilePic} marginBottom={-5} />
          </div>

          <div className='col-md-6 d-inline-block edit-avatar ' style={{ position: 'relative' }}>
            <label className='form-label' style={{ display: 'block' }}>
              Cover Image
            </label>

            {formik?.values?.coverPic ? (
              <img
                alt=''
                onError={(event) => {
                  event.target.src = ProfileDefault;
                  event.onerror = null;
                }}
                src={assignBucketUrl(formik?.values?.coverPic)}
                style={{
                  width: '60px',
                  transition: '0.2s all',
                  height: '60px',
                  border: '1px solid rgb(25, 19, 19, 0.25)',
                  borderRadius: '50%',
                  boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                  marginRight: '10px',
                }}
              />
            ) : (
              <img
                alt=''
                onError={(event) => {
                  event.target.src = ProfileDefault;
                  event.onerror = null;
                }}
                src={assignBucketUrl(ProfileDefault)}
                style={{
                  width: '60px',
                  transition: '0.2s all',
                  height: '60px',
                  border: '1px solid rgb(25, 19, 19, 0.25)',
                  borderRadius: '50%',
                  boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                  marginRight: '10px',
                }}
              />
            )}
            <div className='profileImgUpload d-inline-block'>
              <label htmlFor='coverImg'>
                <div
                  className='changeFileBtn'
                  onClick={() => {
                    inputRef?.current?.click();
                  }}>
                  Choose File
                </div>
              </label>

              {formik.values?.coverPic !== '' ? (
                <div
                  className='removeFileBtn d-inline-block  '
                  onClick={() => {
                    formik.setFieldValue('coverPic', '');
                  }}>
                  Remove
                </div>
              ) : (
                ''
              )}
            </div>
            <input
              type='file'
              title=' '
              id='coverImg'
              className={
                Boolean(formik.touched?.coverPic) && Boolean(formik.errors?.coverPic) ? ' d-none edit-pen2 fileInput' : ' d-none edit-pen2 fileInput'
              }
              name='coverPic'
              onChange={handleFileUpload}
            />
            <InputErrorMessage error={formik.touched.coverPic && formik.errors.coverPic} marginBottom={-5} />
          </div>

          <form onSubmit={formik.handleSubmit} className='mb-10'>
            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label'>
                  First Name <span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='firstName'
                  value={formik.values.firstName}
                  onChange={handleChange}
                  disabled={loader}
                  className={Boolean(formik.touched?.firstName && formik.errors?.firstName) ? 'form-control border-danger' : 'form-control'}
                  placeholder='First Name'
                />
                <InputErrorMessage error={formik.touched.firstName && formik.errors.firstName} marginBottom={-5} />
              </div>
              <div className='col-md-6'>
                <label className='form-label'>
                  Last Name <span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='lastName'
                  onChange={handleChange}
                  value={formik.values.lastName}
                  className={Boolean(formik.touched?.lastName && formik.errors?.lastName) ? 'form-control border-danger' : 'form-control'}
                  disabled={loader}
                  placeholder='Last Name'
                />
                <InputErrorMessage error={formik.touched.lastName && formik.errors.lastName} marginBottom={-5} />
              </div>
              <div className='col-md-6'>
                <label className='form-label'>
                  Email address <span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='email'
                  onChange={handleChange}
                  value={formik.values.email}
                  className={Boolean(formik.touched?.email && formik.errors?.email) ? 'form-control border-danger' : 'form-control'}
                  disabled={loader}
                  placeholder='Your email address'
                />
                <InputErrorMessage error={formik.touched.email && formik.errors.email} marginBottom={-5} />
              </div>

              <div className='col-md-6'>
                <label className='form-label'>
                  Top Skills <span className='text-danger'> *</span>
                </label>
                <CreatableSelect
                  styles={style}
                  isMulti
                  closeMenuOnSelect={false}
                  options={options}
                  maxLength={2}
                  name='skills'
                  placeHolder='Your top skills'
                  value={formik.values.skills}
                  className={Boolean(formik.touched?.skills && formik.errors?.skills) ? 'form-control border-danger p-0' : 'form-control p-0'}
                  onChange={(e) => {
                    formik.setFieldValue('skills', e);
                  }}
                />
                <InputErrorMessage error={formik.touched.skills && formik.errors.skills} marginBottom={-5} />
              </div>
              <div className='col-md-6'>
                <label className='form-label'>
                  Languages <span className='text-danger'> *</span>
                </label>
                <CreatableSelect
                  styles={style}
                  closeMenuOnSelect={false}
                  isMulti
                  name='language'
                  options={languagesOptions}
                  className={Boolean(formik.touched?.language && formik.errors?.language) ? 'form-control border-danger p-0' : 'form-control p-0'}
                  onChange={(e) => {
                    formik.setFieldValue('language', e);
                  }}
                  placeHolder='Languages you know'
                  value={formik.values.language}
                />
                <InputErrorMessage error={formik.touched.language && formik.errors.language} marginBottom={-5} />
              </div>
              <div className='col-md-6'>
                <label className='form-label'>Birth Date <small><em>(for birthday celebration purpose only)</em></small></label>
                <Flatpickr
                  className='form-control cursor-pointer flatpickr-month'
                  style={{ backgroundColor: '#fff' }}
                  options={{ dateFormat: 'M j' }}
                  name='birthDate'
                  value={formik.values.birthDate}
                  onChange={(event) => {
                    handleChangeBirth(event);
                  }}
                />
                <InputErrorMessage error={formik.touched.birthDate && formik.errors.birthDate} marginBottom={-5} />
              </div>

              <div className='col-md-6'>
                <label className='form-label'>
                  Industry
                </label>
                <Select
                  options={indOptions}
                  closeMenuOnSelect={false}
                  isMulti
                  name='industryType'
                  className={Boolean(formik.touched?.industryType && formik.errors?.industryType) ? 'form-control border-danger p-0' : 'form-control p-0'}
                  classNamePrefix='select'
                  value={formik.values.industryType}
                  onChange={(e) => {
                    setSelected({ ...selected, industryType: { value: e?.value, label: e?.label } });
                    formik.setFieldValue('industryType', e);
                  }}
                />
                <InputErrorMessage error={formik.touched.industryType && formik.errors.industryType} marginBottom={-5} />
              </div>
              <div className='col-md-6'>
                <label className='form-label'>
                  Clients worked for
                </label>
                <CreatableSelect
                  isMulti
                  closeMenuOnSelect={false}
                  options={typOptions}
                  name='clientsWorkedFor'
                  placeHolder='Select'
                  value={formik.values.clientsWorkedFor?.length > 0
                    ? formik.values.clientsWorkedFor?.map((el) => {
                      return el;
                    })
                    : ['']}
                  className="form-control p-0"
                  onChange={(e) => {
                    formik.setFieldValue('clientsWorkedFor', e);
                  }}
                />
                <InputErrorMessage error={formik.touched.clientsWorkedFor && formik.errors.clientsWorkedFor} marginBottom={-5} />
              </div>
              <div className='col-md-6'>
                <label className='form-label'>
                  Full Address <span className='text-danger'> *</span>
                </label>
                <Autocomplete
                  fetchDetails={true} // you need this to fetch the details object onPress
                  apiKey={GOOGLE_API}
                  placeholder={''}
                  name='location'
                  className={Boolean(formik.touched?.location && formik.errors?.location) ? 'form-control border-danger' : 'form-control'}
                  defaultValue={formik.values.location}
                  onPlaceSelected={(s) => handleLocationSet(s)}
                  options={{ types: ['geocode', 'establishment'] }} />

                {/* <AsyncSelect
                  cacheOptions
                  loadOptions={fetchLocationArray}
                  onChange={(e) => { handleLocationSet(e) }}
                  defaultOptions
                  name='location'
                  className={Boolean(formik.touched?.timezone && formik.errors?.timezone) ? "form-control border-danger p-0" : "form-control p-0"}
                  value={dummy}
                  onFocus={() => { setDummy({ value: "", label: "" }) }}
                /> */}
                <InputErrorMessage error={formik.touched.location && formik.errors.location} marginBottom={-5} />
              </div>

              <div className='col-md-6'>
                <label className='form-label'>Time Zone</label>
                <Select
                  styles={style}
                  className={Boolean(formik.touched?.timezone && formik.errors?.timezone) ? 'form-control border-danger p-0' : 'form-control p-0'}
                  name='timezone'
                  options={timezoneOptions}
                  placeHolder='Your timezone'
                  onChange={(e) => formik.setFieldValue('timezone', e)}
                  value={formik.values.timezone}
                />
                <InputErrorMessage error={formik.touched.timezone && formik.errors.timezone} marginBottom={-5} />
              </div>

              <div className='col-md-6 mb-2'>
                <div className='row phone-wrap'>
                  <label className='form-label'>
                    Phone No. <span className='text-danger'> *</span>
                  </label>
                  <div className='phone'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <PhoneInput
                          placeholder="Enter phone number"
                          defaultCountry={countryCodeD?.iso}
                          international
                          value={formik?.values?.phoneNo}
                          rules={{ required: true }}
                          onChange={(e) => {
                            if (e) {
                              setCountryCodeD({ label: countryCodeOptions.filter(item => item.value == e.trim())?.[0]?.iso, value: e });
                            }
                            formik.setFieldValue('phoneNo', e || '');
                          }}
                        />
                        <InputErrorMessage error={formik.touched.phoneNo && formik.errors.phoneNo} marginBottom={-5} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-5">
                <label className="form-label">Do you want to be seen by other mashers?</label>
                {/* <span className="text-danger"> *</span> */}
                <div className="position-relative">
                  <Select
                    name="isVisibletoOthers"
                    className={Boolean(formik.touched.isVisibletoOthers && formik.errors.isVisibletoOthers ? 'form-control border-danger p-0' : 'form-control p-0')}
                    options={visibletoOthers}
                    // selected={{ label: 'Australia (+61)', value: '+61' }}
                    onChange={(e) => formik.setFieldValue('isVisibletoOthers', e)}
                    value={formik.values.isVisibletoOthers}
                    isClearable={false}
                  />
                </div>
                <InputErrorMessage error={formik.touched.isVisibletoOthers && formik.errors.isVisibletoOthers} marginBottom={-5} />
              </div>

              <div className='col-md-6'>
                <label className='form-label'>Brands I Love</label>
                <CreatableSelect
                  styles={style}
                  isMulti
                  closeMenuOnSelect={false}
                  maxLength={2}
                  name='lovedBrand'
                  placeHolder='Brands I Love'
                  options={loveBrandOptions}
                  value={formik.values.lovedBrand}
                  className={Boolean(formik.touched?.lovedBrand && formik.errors?.lovedBrand) ? 'form-control border-danger p-0' : 'form-control p-0'}
                  onChange={(e) => {
                    formik.setFieldValue('lovedBrand', e);
                  }}
                />
                <InputErrorMessage error={formik.touched.lovedBrand && formik.errors.lovedBrand} marginBottom={-5} />
              </div>

              <div className='col-md-6'>
                <label className='form-label'>Brands I have worked with</label>
                <CreatableSelect
                  styles={style}
                  isMulti
                  maxLength={2}
                  name='brand'
                  options={brandOptions}
                  placeHolder='Brands I have worked with'
                  value={formik.values.brand}
                  className={Boolean(formik.touched?.brand && formik.errors?.brand) ? 'form-control border-danger p-0' : 'form-control p-0'}
                  onChange={(e) => {
                    formik.setFieldValue('brand', e);
                  }}
                />
                <InputErrorMessage error={formik.touched.brand && formik.errors.brand} marginBottom={-5} />
              </div>

              <div className='col-md-6 mb-5'>
                <label className='form-label'>Linked In</label>
                <input
                  type='text'
                  className={Boolean(formik.touched?.linkedin && formik.errors?.linkedin) ? 'form-control border-danger' : 'form-control'}
                  name='linkedin'
                  value={formik.values.linkedin}
                  onChange={handleChange}
                  placeholder='linkedin'
                />
                <InputErrorMessage error={formik.touched.linkedin && formik.errors.linkedin} marginBottom={-5} />
              </div>
              <div className='col-md-6 mb-5'>
                <label className='form-label'>Facebook</label>
                <input
                  type='text'
                  className={Boolean(formik.touched?.facebook && formik.errors?.facebook) ? 'form-control border-danger' : 'form-control'}
                  name='facebook'
                  value={formik.values.facebook}
                  onChange={handleChange}
                  placeholder='Facebook'
                />
                <InputErrorMessage error={formik.touched.facebook && formik.errors.facebook} marginBottom={-5} />
              </div>
              <div className='col-md-6 mb-5'>
                <label className='form-label'>Twitter</label>
                <input
                  type='text'
                  className={Boolean(formik.touched?.twitter && formik.errors?.twitter) ? 'form-control border-danger' : 'form-control'}
                  name='twitter'
                  value={formik.values.twitter}
                  onChange={handleChange}
                  placeholder='Twitter'
                />
                <InputErrorMessage error={formik.touched.twitter && formik.errors.twitter} marginBottom={-5} />
              </div>
              <div className='col-md-6 mb-5'>
                <label className='form-label'>Instagram</label>
                <input
                  type='text'
                  className={Boolean(formik.touched?.instagram && formik.errors?.instagram) ? 'form-control border-danger' : 'form-control'}
                  name='instagram'
                  value={formik.values.instagram}
                  onChange={handleChangeInstagram}
                  placeholder='Instagram'
                />
                <InputErrorMessage error={formik.touched.instagram && formik.errors.instagram} marginBottom={-5} />
              </div>
              <div className='col-md-6 mb-5'>
                <label className='form-label'>TikTok</label>
                <input
                  type='text'
                  className={Boolean(formik.touched?.tiktok && formik.errors?.tiktok) ? 'form-control border-danger' : 'form-control'}
                  name='tiktok'
                  value={formik.values.tiktok}
                  onChange={handleChange}
                  placeholder='TikTok'
                />
                <InputErrorMessage error={formik.touched.tiktok && formik.errors.tiktok} marginBottom={-5} />
              </div>
              <div className='col-md-6 mb-5'>
                <label className='form-label'>Website Url</label>
                <input
                  type='text'
                  className={Boolean(formik.touched?.websiteUrl && formik.errors?.websiteUrl) ? 'form-control border-danger' : 'form-control'}
                  name='websiteUrl'
                  value={formik.values.websiteUrl}
                  onChange={handleChange}
                  placeholder='Website Url'
                />
                <InputErrorMessage error={formik.touched.websiteUrl && formik.errors.websiteUrl} marginBottom={-5} />
              </div>

              <div className='col-md-12 mb-5'>
                <label className='form-label'>Biography</label>
                <textarea
                  onKeyUp={(e) => autoHeightText(e)}
                  type='text'
                  className={Boolean(formik.touched?.biography && formik.errors?.biography) ? 'form-control border-danger' : 'form-control'}
                  name='biography'
                  value={formik.values.biography}
                  onChange={handleChange}
                  placeholder='Add Biography...'></textarea>
              </div>

              <div className='col-md-6 mb-5 ' style={{ position: 'relative' }}>
                <label className='form-label' style={{ display: 'block' }}>
                  Upload CV
                </label>

                {formik?.values?.cv ? (
                  <a className='blackOnHover' rel='noreferrer' href={assignBucketUrl(formik?.values?.cv)} target='_blank'>
                    <div
                      style={{
                        width: '60px',
                        transition: '0.2s all',
                        height: '60px',
                        border: '1px solid rgb(25, 19, 19, 0.25)',
                        borderRadius: '50%',
                        boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                        marginRight: '10px',
                        background: 'rgba(230, 203, 71, 1)',
                        color: 'black',
                      }}
                      className='icon icon-shape text-xl masherIcon  '>
                      <i className='bi bi-file-earmark-fill'></i>
                    </div>
                  </a>
                ) : (
                  <div
                    style={{
                      width: '60px',
                      transition: '0.2s all',
                      height: '60px',
                      border: '1px solid rgb(25, 19, 19, 0.25)',
                      borderRadius: '50%',
                      boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                      marginRight: '10px',
                      background: 'rgba(230, 203, 71, 1)',
                      color: 'black',
                    }}
                    className='icon icon-shape text-xl masherNoFile '>
                    <i className='bi bi-file-earmark-fill'></i>
                  </div>
                )}
                <div className='profileImgUpload d-inline-block  '>
                  <label htmlFor='cv'>
                    <div
                      className='changeFileBtn'
                      onClick={() => {
                        inputRef?.current?.click();
                      }}>
                      Choose File
                    </div>
                  </label>
                </div>

                {formik.values?.cv !== '' ? (
                  <div
                    className='removeFileBtn d-inline-block  '
                    onClick={() => {
                      formik.setFieldValue('cv', '');
                    }}>
                    Remove
                  </div>
                ) : (
                  <div className=' d-inline-block'>No CV Found</div>
                )}
                <input
                  type='file'
                  name='cv'
                  onChange={handleCvUpload}
                  placeholder=''
                  id='cv'
                  className={
                    Boolean(formik.touched?.cv) && Boolean(formik.errors?.cv)
                      ? 'btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                      : '  btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                  }
                />
              </div>

              <div className='col-md-6 mb-5 ' style={{ position: 'relative' }}>
                <label className='form-label' style={{ display: 'block' }}>
                  Intro Video
                </label>

                {formik?.values?.introVideo ? (
                  <a className='blackOnHover' rel='noreferrer' href={formik?.values?.introVideo} target='_blank'>
                    <div
                      style={{
                        width: '60px',
                        transition: '0.2s all',
                        height: '60px',
                        border: '1px solid rgb(25, 19, 19, 0.25)',
                        borderRadius: '50%',
                        boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                        marginRight: '10px',
                        background: 'rgba(230, 203, 71, 1)',
                        color: 'black',
                      }}
                      className='icon icon-shape text-xl masherIcon  '>
                      <i className='bi bi-camera-video'></i>
                    </div>
                  </a>
                ) : (
                  <div
                    style={{
                      width: '60px',
                      transition: '0.2s all',
                      height: '60px',
                      border: '1px solid rgb(25, 19, 19, 0.25)',
                      borderRadius: '50%',
                      boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                      marginRight: '10px',
                      background: 'rgba(230, 203, 71, 1)',
                      color: 'black',
                    }}
                    className='icon icon-shape text-xl masherNoFile'>
                    <i className='bi bi-camera-video'></i>
                  </div>
                )}
                <div className='profileImgUpload d-inline-block  '>
                  <label htmlFor='introVideo'>
                    <div
                      className='changeFileBtn'
                      onClick={() => {
                        inputRef?.current?.click();
                      }}>
                      Choose File
                    </div>
                  </label>
                </div>

                {formik.values?.introVideo !== '' ? (
                  <div
                    className='removeFileBtn d-inline-block'
                    onClick={() => {
                      formik.setFieldValue('introVideo', '');
                    }}>
                    Remove
                  </div>
                ) : (
                  <div className='d-inline-block'>No Intro Video Found</div>
                )}
                <input
                  type='file'
                  name='introVideo'
                  onChange={(e) => helperVideoUpload(formik, e)}
                  placeholder=''
                  id='introVideo'
                  className={
                    Boolean(formik.touched?.introVideo) && Boolean(formik.errors?.introVideo)
                      ? 'btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                      : '  btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                  }
                />
              </div>

              <div className='col-md-6 mb-5 ' style={{ position: 'relative' }}>
                <label className='form-label' style={{ display: 'block' }}>
                  Portfolio Video
                </label>

                {formik?.values?.portfolioVideo ? (
                  <a className='blackOnHover' rel='noreferrer' href={formik?.values?.portfolioVideo} target='_blank'>
                    <div
                      style={{
                        width: '60px',
                        transition: '0.2s all',
                        height: '60px',
                        border: '1px solid rgb(25, 19, 19, 0.25)',
                        borderRadius: '50%',
                        boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                        marginRight: '10px',
                        background: 'rgba(230, 203, 71, 1)',
                        color: 'black',
                      }}
                      className='icon icon-shape text-xl masherIcon'>
                      <i className='bi bi-camera-video'></i>
                    </div>
                  </a>
                ) : (
                  <div
                    style={{
                      width: '60px',
                      transition: '0.2s all',
                      height: '60px',
                      border: '1px solid rgb(25, 19, 19, 0.5)',
                      borderRadius: '50%',
                      boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                      marginRight: '10px',
                      background: 'rgba(230, 203, 71, 1)',
                      color: 'black',
                    }}
                    className='icon icon-shape text-xl masherNoFile '>
                    <i className='bi bi-camera-video'></i>
                  </div>
                )}
                <div className='profileImgUpload d-inline-block'>
                  <label htmlFor='portfolioVideo'>
                    <div
                      className='changeFileBtn'
                      onClick={() => {
                        inputRef?.current?.click();
                      }}>
                      Choose File
                    </div>
                  </label>
                </div>

                {formik.values?.portfolioVideo !== '' ? (
                  <div
                    className='removeFileBtn d-inline-block'
                    onClick={() => {
                      formik.setFieldValue('portfolioVideo', '');
                    }}>
                    Remove
                  </div>
                ) : (
                  <div className='d-inline-block'>No Portfolio Video Found</div>
                )}
                <input
                  type='file'
                  name='portfolioVideo'
                  onChange={(e) => helperVideoUpload(formik, e)}
                  placeholder=''
                  id='portfolioVideo'
                  className={
                    Boolean(formik.touched?.portfolioVideo) && Boolean(formik.errors?.portfolioVideo)
                      ? 'btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                      : '  btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                  }
                />
              </div>
              <div className='text-right-custom'>
                <button type='submit' className='btn btn-sm btn_primary_black d-inline-block'>
                  {!loader ? (
                    'Save Changes'
                  ) : (
                    <div className='text-xs w-full'>
                      <EditProfileLoader />
                    </div>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
