import React, {useState, useEffect} from 'react';
import {fetchCountrycodeApi} from '../../apis/index';

function CountryBadge({backgroundColor, title}) {
  const [countryDetails, setCountryDetails] = useState([]);

  const countryLookup = async () => {
    const countries = await fetchCountrycodeApi();
    setCountryDetails(countries.data.countries.find(country => country.name == title));
  };

  useEffect(() => {
    countryLookup();
  },[title]);
  
  function getFlagEmoji(countryCode) {
    if (countryCode) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char =>  127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    }
    return '';
  }
  const style = {
    backgroundColor:`${backgroundColor}`,
    display: 'flex',
    padding: '3px 6px 3px 6px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
    color: '#026AA2'
  };
  const flagStyle = {
    backgroundColor:'white',
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginRight: '4px',
  };
  return (
    <>
      <div style={style}>
        <span style={flagStyle}>{getFlagEmoji(countryDetails?.iso)}</span>
        <div>{title}</div>
      </div>
    </>
  );
}

export default CountryBadge;