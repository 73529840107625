import React from 'react';
import './tableList.css';
function TableListHeader({headers, headerClass}) {

  return (
    <div className='table-list-header-container overflow-auto'>
      {headers?.map((header, i) => {
        return <div className={'table-list-header-item ' + headerClass} key={i} style={{display: `${header.display}`, justifyContent: `${header.justify}`}}>{header.title}</div>;
      })}
    </div>
  );
}

export default TableListHeader;