import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { updateMasherPreferenceApiV2 } from '../../apis/index.js';
import chevronDown from '../../Icons/chevronDown.svg';
import chevronUp from '../../Icons/chevronUp-white.svg';
import { EditProfileLoader } from '../common/loader';
const MasherPreference = ({ briefId, deliverable, role, masher }) => {
  const [status, setStatus] = useState(null);
  const [updating, setUpdating] = useState(false);
  

  const [showOptions, setShowOptions] = useState(false);
  const prefTypes = [
    'primary',
    'second',
    'third'
  ];
  const editPref = async (formPref) => {
    setUpdating(true);
    const res = await updateMasherPreferenceApiV2({
      id: briefId,
      deliverableId: deliverable.deliverableId,
      roleId: role.roleId,
      masherId: masher.masherId,
      preference: formPref
    });
    setUpdating(false);
    if (res.status === 200 || 201) {                   
      setStatus(formPref);
      toast.success(`Masher Preference set to ${formPref}.`, { id: '001' });
    } else { 
    }
  }

  return (
    deliverable ?
      <div className='brief-status-container' >
        {
          updating ?          
          <div className={`brief-status brief-status-closed`}>
            <EditProfileLoader /> 
          </div> :
          <div className={`brief-status brief-status-${showOptions ? 'opened': 'closed'}`}>
          <p style={{marginRight: '4px'}}>{status ? status : masher.preference}</p>
          {
            showOptions ?           
            <img src={chevronUp} onClick={() => setShowOptions(!showOptions)} /> :
            <img src={chevronDown} onClick={() => setShowOptions(!showOptions)} />
          }
          </div>
        }
        {
          showOptions ?
          <div className='brief-status-select'>
            {
              prefTypes.map((type, i) => {
                return (
                  <div key={i} className='brief-status-option' onClick={() => {
                    editPref(prefTypes[i]); 
                    setShowOptions(false);
                  }}>
                    {type}
                  </div>
                );
              })
            }
          </div> :
          ''
        }      
      </div> :
      ''
  );
}

export default MasherPreference;