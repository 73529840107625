import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AdminLayout from '../../components/admin-layout';
import EditProfile from '../../Assets/img/covers/userDefault.png';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import CoverDefault from '../../Assets/img/covers/img-1.jpg';
import SharePopup from '../../components/Popups/SharePopup';
import { addMasherNotesApi, getAllBriefApi, getMasherNotesApi, getMasherByIdApi, getMasherReferenceApi } from '../../apis';
import { assignBucketUrl, getLastElementId, MashersForBriefInvite } from '../../utils/helperFunctions';
import { ADMIN_BASE_URL } from '../../utils/baseurl';
import { EditProfileLoader, Loader } from '../../components/common/loader';
import { handleMasherUpdate } from '../../utils/helperFunctions';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { addNoteValidation } from '../../components/Validation';
import { InputErrorMessage } from '../../components/errorMessages';
import Moment from 'react-moment';
import { API_BASE_URL } from '../../utils/baseurl';

export default function MasherProfile() {
  const masherId = getLastElementId();
  const [profileData, setProfileData] = useState({ data: null, referedBy: null, loading: true });
  const [note, setNote] = useState({ loading: false });
  const [noteData, setNoteData] = useState({ data: null, loading: false });
  const [refData, setRefData] = useState({ data: null, loading: false });
  const [profile] = useState('');
  const [masherDataShare, setMasherDataShare] = useState({ data: null, loading: false });
  const [brief, setBrief] = useState(1);
  const data = profileData?.data;
  const referedBy = profileData?.referedBy;
  var moment = require('moment-timezone');

  const getMasherProfile = async (id) => {
    setProfileData({ loading: true });
    try {
      const res = await getMasherByIdApi(id);
      if (res.status === 200) {
        setProfileData({ data: res.data.masherProfile, referedBy: res.data.referedBy, loading: false });
      }
    } catch (error) {
      setProfileData({ data: null, referedBy: null, loading: false });
    }
  };

  const getMasherRef = async (id) => {
    setRefData({ loading: true });
    try {
      const res = await getMasherReferenceApi(id);
      if (res.status === 200) {
        setRefData({ data: res.data.finalRefered, loading: false });
      }
    } catch (error) {
      setRefData({ data: null, loading: false });
    }
  };

  const getNotes = async (id) => {
    setNoteData({ loading: true });

    const res = await getMasherNotesApi(id);
    if (res.status === 200) {
      setNoteData({ data: res.data.notes, loading: false });
    } else {
      setNoteData({ loading: false });
    }
  };

  const formik = useFormik({
    initialValues: {
      message: '',
      masherId: masherId,
    },
    validationSchema: addNoteValidation,
    onSubmit: async (values) => {
      setNote({ loading: true });
      const res = await addMasherNotesApi(values);
      try {
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message);
          getNotes(masherId);
          setNote({ loading: false });
          formik.resetForm();
        }
      } catch (error) {
        setNote({ loading: false });
      }
    },
  });

  const allBrief = async (search, startDate, dueDate, page, limit) => {
    try {
      const res = await getAllBriefApi(search, startDate, dueDate, page, limit);
      if (res.status === 200) {
        setBrief({ data: res.data.allBriefs, loading: false });
      }
    } catch (error) {
      toast.error(error);
      setBrief({ loading: false });
    }
  };

  useEffect(() => {
    getMasherProfile(masherId);
    getNotes(masherId);
    allBrief();
  }, [masherId]);

  useEffect(() => {
    if (profileData?.data?.userId?._id) {
      getMasherRef(profileData?.data?.userId?._id);
    }
  }, [profileData]);

  return (
    <AdminLayout>
      {profileData?.loading ? (
        <div className='text-center p-4'>
          <Loader />
        </div>
      ) : (
        <div className='d-flex flex-column flex-lg-row h-lg-full'>
          <div className='flex-lg-1'>
            <header className=''>
              <div
                className='bg-cover'
                style={{
                  height: 300,
                  backgroundImage: `${profile
                    ? `url(${encodeURI(profile)})`
                    : Boolean(data?.coverPic)
                      ? `url(${assignBucketUrl(data?.coverPic)})`
                      : `url(${CoverDefault})`
                  }`,
                  backgroundPosition: 'center center',
                }}
              />
              {/* Info */}
              <div className='container-fluid max-w-screen-xl  '>
                <div className='row g-0'>
                  <div className='col-auto'>
                    <div style={{ width: '170px', height: '170px' }} className='position-relative rounded-circle masherProfilePic'>
                      <label
                        className='col-sm-4 bg-repeat-0 bg-position-center bg-size-cover'
                        htmlFor='profileImage'
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundColor: 'white',
                          position: 'absolute',
                          top: '-18%',
                          width: '170px',
                          height: '170px',
                          border: '1px solid rgb(25, 19, 19, 0.15)',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          boxShadow: 'rgb(0 0 0 / 44%) 0px 2px 20px 0px',
                          backgroundImage: `${profile
                            ? `url(${encodeURI(profile)})`
                            : Boolean(data?.userId?.profilePic)
                              ? `url(${assignBucketUrl(data?.userId?.profilePic)})`
                              : `url(${EditProfile})`
                          }`,
                        }}></label>
                    </div>
                  </div>
                  <div className='col ps-4 pt-4'>
                    <h1 className='h2'>{data?.userId?.firstName + ' ' + data?.userId?.lastName}</h1>
                    <div className='d-flex gap-2 flex-wrap mt-3 d-none d-sm-block'>
                      {data?.skills?.length > 0 &&
                        data?.skills?.slice(0, 3)?.map((el, i) => {
                          return (
                            <Link
                              to={''}
                              key={i}
                              className='bg-white bg-opacity-50 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs'>
                              {el?.label}
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                  <div className='col-12 col-md-auto mt-2 mt-md-0 mb-md-3 hstack gap-2 mt-4 mt-sm-0'>
                    <button
                      className='btn btn-sm btn-neutral border-base'
                      href='#modalExportMasher'
                      onClick={() => {
                        MashersForBriefInvite(setMasherDataShare, 'approved');
                      }}
                      data-bs-toggle='modal'>
                      Share Brief
                    </button>

                    <Link to='' className='btn btn-sm btn-neutral border-base text-capitalize '>
                      <span>{data?.status === 'approved' ? 'Active' : data?.status}</span>
                    </Link>

                    <div className='position-sticky bg-white' style={{ right: 0 }}>
                      <div className='dropdown'>
                        <Link to='' className='btn btn-sm btn-neutral text-muted' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                          <i className='bi bi-three-dots-vertical' />
                        </Link>
                        <div className='dropdown-menu'>
                          <Link to={`/setting/${data?._id}`} className='dropdown-item'>
                            <i className='bi bi-pencil me-3'></i>
                            Edit Profile
                          </Link>
                          <a href={`${API_BASE_URL + '/api/v1/common/pdf/masher?id=' + data?._id}`}
                            data-target="#"
                            download
                            className='dropdown-item downloadPdfBtn'>
                            <i className='bi bi-download me-3'></i>
                            Download Profile
                          </a>
                          <Link to='' className='dropdown-item'>
                            <i className='bi bi-clipboard me-3'></i>
                            <CopyToClipboard text={`${ADMIN_BASE_URL}/mashers/mashers-profile/${masherId}`} onCopy={() => toast.success('Copied Successfully!')}>
                              <span>Share Profile Link</span>
                            </CopyToClipboard>
                          </Link>

                          {data?.status === 'approved' ? (
                            <Link
                              to=''
                              className='dropdown-item suspendMasher'
                              onClick={(e) => {
                                handleMasherUpdate(data?._id, 'suspended');
                                getMasherProfile(masherId);
                              }}>
                              <i className='bi bi-bullseye me-3'></i>
                              Suspend Masher
                            </Link>
                          ) : (
                            <Link
                              to=''
                              className='dropdown-item suspendMasher'
                              onClick={(e) => {
                                handleMasherUpdate(data?._id, 'approved');
                                getMasherProfile(masherId);
                              }}>
                              <i className='bi bi-bullseye me-3'></i>
                              Activate Masher
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Nav */}
                <ul className='nav nav-tabs overflow-x ms-1 mt-4'>
                  <li className='nav-item'>
                    <a
                      href='#!'
                      className='nav-link active font-bold'
                      id='profile1-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#profile1'
                      type='button'
                      role='tab'
                      aria-controls='profile1'
                      aria-selected='true'>
                      Profile
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      href='#!'
                      className='nav-link'
                      id='workdetails1-tab'
                      data-bs-toggle='tab'
                      data-bs-target='#workdetails1'
                      type='button'
                      role='tab'
                      aria-controls='workdetails1'
                      aria-selected='false'>
                      My Work
                    </a>
                  </li>
                </ul>
              </div>
            </header>
            {/* Main */}
            <div className='custom-tab-content'>
              <main className='pt-6'>
                <div className='container-fluid max-w-screen-xl'>
                  <div className='row g-6'>
                    <div className='col-xl-8'>
                      <div className='vstack gap-6'>
                        <div className='tab-content' id='myTabContent'>
                          <div className='tab-pane fade show active' id='profile1' role='tabpanel' aria-labelledby='profile1-tab'>
                            <div className='card shadow-none'>
                              <div className='card-body'>
                                <h5 className='mb-3'>Biography</h5>
                                <p className='text-sm lh-relaxed mb-4 preWrap' style={{ textAlign: 'justify' }}>
                                  {profileData?.data?.biography || 'No Biography'}
                                </p>
                              </div>

                              <div className='card-body'>
                                <h5 className='mb-3'>Intro Video</h5>
                                <div className='ratio ratio-16x9'>
                                  {data?.introVideo ? (
                                    <video width='750' height='500' controls>
                                      <source src={assignBucketUrl(data?.introVideo)} type='video/mp4' />
                                    </video>
                                  ) : (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '2px solid rgba(0,0,0,0.03)',
                                        borderRadius: '5px',
                                      }}>
                                      No Video found
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='tab-pane fade' id='workdetails1' role='tabpanel' aria-labelledby='workdetails1-tab'>
                            {/* <WorkDetail/> */}
                            <div className='vstack gap-6'>
                              <div className='card work-card shadow-none'>
                                <div className='card-body row'>
                                  <h5 className='mb-3'>Brands worked with</h5>

                                  <div className='personality-pill'>
                                    {!profileData?.data?.brand?.length <= 0
                                      ? profileData?.data?.brand?.map((el, i) => {
                                        return (
                                          <span key={i} className='badge rounded-pill bg-yellow-600 text-dark me-2'>
                                            {el?.label}
                                          </span>
                                        );
                                      })
                                      : 'No Brands'}
                                  </div>
                                </div>
                              </div>

                              {/* ?????????????????????????????????? */}

                              <div className=' card work-card shadow-none'>
                                <div className='card-body'>
                                  <h5>Portfolio</h5>

                                  <br />

                                  <div className='mb-5'>
                                    {data?.workDetails2?.length > 0
                                      ? data?.workDetails2?.map((el, i) => {
                                        return (
                                          <div key={i}>
                                            <div key={i} className=''>
                                              {el?.workVideo !== '' ? (
                                                <div className='ratio ratio-16x9'>
                                                  <video width='750' height='500' controls>
                                                    <source
                                                      src={assignBucketUrl(el?.workVideo)}
                                                      onError={(event) => {
                                                        event.target.src = ProfileDefault;
                                                        event.onerror = null;
                                                      }}
                                                      type='video/mp4'
                                                    />
                                                  </video>
                                                  {/* <iframe src={`${API_BASE_URL}/${el?.workVideo}`} title='YouTube video' allowfullscreen></iframe> */}
                                                </div>
                                              ) : (
                                                <div className='text-muted text-center'>No Video Found</div>
                                              )}
                                            </div>
                                            <div className=''>
                                              <h3 className='mb-3 mt-5'>Work Details</h3>
                                              <div className=''>
                                                <div className='row align-items-center mb-2'>
                                                  <div className='col-md-5'>
                                                    <h5 className='mb-0'>Client</h5>
                                                  </div>
                                                  <div className='col-md-7'>
                                                    <span className='text-sm text-heading'>{el?.client}</span>
                                                  </div>
                                                </div>

                                                <div className='row align-items-center mb-2'>
                                                  <div className='col-md-5'>
                                                    <h5 className='mb-0'>Description</h5>
                                                  </div>
                                                  <div className='col-md-7'>
                                                    <span className='text-sm text-heading'>{el?.description}</span>
                                                  </div>
                                                </div>

                                                <div className='row align-items-center mb-2'>
                                                  <div className='col-md-5'>
                                                    <h5 className='mb-0'>Link</h5>
                                                  </div>
                                                  <div className='col-md-7'>
                                                    <span className='text-sm text-heading'>
                                                      <a target='_blank' rel='noreferrer' href={el?.link}>
                                                        {el?.link}
                                                      </a>
                                                    </span>
                                                  </div>
                                                </div>

                                                <div className='row align-items-center mb-2'>
                                                  <div className='col-md-5'>
                                                    <h5 className='mb-0'>Your Role</h5>
                                                  </div>
                                                  <div className='col-md-7'>
                                                    <span className='text-sm text-heading'>{el?.yourRole}</span>
                                                  </div>
                                                </div>
                                                <div className='row align-items-center mb-2'>
                                                  <div className='col-md-5'>
                                                    <h5 className='mb-0'>Work Video</h5>
                                                  </div>
                                                  <div className='col-md-7'>
                                                    <span className='text-sm text-heading'>
                                                      <a target='_blank ' href={el?.workVideo}>
                                                        {el?.workVideo}
                                                      </a>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <hr />
                                          </div>
                                        );
                                      })
                                      : 'No Work details'}
                                  </div>
                                </div>
                                <div className='card-body'>
                                  <h5 className='mb-3'>Portfolio Video</h5>
                                  <div className='ratio ratio-16x9'>
                                    {data?.portfolioVideo ? (
                                      <video width='750' height='500' controls>
                                        <source src={assignBucketUrl(data?.portfolioVideo)} type='video/mp4' />
                                      </video>
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          border: '2px solid rgba(0,0,0,0.03)',
                                          borderRadius: '5px',
                                        }}>
                                        No Video found
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* ????????????????????????????????????? */}

                              <div className='card work-card shadow-none mb-5'>
                                <div className='card-body row'>
                                  <h5 className='mb-3'>Brands I love</h5>

                                  <div className='personality-pill'>
                                    {!profileData?.data?.lovedBrand?.length <= 0
                                      ? profileData?.data?.lovedBrand?.map((el, i) => {
                                        return (
                                          <span key={i} className='badge rounded-pill bg-yellow-600 text-dark me-2'>
                                            {el?.label}
                                          </span>
                                        );
                                      })
                                      : 'No Brands'}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-4'>
                      <div className='vstack gap-6'>
                        <div className='card shadow-none'>
                          <div className='card-body'>
                            <div className='row align-items-center mb-2'>
                              <div className='col-md-5'>
                                <h5 className='mb-0'>Full Address</h5>
                              </div>
                              <div className='col-md-7'>
                                <span className='text-sm text-heading'>{data?.location || ''}</span>
                              </div>
                            </div>

                            <div className='row align-items-center mb-2'>
                              <div className='col-md-5'>
                                <h5 className='mb-0'>Local Time now</h5>
                              </div>
                              <div className='col-md-7'>
                                <span className='text-sm text-heading'>{moment().tz(data?.timezone?.value)?.format('hh:mm A') || '--'}</span>
                              </div>
                            </div>

                            <div className='row align-items-center mb-2'>
                              <div className='col-md-5'>
                                <h5 className='mb-0'>Birthday</h5>
                              </div>
                              <div className='col-md-7'>
                                <span className='text-sm text-heading'>
                                  <Moment format='D MMM' date={data?.birthDate} />
                                </span>
                              </div>
                            </div>

                            <div className='row align-items-center mb-2'>
                              <div className='col-md-5'>
                                <h5 className='mb-0'>Years Experience</h5>
                              </div>
                              <div className='col-md-7'>
                                <span className='text-sm text-heading'>{data?.workDetails?.experience} Years</span>
                              </div>
                            </div>

                            <div className='row align-items-center mb-2'>
                              <div className='col-md-5'>
                                <h5 className='mb-0'>Years Freelancing</h5>
                              </div>
                              <div className='col-md-7'>
                                <span className='text-sm text-heading'>{data?.workDetails?.workedForYourself} Years</span>
                              </div>
                            </div>

                            <div className='row align-items-center mb-2'>
                              <div className='col-md-5'>
                                <h5 className='mb-0'>Min. day rate</h5>
                              </div>
                              <div className='col-md-7'>
                                <span className='text-sm text-heading'>
                                  {data?.workDetails?.minDayRate + ' ' + data?.workDetails?.currency || '--'}
                                </span>
                              </div>
                            </div>

                            <div className='row align-items-center mb-2'>
                              <div className='col-md-5'>
                                <h5 className='mb-0'>Started with Mash</h5>
                              </div>
                              <div className='col-md-7'>
                                <span className='text-sm text-heading'>
                                  <Moment format='DD MMM YY' date={data?.createdAt} />
                                </span>
                              </div>
                            </div>

                            <div className='row align-items-center mb-2'>
                              <div className='col-md-5'>
                                <h5 className='mb-0'>Languages</h5>
                              </div>
                              <div className='col-md-7'>
                                <span className='text-sm text-heading'>
                                  {data?.language?.map((el, i) => {
                                    return (
                                      <div key={i}>
                                        {el?.label}
                                        {i === data?.language?.length - 1 ? '' : ', '}
                                      </div>
                                    );
                                  })}
                                </span>
                              </div>
                            </div>

                            <div className='row align-items-center mb-2'>
                              <div className='col-md-5'>
                                <h5 className='mb-0'>Email</h5>
                              </div>
                              <div className='col-md-7'>
                                <span className='text-sm text-heading'>{data?.userId?.email}</span>
                              </div>
                            </div>
                            <div className='row align-items-center mb-2'>
                              <div className='col-md-5 col-5'>
                                <h5 className='mb-0'>Referred By</h5>
                              </div>
                              <div className='col-md-7 col-7'>
                                {referedBy ? <><p className='text-sm text-heading'>
                                  {`${referedBy?.userId?.firstName} ${referedBy?.userId?.lastName}`}
                                </p>
                                <p className='text-sm text-heading'>
                                  {referedBy?.userId?.email}
                                </p></> : 'No data found'}
                              </div>
                            </div>
                            <div className='row align-items-center mb-2'>
                              <div className='col-md-5 col-5'>
                                <h5 className='mb-0'>Visible to other mashers</h5>
                              </div>
                              <div className='col-md-7 col-7'>
                                <p className='text-sm text-heading'>{!Object.keys(data).includes('isVisibletoOthers') ? 'Yes' : data?.isVisibletoOthers ? 'Yes' : 'No'}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card  shadow-none'>
                          <div className='card-body'>
                            <h5 className='mb-4'>Industry</h5>
                            <div className='vstack gap-4'>
                              <div className='align-items-center'>
                                {(data?.industryType ?
                                  typeof (data.industryType) == 'string' ?
                                    (data.industryType) :
                                    data?.industryType?.map((el, i) => {
                                      return (
                                        <React.Fragment key={`lang-${i}`}>
                                          <p className='badge badge-pill bg-white bg-opacity-50 bg-opacity-100 border rounded px-3 py-1 font-semibold text-heading text-xs me-1'>
                                            {el?.label}
                                            {i === data?.industryType?.length - 1 ? '' : ''}
                                          </p>
                                        </React.Fragment>
                                      );
                                    }) : ('--'))}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='card shadow-none'>
                          <div className='card-body'>
                            <h5 className='mb-4'>Clients worked for</h5>
                            <div className='vstack gap-4'>
                              <div className='align-items-center'>
                                {(data?.clientsWorkedFor ?
                                  typeof (data.clientsWorkedFor) == 'string' ?
                                    (data.clientsWorkedFor) :
                                    data?.clientsWorkedFor?.map((el, i) => {
                                      return (
                                        <React.Fragment key={`lang-${i}`}>
                                          <p className='badge badge-pill bg-white bg-opacity-50 bg-opacity-100 border rounded px-3 py-1 font-semibold text-heading text-xs me-1'>
                                            {el?.label}
                                            {i === data?.clientsWorkedFor?.length - 1 ? '' : ''}
                                          </p>
                                        </React.Fragment>
                                      );
                                    }) : ('--'))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card shadow-none'>
                          <div className='card-body'>
                            <h5 className='mb-4'>Social</h5>
                            <div className='vstack gap-4'>
                              <div className='d-flex align-items-center social-links'>
                                {data?.facebook && (
                                  <a href={data?.facebook} target='_blank' rel='noreferrer' className='text-sm text-heading' alt='mymash.com'>
                                    <i className='bi bi-facebook me-2' />
                                  </a>
                                )}
                                {data?.instagram && (
                                  <a
                                    href={`https://www.instagram.com/${data?.instagram.split('@').slice(1).join('')}/`}
                                    target='_blank'
                                    rel='noreferrer'
                                    className='text-sm text-heading'
                                    alt='mymash.com'>
                                    <i className='bi bi-instagram me-2' />
                                  </a>
                                )}

                                {data?.linkedin && (
                                  <a href={data?.linkedin} target='_blank' rel='noreferrer' className='text-sm text-heading' alt='mymash.com'>
                                    <i className='bi bi-linkedin me-2' />
                                  </a>
                                )}
                                {data?.tiktok && (
                                  <a href={data?.tiktok} target='_blank' rel='noreferrer' className='text-sm text-heading' alt='mymash.com'>
                                    <i className='bi bi-tiktok me-2' />
                                  </a>
                                )}
                                {data?.twitter && (
                                  <a href={data?.twitter} target='_blank' rel='noreferrer' className='text-sm text-heading' alt='mymash.com'>
                                    <i className='bi bi-twitter me-2' />
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {data?.websiteUrl ? (
                          <div className='card shadow-none'>
                            <div className='card-body flex-content '>
                              <div className=''>
                                <h5 className='mb-4'>Website</h5>
                              </div>
                              <div className=''>
                                <a href={data?.websiteUrl || '#'} target='_blank' rel='noreferrer' className='btn btn-sm btn_primary_black'>
                                  Go
                                </a>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {data?.cv ? (
                          <div className='card shadow-none'>
                            <div className='card-body flex-content '>
                              <div className=''>
                                <h5 className='mb-4'>CV</h5>
                              </div>
                              <div className=''>
                                <a href={data?.cv || '#'} target='_blank' rel='noreferrer' className='btn btn-sm btn_primary_black'>
                                  View
                                </a>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        <div className='card shadow-none'>
                          <div className='card-body'>
                            <h5 className='mb-4'>Personality Traits</h5>
                            <div className='vstack gap-4 personality-traits'>
                              <div className='personality-pill'>
                                {data?.traits?.length > 0
                                  ? data?.traits?.map((el, i) => {
                                    return (
                                      <span key={i} className='badge rounded-pill bg-yellow-600 text-dark text-capitalize '>
                                        {el}
                                      </span>
                                    );
                                  })
                                  : 'No traits found'}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* /* Notes */}

                        <div className='col-md-12'>
                          <div className='card mb-5'>
                            <div className='card-header'>
                              <div className='d-flex align-items-center'>
                                <h5 className='mb-0'>NOTES</h5>
                              </div>
                            </div>

                            <div className='' style={{ border: '1px solid #eceef3' }}>
                              <div className='card-body position-relative scrollable-y notesbox'>
                                {noteData?.loading ? (
                                  <div className='text-center p-4'>
                                    <Loader />
                                  </div>
                                ) : noteData?.data?.length <= 0 ? (
                                  <div className='text-center'>No Notes Found...</div>
                                ) : (
                                  noteData?.data
                                    ?.slice()
                                    ?.reverse()
                                    ?.map((el, i) => {
                                      return (
                                        <div key={i} className='notes'>
                                          <div className='row'>
                                            <div className='col-md-9' style={{ whiteSpace: 'pre-wrap' }}></div>
                                            <div className='col-md-3 p-0  text-end'>
                                              <span className='fs-12 font-bold'>{moment(el?.createdAt).fromNow()}</span>
                                            </div>
                                          </div>
                                          <div className='row'>
                                            <div className='col-md-11 p-0' style={{ whiteSpace: 'pre-wrap' }}>
                                              <p className='mb-1'>
                                                <small>{el?.message}</small>
                                              </p>
                                            </div>
                                            <div className='col-md-1 p-0 text-end '>
                                              {/* <i className='bi bi-pencil-square text-muted text-xs' /> */}
                                              <span className='text-sm text-muted'></span>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                )}
                              </div>

                              <div className=''>
                                <form onSubmit={formik.handleSubmit}>
                                  <div className='card-body'>
                                    <div className='row' style={{ textAlign: 'end' }}>
                                      <div className='col-md-9 p-0'>
                                        <input
                                          placeholder='Add notes here...'
                                          value={formik.values.message}
                                          onChange={(e) => formik.handleChange(e)}
                                          name='message'
                                          className={
                                            Boolean(formik.touched.message) && Boolean(formik.errors.message)
                                              ? 'form-control border-danger'
                                              : 'form-control'
                                          }
                                          style={{ padding: '0.4rem' }}
                                        />
                                        <InputErrorMessage
                                          error={formik.touched.message && formik.errors.message}
                                          marginBottom={-20}
                                          style={{ textAlign: 'start' }}
                                        />
                                      </div>
                                      <div className='col-md-3 pe-0'>
                                        <button className='btn btn-sm btn_primary_black btn-width' type='submit'>
                                          {!note?.loading ? (
                                            'Save'
                                          ) : (
                                            <div className='text-xs'>
                                              <EditProfileLoader />
                                            </div>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* /* Notes End */}

                        <div className='col-md-12'>
                          <div className='card mb-5'>
                            <div className='card-header'>
                              <div className='d-flex align-items-center'>
                                <h5 className='mb-0 text-capitalize '>Mashers i've referred </h5>
                              </div>
                            </div>

                            <div className='' style={{ border: '1px solid #eceef3' }}>
                              {refData?.data?.length ? (
                                refData?.data?.map((el, i) => {
                                  let masherFirstLetter = el?.user?.firstName?.charAt(0)?.toUpperCase();
                                  let masherMailFirstLetter = el?.email?.charAt(0)?.toUpperCase();

                                  return el?.email ? (
                                    <div className='m-5 text-black'>
                                      <div className=''>
                                        <span className='mx-3 projectTeam avatar rounded-circle text-white border border-1 border-solid border-card'>
                                          {masherMailFirstLetter}
                                        </span>
                                        <span>{el?.email}</span>
                                      </div>
                                    </div>
                                  ) : (
                                    el?.user?.firstName !== undefined && el?.user?.firstName != '' ?
                                      <Link to={`/mashers/mashers-profile/${el.masherProfile?._id}`} className='' style={{ cursor: 'pointer' }}>
                                        <div className='m-5 text-black'>
                                          <span className='mx-3 projectTeam avatar rounded-circle text-white border border-1 border-solid border-card'>
                                            {masherFirstLetter}
                                          </span>
                                          <span>{el?.user?.firstName + ' ' + el?.user?.lastName}</span>
                                        </div>
                                      </Link>
                                      : ''
                                  );
                                })
                              ) : (
                                <i className='p-4 text-center d-block'>No data found</i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      )}

      {/* <GmailPopup /> */}
      <SharePopup masherDataShare={masherDataShare} brief={brief} />
    </AdminLayout>
  );
}
