import React from 'react';
import '../../Pages/Briefs/briefs.css';

function SimpleTextAndTitle({title, bodyText, startIcon, endIcon}) {
  return (
    <div className='brief-element'>
      
      <h4 className='brief-titles'>{title}</h4>
      <div className='brief-text-block'>
        {startIcon && startIcon}
        <span className='brief-textbox'>{bodyText}</span>
        {endIcon && endIcon}
      </div>
      
    </div>
  );
}

export default SimpleTextAndTitle;