// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-toggle-no-caret::after {
    display: none !important;
  }
  .dropdown-toggle-no-caret:hover {
    background-color: transparent !important;
  }
  .dropdown:focus-visible {
    outline: 0 !important;
    box-shadow: none !important;
  }
  .dropdown-toggle:focus-visible,.dropdown-toggle-no-caret:focus-visible,.dropdown-toggle-no-caret:focus{
    outline: 0 !important;
    box-shadow: none !important;
  
  }
  .btn-custom {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0;
    color: #525f7f;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.3;
    padding: 0px !important;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-user-select: none;
    user-select: none;
    vertical-align: middle;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Briefs/EditBrief/dropdown.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;EAC1B;EACA;IACE,wCAAwC;EAC1C;EACA;IACE,qBAAqB;IACrB,2BAA2B;EAC7B;EACA;IACE,qBAAqB;IACrB,2BAA2B;;EAE7B;EACA;IACE,wCAAwC;IACxC,uBAAuB;IACvB,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;IAClB,qIAAqI;IACrI,yBAAyB;IAEzB,iBAAiB;IACjB,sBAAsB;EACxB","sourcesContent":[".dropdown-toggle-no-caret::after {\n    display: none !important;\n  }\n  .dropdown-toggle-no-caret:hover {\n    background-color: transparent !important;\n  }\n  .dropdown:focus-visible {\n    outline: 0 !important;\n    box-shadow: none !important;\n  }\n  .dropdown-toggle:focus-visible,.dropdown-toggle-no-caret:focus-visible,.dropdown-toggle-no-caret:focus{\n    outline: 0 !important;\n    box-shadow: none !important;\n  \n  }\n  .btn-custom {\n    background-color: transparent !important;\n    border: none !important;\n    border-radius: 0;\n    color: #525f7f;\n    cursor: pointer;\n    display: inline-block;\n    font-size: 1rem;\n    font-weight: 500;\n    line-height: 1.3;\n    padding: 0px !important;\n    text-align: center;\n    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    user-select: none;\n    vertical-align: middle;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
