import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { editBriefStatusApi } from '../../apis/index.js';
import chevronDown from '../../Icons/chevronDown.svg';
import chevronUp from '../../Icons/chevronUp-white.svg';
import { EditProfileLoader } from '../common/loader';
const BriefStatus = ({ briefId, briefStatus }) => {
  const [status, setStatus] = useState(null);
  const [updating, setUpdating] = useState(false);
  

  const [showOptions, setShowOptions] = useState(false);
  const statusTypes = [
    'archived',
    'qualified',
    'proposal requested',
    ' proposal submitted'
  ];

  const editStatus = async (formStatus) => {
    setUpdating(true);
    const res = await editBriefStatusApi({
      id: briefId,
      briefStatus: formStatus
    });
    setUpdating(false);
    if (res.status === 200 || 201) {                   
      setStatus(formStatus);
      toast.success(`Brief status set to ${formStatus}.`, { id: '001' });
    } else { 
    }
  };

  return (
    briefStatus ?
      <div className='brief-status-container'style={{marginBottom: '3px'}} >
        {
          updating ?          
            <div className={'brief-status brief-status-closed'}>
              <EditProfileLoader /> 
            </div> :
            <div className={`brief-status brief-status-${showOptions ? 'opened': 'closed'}`}>
              <p style={{marginRight: '4px'}}>{status ? status : briefStatus}</p>
              {
                showOptions ?           
                  <img src={chevronUp} onClick={() => setShowOptions(!showOptions)} /> :
                  <img src={chevronDown} onClick={() => setShowOptions(!showOptions)} />
              }
            </div>
        }       
        {
          showOptions ?
            <div className='brief-status-select'>
              {
                statusTypes?.map((type, i) => {
                  return (
                    <div key={i} className='brief-status-option' onClick={() => {
                      editStatus(statusTypes[i]); 
                      setShowOptions(false);
                    }}>
                      {type}
                    </div>
                  );
                })
              }
            </div> :
            ''
        }      
      </div> :
      ''
  );
};

export default BriefStatus;