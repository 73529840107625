import React, {useState} from 'react';
import { duplicateBriefApiV2, syncLiveblocks } from '../../../apis';
import toast from 'react-hot-toast';
import { useNavigate,} from 'react-router-dom';
import { EditProfileLoader } from '../../../components/common/loader';

function DuplicateBrief({briefData, closeModal}) {
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();

  const handleConfirmDuplicate = async (e) => {
    e.preventDefault();
    setUpdating(true);
    try {
      await syncLiveblocks({
        briefId: briefData?._id
      })
      const res = await duplicateBriefApiV2({id: briefData?._id});
      if (res.status === 200) {
        toast.success(res?.data?.message, { id: '001' });
        setUpdating(false);
        closeModal();
        window.location.href = `/briefs/edit-brief/${res.data?.duplicateBrief?._id}`;
      }
    } catch(error) {
      toast.error(error?.response?.data?.message, { id: '001' });
      setUpdating(false);
    }
  };

  return (
    <div >
      <form onSubmit={handleConfirmDuplicate}>
        <div className='brief-button-container' style={{margin: '25px 0 20px 0 '}}>
          <button type='button' className='button-close' style={{width: '100%'}} onClick={closeModal}>
              No Thanks
          </button>
          <button type='submit' className='button-primary' disabled={updating} style={{width: '100%'}}>
            {updating ? (
              <div className='text-xs text-center'>
                <EditProfileLoader/>
              </div>
            ) : (
              'Yes Please'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default DuplicateBrief;