import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { loginApi } from '../../apis/index.js';
import { SignInValidation } from '../../components/Validation/index.js';
import LogoImage from '../../Assets/img/logos/mash_yellow.png';
import Cover from '../../Assets/img/covers/Login.jpg';
import { assignBucketUrl } from '../../utils/helperFunctions.js';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import { GoogleLogin } from '@react-oauth/google';
import { verifyToken } from '../../apis/index.js';
import { AppContext } from '../../appContext/index.js';

export default function GoogleSignIn() {
  const appContext = useContext(AppContext);
  const { setIsLoggedIn } = appContext;
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const onVerifyToken = async (values) => {
    try {
      setLoader(true);
      const response = await verifyToken(values);
      const data = response.data;
      if (response.status === 200) {
        localStorage.setItem('token', data.token);
        setIsLoggedIn(true);
        navigate(location?.state?.from || '/');
        toast.success(data.message);
      } else {
        setIsWrongOtp(true);
        setErrorMsg('Wrong OTP');
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message, { id: '001' });
    }
  };
  const responseMessage = (response) => {
    const values = { token: response.credential };
    onVerifyToken(values);
  };
  const errorMessage = (error) => {
    console.log();
  };
  const formik = useFormik({
    initialValues: { email: '', keepLoggedIn: false },
    validationSchema: SignInValidation,
    onSubmit: async (values) => {
      const { email } = values;
      try {
        setLoader(true);
        const res = await loginApi({ email });
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate('/otp', { state: { email: formik.values.email, keepLoggedIn: formik.values.keepLoggedIn } });
        }
      } catch (error) {
        setLoader(false);
        const message = error.response.data.message || error.response.statusText;
        toast.error(message, { id: 'error' });
      }
    },
  });

  return (
    <>
      <div 
        className='google-sign-in-bg'
        style={{
          backgroundImage: `url(${assignBucketUrl(Cover)})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'Cover',
          backgroundPositionY: 'center',
          width: '100dvw',
          height: '100dvh', 
          position: 'absolute'
        }}></div>
      <div className='background-overlay'></div>
      <div className='google-sign-in min-h-screen bg-surface-secondary d-flex flex-column justify-content-center'>
        <div className='row mx-0'>
          
          <div className='google-sign-in-bg col-lg-12 border-left-lg min-h-screen position-relative d-flex align-items-center justify-content-center'
          >
            
            <div className='shadow-sm rounded bg-white text-center google-box d-flex align-items-center flex-column gap-3'>
              <img
                alt=''
                src={assignBucketUrl(LogoImage)}
                onError={(event) => {
                  event.target.src = ProfileDefault;
                  event.onerror = null;
                }}
                width='150'
              />
              <div className='mt-4 text-black' style={{ fontWeight: '500' }}>
                <div className='d-flex flex-column gap-2'>
                  <h1>Welcome Back</h1>
                  <p>Sign in with your Mash account to continue</p>
                </div>
              </div>
              <div className='google-button'>
                <GoogleLogin
                  onSuccess={responseMessage} onError={errorMessage} />
              </div>
            </div>
            <div className='py-lg-16'>
              <div className='row'>
                <div className='col-lg-12 bottom-div px-lg-9 text-end mb-3'>
                  {/* <span className='text-white mb-3 copywrite'>
                    Illustration by Masher <a rel='noreferrer' target='_blank' href='https://www.instagram.com/benjamin_thomson/'>Ben Thomson</a>
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
