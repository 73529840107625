import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import Avatar from '../../../../components/Avatar/Avatar';
import { sendBriefApiV2, syncLiveblocks, checkBriefInvitesSentApiV2 } from '../../../../apis/index';
import { EditProfileLoader } from '../../../../components/common/loader';

export default function ShareBrief({ briefId, deliverables, closeModal }) {
    const [mashers, setMashers] = useState([]);
    const [invited, setInvited] = useState([]);

    const [selected, setSelected] = useState([]);

    const [updating, setUpdating] = useState(false);
        
    if (deliverables) {
      useEffect(() => {
        checkBriefInvitesSentApiV2(briefId).then((res) => {
          if (res.status === 200) {            
            setInvited(res.data?.invited);
          }

          const roles = deliverables.reduce(( collected, deliverable) => {
            if (deliverable.role) {
              const role = deliverable.role.map((r) => {
                r.deliverableName = deliverable.deliverableName;
                return r;
              });
              return collected.concat(role);
            }
            return collected;
          }, []);
          const reduced = roles.reduce((collected, role) => {
            if (role?.Masher) {           
              const mashers = role?.Masher.map((masher) => {
                masher.roleId = role?.roleId;
                masher.roleName = role?.roleName;
                masher.deliverableName = role?.deliverableName;
                return masher;
              });
              return collected.concat(mashers);
            }
            return collected;
          }, []);
          setMashers(reduced);
        });
        
      },[]);
    }

    
  const formik = useFormik({
    initialValues: {
      mashers:[]
    },
    onSubmit: async (values) => {
      setUpdating(true);
      const allRequests = values?.mashers.reduce((c: any, masherid) => {
        c.push(sendBriefApiV2({
          briefId,
          masherid
        }));
        return c;
      }, []);
      Promise.all(allRequests).then(async (responses) => {
        
        const reduced = responses.reduce((c, res) => {
          if (res.status === 200 || 201) {
            c.push(1);
          }
          return c;
        }, []);
        await syncLiveblocks({
          briefId,
        });
        toast.success(`${reduced?.length} out of ${values?.mashers?.length} invitations sent.`, { id: '001' });
        
        setUpdating(false);          
        closeModal();
      });
      // const res = await inviteMasherApi(values);
    }
  });
  
  const handleChange = (e) => {    
    if (e.target.checked) {
      setSelected((s: any) => {
        if (e.target) {
          s.push(e.target.value);
        }
        return s;
      });
    } else {
      setSelected((s: any) => {
        let n = s;
        if (e.target) {
          const index = n.findIndex((el) => el === e.target.value);
          if (index > -1) {
            n.splice(index, 1);
          }
        }
        return n;
      });
    }
    formik.handleChange(e);
  };

  return (<>
    {
      mashers?.length > 0 ?
      <form onSubmit={formik.handleSubmit}>
        {
          mashers.map((masher: any) => {
            let checked = invited.find((el: any) => el.roleId === masher.roleId && el.masherId === masher.masherId);
            let sel = selected.find((el: any) => el === masher.masherId);
            return (
              <div className='brief-share-modal-row'>
                <div className='brief-share-masher-details'>
                  <input name='mashers' value={masher.masherId} onChange={handleChange} type="checkbox" checked={checked || sel} disabled={checked} className='brief-share-modal-row-checkbox' />
                  <Avatar name={masher.masherName} id={masher.masherId} url={masher.masherAvatarUrl}/>
                  <div className='brief-share-modal-row-name'>{masher.masherName}</div>
                </div>
                <div className='brief-share-modal-row-role'><span>{masher?.deliverableName}</span>{masher?.roleName}</div>
              </div>
            )
          })
        }
        <div className='brief-button-container'>
            <button type='button' className='button-close' style={{width: '100%'}} onClick={closeModal}>
              Cancel
            </button>
            <button type='submit' className='button-primary' disabled={updating} style={{width: '100%'}}>
              {updating ? (
                <div className='text-xs text-center'>
                  <EditProfileLoader />
                </div>
              ) : (
                'Send Brief'
              )}
            </button>
          </div>
      </form>
      :
      <div>
        <p>Add some deliverables, roles and mashers to share this brief!</p>
      </div>
    }
  </>);
}