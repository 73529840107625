import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getMasherByIdApi, updateMasherProfileApi } from '../../../apis';
import { InputErrorMessage } from '../../../components/errorMessages';
import { AccountDetailsValidation } from '../../../components/Validation';
import { getLastElementId } from '../../../utils/helperFunctions';

export default function FormSetting() {
  const [loader, setLoader] = useState(false);
  const [profileData, setProfileData] = useState({ data: null, loading: false });
  const masherId = getLastElementId();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      id: '',
      experience: '',
      workedForYourself: '',
      minDayRate: '',
      currency: '',
    },
    validationSchema: AccountDetailsValidation,
    onSubmit: async (values) => {
      try {
        setLoader(true);
        const res = await updateMasherProfileApi({ workDetails: { ...values }, id: profileData?.data?._id });
        if (res.status === 200) {
          toast.success(res.data.message, { id: 'succcess' });
          navigate(`/mashers/mashers-profile/${masherId}`);
        }
      } catch (error) {
        setLoader(false);
        toast.error(error.response.data.message, { id: 'error' });
        if (error?.response?.data?.status === 401) {
          navigate('/sign-in');
        }
      }
    },
  });

  const getMasherProfile = async (id) => {
    setProfileData({ loading: true });
    try {
      const res = await getMasherByIdApi(id);
      if (res.status === 200) {
        setProfileData({ data: res.data.masherProfile, loading: false });
      }
    } catch (error) {
      setProfileData({ data: null, loading: false });
    }
  };

  useEffect(() => {
    getMasherProfile(masherId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  const handleSelectCurrency = (e) => {
    formik.setFieldValue('currency', e.target.value);
    e.preventDefault();
    formik.handleChange(e);
  };

  useEffect(() => {
    const Data = profileData?.data;
    formik.setValues({
      id: masherId,
      experience: Data?.workDetails?.experience,
      workedForYourself: Data?.workDetails?.workedForYourself,
      minDayRate: Data?.workDetails?.minDayRate,
      currency: Data?.workDetails?.currency,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='container px-0'>
        <main className='bg'>
          <div className='row mb-4'>
            <div className='col-md-6'>
              <label className='form-label'>How many years of experience do you have on this sector ?</label>

              <input
                type='text'
                name='experience'
                onChange={handleChange}
                disabled={loader}
                value={formik.values.experience}
                className='form-control'
                placeholder='Example : 2 Years'
              />
              <InputErrorMessage error={formik.touched.experience && formik.errors.experience} marginBottom={-5} />
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-md-6'>
              <label className='form-label'>How long have you worked for yourself ?</label>

              <input
                type='text'
                name='workedForYourself'
                onChange={handleChange}
                disabled={loader}
                value={formik.values.workedForYourself}
                className='form-control'
                placeholder='Example : 2 Years'
              />
              <InputErrorMessage error={formik.touched.workedForYourself && formik.errors.workedForYourself} marginBottom={-5} />
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-md-6'>
              <label className='form-label'>What is your minimum day rate ?</label>

              <div className='input-group mb-2 mr-sm-2 mb-sm-0'>
                <div className='input-group-addon currency-addon'>
                  <select className='currency-selector' name='currency' value={formik.values.currency} onChange={handleSelectCurrency}>
                    <option data-symbol='$' value={'USD'} data-placeholder='0.00' selected>
                      USD
                    </option>
                    <option data-symbol='$' value={'AUD'} data-placeholder='0.00'>
                      AUD
                    </option>
                    <option data-symbol='£' value={'GBP'} data-placeholder='0.00'>
                      GBP
                    </option>
                    <option data-symbol='¥' value={'EUR'} data-placeholder='0'>
                      EUR
                    </option>
                    <option data-symbol='$' value={'SGD'} data-placeholder='0.00'>
                      SGD
                    </option>
                  </select>
                </div>
                <input
                  type='text'
                  className={Boolean(formik.touched?.minDayRate && formik.errors?.minDayRate) ? 'form-control border-danger' : 'form-control'}
                  id='minDayRate'
                  name='minDayRate'
                  value={formik.values.minDayRate}
                  onChange={handleChange}
                  placeholder='Enter your minimum day rate'
                />
              </div>
              <InputErrorMessage error={formik.touched.minDayRate && formik.errors.minDayRate} marginBottom={-5} />
            </div>
            <div className='col-sm-12'>
              <button type='submit' className='btn btn-sm btn-primary btn-width mt-10 btn_primary_black'>
                <p>Save Changes</p>
              </button>
            </div>
          </div>
        </main>
      </form>
    </>
  );
}
