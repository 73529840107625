import React from 'react';
import PageHeader from '../../components/pageElements/pageHeader/PageHeader';
import AddButton from '../../components/common/buttons/AddButton';
import AdminLayout from '../../components/admin-layout';

import { UserContext } from '../../appContext';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import TypesenseCompanyListComponent from '../../components/TypesenseList/TypesenseCompanyListComponent';

export default function CompaniesListView() {

  const typesenseInitiate = () => {
    try{
      const typesenseInstantsearchAdapter =  new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            {
              host: process.env.REACT_APP_HOST_NAME,
              protocol: 'https',
            },
          ],
        },
        additionalSearchParameters: {
          collection: 'companies',
          sort_by: 'properties_name:asc',
          query_by: 'properties_name, properties_country, properties_industry',
        }
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch(e) {
      return 'failed';
    }
  };
  const searchClient = typesenseInitiate();

  const tableHeaders = [
    {
      title: 'Company'
    },
    {
      title: 'Domain'
    },
    {
      title: 'Industry'
    },
    {
      title: 'Country'
    },
    {
      title: 'Hubspot',
      display: 'flex',
      justify: 'center'
    }
  ];
  const columnWidth = () => {
    return `${100 / tableHeaders.length}%`;
  };
  

  return (
    <AdminLayout>
      <UserContext.Provider>
        <PageHeader title='Companies'/>
        <div className='container-fluid !px-4 md:!px-16'>
          <article>
            <main className='pt-6 d-flex flex-column'>
              <TypesenseCompanyListComponent
                searchClient={searchClient}
                headers={tableHeaders}
                columnWidth={'100%'}
                blockTitle='Companies'
                tablePaddingRequired={false}
              /> 
            </main>
          </article>
        </div>

      </UserContext.Provider>
    </AdminLayout>
  );
}
