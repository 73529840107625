import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { briefValidation, briefValidation2, projectVal3 } from '../../../components/Validation';
import { ConvertToProjectContext } from '../../../appContext';
import { v4 as uuidv4 } from 'uuid';
import {
  convertToProjectApi,
  editBriefApi,
  getAllBriefApi,
  getBriefByIdApi,
  fetchCategoryApi,
  fetchClientApi,
  fetchEngagementTypeApi,
  fetchIndustryApi,
  fetchRegionApi,
  getMasherShareApi,
} from '../../../apis/index.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import ConvertProject1 from './ConvertProject1.js';
import ConvertProject2 from './ConvertProject2.js';
import ConvertProject3 from './ConvertProject3.js';
import AdminLayout from '../../../components/admin-layout/index.js';
import { allClientData } from '../../../utils/helperFunctions';

const EditBrief = () => {
  const [step, setStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [briefData, setBriefData] = useState({ data: '', loading: false });
  const [formSelects, setformSelects] = useState({ region: '', industry: '', engagementType: '', category: '', client: '' });
  const [masherData, setMasherData] = useState({ data: null, loading: false });
  const searchFilter = { search: '', skills: '', minExperience: '', maxExperience: '', location: '', page: 1, limit: '', status: 'approved' };
  const [brief, setBrief] = useState({ data: null, loading: false });
  const [value, setValue] = useState('');
  const [client, setClient] = useState({ data: null, loading: false });
  const location = useLocation();

  const navgate = useNavigate();
  const currDate = moment().format('YYYY-MM-DD');
  const briefDetails = briefData?.data;

  const getAllBrief = async (search, startDate, dueDate, page, limit) => {
    try {
      const res = await getAllBriefApi(search, startDate, dueDate, page, limit);
      if (res.status === 200) {
        setBrief({ data: res.data.allBriefs });
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const getBriefById = async (id) => {
    setBriefData({ loading: true });
    setLoader(true);
    try {
      const res = await getBriefByIdApi(id);
      if (res.status === 200) {
        setLoader(false);
        setBriefData({ data: res.data.brief, loading: false });
      }
    } catch (error) {
      setLoader(false);
      setBriefData({ loading: false });

      return error;
    }
  };

  const formik = useFormik({
    initialValues: {
      clientName: '',
      briefName: '',
      logo: '',
      industry: '',
      region: '',
      clientType: '',
      category: '',
      engagementStructure: '',
    },
    validationSchema: briefValidation,
    onSubmit: () => {
      setStep(1);
      // setFormData(values);
    },
  });
  const formik2 = useFormik({
    initialValues: {
      inBrief: '',
      background: '',
      projectAmbition: '',
      brand: '',
      audience: '',
      kpis: '',
      experience: '',
    },
    validationSchema: briefValidation2,
    onSubmit: () => {
      setStep(2);
      // setFormData2(values);
    },
  });

  const formik3 = useFormik({
    initialValues: {
      deliverableDetails: [
        { id: uuidv4(), name: '', startDate: currDate, dueDate: currDate, masherRole: '', delieveryFormat: '', order: '', description: '' },
      ],
      websiteLink: [''],
      budget: '',
      attachments: [{ name: '', file: '' }],
      currency: '',
      fileName: '',
      mashManager: '',
      id: '',
      projectFee: '',
      projectCurrency: '',
      team: [{ id: uuidv4(), masherName: '', currency: '', fees: '', userId: '' }],
    },
    validationSchema: projectVal3,
    onSubmit: async (values) => {
      let alldata = { ...values, ...formik.values, ...formik2.values };
      const { value, label } = alldata.clientName[0];
      alldata.clientId = value;
      alldata.clientName = label;
      const {
        team,
        clientId,
        clientName,
        briefName,
        logo,
        industry,
        region,
        clientType,
        category,
        engagementStructure,
        inBrief,
        background,
        projectAmbition,
        brand,
        audience,
        kpis,
        experience,
        deliverableDetails,
        websiteLink,
        budget,
        attachments,
        currency,
        fileName,
        mashManager,
        id,
      } = alldata;

      const briefData = {
        team,
        id,
        clientId,
        clientName,
        briefName,
        logo,
        industry,
        region,
        clientType,
        category,
        engagementStructure,
        inBrief,
        background,
        projectAmbition,
        brand,
        audience,
        kpis,
        experience,
        deliverableDetails,
        websiteLink,
        budget,
        attachments,
        currency,
        fileName,
      };
      const projectData = { briefId: id, team, mashManager, projectFee: budget, projectCurrency: currency, clientName };

      try {
        setLoader(true);
        const projectApi = await convertToProjectApi(projectData);
        const briefApi = await editBriefApi(briefData);

        if (projectApi.status === 200 || projectApi.status === 201) {
          toast.success(projectApi.data.message, { id: '001' });
          setLoader(false);
          navgate('/');
        }

        if (briefApi.status === 200 || 201) {
          setLoader(false);
          navgate('/');
        }
      } catch (error) {
        setLoader(false);
      }
    },
  });

  const allMasherData = async (masherPayload) => {
    setMasherData({ loading: true });
    try {
      const res = await getMasherShareApi(masherPayload);
      if (res.status === 200) {
        setMasherData({ data: res.data.data, loading: false });
      }
    } catch (error) {
      setMasherData({ loading: false });
    }
  };

  const fetchSelectRegion = async () => {
    setformSelects({ loading: true });
    const selectResponse = {
      regionRes: await fetchRegionApi(),
      industryRes: await fetchIndustryApi(),
      engagementRes: await fetchEngagementTypeApi(),
      cateRes: await fetchCategoryApi(),
      clientRes: await fetchClientApi(),
    };

    setformSelects({
      loading: false,
      region: selectResponse?.regionRes.data,
      industry: selectResponse?.industryRes.data,
      engagementType: selectResponse?.engagementRes.data,
      category: selectResponse?.cateRes.data,
      client: selectResponse?.clientRes.data,
    });
  };

  useEffect(() => {
    fetchSelectRegion();
    allClientData({ ...searchFilter }, setClient);
    allMasherData('approved');
    getAllBrief();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let briefId = location?.state?.briefId;
    let briefName = location?.state?.briefName;
    if (briefId) {
      setValue({ value: briefId, label: briefName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.briefId]);

  useEffect(() => {
    if (value?.value) {
      getBriefById(value?.value);
    }
  }, [value]);

  useEffect(() => {
    if (briefDetails) {
      formik.setValues({
        clientName: [{ value: briefDetails.clientId._id, label: briefDetails.clientName }],
        briefName: briefDetails.briefName,
        logo: briefDetails.logo,
        industry: briefDetails.industry,
        region: briefDetails.region,
        clientType: briefDetails.clientType,
        category: briefDetails.category,
        engagementStructure: briefDetails.engagementStructure,
      });
      formik2.setValues({
        inBrief: briefDetails.inBrief,
        experience: briefDetails.experience,
        background: briefDetails.background,
        projectAmbition: briefDetails.projectAmbition,
        brand: briefDetails.brand,
        audience: briefDetails.audience,
        kpis: briefDetails.kpis,
      });
      formik3.setValues({
        deliverableDetails: briefDetails.deliverableDetails,
        websiteLink: briefDetails.websiteLink,
        budget: briefDetails.budget,
        currency: briefDetails.currency,
        attachments: briefDetails.attachments,
        team: [
          {
            id: uuidv4(),
            masherName: '',
            currency: '',
            fees: '',
            userId: '',
          },
        ],
        id: value.value,
        fileName: briefDetails.fileName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [briefDetails]);
 
  return (
    <>
      <ConvertToProjectContext.Provider
        value={{
          client,
          formik,
          formik2,
          formik3,
          setStep,
          loader,
          briefData,
          value,
          setValue,
          brief,
          formSelects,
          masherData,
          getBriefById,
        }}>
        <AdminLayout>
          <div className='container create-brief'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/' className='blackOnHover'>
                    Home
                  </Link>
                </li>

                <li className='breadcrumb-item active' aria-current='page'>
                  Convert To Project
                </li>
              </ol>
            </nav>

            {step === 0 && <ConvertProject1 />}
            {step === 1 && <ConvertProject2 />}
            {step === 2 && <ConvertProject3 />}
          </div>
        </AdminLayout>
      </ConvertToProjectContext.Provider>
    </>
  );
};

export default EditBrief;
