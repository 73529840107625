import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Loader } from '../../components/common/loader';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { editClientApi, fetchClientApi, fetchIndustryApi, fetchRegionApi, getMasherShareApi, getClientByIdApi, fetchClientStatusApi, fetchCountrycodeApi } from '../../apis';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import AdminLayout from '../../components/admin-layout';
import { InputErrorMessage } from '../../components/errorMessages';
import { createClientValidation } from '../../components/Validation';
import { assignBucketUrl, getLastElementId, helperImageUpload, style } from '../../utils/helperFunctions';
import { parsePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export default function EditClient() {
  const [select, setSelect] = useState({ region: '', industry: '', type: '', clientStatus: '' });
  const [clientById, setClientById] = useState({ data: null, loading: true });
  const [selected, setSelected] = useState({ industry: { value: '', label: '' }, region: { value: '', label: '' }, clientStatus: { value: '', label: '' }, type: { value: '', label: '' }, countryCode: { value: '', label: '' } });
  const [masherData, setMasherData] = useState({ data: null, loading: true });
  const [masherWorkWith, setMasherWorkWith] = useState(['']);
  const searchFilter = useMemo(
    () => [{ search: '', skills: '', minExperience: '', maxExperience: '', location: '', page: 1, limit: '', status: 'approved' }],
    []
  );

  const inputRef = useRef(null);
  const client = clientById?.data;
  const clientId = getLastElementId();
  const navigate = useNavigate();
  const [code, setCode] = useState(false);

  const fetchCountryCodes = async () => {
    const res = await fetchCountrycodeApi();
    setCode(res.data.countries);
  };

  const countryCode =
    code &&
    code?.map((el, i) => {
      const container = {};
      container['value'] = el.code;
      container['label'] = `${el?.name} (${el.code})` || '--';
      return container;
    });

  const formik = useFormik({
    initialValues: {
      id: '',
      brandName: '',
      logo: '',
      coverImage: '',
      industry: '',
      type: '',
      region: '',
      clientStatus: '',
      masherWorkWith,
      clientName: '',
      clientEmail: '',
      websiteLink: '',
      notes: '',
      clientContacts: [],
      clientDesignation: '',
      clientPhoneNo: '',
      clientPhoneCode: ''
    },
    validationSchema: createClientValidation,
    onSubmit: async (values) => {
      try {
        const res = await editClientApi(values);
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message, { id: '001' });
          navigate(`/brands/brand-profile/${clientId}`);
        }
      } catch (error) {
        console.log(error);

        toast.error(error?.response?.data?.message);
      }
    },
  });
  const handleAddContact = () => {
    formik.setFieldValue('clientContacts', [...formik?.values?.clientContacts, { clientName: '', email: '', clientPhoneNo: '', clientPhoneCode: '', clientDesignation: '' }]);
  };

  const handleRemoveContact = index => {
    const newContacts = [...formik?.values?.clientContacts];
    newContacts.splice(index, 1);
    formik.setFieldValue('clientContacts', newContacts);
  };
  const getClient = async (id) => {
    setClientById({ loading: true });
    try {
      const res = await getClientByIdApi(id);
      if (res.status === 200 || 201) {
        setClientById({ data: res.data.client, loading: false });
      }
    } catch (error) {
      setClientById({ loading: false });
    }
  };
  const fetchSelectRegion = async () => {
    const regionRes = await fetchRegionApi();
    const industryRes = await fetchIndustryApi();
    const typeRes = await fetchClientApi();
    const clientStatus = await fetchClientStatusApi();

    setSelect({ region: regionRes.data, industry: industryRes.data, type: typeRes.data, clientStatus: clientStatus.data });
  };
  const handleFileUpload = (event) => {
    helperImageUpload(formik, event);
  };
  const handleChange = (e) => {
    formik.handleChange(e);
  };

  const indOptions =
    select?.industry &&
    select?.industry?.map((el, i) => {
      const container = {};
      container['value'] = el.id;
      container['label'] = el?.name || '--';
      return container;
    });

  const regOptions =
    select?.region &&
    select?.region?.map((el, i) => {
      const container = {};
      container['value'] = el.id;
      container['label'] = el?.name || '--';
      return container;
    });

  const clientStatusOptions =
    select?.clientStatus &&
    select?.clientStatus?.map((el, i) => {
      const container = {};
      container['value'] = el.id;
      container['label'] = el?.name || '--';
      return container;
    });

  const typOptions =
    select?.type &&
    select?.type?.map((el, i) => {
      const container = {};
      container['value'] = el.id;
      container['label'] = el?.name || '--';
      return container;
    });

  const allMasherData = async (status) => {
    setMasherData({ loading: true });
    try {
      const res = await getMasherShareApi(status);
      if (res.status === 200) {
        setMasherData({ data: res.data.data, loading: false });
      }
    } catch (error) {
      setMasherData({ loading: false });
    }
  };

  const teamOptions = masherData?.data?.map((el) => {
    const container = {};
    container['value'] = el?._id;
    container['label'] = el?.userId?.firstName + ' ' + el?.userId?.lastName;
    return container;
  });

  useEffect(() => {
    allMasherData('approved');
    fetchSelectRegion();
    getClient(clientId);
  }, [searchFilter, clientId]);

  useEffect(() => {
    if (client?._id) {
      formik.setValues({
        id: client?._id,
        brandName: client?.brandName,
        logo: client?.logo,
        coverImage: client?.coverImage,
        industry: client?.industry,
        type: client?.type,
        region: client?.region,
        clientStatus: client?.clientStatus,
        masherWorkWith:
          client?.masherWorkWith?.length > 0
            ? client?.masherWorkWith?.map((el) => {
              return el;
            })
            : [''],
        clientName: client?.clientName,
        clientEmail: client?.clientEmail,
        websiteLink: client?.websiteLink,
        notes: client?.notes,
        clientContacts: client?.clientContacts,
        clientDesignation: client?.clientDesignation,
        clientPhoneNo: client?.clientPhoneNo,
        clientPhoneCode: client?.clientPhoneCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    formik.setFieldValue('masherWorkWith', masherWorkWith);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masherWorkWith]);

  useEffect(() => {
    fetchCountryCodes();
  }, []);

  return (
    <AdminLayout>
      <div className='container create-brief'>
        <>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='/' className='blackOnHover'>
                  Home
                </Link>
              </li>
              <li className='breadcrumb-item'>
                <Link to='/brands' className='blackOnHover'>
                  Brands
                </Link>
              </li>
              <li className='breadcrumb-item active' aria-current='page'>
                Edit Brand
              </li>
            </ol>
          </nav>
          {clientById?.loading ? (
            <div className='text-center mt-6'>
              <Loader />
            </div>
          ) : (
            <>
              <form>
                <div className='row'>
                  <div className='col-md-6 mb-3 ' style={{ position: 'relative' }}>
                    <label className='form-label' style={{ display: 'block' }}>
                      Profile Image
                    </label>

                    <img
                      alt=''
                      onError={(event) => {
                        event.target.src = ProfileDefault;
                        event.onerror = null;
                      }}
                      src={formik?.values?.logo ? assignBucketUrl(formik?.values?.logo) : ProfileDefault}
                      style={{
                        width: '80px',
                        height: '80px',
                        border: '1px solid rgb(25, 19, 19, 0.25)',
                        borderRadius: '50%',
                        boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                        marginRight: '10px',
                      }}
                    />

                    <div className='profileImgUpload d-inline-block'>
                      <label htmlFor='profile'>
                        <div
                          className='changeFileBtn'
                          onClick={() => {
                            inputRef?.current?.click();
                          }}>
                          Choose File
                        </div>
                      </label>
                    </div>

                    {formik.values?.logo !== '' ? (
                      <div
                        className='removeFileBtn d-inline-block'
                        onClick={() => {
                          formik.setFieldValue('logo', '');
                        }}>
                        Remove
                      </div>
                    ) : (
                      <div className='d-inline-block'>No Profile Image Found</div>
                    )}
                    <input
                      onChange={handleFileUpload}
                      type='file'
                      title=' '
                      id='profile'
                      className='btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                      placeholder='Upload'
                      name='logo'
                    />
                  </div>

                  <div className='col-md-6 mb-3 ' style={{ position: 'relative' }}>
                    <label className='form-label' style={{ display: 'block' }}>
                      Cover Image
                    </label>

                    <img
                      alt=''
                      src={formik?.values?.coverImage ? assignBucketUrl(formik?.values?.coverImage) : assignBucketUrl(ProfileDefault)}
                      onError={(event) => {
                        event.target.src = ProfileDefault;
                        event.onerror = null;
                      }}
                      style={{
                        width: '80px',
                        height: '80px',
                        border: '1px solid rgb(25, 19, 19, 0.25)',
                        borderRadius: '50%',
                        boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                        marginRight: '10px',
                      }}
                    />

                    <div className='profileImgUpload d-inline-block'>
                      <label htmlFor='cover'>
                        <div
                          className='changeFileBtn'
                          onClick={() => {
                            inputRef?.current?.click();
                          }}>
                          Choose File
                        </div>
                      </label>

                      {formik.values?.coverImage !== '' ? (
                        <div
                          className='removeFileBtn d-inline-block  '
                          onClick={() => {
                            formik.setFieldValue('coverImage', '');
                          }}>
                          Remove
                        </div>
                      ) : (
                        <div className='d-inline-block'>No Cover Image Found</div>
                      )}
                    </div>
                    <input
                      onChange={handleFileUpload}
                      type='file'
                      title=' '
                      id='cover'
                      className={
                        Boolean(formik.touched?.coverImage) && Boolean(formik.errors?.coverImage)
                          ? 'edit-pen2 d-none  fileInput hidden '
                          : ' hidden edit-pen2 d-none  fileInput'
                      }
                      placeholder='Upload'
                      name='coverImage'
                    />
                  </div>

                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>
                      Brand<span className='text-danger'> *</span>
                    </label>
                    <input
                      onChange={handleChange}
                      value={formik.values.brandName}
                      type='text'
                      className={
                        Boolean(formik.touched?.brandName) && Boolean(formik.errors?.brandName) ? 'form-control border-danger' : 'form-control'
                      }
                      placeholder='Enter Brand Name'
                      name='brandName'
                    />
                    <InputErrorMessage error={formik.touched.brandName && formik.errors.brandName} marginBottom={-5} />
                  </div>

                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>
                      Industry<span className='text-danger'> *</span>
                    </label>

                    <Select
                      styles={style}
                      options={indOptions}
                      placeholder="Select Industry"
                      name={'industry'}
                      className={
                        Boolean(formik.touched?.industry) && Boolean(formik.errors?.industry)
                          ? 'form-control border-danger p-015 '
                          : 'form-control p-015'
                      }
                      classNamePrefix='select'
                      value={formik.values.industry}
                      onChange={(e) => {
                        setSelected({ ...selected, industry: { value: e?.value, label: e?.label } });
                        formik.setFieldValue('industry', [{ value: e.value, label: e.label }]);
                      }}
                    />
                    <InputErrorMessage error={formik.touched.industry && formik.errors.industry} marginBottom={-5} />
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>
                      Client Type<span className='text-danger'> *</span>
                    </label>

                    <Select
                      styles={style}
                      placeholder="Select Client Type"
                      options={typOptions}
                      name='type'
                      className={
                        Boolean(formik.touched?.type) && Boolean(formik.errors?.type) ? 'form-control border-danger p-015 ' : 'form-control p-015'
                      }
                      classNamePrefix='select'
                      value={formik.values.type}
                      onChange={(e) => {
                        setSelected({ ...selected, type: { value: e?.value, label: e?.label } });
                        formik.setFieldValue('type', [{ value: e.value, label: e.label }]);
                      }}
                    />
                    <InputErrorMessage error={formik.touched.type && formik.errors.type} marginBottom={-5} />
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>
                      Region<span className='text-danger'> *</span>
                    </label>

                    <Select
                      styles={style}
                      placeholder="Select Region"
                      options={regOptions}
                      name='region'
                      className={
                        Boolean(formik.touched?.region) && Boolean(formik.errors?.region) ? 'form-control border-danger p-015 ' : 'form-control p-015'
                      }
                      classNamePrefix='select'
                      value={formik.values.region}
                      onChange={(e) => {
                        setSelected({ ...selected, region: { value: e?.value, label: e?.label } });
                        formik.setFieldValue('region', [{ value: e.value, label: e.label }]);
                      }}
                    />
                    <InputErrorMessage error={formik.touched.region && formik.errors.region} marginBottom={-5} />
                  </div>

                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>
                      Client Status
                    </label>

                    <Select
                      styles={style}
                      placeholder="Select Client Status"
                      options={clientStatusOptions}
                      name='clientStatus'
                      className='form-control p-015'
                      classNamePrefix='select'
                      value={formik?.values?.clientStatus}
                      onChange={(e) => {
                        setSelected({ ...selected, clientStatus: { value: e?.value, label: e?.label } });
                        formik.setFieldValue('clientStatus', [{ value: e.value, label: e.label }]);
                      }}
                    />
                    <InputErrorMessage error={formik.touched.clientStatus && formik.errors.clientStatus} marginBottom={-5} />
                  </div>

                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>Mashers Worked With</label>

                    <Select
                      styles={style}
                      placeholder="Select Mashers Worked With"
                      options={teamOptions}
                      isMulti
                      creatable
                      name='masherWorkWith'
                      className={
                        Boolean(formik.touched?.masherWorkWith) && Boolean(formik.errors?.masherWorkWith)
                          ? 'form-control border-danger p-015 '
                          : 'form-control p-015'
                      }
                      classNamePrefix='select form-control'
                      value={formik.values.masherWorkWith}
                      onChange={(e) => {
                        setMasherWorkWith(e);
                      }}
                    />

                    {/* <InputErrorMessage error={formik.touched.masherWorkWith && formik.errors.masherWorkWith} marginBottom={-5} /> */}
                  </div>
                  <label className='surtitle mb-2 mt-5-mob' style={{ textTransform: 'none', fontSize: '1rem' }}>
                    Primary Contact<span className='text-danger'></span>
                  </label>
                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>
                      Client Contact Name<span className='text-danger'> *</span>
                    </label>
                    <input
                      onChange={handleChange}
                      value={formik?.values?.clientName}
                      type='text'
                      className={
                        Boolean(formik.touched?.clientName) && Boolean(formik.errors?.clientName) ? 'form-control border-danger' : 'form-control '
                      }
                      placeholder='Enter Client Contact Name'
                      name='clientName'
                    />
                    <InputErrorMessage error={formik.touched.clientName && formik.errors.clientName} marginBottom={-5} />
                  </div>
                  <div className='col-md-6 mb-3'>
                    <label className='form-label'>
                      Client Contact Email<span className='text-danger'> *</span>
                    </label>
                    <input
                      onChange={handleChange}
                      value={formik?.values?.clientEmail}
                      type='text'
                      className={
                        Boolean(formik.touched?.clientEmail) && Boolean(formik.errors?.clientEmail) ? 'form-control border-danger' : 'form-control '
                      }
                      placeholder='Enter Client Contact Email'
                      name='clientEmail'
                    />
                    <InputErrorMessage error={formik.touched.clientEmail && formik.errors.clientEmail} marginBottom={-5} />
                  </div>
                  <div className='col-md-6'>
                    <label className='form-label'>
                      Client Designation
                    </label>
                    <input
                      type="text"
                      name={'clientDesignation'}
                      value={formik?.values?.clientDesignation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Client Designation"

                      className={
                        Boolean(formik.touched?.clientDesignation) && Boolean(formik.errors?.clientDesignation) ? 'form-control border-danger' : 'form-control '
                      }
                    />
                    <InputErrorMessage error={formik.touched?.clientDesignation && formik.errors?.clientDesignation} marginBottom={-5} />
                  </div>
                  <div className='col-md-6 mb-2'>
                    <div className='row phone-wrap'>
                      <label className='form-label'>
                        Client Phone No.
                      </label>
                      <div className='phone'>
                        <div className='row'>
                          <div className='col-md-12 pe-0'>
                            <PhoneInput
                              placeholder="Enter Client Phone No"
                              defaultCountry="AU"
                              name={'clientPhoneNo'}
                              autoComplete="off"
                              value={formik.values.clientPhoneNo}
                              rules={{ required: true }}
                              onChange={(e) => {
                                if (e) {
                                  const obj = { label: `${parsePhoneNumber(e)?.country}`, value: `+${parsePhoneNumber(e)?.countryCallingCode}` };
                                  formik.setFieldValue('clientPhoneCode', obj);
                                }
                                formik.setFieldValue('clientPhoneNo', e || '');
                              }}
                            />
                            <InputErrorMessage error={formik.touched?.clientPhoneNo && formik.errors?.clientPhoneNo} marginBottom={-5} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-md-12 mt-5'> */}
                  {formik.values.clientContacts.map((contact, index) => (
                    <>
                      <div key={'nm_' + index} className='col-md-6'>
                        <label className='form-label'>
                          Client Contact Name<span className='text-danger'> *</span>
                        </label>
                        <input
                          onChange={formik.handleChange}
                          value={contact.clientName}
                          type="text"
                          name={`clientContacts[${index}].clientName`}
                          className={
                            Boolean(formik.touched?.clientContacts?.[index]?.clientName) && Boolean(formik.errors?.clientContacts?.[index]?.clientName) ? 'form-control border-danger' : 'form-control '
                          }
                          placeholder='Enter Client Contact Name'
                        />
                        <InputErrorMessage error={formik.touched?.clientContacts?.[index]?.clientName && formik.errors?.clientContacts?.[index]?.clientName} marginBottom={-5} />
                      </div>

                      <div key={'em_' + index + 1} className='col-md-6' style={{ position: 'relative' }}>
                        <div>
                          <label className='form-label'>
                            Client Contact Email<span className='text-danger'> *</span>
                          </label>
                          <input
                            type="text"
                            name={`clientContacts[${index}].clientEmail`}
                            value={contact.clientEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Enter Client Contact Email"

                            className={
                              Boolean(formik.touched?.clientContacts?.[index]?.clientEmail) && Boolean(formik.errors?.clientContacts?.[index]?.clientEmail) ? 'form-control border-danger' : 'form-control '
                            }
                          />
                          <InputErrorMessage error={formik.touched?.clientContacts?.[index]?.clientEmail && formik.errors?.clientContacts?.[index]?.clientEmail} marginBottom={-5} />
                        </div>
                        <button key={'btn_' + index + 1} type="button" onClick={() => handleRemoveContact(index)} className="btn btn-sm btn_primary_black btn-remove">
                          Remove
                        </button>
                      </div>
                      <div key={'cnt_' + index} className='col-md-6'>
                        <label className='form-label'>
                          Client Designation
                        </label>
                        <input
                          type="text"
                          name={`clientContacts[${index}].clientDesignation`}
                          value={contact.clientDesignation}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Enter Client Designation"

                          className={
                            Boolean(formik.touched?.clientContacts?.[index]?.clientDesignation) && Boolean(formik.errors?.clientContacts?.[index]?.clientDesignation) ? 'form-control border-danger' : 'form-control '
                          }
                        />
                        <InputErrorMessage error={formik.touched?.clientContacts?.[index]?.clientDesignation && formik.errors?.clientContacts?.[index]?.clientDesignation} marginBottom={-5} />
                      </div>
                      <div className='col-md-6 mb-2' key={'phone_' + index}>
                        <div className='row phone-wrap'>
                          <label className='form-label'>
                            Client Phone No.
                          </label>
                          <div className='phone'>
                            <div className='row'>
                              <div className='col-md-12 pe-0'>
                                <PhoneInput
                                  placeholder="Enter Client Phone No"
                                  autoComplete="off"
                                  name={`clientContacts[${index}].clientPhoneNo`}
                                  value={contact.clientPhoneNo}
                                  rules={{ required: true }}
                                  onChange={(e) => {
                                    if (e) {
                                      const obj = { label: `${parsePhoneNumber(e)?.country}`, value: `+${parsePhoneNumber(e)?.countryCallingCode}` };
                                      formik.setFieldValue(`clientContacts[${index}].clientPhoneCode`, obj);
                                    }
                                    formik.setFieldValue(`clientContacts[${index}].clientPhoneNo`, e || '');
                                  }}
                                />
                                <InputErrorMessage error={formik.touched?.clientContacts?.[index]?.clientPhoneNo && formik.errors?.clientContacts?.[index]?.clientPhoneNo} marginBottom={-5} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  <button type="button" className="btn btn-sm btn_primary_black" style={{ width: 'fit-content', marginLeft: '12px', marginBottom: '20px' }} onClick={handleAddContact}>
                    Add Contact
                  </button>
                  <div className='col-md-12 mb-3'>
                    <label className='form-label'>
                      Website Link<span className='text-danger'> *</span>
                    </label>
                    <input
                      onChange={handleChange}
                      value={formik.values.websiteLink}
                      type='text'
                      className={
                        Boolean(formik.touched?.websiteLink) && Boolean(formik.errors?.websiteLink) ? 'form-control border-danger' : 'form-control '
                      }
                      placeholder='Enter Website Link'
                      name='websiteLink'
                    />
                    <InputErrorMessage error={formik.touched.websiteLink && formik.errors.websiteLink} marginBottom={-5} />
                  </div>
                  <div className='col-md-12 mb-3'>
                    <label className='form-label'>
                      Notes<span className='text-danger'> *</span>
                    </label>
                    <textarea
                      placeholder='Enter Notes...'
                      onChange={handleChange}
                      value={formik.values.notes}
                      name='notes'
                      className={Boolean(formik.touched?.notes) && Boolean(formik.errors?.notes) ? 'form-control border-danger' : 'form-control '}
                      rows={4}></textarea>
                    <InputErrorMessage error={formik.touched.notes && formik.errors.notes} marginBottom={-5} />
                  </div>
                  <div className='col-md-12 mt-3 mb-3'>
                    <button type='submit' onClick={formik.handleSubmit} className='btn btn-sm btn_primary_black btn-width'>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </>
      </div>
    </AdminLayout>
  );
}
