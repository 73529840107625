import { useFormik } from 'formik';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import { extendTimeForBriefApi, uploadProfileImgApi } from '../../apis';
import { AppContext } from '../../appContext';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import UserImage from '../../Assets/img/icons/users.png';
import { Loader } from '../../components/common/loader';
import { newChatValidation } from '../../components/Validation';
import { API_BASE_URL } from '../../utils/baseurl';
import { assignBucketUrl, capitalizeFirstLetter, getBriefInvitationTimeRemaining, getLastElementId } from '../../utils/helperFunctions';
import ExtendModal1 from './ExtendModal1';

export default function NewChatSection({ masherForChat, chatMasher, isProject }) {
  const { loggedInUser } = useContext(AppContext);
  const [chat, setChat] = useState([]);
  const [socket, setSocket] = useState(null);
  const [activeMasher, setActiveMasher] = useState(0);
  const [timeRem, setTimeRem] = useState(0);
  const [attaches2, setAttaches2] = useState({ data: null, loading: false });
  const [extendHours, setExtendHours] = useState({ days: '', hours: '', loading: false });

  const messagesEndRef = useRef(null);
  const inputRef = useRef();
  const id = getLastElementId();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const handleChange = (e) => {
    formik.handleChange(e);
  };

  const fileCheck = (file) => {
    const fileExt = file.split('.').at(-1);
    if (fileExt.toLowerCase() === 'png' || fileExt.toLowerCase() === 'jpg' || fileExt.toLowerCase() === 'jpeg') {
      return (
        <a href={file} target='_blank' rel='noreferrer'>
          <img
            style={{ width: '80px', height: '80px' }}
            alt='placeholder'
            src={assignBucketUrl(file)}
            onError={(event) => {
              event.target.src = ProfileDefault;
              event.onerror = null;
            }}
          />
        </a>
      );
    } else {
      return (
        <a href={file} target='_blank' rel='noreferrer'>
          <div className='icon icon-shape text-xl bg-black text-white'>
            <i className='bi bi-file-earmark-fill'></i>
          </div>
        </a>
      );
    }
  };
  const formatDiffTimeFromNowHHMMfor48hours = (validFrom, time) => {
    let __time = getBriefInvitationTimeRemaining(validFrom, time);
    if (__time === -1) {
      return '00D // 00H';
    }
    return `${__time?.Days}D // ${__time?.Hours}H`;
  };

  const handleAttachment = async (e) => {
    try {
      let file = e.target.files[0];
      let validFile = file.type.split('/').at(-1).toLowerCase();

      if (validFile?.match(/\.(jpg|jpeg|png|mp4|mov|mkv|webm|pdf|doc|docx|xls)$/)) {
        formik.setFieldError('attachment', 'Only PDF format allowed');

        toast('Incorrect Format', { icon: '⚠️' });
        return;
      }
      if (file.size > 10000001) {
        toast('File size is more than 10 MB', { icon: '⚠️' });

        formik.setFieldError('attachment', 'image size is more than 1 MB');

        return;
      } else {
        let fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onload = function () {
          // setAttaches(fr.result);
        };
      }

      const formdata = new FormData();
      formdata.append('file', file);
      setAttaches2({ loading: true });

      const response = await uploadProfileImgApi(formdata);
      if (response.status === 200) {
        setAttaches2({ data: response.data.filePath, loading: false });
      }
      formik.setFieldValue('attachment', { data: response.data.filePath });
    } catch (error) {
      toast.error(error.response.data.message, { id: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      message: '',
      senderId: '',
      recieverId: '',
      convoId: '',
      isRead: false,
      attachment: {},
    },
    validationSchema: newChatValidation,
    onSubmit: async (values) => {
      values.message = values?.message?.trim();

      if (!values?.message) {
        if (attaches2?.data) {
          socket?.emit('send message', values);
          formik.setFieldValue('attachment', '');
        }
      } else if (values?.message) {
        socket?.emit('send message', values);
        formik.setFieldValue('message', '');
      }

      setAttaches2({ data: null });
    },
  });

  const masherForChatFilteredArr = masherForChat?.data?.briefInvitation?.filter(
    (obj, index, self) => index === self.findIndex((t) => t?.masherId?._id === obj?.masherId?._id)
  );
  const isProjectTrue = isProject?.data?.project;

  const isProjectTrueFilteredArr = isProjectTrue?.team?.filter(
    (obj, index, self) => index === self.findIndex((t) => t?.userId?._id === obj?.userId?._id)
  );

  const handleGetId = (i) => {
    if (Boolean(masherForChatFilteredArr?.[i]?.briefId?._id && masherForChatFilteredArr?.[i]?.masherId?.userId?._id)) {
      const convoId = masherForChatFilteredArr?.[i]?.briefId?._id + '_' + masherForChatFilteredArr?.[i]?.masherId?.userId?._id;

      formik.setFieldValue('recieverId', masherForChatFilteredArr?.[i]?.masherId?.userId?._id);
      formik.setFieldValue('convoId', convoId);
      formik.setFieldValue('senderId', masherForChatFilteredArr?.[i]?.adminId?._id);
      setChat([]);
      setActiveMasher(i);
      setSocket(
        io(API_BASE_URL, {
          transports: ['websocket'],
          query: { convoId: convoId },
        })
      );
      setTimeRem({
        id: masherForChatFilteredArr?.[i]?.masherId?._id,
        name: masherForChatFilteredArr?.[i]?.masherId?.userId?.firstName,
      });
    }
  };

  const handleGetId2 = (i) => {
    if (Boolean(isProject?.data?.project?.briefId?._id && isProject?.data?.project?.team[i]?.masherName?.userId?._id)) {
      const briefId = isProject?.data?.project?.briefId?._id;
      const masherId = isProject?.data?.project?.team[i]?.masherName?.userId?._id;
      const masherName = isProject?.data?.project?.team[i]?.masherName?.label;

      const adminId = loggedInUser?.data[0]?._id;

      const convoId = briefId + '_' + masherId;

      formik.setFieldValue('recieverId', masherId);
      formik.setFieldValue('convoId', convoId);
      formik.setFieldValue('senderId', adminId);

      setChat([]);
      setActiveMasher(i);
      setSocket(
        io(API_BASE_URL, {
          transports: ['websocket'],
          query: { convoId: convoId },
        })
      );
      setTimeRem({
        id: masherId,
        name: masherName,
      });
    }
  };
 
  useEffect(() => {
    socket?.on('previous message', (message) => {
      setChat(message);
    });
  }, [socket]);

  useEffect(() => {
    socket?.on('message', (message) => {
      setChat(chat[0]?.msg ? [message] : [...chat, message]);
    });
  }, [chat, socket]);

  useEffect(() => {
    if (Boolean(masherForChat?.data?.briefInvitation?.length >= 1)) {
      handleGetId(0);
    }
    if (Boolean(isProject?.data?.project?.team?.length >= 1)) {
      handleGetId2(0);
    }
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masherForChat?.data?.briefInvitation]);

  return (
    <div className='chat-ui'>
      <div className='card'>
        <div className='card-body'>
          <div className='row align-items-center'>
            {isProject?.data?.project?.team?.length >= 1
              ? isProjectTrueFilteredArr?.map((el, i) => {
                const masherChatPic = isProject?.data?.project?.team[i]?.masherName?.userId?.profilePic;

                return (
                  <div key={`chat=${i}`} className='col-xxl-3 col-md-3 col-sm-6 border-end text-center '>
                    <div className='d-flex align-items-center'>
                      <div className='me-3'>
                        <div
                          className={`avatar rounded-circle cursor-pointer mashercircle ${activeMasher === i ? 'activeChat' : 'notActiveChat'} `}>
                          <img
                            onClick={() => {
                              handleGetId2(i);
                            }}
                            alt='...'
                            src={assignBucketUrl(masherChatPic)}
                            onError={(event) => {
                              event.target.src = ProfileDefault;
                              event.onerror = null;
                            }}
                          />
                        </div>
                        <small>{el?.masherName?.label?.length > 5 ? el?.masherName?.label?.slice(0, 5) + '...' : el?.masherName?.label}</small>
                      </div>
                      <div className='flex-fill text-limit'>
                        <Link to='' className='d-block h6 font-semibold'>
                          Robert Fox
                        </Link>
                        <span className='d-block text-xs text-muted'>Web Developer</span>
                      </div>
                    </div>
                  </div>
                );
              })
              : masherForChatFilteredArr?.map((el, i) => {
                const masherChatPic = masherForChatFilteredArr?.[i]?.masherId?.userId?.profilePic;

                return (
                  <div key={`chat=${i}`} className='col-xxl-3 col-md-3 col-sm-6 border-end text-center '>
                    <div className='d-flex align-items-center'>
                      <div className='me-3'>
                        <div
                          className={`avatar rounded-circle cursor-pointer mashercircle ${activeMasher === i ? 'activeChat' : 'notActiveChat'} `}>
                          <img
                            onClick={() => {
                              handleGetId(i);
                            }}
                            alt='...'
                            src={assignBucketUrl(masherChatPic)}
                            onError={(event) => {
                              event.target.src = ProfileDefault;
                              event.onerror = null;
                            }}
                          />
                        </div>
                        <small>
                          {el?.masherId?.userId?.firstName?.length > 6
                            ? el?.masherId?.userId?.firstName?.slice(0, 6) + '...'
                            : el?.masherId?.userId?.firstName}
                        </small>
                      </div>
                      <div className='flex-fill text-limit'>
                        <Link to='' className='d-block h6 font-semibold'>
                          Robert Fox
                        </Link>
                        <span className='d-block text-xs text-muted'>Web Developer</span>
                      </div>
                    </div>
                  </div>
                );
              })}

            { }
          </div>
        </div>
      </div>
      <div className='container px-0'>
        <header className='d-flex bg-primary p-3 d-none'>
          <Link to=''>
            <img
              src={assignBucketUrl(UserImage)}
              onError={(event) => {
                event.target.src = ProfileDefault;
                event.onerror = null;
              }}
              alt=''
              className='user_image'
            />
          </Link>
          <div className='ps-3'>
            <small className='d-block text-xs text-secondary'>Admin</small>
            <h4 className='text-white '>Script Writer</h4>
          </div>
        </header>

        <div className='chat-container'>
          <div className='text-center'>
            {isProject?.data?.project?.team?.length >= 1
              ? ''
              :  
              masherForChatFilteredArr?.map((el, i) => {
                let colorForTimer = getBriefInvitationTimeRemaining(el?.validFrom, el?.validTill) === -1 ? 'red' : 'black';
                return timeRem.id === el?.masherId?._id ? (
                  <p
                    key={`inv-${i}`}
                    className='py-1'
                    style={{ color: 'black', borderBottom: '1px solid #0000000d', boxShadow: '0px 15px 10px -15px #00000026' }}>
                    Time remaining :{' '}
                    <span style={{ fontWeight: 'bolder', color: `${colorForTimer}` }}>{formatDiffTimeFromNowHHMMfor48hours(el?.validFrom, el?.validTill)}</span>
                  
                  </p>
                ) : (
                  ''
                );
              })}
          </div>
          <div className='chat-container-inner card-body position-relative scrollable-y'>
            {chat?.length >= 1 && !chat[0]?.msg ? (
              <div className='w-100'>
                {chat?.map((el, i) => {
                  const sender = Boolean(el?.senderId?._id === formik.values.senderId);
                  const senderName = el?.senderId?.firstName;
                  const senderLName = el?.senderId?.lastName;

                  return el.messageType === 'briefAction' ? (
                    <div key={`msg-${i}`} className={`chat-wrapper 111 ${(sender && 'senderWrapper') || ' recieverWrapper'}`} ref={messagesEndRef}>
                      <div className={`time-date ${(sender && 'text-start') || '  '}   mb-1`}>
                        <Moment format='DD MMM, LT' date={el?.createdAt} />
                      </div>
                      <div className={`chat-box ${(sender && 'right-user text-start') || 'left-user'} `}>
                        <div className='card'>
                          <div className={`card-body ${(sender && 'text-start') || ''}  `}>
                            <label className='font-semibold username'>
                              {senderName && capitalizeFirstLetter(senderName)} {' '}{senderLName && capitalizeFirstLetter(senderLName)} (Brief <i>{el?.attachment?.action}</i>)
                            </label>

                            <p className='user_message' style={{ whiteSpace: 'pre-line' }}>
                              <b>Availability :</b> {el?.attachment?.briefInvitationDetails?.availability}
                              <br />
                              <b>Masher Quote :-</b>
                              {el?.attachment?.briefInvitationDetails?.masherQuotedAmount?.map((el, i) => (
                                <p className='ps-2 mb-2 masherQuoteDetails' key={`quote-${i}`}>
                                  <h6>Deliverable Name : </h6>
                                  <span>{el?.deliverableDetails?.name}</span>
                                  <h6>Amount : </h6>
                                  <span>{el?.currency + ' ' + el.amount}</span>
                                </p>
                              ))}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div key={i} className={`chat-wrapper 222 w-100 ${sender ? 'senderWrapper' : 'recieverWrapper'}`} ref={messagesEndRef}>
                      <div className={`time-date ${sender ? 'text-end' : 'text-start'} mb-1`}>
                        <Moment format='DD MMM, LT' date={el?.createdAt} />
                      </div>
                      <div className={`chat-box ${sender ? 'right-user' : 'left-user'} `}>
                        <div className='card'>
                          <div className={`card-body ${sender ? 'text-end' : 'text-start'}  `}>
                            <label className='font-semibold username'>{`${loggedInUser?.data?.[0]?.firstName || ''} ${loggedInUser?.data?.[0]?.lastName || ''}  (You)`}</label>

                            {Boolean(el?.attachment?.data) && <p>{fileCheck(el?.attachment?.data)}</p>}
                            {Boolean(el?.message) && (
                              <p className='user_message' style={{ whiteSpace: 'pre-line' }}>
                                {el?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              chat[0]?.msg
            )}

            {chat?.length <= 0 && <Loader />}
          </div>

          <div className=''>
            <div>
              {attaches2?.loading ? (
                <>
                  <div className='text-end mb-20 me-10'>
                    <Loader />
                  </div>
                </>
              ) : attaches2?.data !== null ? (
                <div className='position-absolute chat-preview'>
                  <p className='text-sm'>Preview</p>
                  {fileCheck(attaches2?.data)}
                </div>
              ) : (
                <div className='position-absolute chat-preview d-none'></div>
              )}
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className='textarea-wrapper-bottom row align-items-center mt-10'>
                <div className='col-md-8 col-8 me-5'>
                  <input name='message' value={formik.values.message} onChange={handleChange} placeholder='Type here...' className='chatInput' />
                </div>
                <div className='col-md-3 col-3 text-end d-flex justify-content-end align-items-center'>
                  <div className='chat-attachment cursor-pointer me-1'>
                    <div className=''>
                      <i className='bi bi-paperclip' onClick={handleClick}></i>
                    </div>
                    <input id='chat_attachment' hidden={true} type='file' name='attachment' onChange={handleAttachment} ref={inputRef} />
                  </div>
                  <button type='submit' className='btn btn_primary_black btn_send mt-0' style={{ maxHeight: '40px' }}>
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
