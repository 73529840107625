import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Avatar from '../../../components/Avatar/Avatar';
import useOpenController from '../../../Hooks/useOpenController';
import ExpandableButton from '../EditBrief/components/ExpandableButton';
import editIcon from '../../../Icons/editIconSimple.svg';
import trashIcon from '../../../Icons/trachIcon.svg';
import plusIconBlack from '../../../Icons/plusIconBlack.svg';
import { addRoleApi, assignMasherToRoleApi, removeMasherFromRoleApi } from '../../../apis/index';
import { Loader, EditProfileLoader } from '../../../components/common/loader';
import SimpleModal from '../../../components/Modals/SimpleModal';
import AssignMasherToRole from '../EditBrief/components/AssignMasherToRole';
import MasherPreference from '../../../components/Brief/MasherPreference';
import RejectedPopover from './RejectedPopover';
import ProfilePreview from '../../MasherProfileView2';

function RoleCard({deliverable, briefId, refreshBrief, searchClient, convertToProject, deliverables}) {
  // const { deliverableName } = deliverable;
  const[currentid,setcurrentid]=useState('');
  const { isOpen, toggle } = useOpenController(false);
  const [opened, setOpened] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addRole, setAddRole] = useState(false);
  const [removeMasher, setRemoveMasher] = useState(false);
  const [removeMasherLoading, setRemoveMasherLoading] = useState(false);
  const [currentMasher, setCurrentMasher] = useState();
  const [currentRole, setCurrentRole] = useState();
  
  const { deliverableId, role} = deliverable;

  const handleAddRole = async () => {
    // setLoading(true);
    setAddRole(true);
    // const res = await assignMasherToRoleApi({ id: briefId, deliverableId});
    
    // if (res?.data?.roleId) {
    //   setLoading(false);
    //   // refreshBrief();
    // }
  };

  const handleRemoveMasher = async (params) => {
    try {
      setRemoveMasherLoading(true);
      const res = await removeMasherFromRoleApi({
        deliverableId,
        id: briefId,
        roleId: params.roleId,
        masherId: params.masherId
      });
      setRemoveMasherLoading(false);
      if (res.status === 200 || 201) {
        toast.success(res?.data?.message, { id: '001' });    
        setRemoveMasher(false);
        refreshBrief();
      } else {

      }
    } catch(e) {

    }
  };

  return (
    deliverable ? deliverable.role?.map((role, j) => {

      // Filter deliverables to find those with at least one matching roleId
      const relatedDeliverables = deliverables.filter(item =>
        item.role?.some(roles => 
          roles.roleId === currentRole
        )
        && item.deliverableId !== deliverable.deliverableId
      );
      return (
        <div className='role-card' key={j}>
          <div className="role-card-header">
            <div className='role-card-left-detail' style={{display: 'flex', alignItems: 'center'}}>
              <ExpandableButton isOpen={opened.findIndex((o) => o === deliverable.role[j].roleId) > -1} toggle={() => {
                toggle();
                setOpened((prev) => {
                  let index = opened.findIndex((o) => o === deliverable.role[j].roleId);
                  if (index < 0) {
                    prev.push(deliverable.role[j].roleId);
                  } else {
                    prev.splice(index, 1);
                  }

                  return prev;
                });
              }} backgroundColor='#FFF'/>
              <div>
                <h5 style={{marginBottom: '5px'}}>{ deliverable.deliverableName }</h5>
                <p>{deliverable.role ? deliverable.role[j]?.roleName : ''}</p>
              </div>
            </div>
            <div className='role-card-masher-avatars'>
              {
                deliverable.role ?
                  deliverable.role[j]?.Masher?.map((masher, index) => {
                    return (
                      <div key={index} className='role-card-avatar-list'>
                        <div className='role-card-avatar-container' style={{zIndex:index, right: `${index * 10}px`}}>
                        <a 
                        data-bs-toggle="modal"
                        data-bs-target="#profilePreview2"
                        id="profilePreview2_open"
                        className='flex items-center'
                        onClick={()=>{setcurrentid(masher.masherId);}}
                      >
                          <Avatar name={masher.masherName} url={masher.masherAvatarUrl} id={masher.masherId} borderColor='white'/>
                      </a>
                        </div>
                      </div>
                    );
                  }) :
                  <></>
              }
            </div>
            {
              convertToProject ?
                '' :
                <div className='role-card-action-container'>
                  {
                    loading ?
                      <Loader /> :
                      deliverable.role?.length > 0 ? <img src={plusIconBlack} onClick={() => {
                        setCurrentRole(deliverable.role[j].roleId);
                        handleAddRole();
                      }} /> : ''
                  }
                </div>
            }
            
          </div>
          {opened.findIndex((o) => o === deliverable.role[j].roleId) > -1 && 
            <div className='role-card-body'>
              <div className='role-card-body-block'>
                {
                  deliverable.role ?
                    deliverable.role[j]?.Masher?.map((masher, i) => {
                      return (
                        <div key={i} className='role-card-masher-info'>
                          <div  className='role-card-masher-empty-column'></div>
                          <div className='role-card-masher-details-column gap-4'>
                            <div className='role-card-masher-details'>
                            <a 
                        data-bs-toggle="modal"
                        data-bs-target="#profilePreview2"
                        id="profilePreview2_open"
                        className='flex items-center'
                        onClick={()=>{setcurrentid(masher.masherId);}}
                      >
                         <Avatar name={masher.masherName} url={masher.masherAvatarUrl} id={masher.masherId}/>
                      </a>
                              
                              <div>
                                <label className='form-label' style={{minWidth: 'fit-content', marginLeft: '10px'}}>{masher.masherName}</label>
                              </div>
                            </div>
                          
                            <div className='role-card-status'>
                              <div className='role-card-badge flex gap-4'>
                                {masher.status === 'declined' && <RejectedPopover reason={masher.masherNotes} />}<p className={masher.status + ''}>{masher.status === 'accepted' ? 'applied' : masher.status}</p>
                              </div>
                            </div>
                            <div className='role-card-preference'>
                              <div className='role-card-badge'>
                                <MasherPreference briefId={briefId} deliverable={deliverable} role={deliverable.role[j]} masher={masher} />
                              </div>
                            </div>
                          </div>
                          <div className='role-card-action-column'>
                            <img src={trashIcon} onClick={() => {
                              setCurrentMasher(deliverable.role[j]?.Masher[i].masherId);
                              setCurrentRole(deliverable.role[j].roleId);
                              setRemoveMasher(true);
                            }}/>
                           
                          </div>
    
                        </div>
                      );
                    })
                    : <></>
                }
              </div>
            </div>
          }      
          {addRole &&  
            <SimpleModal
              title={
                `Add a ${currentRole ? deliverable.role[deliverable.role.findIndex((r) => r.roleId === currentRole)]?.roleName : 'masher'} for ${deliverable?.deliverableName  ? deliverable?.deliverableName : 'this deliverable'}`
              }
              description='Search using any combination of keywords, e.g. “art direction Singapore” or “photography technology Netflix”.'
              closeModal={() => {setAddRole(false);}}
              modalHeight={'85vh'}
              noDivider={true}
              noScroll={true}
            >
              <AssignMasherToRole briefId={briefId} roleId={currentRole ? currentRole : ''} deliverable={deliverable} searchClient={searchClient} related={relatedDeliverables} closeModal={() => {
                setAddRole(false);
                refreshBrief();
              }} />
            </SimpleModal>
          }
          {removeMasher &&  
            <SimpleModal title='Delete Masher' isWarning={true} noScroll={true} closeModal={() => {setRemoveMasher(false);}} >
              <div className='row'>
                <div className='flex flex-col items-center'>
                  
                  <label className='form-label' style={{textAlign: 'center', width: '100%'}}>
                    Are you sure you want to delete this item? This action cannot be undone.
                  </label>
                  <p className='text-sm font-bold text-black w-96 mt-4'>Reason:</p>
                  <textarea className='mb-4 mt-2 px-4 py-2 w-96 border'></textarea>
                </div>
                <div className='brief-button-container'>
                  <button type='button' className='button-close' style={{width: '100%'}} onClick={() => {setRemoveMasher(false);}}>
                    Cancel
                  </button>
                  <button onClick={() => {
                    handleRemoveMasher({
                      masherId: currentMasher,
                      roleId: currentRole
                    });
                  }} className='button-warning ' disabled={removeMasherLoading} style={{width: '100%'}}>
                    {removeMasherLoading ? (
                      <div className='text-xs text-center'>
                        <EditProfileLoader />
                      </div>
                    ) : (
                      'Delete'
                    )}
                  </button>
                </div>
              </div>
            </SimpleModal>
          }
          <ProfilePreview id={currentid}/>
        </div>
      );
    }) : []    
  );
}

export default RoleCard;