import React, {useState, useEffect} from 'react';
import Avatar from '../../../components/Avatar/Avatar';
import useOpenController from '../../../Hooks/useOpenController';
import ExpandableButton from '../EditBrief/components/ExpandableButton';
import { roles } from 'aria-query';


function PricingCard({deliverable, index, updateNewRoleCost}) {
  const { deliverableName } = deliverable;
  const { isOpen, toggle } = useOpenController(false);
  const [opened, setOpened] = useState([]);
  const [roleCost, setRoleCost] = useState([]);

  useEffect(() => {
    setRoleCost(deliverable.role.map((role) => {
      return role.suggestedFeeAllocation;
    }));
  }, []);

  const updateRoleCost = (e, roleIndex) => {
    setRoleCost((roles) => {
      roles[roleIndex] = e.target.value;
      return roles;
    });
    updateNewRoleCost(index, e.target.value || 0, roleIndex);
  };

  const badgeClass = (v) => {
    if(v.toString() === 'pending') return 'pending';
    if(v === 'accepted' || v === 'applied') return 'applied';
    if(v === 'skipped') return 'skipped';
  };
  return (
    deliverable.role.map((role, j) => {
      return (
        <div className='pricing-card'>
          <div className="pricing-card-header">
            <div className='pricing-card-left-detail' style={{display: 'flex', alignItems: 'center'}}>
              <ExpandableButton isOpen={opened.findIndex((o) => o === deliverable.role[j].roleId) > -1} toggle={() => {
                toggle();
                setOpened((prev) => {
                  let index = opened.findIndex((o) => o === deliverable.role[j].roleId);
                  if (index < 0) {
                    prev.push(deliverable.role[j].roleId);
                  } else {
                    prev.splice(index, 1);
                  }

                  return prev;
                });
              }}/>
              <div>
                <h4 style={{marginBottom: '10px'}}>{deliverableName}</h4>
                <p>{deliverable.role[j]?.roleName}</p>
              </div>
            </div>
            {!(opened.findIndex((o) => o === deliverable.role[j].roleId) > -1) && <div className='pricing-card-masher-avatars'>
              {
                role?.Masher?.map((masher, i) => {
                  return (
                    <div key={i} className='pricing-card-avatar-list'>
                      <Avatar id={masher.masherId} name={masher.masherName} url={masher.masherAvatarUrl}/>
                    </div>
                  );
                })
              }
            </div>}
            <div className='pricing-card-role-cost'>
              <input 
                type='number' 
                className='pricing-card-input' 
                value={roleCost[j]}
                onChange={(e) => { updateRoleCost(e, j); }}
              />
              <p>.00</p>
            </div>
          </div>
          {opened.findIndex((o) => o === deliverable.role[j].roleId) > -1 && 
              <div className='pricing-card-body'>
                <div className='pricing-card-body-block'>
                  {
                    deliverable.role[j]?.Masher?.map((masher, i) => {
                      return (
                        <div key={i} className='pricing-card-masher-info'>
                          <div className='pricing-card-masher-details'>
                            <Avatar name={masher?.masherName} url={masher?.masherAvatarUrl} id={masher?.masherId}/>
                            <div className='pricing-card-mash-cost'>
                              <label className='form-label' style={{minWidth: 'fit-content', marginRight: '10px'}}>{masher?.masherName}</label>
                            </div>
                          </div>
                          <div style={{width: '100px'}}>
                            <div className='pricing-card-badge'>
                              <p className={badgeClass(masher.status)}>{masher.status === 'accepted' ? 'applied' : masher.status}</p>
                            </div>
                          </div>
                          <div style={{width: '100px'}}>
                            <div className='pricing-card-badge'><p>{masher.preference}</p></div>
                          </div>
                          <p className='pricing-card-masher-price' style={{fontSize: 'small'}}>{masher?.masherQuote}</p>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
          }
        </div>
      );
    })
  );
}

export default PricingCard;