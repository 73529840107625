import React, { useEffect, useState, useCallback } from 'react';
import { WeavyClient, WeavyProvider, Chat, MessengerProvider, ConversationList, Conversation } from '@weavy/uikit-react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import '@weavy/uikit-react/dist/css/weavy.css';
import { weavyTokenApi } from '../../../../apis';
import Avatar from '../../../../components/Avatar/Avatar';

const accessToken = process.env.REACT_APP_WEAVY_API_KEY;
const tsKey = process.env.REACT_APP_TYPESENSE_API_KEY;
const tsHost = process.env.REACT_APP_HOST_NAME;
const weavyUrl = process.env.REACT_APP_WEAVY_URL;

const MasherChat = ({loggeduser , mashers, briefContributors, projectName }) => {
  const [weavyClient, setWeavyClient] = useState(null);
  const [chat, setChat] = useState();
  const [appUIDs, setAppUIDs] = useState([]);
  const params = useParams();
  const roomId = params.projectId;
  //Room for project group chat
  const chatId = `$${roomId}-project`;
  const username = loggeduser?.data[0]?._id;
  const contributors = briefContributors.filter((m) => m.id !== username)
  const getLastMessage = async (appId) => {
    const url = `${weavyUrl}/api/apps/${appId}/messages?orderby=createdat+desc&skip=0&top=1`; // Replace with the actual API endpoint
    const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.get(url, { headers });
      return {message: response.data?.data?.[0]?.text ?? 'No message yet.', timestamp: response.data?.data?.[0]?.created_at ?? undefined, unread: false}; // Assuming this is the format of the notifications
    } catch (error) {
      console.error('Error fetching notifications for app', appId, error);
      return [];
    }
  };
    
  const initChat = (masher) => {
    const chaturl = weavyUrl + '/api/apps/init';
    const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
    const ids = [masher?.id, username].sort((a, b) => {
      if (a < b) return 1;
      if (a > b) return -1;
      return 0;
    });
    const chatdata = {
      app: {
        uid: `${ids[0]}_${ids[1]}_${roomId}`,
        name: masher?.name,
        type: 'Chat',
      },
      user: {
        uid: username
      },
    };

    return axios.post(chaturl, chatdata, { headers }).then(async (response) => { 
      const chaturl = weavyUrl + `/api/apps/${response.data.id}/subscribe`;
        
      //await axios.post(chaturl, {}, {headers});
      return {
        chatData: response.data,
        avatar: masher?.avatar // Include the masher's avatar
      };
    }
    );
  };

  const getWeavyToken = useCallback(async (username) =>{
    try {
      //alert("Inside getWeavyToken",userfname);
      const values = {firstname: username};
      const res = await weavyTokenApi(values);
      if (res.status === 200 || 201) {
        // alert(res);
        return res.data;
      }
    } catch (err) {
      //alert(err);
    }
  }, []);
    
  const setToken = async (username) => {
    if (username) {
      const usertoken = await getWeavyToken(username);
      let client = new WeavyClient({ 
        url: weavyUrl, 
        tokenFactory: async () => usertoken
      });
      await weavySetup(chatId);
      setWeavyClient(client);
    }
        
  };
  const handleMessage = (message) => {
    const { app_id, text, created_at } = message.message;
    setAppUIDs(prevAppUIDs => {
      return prevAppUIDs.map(app => {
        if (app.id === app_id) {
          return { ...app, lastMessage: {message: text, timestamp: created_at, unread: true} };
        }
        return app;
      });
    });
  };
  const handleMarked = (marked) => {
    const { id } = marked.conversation;
    setAppUIDs(prevAppUIDs => {
      return prevAppUIDs.map(app => {
        if (app.id === id) {
          return {
            ...app,
            lastMessage:
                { ...app.lastMessage, unread: false }
          };
        }
        return app;
      });
    });
  };
  useEffect(() => {
    if (weavyClient) {
      weavyClient?.subscribe(null, 'message_created', handleMessage);
      weavyClient?.subscribe(null, 'conversation_marked', handleMarked);
    }
    return () => {
      if (weavyClient) {
        weavyClient?.unsubscribe(null, 'message_created', handleMessage);
        weavyClient?.unsubscribe(null, 'conversation_marked', handleMarked);
      }
    };
  }, [weavyClient]);
    
  useEffect(() => {
    if (username) {
      setToken(username); 
    }
  }, [username]);
  const weavySetup = async (chatId) => {
    const userurl = weavyUrl+'/api/users/'+username;
    const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
    const data = {
      uid: username,
      name: `${loggeduser?.data[0]?.firstName} ${loggeduser?.data[0]?.lastName}`,
      directory: `${chatId}_directory`
    };
      
    let HQCollab = undefined;
      
    await axios.put(userurl, data, { headers })
      .then(async (response) => {
        // console.log('Success:', response.data.name);
        const chaturl = weavyUrl+'/api/apps/init';
  
        const headers = {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        };
        const chatdata = {
          app: {
            uid: chatId,
            name: `${projectName} Group Chat`,
            type: 'Chat',
          },
          user: {
            uid: username
          },
        };
        
        await axios.post(chaturl, chatdata, { headers })
          .then(async (response) => {
            const lastMessage = await getLastMessage(response.data.id);
            HQCollab = {...response.data, avatar: <Avatar name={'H Q'} borderColor='white'/>, lastMessage: lastMessage};
          })
          .catch(error => {
            console.error('Error:', error);
          });
      })
      .catch(error => {
        console.error('Error:', error);
      });
    const promises = [...mashers, ...contributors].map(async masher => {
      const chatResponse = await initChat(masher);
      const lastMessage = await getLastMessage(chatResponse.chatData.id);
      return {
        ...chatResponse.chatData,
        name: masher.name,
        avatar: chatResponse.avatar,
        lastMessage: lastMessage
      };
    });
    const responses = await Promise.all(promises);
    setAppUIDs([HQCollab, ...responses]);
  };
  return <><div className="App w-full h-full text-wrap">
    {<WeavyProvider client={weavyClient}>
      <MessengerProvider>
        
        {!chat && <div className='h-full flex flex-col'>
          <div className='w-full h-fit bg-[#334054] text-white font-bold text-right px-4 py-4'>Chat</div>
          <div className='flex flex-col w-full gap-2 overflow-auto h-full py-2'>{appUIDs.map(app =>
            <button key={app.uid} className='w-full py-2 group hover:bg-[#334054]/50 hover:text-white rounded-sm flex flex-start px-2 gap-2' onClick={() => setChat(app)}>
              <div className='w-10 relative'>
                {app.avatar}
                {app.lastMessage.unread && <div className='absolute right-0.5 top-0.5 h-2 w-2 bg-red-600 rounded-full flex text-white items-center justify-center text-sm' />}
              </div>
              <div className='flex flex-col justify-start text-start w-full truncate text-ellipsis'>
                <div className='text-black font-bold group-hover:!text-white'>{app.name}</div>
                <div className='flex justify-between gap-2'><div className='text-xs pr-4 truncate w-full'>{app.lastMessage.message}</div>
                  {app.lastMessage.timestamp && <div className='w-fit text-xs text-right mr-1'>{moment(app.lastMessage.timestamp).calendar() }</div>}
                </div>
              </div>
           
            </button>)}
          </div>
        </div>}
        {chat && <div className='w-full max-h-full h-full flex flex-col'>
          <div className='p-4 h-fit flex bg-[#334054] text-white justify-between items-center'>
            <button className='color-white' onClick={() => setChat(null)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9571 3.29289C12.3176 3.65338 12.3453 4.22061 12.0403 4.6129L11.9571 4.70711L5.66475 10.9999L20.25 11C20.8023 11 21.25 11.4477 21.25 12C21.25 12.5128 20.864 12.9355 20.3666 12.9933L20.25 13L5.66437 12.9998L11.9571 19.2929C12.3176 19.6534 12.3453 20.2206 12.0403 20.6129L11.9571 20.7071C11.5966 21.0676 11.0294 21.0953 10.6371 20.7903L10.5429 20.7071L2.54289 12.7071C2.18241 12.3466 2.15468 11.7794 2.4597 11.3871L2.54289 11.2929L10.5429 3.29289C10.9334 2.90237 11.5666 2.90237 11.9571 3.29289Z" fill="white"/>
              </svg>
            </button>
            <div>{chat.name}</div>
          </div>
          <div className='flex flex-col w-full gap-2 overflow-auto h-full'>
            <Chat features={{ cloudFiles: false, attachments: false, mentions: true, receipts: true, typing: true }} uid={chat.uid} />
          </div>
          
        </div>}
      </MessengerProvider>
    </WeavyProvider>}
  </div></>;
};

export default MasherChat;