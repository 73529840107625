import React, {useState} from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import expandView from '../Icons/expandView.svg';
import minimiseView from '../Icons/minimiseView.svg';

export default function ProfilePreview({ id }) {
  const { REACT_APP_PROFILEPREVIEWIFRAME } = process.env;
  const url = `${REACT_APP_PROFILEPREVIEWIFRAME}/${id}/viewonly`;
  const [fullscreenView, setFullscreenView] = useState(false);

  const handleViewSize = () => {
    setFullscreenView(!fullscreenView);
  };
  return (
    <>
      <div
        className="modal"
        id="profilePreview2"
        tabIndex={-1}
        aria-labelledby="profilePreview2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" style={{maxWidth: `${fullscreenView ? '90vw' : '500px'}` }}>
          <div className="modal-content shadow-3">
            <div className="modal-body w-full">
              {/* <div>{id}</div> */}
              <div style={{display: 'flex', alignItems: 'flex-start'}}>
                <div onClick={handleViewSize}><img src={!fullscreenView ? expandView : minimiseView}/></div>
                <div className="ms-auto btn-close cursor-pointer mb-5" data-bs-dismiss="modal" aria-label="Close"></div>
              </div>
              <iframe
                title="Embedded Content"
                className='w-full h-full-vh'
                style={{height:'80vh'}}
                src={url}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}