// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-header {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px 64px;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
} 
.page-header-right {
  font-family: 'Bricolage Grotesque', sans-serif;
  display: inline-flex;
  width:80%
}
.page-header-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.page-header-avatar-container{
  display: block;
  margin-right: 20px;
}
.page-header-avatar {
  border-radius: 50%;
  border: 4px solid var(--mash-white, #FFF);
  background: var(--Not-Quite-White, #EEE9E0);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  height: 96px;
  width: 96px;
}`, "",{"version":3,"sources":["webpack://./src/components/pageElements/pageHeader/pageHeader.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,SAAS;AACX;AACA;EACE,8CAA8C;EAC9C,oBAAoB;EACpB;AACF;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,yCAAyC;EACzC,2CAA2C;EAC3C,8FAA8F;EAC9F,YAAY;EACZ,WAAW;AACb","sourcesContent":[".page-header {\n  width: 100%;\n  background-color: #fff;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding: 32px 64px;\n  align-items: center;\n  flex-wrap: wrap;\n  gap: 16px;\n} \n.page-header-right {\n  font-family: 'Bricolage Grotesque', sans-serif;\n  display: inline-flex;\n  width:80%\n}\n.page-header-left {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n.page-header-avatar-container{\n  display: block;\n  margin-right: 20px;\n}\n.page-header-avatar {\n  border-radius: 50%;\n  border: 4px solid var(--mash-white, #FFF);\n  background: var(--Not-Quite-White, #EEE9E0);\n  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);\n  height: 96px;\n  width: 96px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
