import React from 'react';
import { Link } from 'react-router-dom';
import './simpleButton.css';
import { EditProfileLoader } from '../../loader';

function SimpleButton({title, icon, altText, link, buttonType, buttonFunction, href, onClickEvent, toggle,loaderstate,setloaderstate}) {

  const getClassName = () => {
    const classNameVariant = {
      primary: 'primary',
      primaryUnfilled: 'primary-unfilled',
      secondary: 'secondary',
      secondaryUnfilled: 'secondary-unfilled'
    };

    return classNameVariant[buttonType];
  };

  return (
    <>
      { buttonFunction == 'link' &&
        <Link to={link || ''} onClick={onClickEvent} className={`simple-button !ml-0 md:!ml-5 ${getClassName()}`}>
          {icon && <img src={icon} alt={altText} className='simple-button-icon'/>}
          {title}
        </Link>
      }
      { buttonFunction == 'openModal' &&
          <button className={`simple-button !ml-0 md:!ml-5 ${getClassName()}`} href={href}  data-bs-toggle={toggle} onClick={onClickEvent}>
            {icon && <img src={icon} alt={altText} className='simple-button-icon'/>}
            {title}
          </button>
      }
      { buttonFunction=='withloader' &&

          <button className={`simple-button !ml-0 md:!ml-5 ${getClassName()}`} href={href} disabled={ loaderstate ? true :false}  data-bs-toggle={toggle} onClick={onClickEvent}>
            {
              loaderstate ? (
               <EditProfileLoader />
              ):(
                <>
                {icon && <img src={icon} alt={altText} className='simple-button-icon'/>}
                {title}
               </>
              )
            }
           
          </button>
      }
      { !buttonFunction &&
          <button className={`simple-button !ml-0 md:!ml-5 ${getClassName()}`} href={href}  data-bs-toggle={toggle} onClick={onClickEvent}>
            {icon && <img src={icon} alt={altText} className='simple-button-icon'/>}
            {title}
          </button>
      }

    </>

  );
}

export default SimpleButton;