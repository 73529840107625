// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload {
    border: 1px solid #00A46F;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    gap: 8px;
    width: 100%;
  }
  .download-upload {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .success-upload {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: #00A46F;
    margin-left: auto;
  }
  .file-preview {
    height: 32px;
    width: 32px;
    border-radius: 28px;
    border: 4px solid #F2F4F7;
    background: #F9FAFB;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .file-name-container-pw {
    width: 80%;
  }
  
  .file-name-pw {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #344054;
  }
  .file-size-pw {
    font-size: 14px;
    color: #667085;
  }
  .progress-bar-div-pw {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .progress-bar-pw {
    width: 91%;
    height: 5px;
}
  .progress-bar {
    background-color: #00A46F;
   height: 12px !important;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Briefs/EditBrief/components/Filecomponent.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,QAAQ;IACR,WAAW;EACb;EACA;IACE,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;EACrB;EACA;IACE,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;EACnB;EACA;IACE,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;EACA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,cAAc;EAChB;EACA;IACE,eAAe;IACf,cAAc;EAChB;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,UAAU;IACV,WAAW;AACf;EACE;IACE,yBAAyB;GAC1B,uBAAuB;EACxB","sourcesContent":[".file-upload {\n    border: 1px solid #00A46F;\n    border-radius: 8px;\n    padding: 8px;\n    display: flex;\n    gap: 8px;\n    width: 100%;\n  }\n  .download-upload {\n    margin-left: auto;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .success-upload {\n    height: 16px;\n    width: 16px;\n    border-radius: 8px;\n    background: #00A46F;\n    margin-left: auto;\n  }\n  .file-preview {\n    height: 32px;\n    width: 32px;\n    border-radius: 28px;\n    border: 4px solid #F2F4F7;\n    background: #F9FAFB;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  .file-name-container-pw {\n    width: 80%;\n  }\n  \n  .file-name-pw {\n    width: 100%;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    font-size: 14px;\n    color: #344054;\n  }\n  .file-size-pw {\n    font-size: 14px;\n    color: #667085;\n  }\n  .progress-bar-div-pw {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n  }\n  \n  .progress-bar-pw {\n    width: 91%;\n    height: 5px;\n}\n  .progress-bar {\n    background-color: #00A46F;\n   height: 12px !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
