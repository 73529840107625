import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FiletypeAi, FiletypePdf, ThreeDotsVertical } from 'react-bootstrap-icons';
import './dropdown.css'

function DotMenu({pdftoggle,duplicatetoggle}) {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };
 
  
  return (
    <Dropdown show={showDropdown} onToggle={handleDropdownToggle}>
      <Dropdown.Toggle variant="transparent" id="dropdown-basic" className="dropdown-toggle-no-caret btn-custom">
      <div className='hover mt-2'>
                 <ThreeDotsVertical size={24} />
                 </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        
        <div className="menu-row" onClick={() => {
                    duplicatetoggle();
                    }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                     <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                  </svg>
                    Duplicate
                  </div>
                  <div className="menu-row"  onClick={() => {
                    pdftoggle()
                    }}>
                        
                     <FiletypePdf size={17} />
                     PDF
                  </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DotMenu;
