import React, {useState, useEffect} from 'react';
import './tableList.css';
import tickGreen from '../../Icons/tickGreen.svg';
import { useInfiniteHits } from "react-instantsearch"

function TableListCompanies({columnWidth, headers}) {
  const { hits, showMore } = useInfiniteHits()

  return (
    <>
    <div className='w-full overflow-auto'>
      <table className="table table-hover table-nowrap">
        <thead className="text-white !bg-[#101828]">
          <tr>
            {headers.map(header => <th scope="col">{header.title}</th>)}
          </tr>
        </thead>
        <tbody className='bg-white'>
          {hits.map(hit => <tr>
            <td className='text-left'>
            {hit.properties_name}
            </td>
            <td className='text-left'>
            {hit.properties_domain}
            </td>
            <td className='text-left'>
            {hit.properties_industry.split(', ').map(industry => {return industry && <span className='table-list-purple-badge'>{industry}</span>;})}
            </td>
            <td className='text-left'>
            <span className='table-list-green-badge'>{hit.properties_country}</span>
            </td>
            <td className='text-left flex justify-center items-center'>
            <img src={tickGreen}/>
            </td>
          </tr>)}
        </tbody>
      </table>
    </div>
    <button onClick={showMore} className='!w-full'>Show more results</button>
  </>

    // <div>
    //   <div className='table-list-body'>
    //     <div className='table-list-body-item'>
    //       <div style={{width: columnWidth}} className='table-list-font-bold'>{hit.properties_name}</div>
    //       <div style={{width: columnWidth}} className='table-list-font-light'>{hit.properties_domain}</div>
    //       <div style={{width: columnWidth}} className='table-list-font-bold table-list-flex'>
    //         {industries?.map(industry => {return industry && <span className='table-list-purple-badge'>{industry}</span>;})}
    //       </div>
    //       <div style={{width: columnWidth}}><span className='table-list-green-badge'>{hit.properties_country}</span></div>
    //       <div style={{width: columnWidth, display: 'flex', justifyContent: 'center'}}><img src={tickGreen}/></div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default TableListCompanies;