import React from 'react';
import './tableList.css';
import Moment from 'react-moment';
import DateRange from '../Brief/DateRange';

function TableListDeliverables({deliverables, briefId, columnWidth}) {

  return (
    <div>
      <div className='table-list-body'>

        {deliverables?.map((deliverable, i) => {
          return  (
            <div className='table-list-body-item-deliverable' key={i}>
              <div className='table-list-font-bold'>{deliverable.deliverableName || 'New Deliverable'}</div>
              <div className='table-list-font-bold' style={{ width: '300px'}}><DateRange briefId={briefId} deliverable={deliverable} /></div>
            </div>);
        })}

      </div>
    </div>
  );
}

export default TableListDeliverables;