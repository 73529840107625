import React from 'react';
import PlusIconCircle from '../../Icons/plusIconCircleBlack.svg';
import EllipsisIcon from '../../Icons/elipsesIcon.svg';

function AddDeliverableDivider({loading, addDeliverable}) {
  return (
    <div style={{display: 'flex', alignItems: 'center', marginBottom: '40px'}}>
      <div style={{borderTop: '1px solid #525f7f', width: '100%'}}></div>
      {loading 
        ? <img style={{border: '2px solid #525f7f', borderRadius: '50%'}} src={EllipsisIcon} alt="add" />
        : <img src={PlusIconCircle} alt="add" onClick={() => {addDeliverable();}}/>  
      }
      <div style={{borderTop: '1px solid #525f7f', width: '100%'}}></div>
    </div>
  );
}

export default AddDeliverableDivider;