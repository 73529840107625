import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { toast } from 'react-hot-toast';
import { loginApi, verifyOtp } from '../../apis';
import { AppContext } from '../../appContext';
import { EditProfileLoader } from '../../components/common/loader';
import LogoImage from '../../Assets/img/logos/mash_yellow.png';
import Cover from '../../Assets/img/covers/loginBack.jpg';
import { assignBucketUrl } from '../../utils/helperFunctions';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  gtmId: 'GTM-5QDD4W7P'
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize('G-DFX5W72KDE');
export default function Otp() {
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [repeatTimer, setRepeatTimer] = useState('');
  const [otp, setOTP] = useState('');
  const [timer, setTimer] = useState('00:00');

  const navigate = useNavigate();
  const location = useLocation();
  const appContext = useContext(AppContext);
  const { setIsLoggedIn } = appContext;
  const loginEmail = location.state.email;
  const keepLoggedIn = location.state.keepLoggedIn;
  const Ref = useRef(null);

  const onVerifyOtp = async (values) => {
    values.preventDefault();
    try {
      setLoader(true);
      if (otp.length < 6) {
        setLoader(false);
        setIsWrongOtp(true);
        setErrorMsg('Please enter 6 digits otp');
      } else {
        values.OTP = { otp: otp, email: loginEmail, keepLoggedIn: keepLoggedIn };
        const response = await verifyOtp(values.OTP);
        const data = response.data;
        if (response.status === 200) {
          localStorage.setItem('token', data.token);
          setIsLoggedIn(true);
          navigate('/');
          toast.success(data.message);
        } else {
          setIsWrongOtp(true);
          setErrorMsg('Wrong OTP');
        }
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.message, { id: '001' });
    }
  };

  const resendOtp = async (values) => {
    try {
      values = { email: loginEmail };
      const res = await loginApi(values);
      if (res.status === 200) {
        setRepeatTimer(values);
        toast.success(res.data.message, { id: 'resendSuccess' });
      }
    } catch (error) {
      const message = error.response.data.message || error.response.statusText;
      toast.error(message, { id: 'error' });
    }
  };

  const handleOtpInput = (otp) => {
    setIsWrongOtp(false);
    setErrorMsg('');
    setOTP(otp);
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return { total, minutes, seconds };
  };
  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer((minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds));
    }
  };

  const clearTimer = (e) => {
    setTimer('00:59');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 59);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
    setInterval(() => {
      setShow(false);
    }, 60000);
    setTimeout(() => {
      setShow(true);
    }, 0); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeatTimer]);
  
  return (
    <>
      <div className='min-h-screen bg-surface-secondary d-flex flex-column justify-content-center'>
        <div className='row mx-0'>
          <div className='col-lg-3 p-6 h-screen overflow-y-hidden bg-black'>
            <img
              alt=''
              src={assignBucketUrl(LogoImage)}
              onError={(event) => {
                event.target.src = ProfileDefault;
                event.onerror = null;
              }}
              width='150'
            />
            <div className='mt-10'>
              <h1 className='ls-tight display-5 text-white mb-5 font-bolder' style={{ fontWeight: '500' }}>
                Welcome Back{' '}
              </h1>
              <p className='text-white'>The weather in Tulum right now is {'24'}°C🌤️. Food for thought. </p>
              <div className='col-lg-12 px-0 mt-8'>
                <form onSubmit={onVerifyOtp} className="sign-in-up">
                  <h2 className='font-bolder h-4 text-white mb-8'>Login</h2>
                  <div className='mb-5'>
                    <label className='form-label text-white' htmlFor='email'>
                      Email address
                    </label>
                    <input type='text' className='form-control transparent-input-border' id='email' placeholder='Your email address' value={loginEmail} disabled={true} />
                  </div>
                  <div className='text-center mt-2 otpBox '>
                    <OtpInput
                      inputStyle={{
                        marginLeft: '0',
                        width: '2.375rem',
                        height: '2.375rem',
                        borderRadius: '0.313rem',
                        textAlign: 'center',
                        border: '1px solid rgba(0,0,0,0.3)',
                      }}
                      className={'me-2 text-center fs-3  w-50 otpInputt  '}
                      errorStyle={{ border: 'solid 1px red' }}
                      hasErrored={isWrongOtp}
                      isInputNum
                      value={otp}
                      onChange={(otp) => handleOtpInput(otp)}
                      numInputs={6}
                      separator={<span> </span>}
                    />
                    <div className='text-danger'>{errorMsg}</div>
                  </div>
                  <div className='mb-5'>
                    <div className='resend-again'>
                      {show ? (
                        <>
                          <div className='resendBtnhide d-inline text-white'>Resend Again</div>
                          <span className='text-white'> ({timer})</span>
                        </>
                      ) : (
                        <>
                          <div className='resendBtnShow d-inline text-white cursor-pointer ' onClick={() => resendOtp()}>
                            Resend OTP
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <button className='btn bg-primary text-dark w-full noYellowOnHover' type='submit'>
                      {!loader ? (
                        'Submit'
                      ) : (
                        <div className='text-xs'>
                          <EditProfileLoader />
                        </div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className='col-lg-9 border-left-lg min-h-screen position-relative'
            style={{
              backgroundImage: `url(${assignBucketUrl(Cover)})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'Cover',
              backgroundPositionY: 'center',
            }}>
            <div className='py-lg-16'>
              <div className='row'>
                <div className='col-lg-12 top-div px-lg-10 text-end'>
                  <span className='ls-tight mb-3'>
                    Illustration by
                    <br />
                    Masher Ben Thomson
                  </span>
                </div>
                <div className='col-lg-12 bottom-div px-lg-10'>
                  <div className='text-center mb-6 '>
                    <h2 className='ls-tight display-4 mb-3 text-end d-none d-md-block font-bolder' style={{ lineHeight: '1' }}>
                      Our office <br />
                      is only 148 billion <br /> square metres.
                    </h2>
                    <h2 className='ls-tight display-4 mb-3 text-end d-block d-md-none'>Our office is only 148 billion square metres.</h2>
                    <p className='text-end  text-white d-none d-md-block' style={{ lineHeight: '1.3' }}>
                      You're basically standing at our reception <br /> only this time you're in your ugg boots.
                    </p>
                    <p className='text-end  text-white d-block d-md-none'>
                      You're basically standing at our reception only this time you're in your ugg boots.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
