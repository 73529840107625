import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { createBriefApi, fetchCategoryApi, fetchClientApi, fetchEngagementTypeApi, fetchIndustryApi, fetchRegionApi, getMasherShareApi } from '../../../apis';
import AdminLayout from '../../../components/admin-layout/index.js';
import { briefValidation, briefValidation2, briefValidation3 } from '../../../components/Validation';
import { allClientData, getClient } from '../../../utils/helperFunctions';
import CreateBriefsForm1 from './CreateBriefsForm1';
import CreateBriefsForm2 from './CreateBriefsForm2';
import CreateBriefsForm3 from './CreateBriefsForm3';
import FormPreview from './FormPreview.js';
import { BriefFormContext } from '../../../appContext';

const CreateBrief = () => {
  const [step, setStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [client, setClient] = useState({ data: null, loading: true });
  const [formSelects, setformSelects] = useState({ region: '', industry: '', engagementType: '', category: '', client: '' });
  const [select, setSelect] = useState({ industry: null, region: null, client: null, category: null, engage: null });

  const [masherData, setMasherData] = useState({ data: null, loading: false });
  const searchFilter = { search: '', page: 1, limit: '' };
  const currDate = moment().format('YYYY-MM-DD');
  const navgate = useNavigate();
  const location = useLocation();

  const formik1 = useFormik({
    initialValues: {
      clientName: '',
      briefName: '',
      logo: '',
      industry: '',
      region: '',
      clientType: '',
      category: '',
      engagementStructure: '',
    },
    validationSchema: briefValidation,
    onSubmit: (values) => {
      setStep(1);
    },
  });
  const formik2 = useFormik({
    initialValues: {
      inBrief: '',
      background: '',
      projectAmbition: '',
      experience: '',
      brand: '',
      audience: '',
      kpis: '',
    },
    validationSchema: briefValidation2,
    onSubmit: (values) => {
      setStep(2);
    },
  });
  const formik3 = useFormik({
    initialValues: {
      deliverableDetails: [
        { id: uuidv4(), name: '', startDate: currDate, dueDate: currDate, masherRole: '', delieveryFormat: '', order: '', description: '' },
      ],
      websiteLink: [{ name: '', link: '' }],
      currency: '',
      budget: '',
      attachments: [{ name: '', file: '' }],
      fileName: '',
    },
    validationSchema: briefValidation3,
    onSubmit: async (values) => {
      setStep(3);
    },
  });

  const formikPreview = async (values) => {
    values.preventDefault();
    try {
      let allData = { ...formik1.values, ...formik2.values, ...formik3.values };

      let { clientName } = allData;
      const { value: clientId, label } = clientName[0];
      allData.clientId = clientId;
      allData.clientName = label;
      setLoader(true);
      const briefRes = await createBriefApi(allData);
      if (briefRes.status === 200 || briefRes.status === 201) {
        toast.success(briefRes.data.message, { id: '001' });
        setLoader(false);
        navgate('/');
      }
    } catch (error) {
      setLoader(false);
      toast.error('Something went wrong', { id: '001' });
    }
  };

  const fetchSelectRegion = async () => {
    const selectResponse = {
      regionRes: await fetchRegionApi(),
      industryRes: await fetchIndustryApi(),
      engagementRes: await fetchEngagementTypeApi(),
      cateRes: await fetchCategoryApi(),
      clientRes: await fetchClientApi(),
    };

    setformSelects({
      region: selectResponse?.regionRes.data,
      industry: selectResponse?.industryRes.data,
      engagementType: selectResponse?.engagementRes.data,
      category: selectResponse?.cateRes.data,
      client: selectResponse?.clientRes.data,
    });
  };

  
  const allMasherData = async (masherPayload) => {
    setMasherData({ loading: true });
    try {
      const res = await getMasherShareApi(masherPayload);
      if (res.status === 200) {
        setMasherData({ data: res.data.data, loading: false });
      }
    } catch (error) {
      setMasherData({ loading: false });
    }
  };

  useEffect(() => {
    fetchSelectRegion();
    allClientData({ ...searchFilter }, setClient);
    allMasherData('approved');
    if (location?.state) {
      getClient(location?.state, formik1);
      formik1.setFieldValue('clientName', [{ value: location?.state?.value, label: location?.state?.label }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let providerValues = {
    formSelects,
    client,
    getClient,
    formik1,
    formik2,
    formik3,
    setSelect,
    setStep,
    loader,
    formikPreview,
    select,
    masherData,
  };
  return (
    <>
      <BriefFormContext.Provider value={providerValues}>
        <AdminLayout>
          <div className='container create-brief'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb' style={{ borderBottom: 'none', marginBottom: '0px' }}>
                <li className='breadcrumb-item'>
                  <Link to='/' className='blackOnHover'>
                    Home
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link to='/briefs' className='blackOnHover'>
                    Briefs
                  </Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Create new brief
                </li>
              </ol>
            </nav>


            {step === 0 && (
              <>
                <div className='text-capitalize font-bold mb-4 pb-4 breadcrumb ' style={{ borderBottom: 'solid 1px #dfdfdf' }}>
                  form 1
                </div>
                <CreateBriefsForm1 />
              </>
            )}
            {step === 1 && (
              <>
                <div className='text-capitalize font-bold mb-4 pb-4 breadcrumb ' style={{ borderBottom: 'solid 1px #dfdfdf' }}>
                  form 2
                </div>
                <CreateBriefsForm2 />
              </>
            )}
            {step === 2 && (
              <>
                <div className='text-capitalize font-bold mb-4 pb-4 breadcrumb ' style={{ borderBottom: 'solid 1px #dfdfdf' }}>
                  form 3
                </div>
                <CreateBriefsForm3 />
              </>
            )}
            {step === 3 && (
              <>
                <div className='text-capitalize font-bold mb-4 pb-4 breadcrumb ' style={{ borderBottom: 'solid 1px #dfdfdf' }}>
                  form Preview
                </div>
                <FormPreview />
              </>
            )}
          </div>
        </AdminLayout>
      </BriefFormContext.Provider>
    </>
  );
};

export default CreateBrief;
