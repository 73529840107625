import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../appContext';
import userIcon from '../../Icons/userIcon.svg';
import mashIcon from '../../Icons/mashLogoSidebarIcon.svg';
import briefIcon from '../../Icons/briefCaseIcon.svg';
import masherIcon from '../../Icons/mashersPeopleIcon.svg';
import projectsIcon from '../../Icons/projectsIcon.svg';
import customersIcon from '../../Icons/customersIcon.svg';

import cogIcon from '../../Icons/settingsCogIcon.svg';
import editIcon from '../../Icons/editIcon.svg';
import logoutIcon from '../../Icons/logoutIcon.svg';
import infoManual from '../../Icons/infoManual.svg';
import { assignBucketUrl } from '../../utils/helperFunctions';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import './sidebar.css';
import ClickUpHelpButton from '../ClickUpHelpButton';

export default function Sidebar() {

  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { state, logout } = appContext;
  const [isExpanded, setExpanded] = useState(false);
  const { loggedInUser, isLoggedIn } = state;
  const handleLogout = () => {
    logout(navigate);
  };
  const [profileDrawerOpen, setProfileDrawerOpen] = useState(false);
  return (
    <> 
      <section className="sidebar-container !hidden md:!flex"> 
        <div className='sidebar-top-section'>
          <ul className='sidebar-ul'>
            <li className='sidebar-li'>
              <Link to='/'>
                <img src={assignBucketUrl(mashIcon)} alt='avatar'/>
              </Link>
            </li>  
            <li className='sidebar-li'>
              <Link to='/briefs'>
                <img src={briefIcon} alt="briefs button"/>
              </Link>
            </li>
            <li className='sidebar-li'>
              <Link to='/projects'>
                <img src={projectsIcon} alt="projects button"/>
              </Link>
            </li>
            <li className='sidebar-li'>
              <Link to='/mashers'>
                <img src={masherIcon} alt="mashers button"/>
              </Link>
            </li>
            <li className='sidebar-li'>
              <Link to='/brands'>
                <img src={customersIcon} alt="customers button"/>
              </Link>
            </li>
          </ul>
        </div>

        <div className='sidebar-bottom-section'> 
          <ul className='sidebar-ul'>
            <li className='sidebar-li'>
              <div>
                <img
                  alt='user icon'
                  src={userIcon}
                  onClick={(() => setProfileDrawerOpen(!profileDrawerOpen))}
                  onError={(event) => {
                    event.target.src = ProfileDefault;
                    event.onerror = null;
                  }}
                />
              </div>
            </li>
            {/* 'Settings' link is for masher settings for now */}
            <li className='sidebar-li'>
              <Link to='https://docs.thisismash.com/shelves/hq-product-walkthrough' target="_blank">
                <img src={infoManual} alt="User manual button"/>
              </Link>
            </li>
            <li className='sidebar-li'>
              <ClickUpHelpButton url="https://forms.clickup.com/9003215177/f/8ca4aa9-1242/9S2W1EHK23102MTQ6V" open={false} />
            </li>
          </ul>
        </div>

        {/* profile drawer */}
        {
          profileDrawerOpen &&
        <div className='sidebar-profile-drawer !fixed !bottom-16 !top-auto'>
          <ul className='sidebar-profile-drawer-ul'>
            <li className='sidebar-profile-drawer-li'>
              <div className='sidebar-profile-drawer-icon'>
                <img
                  alt='...'
                  src={assignBucketUrl(loggedInUser?.data?.[0]?.profilePic)}
                  onError={(event) => {
                    event.target.src = ProfileDefault;
                    event.onerror = null;
                  }}
                  id='sidebar-profile-drawer-avatar'
                />
              </div>
              <p className='sidebar-profile-drawer-title'>{`${loggedInUser?.data[0]?.firstName} ${loggedInUser?.data[0]?.lastName}`}</p>
            </li>
            <li className='sidebar-profile-drawer-li'>
              <img id='sidebar-profile-drawer-icon' src={editIcon} alt="edit profile button" className='sidebar-profile-drawer-icon'/>
              <Link to='/edit-profile' className='sidebar-profile-drawer-title '>
                <p id='sidebar-profile-drawer-link'>Edit Profile</p>
              </Link>             
            </li>
            <li className='sidebar-profile-drawer-li'>
              <img id='sidebar-profile-drawer-icon' src={logoutIcon} alt="edit profile button" className='sidebar-profile-drawer-icon'/>
              <p className='sidebar-profile-drawer-title' id='sidebar-profile-drawer-link' onClick={handleLogout}>Sign out</p>        
            </li>
          </ul>
        </div>
        }
      </section>
      <div className={`fixed left-0 z-[9999] flex flex-col w-full overflow-hidden sm:w-20 ${isExpanded ? 'h-full' : 'h-16' } md:h-full !bg-black justify-start items-center md:hidden`}>
        <div className={`md:hidden flex flex-col ${isExpanded ? 'items-start mt-[7px] justify-start' : 'items-center md:items-start justify-center'} w-full px-4 h-full max-w-screen`}>
          <div className='flex justify-between items-center w-full'>
            <a className="px-0 py-0" href="/">
              <img src={assignBucketUrl(mashIcon)} alt='avatar' className='!w-[50px]'/>
            </a>
            <button className='!bg-black' onClick={() => setExpanded(!isExpanded)}>
              {isExpanded ? 
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 6L6 18M6 6L18 18" stroke="#FED53F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg> : 
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 12H17M3 6H21M3 18H21" stroke="#FED53F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>}
            
            </button></div>
          
          {isExpanded && (<>
            <Link to={'/briefs'}>
              <div className='w-full text-white flex gap-4 mt-8 ml-4'>
                <img width="33" height="32" src={briefIcon} alt="briefs button" />
                <div className='flex flex-col gap-2'>
                  <span className='text-lg font-semibold'>Briefs</span>
                </div>
              </div>
            </Link>
            <Link to={'/projects'}>
              <div className='w-full text-white flex gap-4 mt-8 ml-4'>
                <img width="33" height="32" src={projectsIcon} alt="projects button" />
                <div className='flex flex-col gap-2'>
                  <span className='text-lg font-semibold'>Projects</span>
                </div>
              </div>
            </Link>
            <Link to={'/mashers'}>
              <div className='w-full text-white flex gap-4 mt-8 ml-4'>
                <img width="33" height="32" src={masherIcon} alt="mashers button" />
                <div className='flex flex-col gap-2'>
                  <span className='text-lg font-semibold'>Mashers</span>
                </div>
              </div>
            </Link>
            <Link to={'/brands'}>
              <div className='w-full text-white flex gap-4 mt-8 ml-4'>
                <img width="33" height="32" src={customersIcon} alt="customers button" />
                <div className='flex flex-col gap-2'>
                  <span className='text-lg font-semibold'>Brands</span>
                </div>
              </div>
            </Link>
            <div className='border-b border-white w-full mt-auto' />
            <Link to={`/account/${loggedInUser?.data?._id}`}>
              <div className='w-full text-white flex gap-4 mt-8 ml-4'>
                <svg width="33" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#EEE9E0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg> 
                <div className='flex flex-col gap-2'>
                  <span className='text-lg font-semibold'>Account</span>
                  <span>Update your account details</span>
                </div>
              </div>
            </Link>
            <div className='w-full text-white flex gap-4 my-8 ml-4'>
              <svg width="33" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#EEE9E0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg> 
              <div className='flex flex-col gap-2'>
                <span className='text-lg font-semibold'>Support</span>
                <span>Get Help</span>
              </div>
            </div>
          </>)}

        </div>

        
      </div>
    </>
  );
}
