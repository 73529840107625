// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-button {
  display: flex;
  font-size: 16px;
  height: 48px;
  width: 150px;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.simple-button-icon {
  width: 20px;
  margin-right: 6px;
}

.primary {
    background-color: #00A46F;
    color: white;
}
.primary-unfilled {
    background-color: transparent;
    border: 1px solid #00A46F;
    color: #00A46F !important;
}
.primary-unfilled > img {
  filter: invert(38%) sepia(60%) saturate(800%) hue-rotate(48deg) brightness(85%) contrast(104%);
}`, "",{"version":3,"sources":["webpack://./src/components/common/buttons/SimpleButton/simpleButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,6BAA6B;IAC7B,yBAAyB;IACzB,yBAAyB;AAC7B;AACA;EACE,8FAA8F;AAChG","sourcesContent":[".simple-button {\n  display: flex;\n  font-size: 16px;\n  height: 48px;\n  width: 150px;\n  align-items: center;\n  justify-content: center;\n  margin-left: 20px;\n}\n.simple-button-icon {\n  width: 20px;\n  margin-right: 6px;\n}\n\n.primary {\n    background-color: #00A46F;\n    color: white;\n}\n.primary-unfilled {\n    background-color: transparent;\n    border: 1px solid #00A46F;\n    color: #00A46F !important;\n}\n.primary-unfilled > img {\n  filter: invert(38%) sepia(60%) saturate(800%) hue-rotate(48deg) brightness(85%) contrast(104%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
