import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import AdminLayout from '../../components/admin-layout';
import MasherTeamUpTable from './MasherTeamUpTable/MasherTeamUpTable';
import PriceTable from './PricingTable/PricingTable';
import TableListHeaderDeliverables from '../../components/TableList/TableListHeaderDeliverables';
import TableListDeliverables from '../../components/TableList/TableListDeliverables';
import { getBriefByIdApiV2, briefToProjectV2, syncLiveblocks } from '../../apis';
import { getLastElementId } from '../../utils/helperFunctions';
import { EditProfileLoader } from '../../components/common/loader';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import PageHeader from '../../components/pageElements/pageHeader/PageHeader';


function BriefToProject() {

  const [briefData, setBriefData] = useState();
  const [saving, setSaving] = useState(false);
  const loc = useLocation();
  const briefId = getLastElementId(loc.pathname);
  const navigate = useNavigate();
  const getBriefData = async (id) => {
    try {
      const res = await getBriefByIdApiV2(id);
      if (res?.status === 200) {
        setBriefData(res.data?.brief);
      } else {
        console.log('Retrying..');
        const retry = await getBriefByIdApiV2(id);
        setBriefData(retry.data?.brief);
      }
    } catch (e) {
      console.error('Failed to get brief data', e);
      console.log('Retrying..');
      const retryOnError = await getBriefByIdApiV2(id);
      setBriefData(retryOnError.data?.brief);
    }
  };
  useEffect(() => {
    getBriefData(briefId);
  }, []);

  const tableHeaders = [
    {
      title: 'Deliverable'
    },
    {
      title: 'Dates'
    }
  ];

  const handleConverToProject = async () => {
    try {
      setSaving(true);

      await syncLiveblocks({
        briefId,
      });

      const res = await briefToProjectV2({
        briefid: briefId
      });
      setSaving(false);
      if (res.status === 200 || 201) {
        toast.success(res?.data?.message, { id: '001' });
        window.location.href = `/projects/project-view/${res?.data?.project?._id}`;
      } else {
        toast.error(res?.data?.message, { id: '001' });
      }
    } catch (e) {
      toast.error(e?.response?.data?.error, { id: '001' });
      setSaving(false);
    }
  };

  return (
    <>
      <AdminLayout>
        <div className="page-header w-full p-8">
          <div className="flex items-start w-full custom-direction gap-10">
            <div className="md:w-1/2 ">
              <div style={{ display: 'flex' }}>
                <h2 style={{ fontSize: '32px' }}>{briefData?.name}</h2>

              </div>
            </div>
            <div className="md:w-1/2 flex  items-center w-full custom-justify gap-2">
              <SimpleButton
                title={saving ? <EditProfileLoader /> : 'Submit'}
                altText='Convert Brief to Project'
                buttonType='primary'
                buttonFunction='openModal'
                onClickEvent={() => {
                  handleConverToProject();
                }}
              />
              <SimpleButton
                title='Cancel'
                altText='Go back'
                buttonType='primaryUnfilled'
                onClickEvent={() => {
                  navigate(-1);
                }}
              />
            </div>
          </div>
        </div>
        {/* <PageHeader title={briefData?.name} briefId={briefId} buttonsArray={[
          <SimpleButton
            title={ saving ? <EditProfileLoader/> : 'Submit'}
            altText='Convert Brief to Project'
            buttonType='primary'
            buttonFunction='openModal'
            onClickEvent={() => {
              handleConverToProject();
            }}
          />,
          <SimpleButton
            title='Cancel'
            altText='Go back'
            buttonType='primaryUnfilled'
            onClickEvent={() => {
              navigate(-1);
            }}
          />
        ]} /> */}
        {briefData ? <div className='search-card-table-container' style={{ padding: '15px 10px 12px 10px' }}>
          <div className='project-mashup-table-title-block'>Deliverables</div>
          <TableListHeaderDeliverables headers={tableHeaders} />
          <div className="align-items-start search-container">
            {/* <EditProfileLoader /> */}
            <div className="col-12 search-card-main-window overflow-auto">
              <TableListDeliverables briefId={briefId} deliverables={briefData.deliverables} />
            </div>
          </div>
        </div> : <EditProfileLoader />}
        <div className='container-fluid px-4'>
          {briefData ?
            <MasherTeamUpTable briefData={briefData} convertToProject={true} refreshBrief={() => {
              getBriefData(briefId);
            }} />
            : <EditProfileLoader />
          }
          <div style={{ width: '100%', height: '65px' }}></div>
          {briefData ?
            <PriceTable isConvertToProject={true} briefData={briefData} />
            : <EditProfileLoader />
          }
        </div>
      </AdminLayout>
    </>
  );
}

export default BriefToProject;