import React, { useContext, useRef } from 'react';
import { InputErrorMessage } from '../../../components/errorMessages';
import { EditProfileLoader } from '../../../components/common/loader';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import Currency2 from '../../../utils/Currency.json';
import { ConvertToProjectContext } from '../../../appContext';
import Select from 'react-select';
import {
  autoHeightText,
  helperFileUpload,
  style,
  handleAddFields,
  handleRemoveFields,
  helperBriefAttachmentCheck,
} from '../../../utils/helperFunctions';
import { Link } from 'react-router-dom';

export default function ConvertProject3() {
  const uniqueIds = [];

  const ProjectContext = useContext(ConvertToProjectContext);
  const { formik3, setStep, loader, masherData } = ProjectContext;
  const inputRef = useRef(null);

  const Currency = Currency2.filter((element) => {
    const isDuplicate = uniqueIds.includes(
      element.name &&
        element.isoAlpha2 &&
        element.isoAlpha3 &&
        element.isoNumeric &&
        element.currency.code &&
        element.currency.name &&
        element.currency.symbol
    );
    if (!isDuplicate) {
      uniqueIds.push(
        element.name,
        element.isoAlpha2,
        element.isoAlpha3,
        element.isoNumeric,
        element.currency.code,
        element.currency.name,
        element.currency.symbol
      );

      return true;
    }
    return false;
  });

  const options = Currency?.map((el) => {
    const container = {};
    container['value'] = el.currency?.code;
    container['label'] = el?.currency?.name;
    return container;
  });

  const handleChange = (e) => {
    formik3.setFieldValue(e.target.name, e.target.value);
    formik3.handleChange(e);
  };

  const handleChangeDatePicker = (name, value) => {
    formik3.setFieldValue(name, moment(value).format('YYYY-MM-DD'));
  };

  const handleAddattachement = () => {
    const attachments = [...formik3.values.attachments, { name: '', file: '' }];
    formik3.setFieldValue('attachments', attachments);
  };
  const handleRemoveattachement = (i) => {
    const id = formik3.values.attachments[i];
    const attachments = formik3.values.attachments.filter((el) => el !== id);
    formik3.setFieldValue('attachments', attachments);
  };

  const currencyOptions = Currency?.map((el) => {
    const container = {};
    container['value'] = el.currency?.code;
    container['label'] = el?.currency?.name;
    return container;
  });

  const masherOptions = masherData?.data?.map((el) => {
    const container = {};
    container['value'] = el?._id;
    container['label'] = el?.userId?.firstName + ' ' + el?.userId?.lastName;
    container['email'] = el?.userId?.email;
    container['isMailSent'] = false;
    container['userId'] = el?.userId;
    return container;
  });

  return (
    <>
      <form onSubmit={formik3.handleSubmit}>
        {formik3?.values?.deliverableDetails?.map((el, i, self) => {
          return (
            <div className='row' key={i}>
              <div className='col-md-6 mb-2'>
                <label className='form-label'>
                  Deliverable Details<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  className={`${
                    Boolean(formik3.touched?.deliverableDetails?.[i]?.name) && Boolean(formik3.errors?.deliverableDetails?.[i]?.name)
                      ? ' border-danger '
                      : ' '
                  } form-control `}
                  placeholder='Name'
                  name={`deliverableDetails.${[i]}.name`}
                  value={formik3?.values?.deliverableDetails?.[i]?.name}
                  onChange={handleChange}
                />
                <InputErrorMessage
                  error={formik3.touched?.deliverableDetails?.[i]?.name && formik3.errors?.deliverableDetails?.[i]?.name}
                  marginBottom={-5}
                />
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-6 mb-2'>
                    <label className='form-label'>
                      Start date<span className='text-danger'> *</span>
                    </label>
                    <DatePicker
                      minDate={new Date(moment().format('YYYY-MM-DD'))}
                      clearIcon={null}
                      className={`${
                        Boolean(formik3.touched?.deliverableDetails?.[i]?.startDate) && Boolean(formik3.errors?.deliverableDetails?.[i]?.startDate)
                          ? ' border-danger'
                          : ''
                      } form-control chatInput `}
                      name={`deliverableDetails.${[i]}.startDate`}
                      value={new Date(formik3.values?.deliverableDetails?.[i]?.startDate)}
                      onChange={(e) => handleChangeDatePicker(`deliverableDetails.${[i]}.startDate`, e)}
                    />
                  </div>
                  <div className='col-md-6 mb-2'>
                    <label className='form-label'>
                      Due date<span className='text-danger'> *</span>
                    </label>
                    <DatePicker
                      minDate={new Date(formik3?.values?.deliverableDetails?.[i]?.startDate)}
                      className={`${
                        Boolean(formik3.touched?.deliverableDetails?.[i]?.dueDate) && Boolean(formik3.errors?.deliverableDetails?.[i]?.dueDate)
                          ? ' border-danger'
                          : ''
                      } form-control chatInput `}
                      clearIcon={null}
                      name={`deliverableDetails.${[i]}.dueDate`}
                      value={new Date(formik3.values?.deliverableDetails?.[i].dueDate)}
                      onChange={(e) => handleChangeDatePicker(`deliverableDetails.${[i]}.dueDate`, e)}
                    />
                  </div>
                </div>
              </div>

              <div className='col-md-4'>
                <label className='form-label'>
              Lead Mashers<span className='text-danger'> *</span>
                </label>
                <Select
                  styles={style}
                  options={masherOptions}
                  name={`deliverableDetails.${[i]}.masherRole`}
                  placeholder='Lead Mashers'
                  className={`${
                    Boolean(formik3.touched?.deliverableDetails?.[i]?.masherRole) && Boolean(formik3.errors?.deliverableDetails?.[i]?.masherRole)
                      ? 'form-control border-danger p-015'
                      : 'form-control p-015'
                  }`}
                  classNamePrefix='select'
                  value={formik3?.values?.deliverableDetails?.[i]?.masherRole}
                  onChange={(e) => {
                    formik3.setFieldValue(`deliverableDetails.${[i]}.masherRole`, e);
                    formik3.setFieldValue(`deliverableDetails.${[i]}.userId`, e?.userId);
                  }}
                />
                <InputErrorMessage
                  error={formik3.touched?.deliverableDetails?.[i]?.masherRole && formik3.errors?.deliverableDetails?.[i]?.masherRole}
                  marginBottom={-5}
                />
              </div>
              <div className='col-md-4 mb-2'>
                <label className='form-label'>
                  Delivery Format<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  className={`${
                    Boolean(formik3.touched?.deliverableDetails?.[i]?.delieveryFormat) &&
                    Boolean(formik3.errors?.deliverableDetails?.[i]?.delieveryFormat)
                      ? ' border-danger'
                      : ' '
                  } form-control `}
                  placeholder='Delivery Format'
                  name={`deliverableDetails.${[i]}.delieveryFormat`}
                  value={formik3?.values?.deliverableDetails?.[i]?.delieveryFormat}
                  onChange={handleChange}
                />
                <InputErrorMessage
                  error={formik3.touched.deliverableDetails?.[i]?.delieveryFormat && formik3.errors?.deliverableDetails?.[i]?.delieveryFormat}
                  marginBottom={-5}
                />
              </div>
              <div className='col-md-4 mb-2'>
                <label className='form-label'>
                Order<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  className={`${
                    Boolean(formik3.touched?.deliverableDetails?.[i]?.order) && Boolean(formik3.errors?.deliverableDetails?.[i]?.order)
                      ? ' border-danger'
                      : ' '
                  } form-control`}
                  placeholder='Order'
                  name={`deliverableDetails.${[i]}.order`}
                  value={formik3?.values?.deliverableDetails?.[i]?.order}
                  onChange={handleChange}
                />
                <InputErrorMessage
                  error={formik3.touched.deliverableDetails?.[i]?.order && formik3.errors?.deliverableDetails?.[i]?.order}
                  marginBottom={-5}
                />
              </div>

              <div className='col-md-12 mb-3'>
                <label className='form-label'>
              Description<span className='text-danger'> *</span>
                </label>
                <textarea
                  onKeyUp={(e) => autoHeightText(e)}
                  className={`${
                    Boolean(formik3.touched?.deliverableDetails?.[i]?.description) && Boolean(formik3.errors?.deliverableDetails?.[i]?.description)
                      ? ' border-danger '
                      : ' '
                  } form-control `}
                  placeholder='Description'
                  rows='4'
                  name={`deliverableDetails.${[i]}.description`}
                  value={formik3?.values?.deliverableDetails?.[i]?.description}
                  onChange={handleChange}></textarea>
                <InputErrorMessage
                  error={formik3.touched?.deliverableDetails?.[i]?.description && formik3.errors?.deliverableDetails?.[i]?.description}
                  marginBottom={-5}
                />
              </div>
              <div className='col-md-12 mb-3'>
                {Boolean(formik3?.values?.deliverableDetails?.length - 1 === i) ? (
                  <div className='mb-2 col-2 btn-area-details d-inline mx-1 '>
                    <span
                      className='btn btn-sm btn-neutral border-base py-2 buton-width-del  '
                      onClick={() => handleAddFields('deliverableDetails', formik3)}>
                      <i className='bi bi-plus-lg'></i> Add Details
                    </span>
                  </div>
                ) : null}

                {Boolean(self?.length > 1) && (
                  <div className='mb-2 col-2 btn-area-details d-inline mx-1 mb-3'>
                    <span
                      className='btn btn-sm btn-neutral-rmv btn-neutral border-base py-2 buton-width-del   text-danger '
                      onClick={() => handleRemoveFields('deliverableDetails', formik3, i)}>
                      <i className='bi bi-dash-lg text-danger'></i> Remove Details
                    </span>
                  </div>
                )}
                {formik3?.values?.deliverableDetails?.length > 1 ? <hr /> : ''}
              </div>
            </div>
          );
        })}

        <div className='col-md-12 mb-3'>
          <label className='form-label'>Budget</label>
          <div className='row'>
            <div className='col-6'>
              <Select
                styles={style}
                placeholder={<div>Select Currency...</div>}
                options={options}
                name='currency'
                className={' form-control p-015'}
                classNamePrefix='select'
                value={formik3.values.currency}
                onChange={(e) => {
                  // setCurrency({ value: e.value, label: e.label });
                  formik3.setFieldValue('currency', [{ value: e.value, label: e.label }]);
                }}
              />

              {/* <InputErrorMessage error={formik3.touched.currency && formik3.errors.currency} marginBottom={5} /> */}
            </div>

            <div className='col-6'>
              <input
                type='text'
                className={Boolean(formik3.touched?.budget) && Boolean(formik3.errors?.budget) ? 'form-control border-danger ' : 'form-control'}
                placeholder='Budget'
                name='budget'
                value={formik3.values.budget}
                onChange={handleChange}
              />
              {/* <InputErrorMessage error={formik3.touched.budget && formik3.errors.budget} marginBottom={5} /> */}
            </div>
          </div>
        </div>
        <div className='col-md-12 my-5 create_brief_links'>
          <label className='form-label'>Client Website / Social / Press Links</label>
          {formik3.values.websiteLink?.map((el, i, initArr) => {
            return (
              <div key={i}>
                <div className='row mb-5' style={{ marginBottom: '20px' }}>
                  <div className='col-6'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Name Here...'
                      name={`websiteLink.${[i]}.name`}
                      value={formik3.values.websiteLink?.[i]?.name}
                      onChange={handleChange}
                    />
                    {/* <InputErrorMessage error={formik3.touched.websiteLink?.[i]?.name && formik3.errors.websiteLink?.[i]?.name} marginBottom={-5} /> */}
                  </div>

                  <div className='col-6'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Links Here...'
                      name={`websiteLink.${[i]}.link`}
                      value={formik3.values.websiteLink?.[i]?.link}
                      onChange={handleChange}
                    />

                    <InputErrorMessage error={formik3.touched.websiteLink?.[i]?.link && formik3.errors.websiteLink?.[i]?.link} marginBottom={10} />
                  </div>
                </div>

                {Boolean(formik3.values.websiteLink?.length - 1 === i) ? (
                  <div className='mb-2 col-2 p-0 btn-area-details d-inline mt-5 mx-1 '>
                    <span
                      className='btn btn-sm btn-neutral border-base py-2 buton-width-del mx-1'
                      onClick={() => handleAddFields('websiteLink', formik3)}>
                      <i className='bi bi-plus-lg'></i> Add More
                    </span>
                  </div>
                ) : null}

                {Boolean(initArr?.length > 1) && (
                  <div className='mb-2 col-2 p-0 btn-area-details d-inline mt-5 mx-1'>
                    <span
                      className='btn btn-sm btn-neutral-rmv btn-neutral border-base py-2 buton-width-del mx-1 text-danger'
                      onClick={() => handleRemoveFields('websiteLink', formik3, i)}>
                      <i className='bi bi-dash-lg text-danger'></i> Remove
                    </span>
                  </div>
                )}
                {formik3?.values?.websiteLink?.length > 1 ? <hr /> : ''}
              </div>
            );
          })}
        </div>

        <div className='row mt-4'>
          <div className='col-md-12'>
            <div className='col-md-12 create_brief_links'>
              <label className='form-label'>Attachment</label>
              {formik3.values.attachments?.map((el, i, initArr) => {
                return (
                  <div key={i}>
                    <div className='row  '>
                      <div className='col-12 col-sm-6'>
                        {helperBriefAttachmentCheck(formik3?.values?.attachments[i]?.file, formik3, i)}
                        <div className='profileImgUpload d-inline-block'>
                          <label htmlFor={`attachment${i}`}>
                            <div
                              className='changeFileBtn'
                              onClick={() => {
                                inputRef?.current?.click();
                              }}>
                              Choose File
                            </div>
                          </label>

                          {formik3.values.attachments[i]?.file ? (
                            <div
                              className='removeFileBtn d-inline-block'
                              onClick={() => {
                                formik3.setFieldValue(`attachments.${[i]}.file`, '');
                              }}>
                              Remove File
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className='col-12 col-sm-6'>
                        <input
                          id={`attachment${i}`}
                          type='file'
                          className='form-control d-none '
                          placeholder='Upload'
                          name={`attachments.${[i]}.file`}
                          onChange={(event) => helperFileUpload(formik3, event)}
                        />
                        <input
                          type='text'
                          className='form-control'
                          placeholder='File Name'
                          name={`attachments.${[i]}.name`}
                          value={formik3.values.attachments?.[i]?.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                      {Boolean(formik3.values.attachments?.length - 1 === i) ? (
                        <div className='mb-2 p-0 btn-area-details d-inline  mx-1 '>
                          <span className='btn btn-sm btn-neutral border-base py-2 buton-width-del mb-5   mx-1 ' onClick={handleAddattachement}>
                            <i className='bi bi-plus-lg m-0'></i>
                          </span>
                        </div>
                      ) : null}
                      {Boolean(initArr?.length > 1) && (
                        <div className='mb-2 p-0 btn-area-details d-inline  mx-1'>
                          <span
                            className=' removeFileBtn btn btn-sm text-white  btn-neutral border-base py-2 buton-width-del mb-5  mx-1 '
                            onClick={() => handleRemoveattachement(i)}>
                            <i className='bi bi-trash  text-white m-0 '></i>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {formik3?.values?.attachments?.length > 1 ? <hr /> : ''}
          </div>
        </div>

        <div className='col-md-12 mb-3'>
          <label className='form-label'>MASH Manager (Project Lead).</label>
          <Select
            styles={style}
            options={masherOptions}
            name='mashManager'
            className={
              Boolean(formik3.touched?.mashManager) && Boolean(formik3.errors?.mashManager)
                ? 'form-control border-danger p-015'
                : 'form-control p-015'
            }
            placeholder='Enter MASH Manager (Project Lead)'
            classNamePrefix='select'
            value={formik3.values.mashManager}
            onChange={(e) => {
              formik3.setFieldValue('mashManager', [{ value: e.value, label: e.label }]);
            }}
          /> 

          <InputErrorMessage error={formik3.touched.mashManager && formik3.errors.mashManager} marginBottom={-5} />
        </div>

        <div className='col-md-12 mb-3 d-none'>
          <label className='form-label d-block'>Project Fees</label>
          <div className='row'>
            <div className='col-md-6 d-inline-block'>
              <label className='form-label d-block' style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                Currency
              </label>
              <Select
                styles={style}
                options={currencyOptions}
                name='projectCurrency'
                className={
                  Boolean(formik3.touched?.masherFee) && Boolean(formik3.errors?.masherFee)
                    ? 'form-control border-danger p-015 '
                    : 'form-control p-015'
                }
                classNamePrefix='select'
                value={formik3.values.budget}
                onChange={(e) => {
                  // setProjectCurrency({ value: e.value, label: e.label });
                  formik3.setFieldValue('budget', { value: e.value, label: e.label });
                }}
              />
              <InputErrorMessage error={formik3.touched.masherFee && formik3.errors.masherFee} marginBottom={-5} />
            </div>
            <div className='col-md-6 d-inline-block'>
              <label className='form-label d-block ' style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                Fees
              </label>
              <input
                className={
                  Boolean(formik3.touched?.projectFee) && Boolean(formik3.errors?.projectFee) ? 'form-control border-danger ' : 'form-control '
                }
                placeholder='Enter Project Fees'
                name={'projectFee'}
                value={formik3?.values?.projectFee}
                onChange={handleChange}
              />
              <InputErrorMessage error={formik3.touched.projectFee && formik3.errors.projectFee} marginBottom={-5} />
            </div>
          </div>
        </div>

        {formik3?.values?.team?.map((el, i, self) => {
          return (
            <div key={`team-${i}`}>
              <div className='row'>
                <div className='col-md-12 '>
                  <label className='form-label'>Team</label>
                </div>

                <div className='col-md-4'>
                  <label className='form-label d-block ' style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    Masher Name
                  </label>

                  <Select
                    styles={style}
                    options={masherOptions}
                    name={`team.${[i]}.masherName`}
                    className={
                      Boolean(formik3.touched?.team?.[i]?.masherName) && Boolean(formik3.errors?.team?.[i]?.masherName)
                        ? 'form-control border-danger p-015'
                        : 'form-control p-015'
                    }
                    classNamePrefix='select'
                    value={formik3?.values?.team?.[i]?.masherName}
                    onChange={(e) => {
                      formik3.setFieldValue(`team.${[i]}.masherName`, e);
                      formik3.setFieldValue(`team.${[i]}.userId`, e?.userId);
                    }}
                  />
                  
                  <InputErrorMessage error={formik3.touched.team?.[i]?.masherName && formik3.errors.team?.[i]?.masherName} marginBottom={-5} />
                </div>

                <div className='col-md-4'>
                  <label className='form-label d-block ' style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    Currency
                  </label>

                  <select
                    className={
                      Boolean(formik3.touched?.team?.[i]?.currency) && Boolean(formik3.errors?.team?.[i]?.currency)
                        ? 'form-control border-danger'
                        : 'form-control '
                    }
                    placeholder='currency'
                    name={`team.${[i]}.currency`}
                    value={formik3?.values?.team?.[i]?.currency}
                    onChange={handleChange}>
                    <option hidden>Select currency</option>
                    {Currency?.map((el, _i) => {
                      return (
                        <option key={`cur-${_i}`} value={el.currency?.code}>
                          {el?.currency?.name}
                        </option>
                      );
                    })}
                  </select>

                  <InputErrorMessage error={formik3.touched.team?.[i]?.currency && formik3.errors.team?.[i]?.currency} marginBottom={-5} />
                </div>

                <div className='col-md-4 mb-3'>
                  <label className='form-label d-block ' style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    Masher Fees
                  </label>
                  <input
                    type='number'
                    className={
                      Boolean(formik3.touched?.team?.[i]?.fees) && Boolean(formik3.errors?.team?.[i]?.fees)
                        ? 'form-control border-danger'
                        : 'form-control '
                    }
                    placeholder='fees'
                    name={`team.${[i]}.fees`}
                    value={formik3?.values?.team?.[i]?.fees}
                    onChange={handleChange}
                  />
                  <InputErrorMessage error={formik3.touched.team?.[i]?.fees && formik3.errors.team?.[i]?.fees} marginBottom={-5} />
                </div>

                <div className='col-md-12'>
                  {Boolean(formik3?.values?.team?.length - 1 === i) ? (
                    <div className='mb-2 col-2 btn-area-details d-inline mx-1 '>
                      <span className='btn btn-sm btn-neutral border-base py-2 buton-width-del mb-3' onClick={() => handleAddFields('team', formik3)}>
                        <i className='bi bi-plus-square'></i> Add Team
                      </span>
                    </div>
                  ) : null}

                  {Boolean(self?.length > 1) && (
                    <div className='mb-2 col-2 btn-area-details d-inline mx-1'>
                      <span
                        className='btn btn-sm btn-neutral-rmv btn-neutral border-base py-2 buton-width-del mb-3 text-danger  '
                        onClick={() => handleRemoveFields('team', formik3, i)}>
                        <i className='bi bi-dash-square text-danger'></i> Remove Team
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <InputErrorMessage error={formik3.touched.team?.[i] && formik3.errors.team?.[i]} marginBottom={-5} />
            </div>
          );
        })}

        <div className='col-md-12 next-btn-cntnr mb-3 mt-5'>
          <Link to='' className='btn btn-sm btn-neutral border-base me-3' onClick={() => setStep((v) => v - 1)}>
            Back
          </Link>
          <button type='submit' className='btn btn-sm btn_primary_black btn-width'>
            {!loader ? (
              'Save'
            ) : (
              <div className='text-xs text-center'>
                <EditProfileLoader />
              </div>
            )}
          </button>
        </div>
      </form>
    </>
  );
}
