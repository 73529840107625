import toast from 'react-hot-toast';
import { Navigate } from 'react-router-dom';
import { isAuth } from '../utils/helperFunctions';

export default function PublicRoute({ children }) {
  const auth = isAuth();

  const signOut = () => {
    toast.error('Session Expired');
    return <Navigate to='/' />;
  };

  return !auth ? children : signOut();
}
