import React, { useContext, useEffect } from 'react';
import { EditProfileLoader } from '../../../components/common/loader';
import { BriefFormContext } from '../../../appContext';
import noPreview from '../../../Assets/img/media/no.png';
import ProfileDefault from '../../../Assets/img/covers/userDefault.png';
import { assignBucketUrl, helperBriefAttachmentCheck } from '../../../utils/helperFunctions';

const FormPreview = () => {
  const FormContext3 = useContext(BriefFormContext);
  const { formik1, formik2, formik3, setStep, loader, formikPreview, select } = FormContext3;

  function adjustHeight() {
    const collection = document.getElementsByTagName('textarea');

    for (var i = 0; i < collection.length; i++) {
      collection[i].style.height = '69px';
      collection[i].style.height = 1 + collection[i].scrollHeight + 'px';
    }
  }

  useEffect(() => {
    formik1.setValues((c) => ({
      ...c,
    }));
    formik2.setValues((c) => ({
      ...c,
    }));
    formik3.setValues((c) => ({
      ...c,
    }));
    adjustHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={formikPreview}>
        <div className='row'>
          <div className='col-md-6 mb-5'>
            <label className='form-label'>Brands name</label>
            <input disabled={true} type='text' className='form-control' name='clientName' value={formik1.values.clientName[0].label} />
          </div>
          <div className='col-md-6 mb-5'>
            <label className='form-label'>Brief Name</label>
            <input disabled={true} type='text' className='form-control' name='briefName' value={formik1.values.briefName} />
          </div>
          <div className='col-md-6 mb-5'>
            <label className='form-label'>Logo</label>
            {formik1.values.logo ? (
              <a href={formik1.values.logo} target='_blank' rel='noreferrer'>
                <img
                  src={assignBucketUrl(formik1.values.logo)}
                  onError={(event) => {
                    event.target.src = ProfileDefault;
                    event.onerror = null;
                  }}
                  alt='Logo'
                  className='brief-img attachment d-block'
                  style={{
                    width: '100px',
                    height: '100px',
                    border: '1px solid rgb(25, 19, 19, 0.25)',
                    borderRadius: '50%',
                    boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                    marginRight: '10px',
                  }}
                />
              </a>
            ) : (
              <img
                alt=''
                src={assignBucketUrl(noPreview)}
                onError={(event) => {
                  event.target.src = ProfileDefault;
                  event.onerror = null;
                }}
                style={{ width: '8%', border: '1px solid black', borderRadius: '8px' }}
              />
            )}
          </div>
          <div className='col-md-6 mb-5'>
            <label className='form-label'>Industry</label>
            <input
              disabled={true}
              className={Boolean(formik1.touched?.industry) && Boolean(formik1.errors?.industry) ? 'form-select border-danger' : 'form-control'}
              name='industry'
              value={select?.industry}
            />
          </div>
          <div className='col-md-6 mb-5'>
            <label className='form-label'>Region</label>
            <input
              disabled={true}
              className={Boolean(formik1.touched?.region) && Boolean(formik1.errors?.region) ? 'form-select border-danger' : 'form-control'}
              name='region'
              value={select?.region}
            />
          </div>

          <div className='col-md-6 mb-5'>
            <label className='form-label'>Client Type</label>
            <input
              disabled={true}
              className={Boolean(formik1.touched?.clientType) && Boolean(formik1.errors?.clientType) ? 'form-select border-danger' : 'form-control'}
              name='clientType'
              value={select.client}
            />
          </div>

          <div className='col-md-6 mb-5'>
            <label className='form-label'>Category</label>
            <input
              disabled={true}
              className={Boolean(formik1.touched?.category) && Boolean(formik1.errors?.category) ? 'form-select border-danger' : 'form-control'}
              name='category'
              value={select?.category}
            />
          </div>

          <div className='col-md-6 mb-5'>
            <label className='form-label'>Engagement Structure</label>
            <input
              disabled={true}
              className={
                Boolean(formik1.touched?.engagementStructure) && Boolean(formik1.errors?.engagementStructure)
                  ? 'form-select border-danger'
                  : 'form-control'
              }
              name='engagementStructure'
              value={select?.engage}
            />
          </div>

          <hr />
        </div>

        {/*  Form 222222222222222222222222222222222222222222222 */}

        <div className='row'>
          <div className='col-md-12 mb-5'>
            <label className='form-label'>The Brief</label>
            <input disabled={true} type='text' className='form-control' name='inBrief' value={formik2.values.inBrief} />
          </div>

          <div className='col-md-12 mb-5' onMouseDown={(e) => adjustHeight(e)}>
            <label className='form-label'>Project Background</label>
            <textarea disabled={true} type='text' className='form-control' name='background' value={formik2.values.background}></textarea>
          </div>

          <div className='col-md-12 mb-5' onMouseDown={(e) => adjustHeight(e)}>
            <label className='form-label'>Project Goal</label>
            <textarea disabled={true} type='text' className='form-control' name='projectAmbition' value={formik2.values.projectAmbition}></textarea>
          </div>

          <div className='col-md-12 mb-5'>
            <label className='form-label'>Product / Experience</label>
            <textarea disabled={true} type='text' className='form-control' name='experience' value={formik2.values.experience}></textarea>
          </div>

          <div className='col-md-12 mb-5'>
            <label className='form-label'>Brand</label>
            <textarea disabled={true} type='text' className='form-control' name='brand' value={formik2.values.brand}></textarea>
          </div>

          <div className='col-md-12 mb-5' onMouseDown={(e) => adjustHeight(e)}>
            <label className='form-label'>Audience</label>
            <textarea disabled={true} className='form-control' name='audience' value={formik2.values.audience}></textarea>
          </div>

          <div className='col-md-12 mb-5'>
            <label className='form-label'>KPIs</label>
            <textarea disabled={true} className='form-control' name='kpis' value={formik2.values.kpis}></textarea>
          </div>

          <hr />
        </div>

        {/* Form  3333333333333333333333333333333333333333333333333333333333 */}

        <div className=''>
          {formik3?.values?.deliverableDetails?.map((el, i, self) => {
            return (
              <div className='row' key={`deli-${i}`}>
                <div className='col-md-6 mb-5'>
                  <label className='form-label'>Deliverable Details</label>
                  <input
                    disabled={true}
                    type='text'
                    className='form-control'
                    name={`deliverableDetails.${[i]}.name`}
                    value={formik3?.values?.deliverableDetails?.[i]?.name}
                  />
                </div>
                <div className='col-md-6 mb-5'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row'>
                        <div className='col-12'>
                          <div>
                            <label className='form-label'>Start date</label>
                            <input
                              disabled={true}
                              type='text'
                              className='form-control'
                              name={`deliverableDetails.${[i]}.startDate`}
                              value={formik3?.values?.deliverableDetails?.[i]?.startDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div>
                        <label className='form-label'>Due date</label>
                        <input
                          disabled={true}
                          type='text'
                          className='form-control'
                          name={`deliverableDetails.${[i]}.dueDate`}
                          value={formik3?.values?.deliverableDetails?.[i]?.dueDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-12 mb-5'>
                  <div className='row'>
                    <div className='col-md-4 pr-15'>
                      <input
                        disabled={true}
                        type='text'
                        className='form-control'
                        name={`deliverableDetails.${[i]}.masherRole`}
                        value={formik3?.values?.deliverableDetails?.[i]?.masherRole.label}
                      />
                    </div>
                    <div className='col-md-4 mb-5'>
                      <input
                        disabled={true}
                        type='text'
                        className='form-control'
                        name={`deliverableDetails.${[i]}.delieveryFormat`}
                        value={formik3?.values?.deliverableDetails?.[i]?.delieveryFormat}
                      />
                    </div>
                    <div className='col-md-4 mb-2'>
                      <input
                        disabled={true}
                        type='text'
                        className={`${
                          Boolean(formik3.touched?.deliverableDetails?.[i]?.order) && Boolean(formik3.errors?.deliverableDetails?.[i]?.order)
                            ? ' border-danger'
                            : ' '
                        } form-control`}
                        placeholder='Order'
                        name={`deliverableDetails.${[i]}.order`}
                        value={formik3?.values?.deliverableDetails?.[i]?.order}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-md-12 mb-5' onMouseDown={(e) => adjustHeight(e)}>
                  <textarea
                    disabled={true}
                    className='form-control'
                    name={`deliverableDetails.${[i]}.description`}
                    value={formik3?.values?.deliverableDetails?.[i]?.description}></textarea>
                </div>
                <div className='col-md-12'>
                  <hr />
                </div>
              </div>
            );
          })}
          <div className='col-md-12 mb-5'>
            <label className='form-label'>Budget</label>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <input disabled={true} type='text' className='form-control' name='budget' value={formik3.values.currency[0]?.label} />
              </div>

              <div className='col-12 col-md-6'>
                <input disabled={true} type='text' className='form-control' name='budget' value={formik3.values.budget} />
              </div>
            </div>
          </div>

          <div className='col-md-12 mb-5 create_brief_links'>
            <label className='form-label'>Client Website / Social / Press Links</label>

            {formik3.values.websiteLink?.map((el, i) => {
              return (
                <div className='row mt-5  ' key={`webs-${i}`} style={{ marginBottom: '20px' }}>
                  <div className='col-12 col-md-6'>
                    <input
                      disabled={true}
                      type='text'
                      className='form-control'
                      name={`websiteLink.${[i]}.name`}
                      value={formik3.values.websiteLink?.[i]?.name}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <input
                      disabled={true}
                      type='text'
                      className='form-control'
                      name={`websiteLink.${[i]}.link`}
                      value={formik3.values.websiteLink?.[i]?.link}
                    />
                  </div>
                </div>
              );
            })}

            <div className='row'>
              <div className='col-md-12'>
                <div className='col-md-12 create_brief_links'>
                  {formik3.values.attachments?.map((el, i, initArr) => {
                    return (
                      <div key={`att-${i}`}>
                        <label className='form-label'>Attachment</label>
                        <div key={i}>
                          <div className='row mb-4'>
                            <div className='col-12 col-sm-6'>
                              {helperBriefAttachmentCheck(formik3?.values?.attachments[i]?.file)}
                              <div className='profileImgUpload d-inline-block'></div>
                            </div>
                            <div className='col-12 col-sm-6'>
                              <input
                                disabled={true}
                                id={`attachment${i}`}
                                type='file'
                                className='form-control d-none '
                                placeholder='Upload'
                                name={`attachments.${[i]}.file`}
                              />
                              <input
                                disabled={true}
                                type='text'
                                className='form-control'
                                placeholder='File Name'
                                name={`attachments.${[i]}.name`}
                                value={formik3.values.attachments?.[i]?.name}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {formik3?.values?.attachments?.length > 1 ? <hr /> : ''}
              </div>
            </div>
          </div>

          <div className='col-md-12 next-btn-cntnr mb-5 mt-5'>
            <button className='btn btn-sm btn-neutral border-base me-3' onClick={() => setStep((v) => v - 1)}>
              Back
            </button>
            <button type='submit' className='btn btn-sm btn_primary_black btn-width'>
              {!loader ? (
                'Save'
              ) : (
                <div className='text-xs text-center'>
                  <EditProfileLoader />
                </div>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default FormPreview;
