import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { autoHeightText } from '../../../utils/helperFunctions';
import { ConvertToProjectContext } from '../../../appContext';

export default function ConvertProject2() {
  const ProjectContext = useContext(ConvertToProjectContext);
  const { formik2, setStep } = ProjectContext;

  const handleChange = (e) => {
    formik2.handleChange(e);
  };

  return (
    <>
      <form onSubmit={formik2.handleSubmit}>
        <div className='row'>
          <div className='col-md-12 mb-5'>
            <label className='form-label'>The Brief</label>
            <input
              type='text'
              className='form-control'
              placeholder='The Brief'
              name='inBrief'
              value={formik2.values.inBrief}
              onChange={handleChange}
            />
            {/* <InputErrorMessage error={formik2.touched.inBrief && formik2.errors.inBrief} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-5'>
            <label className='form-label'>Project Background</label>
            <textarea
              onKeyUp={(e) => autoHeightText(e)}
              type='text'
              className={
                Boolean(formik2.touched?.background) && Boolean(formik2.errors?.background) ? 'form-control border-danger ' : 'form-control '
              }
              placeholder='Project Background
'
              name='background'
              value={formik2.values.background}
              onChange={handleChange}></textarea>
            {/* <InputErrorMessage error={formik2.touched.background && formik2.errors.background} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-5'>
            <label className='form-label'>Project Goal</label>
            <textarea
              onKeyUp={(e) => autoHeightText(e)}
              type='text'
              className={
                Boolean(formik2.touched?.projectAmbition) && Boolean(formik2.errors?.projectAmbition)
                  ? 'form-control border-danger '
                  : 'form-control '
              }
              placeholder='what are the business objectives that this brief is attempting to tackle?'
              name='projectAmbition'
              value={formik2.values.projectAmbition}
              onChange={handleChange}></textarea>
            {/* <InputErrorMessage error={formik2.touched.projectAmbition && formik2.errors.projectAmbition} marginBottom={-5} /> */}
          </div>

          <div className='col-md-12 mb-5'>
            <label className='form-label'>Product / Experience</label>
            <textarea
              type='text'
              onKeyUp={(e) => autoHeightText(e)}
              className={
                Boolean(formik2.touched?.experience) && Boolean(formik2.errors?.experience) ? 'form-control border-danger ' : 'form-control '
              }
              placeholder='What makes the brand special / how does it fulfill the challenge the audience faces?'
              name='experience'
              value={formik2.values.experience}
              onChange={handleChange}></textarea>
          </div>

          <div className='col-md-12 mb-5'>
            <label className='form-label'>Brand</label>
            <textarea
              type='text'
              onKeyUp={(e) => autoHeightText(e)}
              className='form-control'
              placeholder='What makes the brand special / how does it fulfill the challenge the audience faces?'
              name='brand'
              value={formik2.values.brand}
              onChange={handleChange}></textarea>
            {/* <InputErrorMessage error={formik2.touched.brand && formik2.errors.brand} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-5'>
            <label className='form-label'>Audience</label>
            <textarea
              onKeyUp={(e) => autoHeightText(e)}
              className='form-control'
              placeholder='Who are we talking to (demographics, location, age, etc) and what do we know about them that matters most to this brief?'
              name='audience'
              value={formik2.values.audience}
              onChange={handleChange}></textarea>
            {/* <InputErrorMessage error={formik2.touched.audience && formik2.errors.audience} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-5'>
            <label className='form-label'>KPIs</label>
            <textarea
              type='text'
              onKeyUp={(e) => autoHeightText(e)}
              className='form-control'
              placeholder='How will we measure the success of this project'
              name='kpis'
              value={formik2.values.kpis}
              onChange={handleChange}></textarea>
            {/* <InputErrorMessage error={formik2.touched.kpis && formik2.errors.kpis} marginBottom={-5} /> */}
          </div>

          <div className='col-md-12 mb-5 d-none'>
            <label className='form-label'>Client Type</label>
            <select className='form-select'>
              <option>MNC</option>
              <option>SME</option>
            </select>
            {/* <InputErrorMessage error={formik2.touched.engagementStructure && formik2.errors.engagementStructure} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-5 d-none'>
            <label className='form-label'>Category</label>
            <select className='form-select'>
              <option>Select Category</option>
            </select>
            {/* <InputErrorMessage error={formik2.touched.engagementStructure && formik2.errors.engagementStructure} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-5 d-none'>
            <label className='form-label'>Engagement Structure</label>
            <select className='form-select'>
              <option>Select Engagement Structure</option>
            </select>
            {/* <InputErrorMessage error={formik2.touched.engagementStructure && formik2.errors.engagementStructure} marginBottom={-5} /> */}
          </div>
          <div className='col-md-12 mb-5 d-none'>
            <label className='form-label'>Attachment</label>
            <input type='file' className='form-control' placeholder='Upload' />
          </div>

          <div className='col-md-12 next-btn-cntnr mb-5 mt-5'>
            <Link to='' className='btn btn-sm btn-neutral border-base me-3' onClick={() => setStep((v) => v - 1)}>
              Back
            </Link>
            <button type='submit' className='btn btn-sm btn_primary_black btn-width'>
              Next
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
