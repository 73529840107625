import { Popover } from '@headlessui/react';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
const RejectedPopover = ({ reason = '' }) => {
  return <Popover className="relative">
    <Popover.Button><ExclamationCircleFill size={25} color='#DC6803' /></Popover.Button>

    <Popover.Panel className="absolute z-10">
      <div className="px-8 py-4 border w-96 border-black rounded-lg bg-white font-normal text-sm"><p className="font-bold text-md">Masher skipped this brief.</p>{reason && <><p className="mt-4 text-sm">Reason:</p><span>{ reason }</span></>}</div>
    </Popover.Panel>
  </Popover>;
};
export default RejectedPopover;