import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { editBriefValidation } from '../../../components/Validation/index.js';
import { v4 as uuidv4 } from 'uuid';
import { FilePreviewComponent, FileUploadComponent } from './components/FileUploadComponent.js';
const countries = require('i18n-iso-countries');
import {
  getUserApi,
  editBriefApi,
  editBriefApiV2,
  getBriefByIdApi,
  getBriefByIdApiV2,
  fetchCategoryApi,
  fetchClientApi,
  fetchClientApiV2,
  fetchEngagementTypeApi,
  fetchIndustryApi,
  fetchRegionApi,
  syncLiveblocks,
  DeleteBriefAttachApi,
} from '../../../apis/index.js';
import DatePicker from 'react-date-picker';
import SimpleModal from '../../../components/Modals/SimpleModal.tsx';
import { allClientData, getLastElementId, getClient, style, brandOptions } from '../../../utils/helperFunctions.js';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import PageHeader from '../../../components/pageElements/pageHeader/PageHeader.js';
import SimpleButton from '../../../components/common/buttons/SimpleButton/SimpleButton.js';
import EditBriefForm from './EditBriefForm.tsx';
import EditBrief from '../EditBrief.js';
import pdfIcon from '../../../Icons/pdfIcon.svg';
import AdminLayout from '../../../components/admin-layout/index.js';
import SimpleTextAndTitle from '../../../components/text-boxes/SimpleTextAndTitle.js';
import editIcon from '../../../Icons/editIconNoBorder.svg';
import calendarIcon from '../../../Icons/calendarIcon.svg';
import closeCrossIcon from '../../../Icons/closeCrossIcon.svg';
import plusIconBlack from '../../../Icons/plusIconBlack.svg';
import chevronRight from '../../../Icons/chevronRight.svg';
import { EditFormContext, AppContext } from '../../../appContext/index.js';
import PricingTable from '../PricingTable/PricingTable.js';
import MasherChat from './components/MasherChat';
import SimplePrintModal from '../../../components/Modals/SimplePrintModal';
import BriefPDFView from '../BriefPDFView';
import '../briefs.css';
import CollabProvider, { CollabContext } from './providers/CollabProvider.jsx';
import MasherTeamUpTable from '../MasherTeamUpTable/MasherTeamUpTable.js';
import Emoji from '../../../components/emojis/Emojis.jsx';
import ShareBrief from './components/ShareBrief.tsx';
import DuplicateBrief from './DuplicateBrief.js';
import Avatar from '../../../components/Avatar/Avatar';
import { fileUploadApi, createBriefAttachApi } from '../../../apis/index.js';
import BriefStatus from '../../../components/Brief/BriefStatus.jsx';
import { FiletypePdf, ThreeDotsVertical } from 'react-bootstrap-icons';
import DropdownMenu from './MenuDropdown.js';

const EditBriefIndex = () => {
  const { liveBrief, setLiveBrief } = useContext(CollabContext);
  const [currentUser, setCurrentUser] = useState('');
  const [formSelects, setformSelects] = useState({ region: '', industry: '', engagementType: '', category: '', client: '', loading: true });
  const [client, setClient] = useState({ data: null, loading: true });
  const [step, setStep] = useState(0);
  const [editDetails, setEditDetails] = useState(false);
  const [shareBrief, setShareBrief] = useState(false);
  const searchFilter = { search: '', page: 1, limit: '' };
  const [loader, setLoader] = useState(false);
  const [briefData, setBriefData] = useState({ data: {}, loading: false });
  const currDate = moment().format('YYYY-MM-DD');
  const briefId = getLastElementId();
  const navigate = useNavigate();
  const briefDetails = briefData?.data;
  // const [briefDetails
  const [masherData, setMasherData] = useState({ data: null, loading: false });
  const [industries, setIndustries] = useState([]);
  const [exportPdf, setExportPdf] = useState(false);
  const [duplicateBrief, setDuplicateBrief] = useState(false);
  const [pricingTableViewable, setPricingTableViewable] = useState(false);

  const appContext = useContext(AppContext);
  const { state } = appContext;
  const { loggedInUser } = state;

  function getFlagEmoji(countryCode) {
    if (countryCode) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    }
    return '';
  }

  function getCurrencyFlag(currencyCode) {
    if (currencyCode === 'USD') {
      return getFlagEmoji('US');
    } else if (currencyCode === 'EUR') {
      return getFlagEmoji('EU');
    } else if (currencyCode === 'AUD') {
      return getFlagEmoji('AU');
    } else if (currencyCode === 'GBP') {
      return getFlagEmoji('UK');
    } else if (currencyCode === 'SGD') {
      return getFlagEmoji('SG');
    }
  }

  const getBriefById = async (id) => {
    setLoader(true);
    try {
      const res = await getBriefByIdApiV2(id);
      if (res.status === 200) {
        setLoader(false);
        setBriefData({ data: res.data.brief });
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  };

  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  const countryName = countries.getNames('en', { select: 'official' });
  const countryCode = countries.getNumericCodes();
  const countriesList = Object.keys(countryCode).map((code, i) => {
    const iso = countryCode[code];
    return {
      id: code,
      countryISO: iso,
      countryName: countryName[iso]
    };
  });
  const countriesListSorted = countriesList.sort((a, b) => {
    if (a.countryName < b.countryName) {
      return -1;
    } else {
      return 1;
    }
    return 0;
  });

  const fetchSelectData = async () => {
    setformSelects({ loading: true });
    try {
      const selectResponse = {
        regionRes: await fetchRegionApi(),
        // countryRes: await fetchCountrycodeApi(),
        industryRes: await fetchIndustryApi(),
        engagementRes: await fetchEngagementTypeApi(),
        cateRes: await fetchCategoryApi(),
        clientRes: await fetchClientApiV2(),
      };

      setformSelects({
        region: selectResponse?.regionRes.data,
        country: countriesListSorted,
        industry: selectResponse?.industryRes.data,
        engagementType: selectResponse?.engagementRes.data,
        category: selectResponse?.cateRes.data,
        client: selectResponse?.clientRes.data,
        loading: false,
      });
    } catch (error) {
      setformSelects({ loading: false });
    }

  };

  const options = () => {
    return client ? brandOptions(client) : false;
  };

  const allMasherData = async (masherPayload) => {
    setMasherData({ loading: true });
    try {
      const res = await getMasherShareApi(masherPayload);
      if (res.status === 200) {
        setMasherData({ data: res.data.data, loading: false });
      }
    } catch (error) {
      setMasherData({ loading: false });
    }
  };

  const getIndustries = async () => {
    const returnedIndustries = await fetchIndustryApi();
    setIndustries(returnedIndustries);
  };

  const currentUserApi = async () => {
    const res = await getUserApi();
    setCurrentUser(res?.data?.user[0]._id);
  };

  useEffect(() => {
    getBriefById(briefId);
    fetchSelectData();
    allClientData({ ...searchFilter }, setClient);
    allMasherData('approved');
    getIndustries();
    currentUserApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDetails]);

  const formik = useFormik({
    initialValues: {
      companyId: '',
      companyName: '',
      attachments: '',
      briefName: '',
      industry: '',
      region: '',
      currency: ''
    },

    validationSchema: editBriefValidation,
    handleSubmit: async (e) => {
      let alldata = { ...e };

      alldata.clientId = companyId;
      alldata.clientName = companyName;

      try {
        setLoader(true);
        const briefRes = await editBriefApiV2(alldata);
        if (briefRes.status === 200 || briefRes.status === 201) {
          toast.success(briefRes.data.message, { id: '001' });
          setLoader(false);
          navigate('/');
        }
      } catch (error) {
        setLoader(false);
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      companyId: briefDetails?.companyId,
      companyName: briefDetails?.companyName,
      attachments: briefDetails?.attachments || null,
      briefName: briefDetails.briefName,
      industry: briefDetails.industry,
      region: briefDetails.region,
      currency: briefDetails.currency,
    });
  }, [briefDetails]);

  const companyIndustries = (industryList) => {
    const companyIndustries = industryList?.data?.filter(industry => industry?.id === briefDetails.industry);
    return companyIndustries;
  };

  const companyRegion = () => {
    const region = formSelects?.region;
    if (region) return region?.filter(i => i?.id === briefDetails.region).map(obj => obj.name);
    return 'No region selected';
  };
  const handlePricingTableView = () => {
    setPricingTableViewable(!pricingTableViewable);
  };
  const editHeaderDetails = () => {
    setEditDetails(!editDetails);
  };

  const toggleExportPdfModal = () => {
    setExportPdf(!exportPdf);
  };
  const toggleDuplicateBriefModal = () => {
    setDuplicateBrief(!duplicateBrief);
  };

  const renderContributors = (contributors) => {
    const contributorsList = [];
    return (
      <div className='brief-contributors-container'>
        {
          contributors?.map((c, i) => {
            if (contributorsList.find(cont => cont === c?.userId)) {
              return null;
            } else {
              contributorsList.push(c.userId);
              return <div key={i} style={{ marginLeft: '-10px' }}><Avatar key={i} name={`${c.firstName} ${c.lastName}`} id={c?.userId} borderColor='white' /></div>;
            }
          })
        }
      </div>
    );
  };
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      // Process each accepted file
      acceptedFiles.forEach((file, index) => {
        fileupload(file, index, acceptedFiles.length);
      });
    }
  }, []);

  const [uploadPercentage, setUploadPercentage] = useState([]);
  const [filename, setfileName] = useState([]);
  const [filesize, setfilesize] = useState([]);
  const [fileiserror, setfileiserror] = useState([]);
  const [isfile, setisfile] = useState(false);
  function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;

    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  }
  const onUploadProgress = (progressEvent, file, index) => {
    setUploadPercentage((prevUploadPercentage) => {
      const updatedProgress = [...prevUploadPercentage];
      updatedProgress[index] = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      return updatedProgress;
    });

    setfileName((prevFileName) => {
      const updatedFileName = [...prevFileName];
      updatedFileName[index] = file.name;
      return updatedFileName;
    });

    setfilesize((prevFileSize) => {
      const updatedFileSize = [...prevFileSize];
      updatedFileSize[index] = formatFileSize(file.size);
      return updatedFileSize;
    });
  };
  const saveBrief = async (briefId) => {
    try {
      setbtnloader(true);
      await syncLiveblocks({
        briefId,
      });
      setbtnloader(false);
    }
    catch (error) {
      // Handle any other errors
      console.error('An error occurred:', error);
      setbtnloader(false);
    }
    finally {
      // This block will always be executed  
      toast.success('Brief saved successfully');
    }
  };
  const fileupload = async (file, index, totalFiles) => {
    try {
      setisfile(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('briefId', briefId);

      // Make the file upload request
      const uploadResponse = await fileUploadApi(formData, (progressEvent) => onUploadProgress(progressEvent, file, index));

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        // If the file upload was successful, update the filename and filesize
        setfileName((prevFileName) => {
          const updatedFileName = [...prevFileName];
          updatedFileName[index] = file.name;
          return updatedFileName;
        });

        setfilesize((prevFileSize) => {
          const updatedFileSize = [...prevFileSize];
          updatedFileSize[index] = formatFileSize(file.size);
          return updatedFileSize;
        });

        // Create a brief attachment
        const createBriefAttachResponse = await createBriefAttachApi({
          'briefId': briefId || '',
          'fileName': file.name,
          'filePath': uploadResponse.data.filePath || '',
          'fileSize': formatFileSize(file.size)
        });

        if (createBriefAttachResponse.status === 200 || createBriefAttachResponse.status === 201) {
          setfileiserror((prevFileName) => {
            const updatedstatus = [...prevFileName];
            updatedstatus[index] = false;
            return updatedstatus;
          });
          // alert(JSON.stringify(createBriefAttachResponse?.data?.Attachments?.[0]?.attachments,null,2))
          formik.setValues({ attachments: createBriefAttachResponse?.data?.Attachments?.[0]?.attachments });
        } else {
          setfileiserror((prevFileName) => {
            const updatedstatus = [...prevFileName];
            updatedstatus[index] = true;
            return updatedstatus;
          });
        }
      } else {
        // Handle error in file upload
        setfileiserror((prevFileName) => {
          const updatedstatus = [...prevFileName];
          updatedstatus[index] = true;
          return updatedstatus;
        });
      }
    } catch (error) {
      // Handle any other errors
      console.error('An error occurred:', error);
      setfileiserror((prevFileName) => {
        const updatedstatus = [...prevFileName];
        updatedstatus[index] = true;
        return updatedstatus;
      });
    }
    finally {
      // This block will always be executed  
      if (index === totalFiles - 1) {
        setisfile(false);
      }
    }
  };


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });

  useEffect(() => {
    if (formik.values.attachments && Array.isArray(formik.values.attachments)) {
      // If formik.values.attachments is not null or undefined and is an array
      setFileDeleteLoader(Array(formik.values.attachments.length).fill(false));
    }
  }, [formik.values.attachments]);
  const [filedeleteloader, setFileDeleteLoader] = useState([]);
  const Deletefun = async (id, index) => {
    try {
      setFileDeleteLoader(prevState => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
      const res = await DeleteBriefAttachApi(briefId, id);

      if (res.status === 200 || 201) {
        toast.success(res.data.message, { id: 'succcess' });
        // alert(JSON.stringify(res.data.result.attachments))
        formik.setValues({ attachments: res.data.result.attachments });
      }
    } catch (error) {
      toast.error(error.message, { id: '001' });
    }
    finally {
      // This block will always be executed  
      setFileDeleteLoader(prevState => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };
  const [btnloader, setbtnloader] = useState(false);
  const [expandheader, setexpandheader] = useState(false);
  return (
    <>
      <EditFormContext.Provider
        value={{
          formSelects,
          getClient,
          client,
          formik,
          setStep,
          loader,
          masherData
        }}>
        <CollabProvider >
          <AdminLayout>

            <div className="page-header w-full p-7">
              <div className="flex items-start w-full custom-direction gap-10">
                <div className="md:w-1/2">
                  <div className='flex items-center'>
                    <h2 style={{ fontSize: '32px' }}>{briefDetails?.name}</h2>
                    <img style={{ margin: '4px 15px' }} src={editIcon} onClick={() => editHeaderDetails()} />
                    {briefId ? <BriefStatus briefId={briefId} briefStatus={briefDetails?.status} /> : ''}
                  </div>
                </div>
                <div className="md:w-1/2 flex  items-center w-full custom-justify">
                  <div className="flex flex-col md:flex-row space-y-3 md:space-x-3 md:space-y-0">
                    <div className="flex flex-row space-x-3  gap-3">
                      <SimpleButton
                        title='Save Brief'
                        altText='Save Brief'
                        buttonType='primary'
                        buttonFunction='withloader'
                        loaderstate={btnloader}
                        setloaderstate={setbtnloader}
                        onClickEvent={() => {
                          saveBrief(`${briefId}`);
                        }}
                      />
                      <SimpleButton
                        title='Share Brief'
                        altText='Share Brief'
                        buttonType='primary'
                        onClickEvent={() => {
                          setShareBrief(true);
                        }}
                      />
                    </div>
                    <div className="flex flex-row space-x-3 md:justify-end lg:justify-start">
                      <SimpleButton
                        title='Convert to Project'
                        altText='Convert to Project'
                        buttonType='primary'
                        buttonFunction='link'
                        link={`/briefs/convert-brief/${briefId}`}
                      />
                      <DropdownMenu
                        pdftoggle={toggleExportPdfModal}
                        duplicatetoggle={() => setDuplicateBrief(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='container-fluid brief-container !px-4 md:!px-12' onClick={() => { setexpandheader(false) }}>
              <div className='flex flex-col w-full overflow-hidden'>
                <div className='brief-details'>
                  <div className='brief-details-row'>
                    <SimpleTextAndTitle title="Brief Owner" bodyText={briefDetails?.owner ? `${briefDetails?.owner[0].firstName} ${briefDetails?.owner[0].lastName}` : 'Not Selected'} endIcon={<img src={editIcon} onClick={() => editHeaderDetails()} />} />

                    <SimpleTextAndTitle title="Company Name" bodyText={briefDetails?.companyName} endIcon={<img src={editIcon} onClick={() => editHeaderDetails()} />} />

                    <div className='brief-element-wrapper'>

                      <SimpleTextAndTitle title="Company Country" bodyText={briefDetails?.companyCountry ? briefDetails.companyCountry[0].countryName : 'Not Selected'} startIcon={<Emoji className='brief-flag' codePoint={getFlagEmoji(briefDetails?.companyCountry ? briefDetails.companyCountry[0].countryISO : '')} />} />
                      <SimpleTextAndTitle title="Brief Currency" bodyText={briefDetails?.companyCurrency || 'Not Selected'} endIcon={<img src={editIcon} onClick={() => editHeaderDetails()} />} />
                    </div>
                    <div className='brief-industry-container'>
                      <h3 style={{ fontSize: '18px', paddingBottom: '10px' }}>Industry</h3>
                      <div className='brief-tag-list'>
                        {briefDetails?.industry?.map(i => {
                          return (
                            <div className='brief-badge' key={i}>

                              <p style={{ marginRight: '4px' }}>{i.industryName}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className='brief-details-row'>
                    <SimpleTextAndTitle title="HQ Contributors" bodyText={briefDetails?.contributors ? renderContributors(briefDetails?.contributors) : 'Not Selected'} />
                    <SimpleTextAndTitle title="Expected Start Date" bodyText={moment(briefDetails?.briefExpectedStartDate).format('YYYY-MM-DD') || currDate} startIcon={<img className='calendar-icon' src={calendarIcon} />} endIcon={<img src={editIcon} onClick={() => editHeaderDetails()} />} />
                    <SimpleTextAndTitle title="Proposal Due Date" bodyText={moment(briefDetails?.briefProposalDueDate).format('YYYY-MM-DD') || currDate} startIcon={<img className='calendar-icon' src={calendarIcon} />} endIcon={<img src={editIcon} onClick={() => editHeaderDetails()} />} />
                    <a onClick={handlePricingTableView} className='brief-budget-link'><p>Budget and fee allocation</p><img src={chevronRight} /></a>
                  </div>

                </div>

                <EditBriefForm briefDetails={briefDetails} refreshBrief={() => {
                  getBriefById(briefId);
                }} />
                <h2 className='brief-deliverables-header'>Mash-Up Team</h2>
                <div style={{ width: '97%' }}>
                  <MasherTeamUpTable briefData={briefDetails} refreshBrief={() => {
                    getBriefById(briefId);
                  }} />
                </div>
                <h2 className='brief-deliverables-header mt-8'>Supporting Documents</h2>
                <div style={{ width: '97%' }}>

                  {!isfile && (
                    <div className='w-full bg-white rounded-xl flex justify-center items-center h-20' {...getRootProps()}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <div className='flex flex-row items-center text-center'>
                          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" /></div>
                          <p className='font-semibold text-[#00A46F] ml-6'>Drop here</p>
                        </div>
                      ) : (
                        <div className='flex flex-row items-center text-center'>
                          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]'><i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" /></div>
                          <p className='font-semibold text-[#00A46F] ml-6'>Click to upload <span className='text-[#667085] font-normal hidden md:block'>or drag and drop</span></p>
                        </div>
                      )}
                    </div>
                  )}
                  {(uploadPercentage?.length > 0 && isfile) && (
                    <div>
                      {uploadPercentage.map((progress, index) => (
                        <div key={index}>
                          <FileUploadComponent name={filename[index]} filesize={filesize[index]} percentage={progress} isfileerror={fileiserror[index]} />
                        </div>
                      ))}
                    </div>
                  )}



                  {
                    formik.values.attachments && (
                      <div>
                        {formik.values.attachments?.length > 0 &&
                          formik.values.attachments.map((attachment, index) => (
                            <div key={index} className='w-full rounded-xl'>
                              <div>
                                <FilePreviewComponent
                                  name={attachment.fileName}
                                  filesize={attachment.fileSize}
                                  url={attachment.filePath}
                                  id={attachment._id}
                                  Deletefun={Deletefun}
                                  filedeleteloader={filedeleteloader[index]}
                                  setFileDeleteLoader={setFileDeleteLoader[index]}
                                  index={index}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    )
                  }
                </div>
              </div>

              <div className='brief-chat-window rounded-md overflow-hidden hidden'>
                {
                  currentUser !== '' ?
                    <MasherChat loggeduser={loggedInUser} mashers={briefDetails?.deliverables?.flatMap(d =>
                      d.role?.flatMap(r =>
                        r.Masher?.map(m => ({ id: m.masherId, name: m.masherName.trim(), avatar: <Avatar key={m.masherId} name={`${m.firstName} ${m.lastName}`} id={m?.masherId} borderColor='white' /> }))
                      )
                    ).reduce((unique, masher) => {
                      if (!unique.find(u => u?.id === masher?.id)) {
                        unique.push(masher);
                      }
                      return unique;
                    }, []) || []} briefOwnerId={briefDetails?.owner ? briefDetails.owner[0].userId : []} /> :
                    <></>
                }
              </div>

            </div>
          </AdminLayout>

          {editDetails &&
            <SimpleModal title='Edit Brief Details' description='All fields are mandatory' closeModal={editHeaderDetails}>
              <EditBrief briefDetails={briefDetails} closeModal={editHeaderDetails} />
            </SimpleModal>
          }
          {duplicateBrief &&
            <SimpleModal title='Duplicate Brief' description='Are you sure you want to create a duplicate of this brief?' closeModal={toggleDuplicateBriefModal} noDivider={true}>
              <DuplicateBrief briefData={briefDetails} closeModal={toggleDuplicateBriefModal} />
            </SimpleModal>}
          {exportPdf &&
            <SimplePrintModal modalWidth='860px' onClickEvent={() => {
              toggleExportPdfModal();
            }}>
              <BriefPDFView brief={briefDetails} />
            </SimplePrintModal>}
          {pricingTableViewable &&
            <SimpleModal title='Budget and fee allocation' description='' closeModal={handlePricingTableView}>
              <PricingTable deliverables={3} briefData={briefDetails} closeModal={handlePricingTableView} roles={'helle'} refreshBrief={() => {
                getBriefById(briefId);
              }} />
            </SimpleModal>
          }

          {shareBrief &&
            <SimpleModal title='Share Brief' closeModal={() => setShareBrief(false)} >
              <ShareBrief briefId={briefId} deliverables={briefDetails?.deliverables} closeModal={() => setShareBrief(false)} />
            </SimpleModal>
          }
        </CollabProvider>
      </EditFormContext.Provider>
    </>
  );
};

export default EditBriefIndex;
