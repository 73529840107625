import moment from 'moment';
import React, { useEffect } from 'react';

const ExtendModal = ({ extendHours, setExtendHours, _i, el, handleExtendTime1, time }) => {
  const duration1 = moment.duration(time, 'hours');
  let days = duration1?._data.days;
  let hours = duration1?._data.hours;

  useEffect(() => {
    setExtendHours({ days, hours });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <div className='modal fade' id={'resetTime' + _i} tabIndex={-1} aria-labelledby='resetTime' aria-hidden='true'>
      <div className='modal-dialog modal-lg vh-100'>
        <div className='modal-content shadow-3'>
          <div className='modal-header '>
            <div className='icon icon-shape rounded-3 bg-soft-primary text-lg me-4 popIcon '>
              <big>🕐</big>
            </div>
            <div>
              <h5 className='mb-1'>Extend time</h5>
            </div>
            <div className='ms-auto'>
              <i className='bi bi-x-lg cursor-pointer' data-bs-dismiss='modal'></i>
            </div>
          </div>
          <div className='modal-body'>
            <div className='row d-flex align-items-center justify-content-center'>
              <div className='col-2'>
                <label className='form-label ps-2'>Days</label>

                <input
                  type='number'
                  value={extendHours.days}
                  name='days'
                  onChange={(e) => setExtendHours((v) => ({ ...v, days: parseInt(e.target.value) }))}
                  className='form-control'
                  style={{ padding: '0.5rem' }}
                />
              </div>
              <div className='col-auto p-0'>
                <span>
                  <p className='mb-0 mt-5' style={{ fontWeight: '900', fontSize: '20px' }}>
                    :
                  </p>
                </span>
              </div>
              <div className='col-2'>
                <label className='form-label ps-2'>Hours</label>
                <input
                  type='number'
                  className='form-control'
                  style={{ padding: '0.5rem' }}
                  value={extendHours.hours}
                  name='hours'
                  onChange={(e) => setExtendHours((v) => ({ ...v, hours: parseInt(e.target.value) }))}
                />
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' data-bs-dismiss='modal' className='btn btn-sm btn_primary_black' onClick={() => handleExtendTime1(el?._id)}>
              Extend
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtendModal;
