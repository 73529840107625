import { useEffect, useState } from 'react';
import { SearchBox, InstantSearch, InfiniteHits, RefinementList, SortBy, Configure, useInstantSearch} from 'react-instantsearch';
import { getAllMashersApi } from '../../apis';
import { EditProfileLoader } from '../common/loader';
import MashCard from '../cards/MashCard';
import './globalSearch.css';

export default function TypesenseMasherListComponent({searchClient,currentid,setcurrentid}) {

  const [mashersFromApi, setMashersFromApi] = useState([]);
  const [showFilterForMasherApproved, setShowFilterForMasherApproved] = useState(false);
  const [searchWindowClassName, setSearchWindowClassName] = useState('search-box-visible');
  const toggleMasherApprovedOpen = () => {setShowFilterForMasherApproved(!showFilterForMasherApproved);};

  const getMashersFromApi = async (...search) => {
    try {
      const res = await getAllMashersApi(...search);
      if (res.status === 200) {
        setMashersFromApi(res.data.data);
        
      }
    } catch (error) {
      console.error('Api call failed', error);
    }
  };
  let searchFilter = {
    search: '',
    skills: '',
    minExperience: '',
    maxExperience: '',
    location: '',
    page: 10,
    limit: 20,
    status: '',
    industryType: '',
    clientsWorkedFor: '',
    startDate: '',
    dueDate: '',
    fieldName: '',
    orderBy: ''
  };
  
  useEffect(() => {
    getMashersFromApi({...searchFilter});
  }, []);

  const LoadingIndicator = () => {
    const { status } = useInstantSearch();
    return status === 'loading' || status === 'stalled' ? <EditProfileLoader/> : null;
  };

  const NoResultsBoundary = ({ children, fallback }) => {
    const { results, error } = useInstantSearch();
    if (error) {
      setSearchWindowClassName('search-window-hidden');
      return (
        <div>
          <span className='d-flex align-items-center'><h5 className='m-1'>Note: </h5><p className='m-1'> Use Control/Command + F to search for a masher</p></span>
          {
            mashersFromApi?.map((masher, i) => {
              return <MashCard hit={masher} key={i}/>;
            })
          }
        </div>);
    }  
     
    if (!results.__isArtificial && results.nbHits === 0) {
      setSearchWindowClassName('search-window-visible');
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      );
    }
    setSearchWindowClassName('search-window-visible');
    return children;
  };
  
  const NoResults = () => {
    const { indexUiState } = useInstantSearch();
    return (
      <div className='no-results-container'>
        <p>
          No results for <q>{indexUiState.query}</q>.
        </p>
      </div>
    );
  };
  const refreshmasher=()=>{
    getMashersFromApi({...searchFilter});
  }
  
  return(
    <div className="col-12 search-window">
      <div className='global-search'>
        <InstantSearch 
          indexName='masherWithUser'
          searchClient={searchClient}
          routing={true}
        >
          <Configure hitsPerPage={15} />
          <main className={`${searchWindowClassName} search-box-header`}>
            <div className='search-box-container !px-4 md:!px-12'>
              <SearchBox placeholder="Start typing to make the magic happen" />
              <span className="search-box-options-text">You can use any combination of keywords, e.g. “art direction Singapore” or “photography technology Netflix”.</span>
            </div>
              
            <div className="filter-sort-container">
              <div className="search-card-sort-container">
                <label>Sort by</label>
                <SortBy
                  classNames={{ option: 'btn btn_primary sort-dropdown' }}
                  defaultrefinement="masherWithUser"
                  items={[
                    { label:'latest', value: 'masherWithUser/sort/createdAt:desc'},
                    { label:'earliest', value: 'masherWithUser/sort/createdAt:asc'},
                  ]}
                />
              </div>
            </div>
          </main>
          <NoResultsBoundary fallback={<NoResults />}>
            <div className="align-items-start search-container">
              <LoadingIndicator/>
              <div className="search-card-main-window-masher overflow-auto">
                <InfiniteHits className='search-row list-style-masher' hitComponent={(hit) => <MashCard {...hit} currentid={currentid} setcurrentid={setcurrentid} refreshmasher={refreshmasher} />} />
              </div>
            </div>
          </NoResultsBoundary>
        </InstantSearch>
      </div>
    </div>
    
  );
    
  
}