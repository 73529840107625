import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './notify.css';
const Notification = ({ id, text, entityId, usertoken }) => {
  const [app, setApp] = useState('');
  const getApp = async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_WEAVY_URL+'/api/messages/'+entityId,
      headers: { 
        'Authorization': 'Bearer '+usertoken
      }
    };
      
    axios.request(config)
      .then((response) => {
        //console.log('Response inside notify'+response.data.app_id);
        let appid = response.data.app_id;
        let data = JSON.stringify({
          'order_by': 'name'
        });

        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: process.env.REACT_APP_WEAVY_URL+'/api/apps/'+appid,
          headers: { 
            'Authorization': 'Bearer '+usertoken, 
            'Content-Type': 'application/json'
          },
          data : data
        };

        axios.request(config)
          .then((appResponse) => {
            const a = appResponse.data.uid;            
            const appuid= a.split('-');
            if(appuid[1] == 'briefs'){
              setApp('/briefs/edit-brief/'+appuid[0]);
            }
            else if(appuid[1] == 'projects'){
              setApp('/projects/project-view/'+appuid[0]);
            }
          })
          .catch((error) => {
            console.log(error);
          });

        
      })
      .catch((error) => {
        console.log(error);
      });

  };

  const markAsRead = () => {
    console.log('marked as read');

    const mark = async () => {

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_WEAVY_URL+'/api/notifications/'+id+'/mark',
        headers: { 
          'Authorization': 'Bearer '+usertoken, 
          'Content-Type': 'application/json'
        }
      };
  
      axios.request(config)
        .then((response) => {
          
        })
        .catch((error) => {
          console.log(error);
        });
    };
    mark();
  };

  useEffect(() => {
    getApp();
  }, []);

  return (
    <>
      {app &&
            <Link to={app} className="list-group-item" onClick={markAsRead}>{text}</Link>
      }
    </>
  );
};

export default Notification;