import React, {useState} from 'react';
import Avatar from '../../../components/Avatar/Avatar';
import useOpenController from '../../../Hooks/useOpenController';
import ExpandableButton from '../../Briefs/EditBrief/components/ExpandableButton';


function PricingCardProject({deliverable, index, updateNewRoleCost}) {
  const { deliverableName } = deliverable;
  const { isOpen, toggle } = useOpenController(false);
  const [opened, setOpened] = useState([]);
  const [roleCost, setRoleCost] = useState(deliverable.role[0]?.suggestedFeeAllocation, index);

  const updateRoleCost = (e) => {
    setRoleCost(e.target.value, index);
    updateNewRoleCost(index, e.target.value || 0);
  };

  const badgeClass = (v) => {
    if(v.toString() === 'pending') return 'pending';
    if(v === 'accepted' || v === 'applied') return 'applied';
    if(v === 'skipped') return 'skipped';
  };
  return (
    deliverable.role.map((role, j) => {
      return (
        <div className='pricing-card'>
          <div className="pricing-card-header">
            <div className='pricing-card-left-detail' style={{display: 'flex', alignItems: 'center'}}>
              <ExpandableButton isOpen={opened.findIndex((o) => o === deliverable.role[j].roleId) > -1} toggle={() => {
                toggle();
                setOpened((prev) => {
                  let index = opened.findIndex((o) => o === deliverable.role[j].roleId);
                  if (index < 0) {
                    prev.push(deliverable.role[j].roleId);
                  } else {
                    prev.splice(index, 1);
                  }

                  return prev;
                });
              }}/>
              <div>
                <h4 style={{marginBottom: '10px'}}>{deliverableName}</h4>
                <p>{deliverable.role[j]?.roleName}</p>
              </div>
            </div>
            {!(opened.findIndex((o) => o === deliverable.role[j].roleId) > -1) && <div className='pricing-card-masher-avatars'>
              {
                deliverable.role[j]?.Masher.map((masher, i) => {
                  return (
                    <div key={i} className='pricing-card-avatar-list'>
                      <Avatar id={masher.masherId} name={masher.masherName} url={masher.masherAvatarUrl}/>
                    </div>
                  );
                })
              }
            </div>}
            <div className='pricing-card-role-cost'>

              <p>{roleCost}.00</p>
            </div>
          </div>
          {opened.findIndex((o) => o === deliverable.role[j].roleId) > -1 && 
            <div className='pricing-card-body'>
              <div className='pricing-card-body-block'>
                {
                  deliverable.role[j]?.Masher.map((masher, i) => {
                    return (
                      <div key={i} className='pricing-card-masher-info'>
                        <div className='pricing-card-masher-details'>
                          <Avatar id={masher.masherId} name={masher.masherName} url={masher.masherAvatarUrl}/>
                          <div className='pricing-card-mash-cost'>
                            <label className='form-label' style={{minWidth: 'fit-content', marginRight: '10px'}}>{masher.masherName}</label>
                          </div>
                        </div>
                        
                        <div style={{width: '100px'}}>
                          <div className='pricing-card-badge'><p>{masher.preference}</p></div>
                        </div>
                        <p className='pricing-card-masher-price' style={{fontSize: 'small'}}>{masher?.masherQuote}.00</p>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          }

        </div>
      );
    })
  );
}

export default PricingCardProject;