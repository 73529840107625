import React, { useEffect, useState, useContext } from 'react';
const QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;
import moment from 'moment';
import { fetchIndustryApi } from '../../apis';
import SimpleTextAndTitle from '../../components/text-boxes/SimpleTextAndTitle';
import { Loader } from '../../components/common/loader';
import logo from '../../Icons/logo.png';
import { CollabContext } from './EditBrief/providers/CollabProvider';
import './briefs.css';

export default function BriefPDFView({brief}) {
  const { liveBrief, setLiveBrief } = useContext(CollabContext);
  const [industries, setIndustries] = useState([]);
  const briefDt = brief;

  const getCompanyIndustries = async () => {
    const returnedIndustries = await fetchIndustryApi();
    setIndustries(returnedIndustries?.data);
  };
  
  useEffect(() => {
    getCompanyIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyIndustries = () => {
    return industries.filter(industry => industry.id === briefDt.industry);
  };

  return (
    <>
      {briefDt ? (
        <div className='brief-pdf-page'>
          <div className='brief-pdf-header'>
            <img src={logo}/>
            <h2 style={{marginLeft: '30px', fontSize: '28px'}}>{briefDt?.name}</h2>
          </div>
          <main>
            <div className='container-fluid'>
              <div className='brief-details'>
                <div className='brief-details-row'>
                  <SimpleTextAndTitle title="Brief Owner" bodyText={briefDt.owner ? `${briefDt.owner[0].firstName} ${briefDt.owner[0].lastName}` : 'Not Selected'} />
                  <SimpleTextAndTitle title="Company Name" bodyText={briefDt.companyName} />
                  <SimpleTextAndTitle title="Company Country" bodyText={briefDt.companyCountry ? briefDt.companyCountry[0].countryName : 'Not Selected'} />
                </div>
                <div className='brief-details-row'>
                  <SimpleTextAndTitle title="Brief Currency" bodyText={briefDt.companyCurrency} />
                  <SimpleTextAndTitle title="Expected Start Date" bodyText={moment(briefDt?.briefExpectedStartDate).format('YYYY-MM-DD')} />
                  <SimpleTextAndTitle title="Expected End Date" bodyText={moment(briefDt?.briefProposalDueDate).format('YYYY-MM-DD')} />
                </div>
              </div>
              <div className='brief-industry-container'>
                <h3 style={{fontSize: '18px', paddingBottom: '10px'}}>Industry</h3>
                {briefDt?.industry?.map((industry, i) => {
                  return (
                    <div className='brief-badge' key={i}>{industry.industryName}</div>
                  );
                })}
              </div> 
              <SimpleTextAndTitle 
                title='How did this brief come about?'
                bodyText={
                  <div className='converted-html-field-print' dangerouslySetInnerHTML={{__html: liveBrief?.howDidThisBriefComeAbout}}></div>
                  || 'N/A'
                }
              />
              <SimpleTextAndTitle 
                title='What’s the brief? Briefly.'
                bodyText={
                  <div className='converted-html-field-print' dangerouslySetInnerHTML={{__html: liveBrief?.whatsTheBrief}}></div>
                  || 'N/A'
                }
              />
              <SimpleTextAndTitle 
                title='What challenges do we need to overcome?'
                bodyText={
                  <div className='converted-html-field-print' dangerouslySetInnerHTML={{__html: liveBrief?.whatChallengesDoWeNeedToOvercome}}></div>
                  || 'N/A'
                }
              />
              <SimpleTextAndTitle 
                title='What are we trying to achieve?'
                bodyText={
                  <div className='converted-html-field-print' dangerouslySetInnerHTML={{__html: liveBrief?.whatAreWeTryingToAchieve}}></div>
                  || 'N/A'
                }
              />
              <p className="brief-titles">Who are we trying to reach?</p>
              <div className='brief-tags'>
                <div className='brief-tag-content'>
                  <h4>Subculture:</h4>
                  <div className='brief-tag-list'>
                    {briefDt?.subculture?.map((culture, index) => {
                      return (
                        <div className='brief-badge' key={index}>
                          <p style={{height: '20px'}}>{culture?.subcultureName}</p>   
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className='brief-tag-content'>
                  <h4>Audience Country:</h4>
                  <div className='brief-tag-list'>
                    {briefDt?.audienceCountry?.map((country, index) => {
                      return (
                        <div className='brief-badge' key={index}>
                          <p style={{height: '20px'}}>{country?.countryName}</p>   
                        
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <SimpleTextAndTitle 
                bodyText={
                  <div className='converted-html-field-print'  style={{paddingBottom: '20px',}} dangerouslySetInnerHTML={{__html: liveBrief?.whoAreWeTryingToReach}}></div>
                  || 'N/A'
                }
              />
              <SimpleTextAndTitle 
                title='What’s the special sauce of this brand or product?'
                bodyText={
                  <div className='converted-html-field-print' dangerouslySetInnerHTML={{__html: liveBrief?.experience}}></div>
                  || 'N/A'
                }
              />
              <h4 style={{fontSize: '28px', marginTop: '25px'}} className='brief-pdf-titles'>Deliverables</h4>
              {briefDt?.deliverables
                ?.map((el, i) => {
                  return (
                    <SimpleTextAndTitle key={i} title={el.deliverableName} bodyText={    
                      el.deliverableDescription === '' ?
                        'N/A' :          
                        <div className='converted-html-field-print' dangerouslySetInnerHTML={{__html: el.deliverableDescription}}></div>
                    } />
                  );
                })}
            </div>
          </main>
          {/* <div className='brief-pdf-header'>
            <img src={logo}/>
            <h1 style={{marginLeft: '30px', fontSize: '28px'}}>{briefDt?.name}</h1>
          </div> */}
        </div>
      ) : (
        <div className='text-center p-4'>
          <Loader />
        </div>
      )}
    </>
  );
}
