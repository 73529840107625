import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Loader } from '../../../../components/common/loader';
import { InputErrorMessage } from '../../../../components/errorMessages';
import { addSubcultureV2 } from '../../../../apis/index';
import { EditProfileLoader } from '../../../../components/common/loader';

export default function AddTag({ briefId, tagType, closeModal }) {
  const [updating, setUpdating] = useState(false);
  const [roles, setRoles] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const addRoleSchema = yup.object({
    tagName: yup.string().required()
  });

  const formik = useFormik({
    initialValues: {
      tagName: '',
    },
    validationSchema: addRoleSchema,
    onSubmit: async (values) => {
      try {
        setUpdating(true);

        const res = await addSubcultureV2({
          id: briefId,
          subculture: values.tagName
        });
        setUpdating(false);

        if (res.status === 200 || 201) {
          toast.success(res?.data?.message, { id: '001' });          
          closeModal();
        } else {

        }
      } catch(e) {
        closeModal();
      }
    },
  });
  
  const handleChange = (e) => {
    formik.handleChange(e);
  };

  return (
    <>    
    {loading ? (
        <div className='text-xs text-center'>
          <Loader />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div>
              <label className='form-label'>
                {tagType}
              </label>
              <input
                type='text'
                className={'form-control'}
                placeholder='Enter Tag'
                name='tagName'
                value={formik.values.tagName}
                onChange={handleChange}
              />
              <InputErrorMessage error={formik.touched.tagName} marginBottom={-5} />
            </div>
            <div className='brief-button-container'>
              <button type='button' className='button-close' style={{width: '100%'}} onClick={closeModal}>
                Cancel
              </button>
              <button type='submit' className='button-primary' disabled={updating} style={{width: '100%'}}>
                {updating ? (
                  <div className='text-xs text-center'>
                    <EditProfileLoader />
                  </div>
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </div>
        </form>
      )
    }
    </>
  );
}
