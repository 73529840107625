
import React from 'react';
import './pageHeader.css';
import BriefStatus from '../../Brief/BriefStatus';

function PageHeader({title, buttonsArray, briefId, briefStatus }) {
  return (
    <div className='page-header'>
      <div className='page-header-right'>
        <h2 style={{fontSize: '32px'}}>{title}</h2>
        { briefId ? <BriefStatus briefId={briefId} briefStatus={briefStatus} /> : ''}
      </div>
      {buttonsArray && 
      <div className='page-header-left'>
        {buttonsArray?.map((link, i) => {
          return <div key={i}>{link}</div>;
        })}
      </div>
      }
    </div>
  );
}

export default PageHeader;