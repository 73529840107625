// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
  display: flex;
  flex-wrap: nowrap;
  background-color: #F2F4F7;
  color: #344054;
  border-radius: 16px;
  padding: 2px 8px;
  font-size: 12px;
  margin: 6px;
  font-weight: 500
}`, "",{"version":3,"sources":["webpack://./src/components/tags/tag.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX;AACF","sourcesContent":[".tag {\n  display: flex;\n  flex-wrap: nowrap;\n  background-color: #F2F4F7;\n  color: #344054;\n  border-radius: 16px;\n  padding: 2px 8px;\n  font-size: 12px;\n  margin: 6px;\n  font-weight: 500\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
