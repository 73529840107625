import React, {useState} from 'react';
// import {dummyBriefData as briefData} from './dummyBriefData';
import PricingCard from './PricingCardProject';
import './pricingTable.css';


export default function PriceTableProject({briefData}) {
  const [deliverables, setDeliverables] = useState(briefData?.deliverables);
  const [margin, setMargin] = useState(briefData?.markupPercentage);

  function getFlagEmoji(countryCode) {
    if (countryCode) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char =>  127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    }
    return 'N/A';
  }

  const getBriefTotal = () => {
    let sum = 0;
    for (let i = 0; i < deliverables?.length; i++){
      sum += parseInt(deliverables[i]?.role[0]?.suggestedFeeAllocation);
    }
    let marginL = sum * (margin/100);
    return sum + marginL;
  };

  const handleMarginChange = (e) => {
    setMargin(e.target.value);
  };

  const updateNewRoleCost = (delId, value) => {
    const update = deliverables?.map(deliverable => deliverable?.deliverableId === delId 
      ? {...deliverable, role: [{...deliverable?.role[0], suggestedFeeAllocationTotal: value}]}
      : deliverable);
    setDeliverables(update);
  };

  return (
    <div style={{width: '95%'}}>
      <div className='pricing-table-header-row'>
        <p style={{marginRight: '10px'}}>Add suggested pricing below in: </p>
        <div className='pricing-table-badge'>
          <p style={{marginLeft: '5px', fontSize: 'small'}}>{briefData?.companyCurrency}</p>
        </div>

      </div>
      <div className='pricing-table-card-container'>
        {
          deliverables?.map((deliverable, i) => {
            return <PricingCard deliverable={deliverable} index={deliverable?.deliverableId} updateNewRoleCost={updateNewRoleCost} key={i} />;
          })
        }
      </div>
      <div className='pricing-table-margin-row'>
        <div className='pricing-table-margin-box-project'>
          <p>{briefData?.feeAllocation[0]?.markupPercentage}% Markup</p>
        </div>
      </div>
      <div className='pricing-table-margin-row'>
        <div className='pricing-card-total-cost'>
          <p className='pricing-card-input'>{Math.ceil(parseFloat(briefData?.feeAllocation[0]?.totalCost))}</p>
          <p>.00</p>
        </div>
      </div>
    </div>
  );

}