import React, { useEffect, useState, createContext } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useLocation } from 'react-router-dom';
import { editClientApi, getAllClientsApi, getAllArchiveApi } from '../../apis';
import AdminLayout from '../../components/admin-layout';
import ClientTable from './BrandTable';
import ArchivedTable from './BrandArchivedTable';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import PageHeader from '../../components/pageElements/pageHeader/PageHeader';
import plusIcon from '../../Icons/plusIcon.svg';
export const BrandContext = createContext();

export default function Index() {
  const location = useLocation();
  const [client, setClient] = useState({ data: null, loading: false });
  const [archiveClient, setArchiveClient] = useState({ data: null, loading: false });
  const [searchFilter, setSearchFilter] = useState({ search: '', page: 1, limit: 5, industry: '', type: '', region: '', fieldName: '', orderBy: '' });
  const [pageCount, setPageCount] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('ASC');
  const [step, setStep] = useState(location.state === null ? 1 : 2);

  const allClientData = async (search, limit, page, industry, type, region, fieldName, orderBy) => {
    setClient({ loading: true });
    try {
      const res = await getAllClientsApi(search.trim(), limit, page, industry, type, region, fieldName, orderBy);
      if (res.status === 200) {
        setClient({ data: res.data.allClients, loading: false });
        setPageCount(res?.data?.totalPages);
      }
    } catch (error) {
      setClient({ loading: false });
    }
  };

  const allArchiveData = async (search, limit, page, industry, type, region, fieldName, orderBy) => {
    setArchiveClient({ loading: true });
    try {
      const res = await getAllArchiveApi(search.trim(), limit, page, industry, type, region, fieldName, orderBy);
      if (res.status === 200) {
        setArchiveClient({ data: res.data.allClients, loading: false });
        setPageCount(res?.data?.totalPages);
      }
    } catch (error) {
      setArchiveClient({ loading: false });
    }
  };

  const deleteBrand = async (values) => {
    try {
      const res = await editClientApi(values);
      if (res.status === 200 || res.status === 201) {
        toast.success(res?.data?.message, { id: '001' });
        const { search, limit, page } = searchFilter;
        allClientData(search, limit, page);
      }
    } catch (error) {
      toast.error(error?.data?.response?.message);
    }
  };
  const handleChange = (name, value) => {
    setSearchFilter({ ...searchFilter, [name]: value });
  };

  const handleChange1 = (name, value) => {
    setSearchFilter({ ...searchFilter, [name]: value, page: 1 });
  };
  const handlePageClick = ({ selected }) => {
    handleChange('page', selected + 1);
  };

  const handleSortingChange = (accessor) => {
    if (accessor) {
      const currentOrder = order === 'ASC' ? 'DESC' : 'ASC';
      setOrder(currentOrder);
      setSortField(accessor);
    }
  };

  useEffect(() => {
    const { search, limit, page, industry, type, region, fieldName, orderBy } = searchFilter;
    const timer = setTimeout(() => {
      if (step === 1) {
        allClientData(search, limit, page, industry, type, region, fieldName, orderBy);
      }
      if (step === 2) {
        allArchiveData(search, limit, page, industry, type, region, fieldName, orderBy);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [step, searchFilter]);

  useEffect(() => {
    if (order && sortField) {
      const sortOrder = order === 'ASC' ? 1 : -1;
      setSearchFilter({ ...searchFilter, fieldName: sortField, orderBy: sortOrder });
    }
  }, [order, sortField]);



  return (
    <AdminLayout>
      <BrandContext.Provider
        value={{
          client,
          handleChange,
          pageCount,
          handlePageClick,
          deleteBrand,
          handleSortingChange,
          archiveClient

        }}>
        <PageHeader title='Customers' buttonsArray={[
          <SimpleButton
            title='Add Customer' 
            icon={plusIcon}
            altText='add customer'
            link='/brands/create-brand'
            buttonType='primary'
            buttonFunction='link'
          />
        ]}/>
        <div className='container-fluid'>
          {/* <div className='col-sm-12 head-text'>
            <p>It's not just what you know, it's who you know.</p>
          </div> */}
          <article>
            <div className='border-bottom pt-2'>
              <ul className='nav nav-tabs overflow-x border-0'>
                <li className='nav-item'>
                  <button
                    className={(step === 1 && 'nav-link active') || 'nav-link'}
                    onClick={() => {
                      setStep(1);
                      setSearchFilter({
                        ...searchFilter, page: 1,
                      });
                    }}>
                    Active
                  </button>
                </li>
                <li className='nav-item'>
                  <button
                    className={(step === 2 && 'nav-link active') || 'nav-link'}
                    onClick={() => {
                      setStep(2);
                      setSearchFilter({
                        ...searchFilter, page: 1,
                      });
                    }}>
                    Archived
                  </button>
                </li>
              </ul>
            </div>
            <main className='pt-6'>
              {/* Container */}
              <div className=''>
                <div className='vstack gap-4 mb-4'>
                  {/* Filters */}
                  <div className='d-flex justify-content-between flex-column flex-sm-row gap-3'>
                    <div className='hstack gap-3'>
                      {/* Search */}
                      <div className='input-group input-group-sm input-group-inline'>
                        <span className='input-group-text pe-2'>
                          <i className='bi bi-search' />
                        </span>
                        <input
                          value={searchFilter.search}
                          onChange={(event) => handleChange1('search', event.target.value)}
                          type='text'
                          className='form-control'
                          placeholder='Search by Brand'
                          aria-label='Search'
                        />
                      </div>
                      <div className='input-group input-group-sm input-group-inline'>
                        <span className='input-group-text pe-2'>
                          <i className='bi bi-search' />
                        </span>
                        <input
                          value={searchFilter.industry}
                          onChange={(event) => handleChange1('industry', event.target.value)}
                          type='text'
                          className='form-control'
                          placeholder='Search by Industry'
                          aria-label='Search'
                        />
                      </div>
                      <div className='input-group input-group-sm input-group-inline'>
                        <span className='input-group-text pe-2'>
                          <i className='bi bi-search' />
                        </span>
                        <input
                          value={searchFilter.type}
                          onChange={(event) => handleChange1('type', event.target.value)}
                          type='text'
                          className='form-control'
                          placeholder='Search by Client Type'
                          aria-label='Search'
                        />
                      </div>
                      <div className='input-group input-group-sm input-group-inline'>
                        <span className='input-group-text pe-2'>
                          <i className='bi bi-search' />
                        </span>
                        <input
                          value={searchFilter.region}
                          onChange={(event) => handleChange1('region', event.target.value)}
                          type='text'
                          className='form-control'
                          placeholder='Search by Region'
                          aria-label='Search'
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              {step === 1 && <ClientTable
                client={client}
                handleChange={handleChange}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                deleteBrand={deleteBrand}
                handleSortingChange={handleSortingChange}
              />}
              {step === 2 && <ArchivedTable
                archiveClient={archiveClient}
                handleChange={handleChange}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                deleteBrand={deleteBrand}
                handleSortingChange={handleSortingChange}
              />}
            </main>
          </article>
        </div>
      </BrandContext.Provider>
    </AdminLayout>
  );
}
