import React, { useContext, useRef } from 'react';
import Select from 'react-select';
import ProfileDefault from '../../../Assets/img/covers/userDefault.png';
import { Loader } from '../../../components/common/loader';
import { InputErrorMessage } from '../../../components/errorMessages';
import { assignBucketUrl, autoHeightText, brandOptions, helperImageUpload, style, getClient } from '../../../utils/helperFunctions';
import { ConvertToProjectContext } from '../../../appContext';

export default function ConvertProject1() {
  const ProjectContext = useContext(ConvertToProjectContext);
  const inputRef = useRef(null);
  const { formik, client, value, setValue, brief, formSelects, briefData } = ProjectContext;

  const handleFileUpload = (event) => {
    helperImageUpload(formik, event);
  };

  const briefOptions = brief?.data?.map((item) => {
    const container = {};
    container['value'] = item._id;
    container['label'] = item.briefName;
    return container;
  });
  const options = () => {
    return client ? brandOptions(client) : false;
  };

  const handleChange = (e) => {
    formik.handleChange(e);
  };

  return (
    <>
      <div className='col-md-12 mb-5'>
        <label className='form-label'>
          Select Brief <span className='text-danger'> *</span>{' '}
        </label>
        <Select
          styles={style}
          onChange={(e) => setValue({ value: e.value, label: e.label })}
          name='briefId'
          options={briefOptions}
          className={
            Boolean(formik.touched?.briefId) && Boolean(formik.errors?.briefId)
              ? 'p-0 form-control border-danger basic-multi-select'
              : 'p-0 form-control basic-multi-select '
          }
          value={value}
          classNamePrefix='select'
        />
      </div>
      {briefData.loading ? (
        <div className='text-xs text-center'>
          <Loader />
        </div>
      ) : (
        <div>
          {value ? (
            <form onSubmit={formik.handleSubmit}>
              <div className='row'>
                <div className='col-md-6 mb-5'>
                  <label className='form-label'>
                    Brands<span className='text-danger'> *</span>
                  </label>
                  <Select
                    styles={style}
                    options={options()}
                    name={'clientName'}
                    className={
                      Boolean(formik.touched?.clientName) && Boolean(formik.errors?.clientName)
                        ? 'form-control border-danger p-015 '
                        : 'form-control p-015'
                    }
                    classNamePrefix='select'
                    value={formik.values.clientName}
                    onChange={(e) => {
                      getClient(e, formik);
                      formik?.setFieldValue('clientName', [{ value: e.value, label: e.label }]);
                    }}
                  />
                  <InputErrorMessage error={formik.touched.clientName && formik.errors.clientName} marginBottom={-5} />
                </div>
                <div className='col-md-6 mb-5'>
                  <label className='form-label'>
                    Brief Name<span className='text-danger'> *</span>
                  </label>
                  <input
                    type='text'
                    className={
                      Boolean(formik.touched?.briefName) && Boolean(formik.errors?.briefName) ? 'form-control border-danger' : 'form-control '
                    }
                    placeholder='Enter Brief Name'
                    name='briefName'
                    value={formik.values.briefName}
                    onChange={handleChange}
                  />
                  <InputErrorMessage error={formik.touched.briefName && formik.errors.briefName} marginBottom={-5} />
                </div>
                <div className='col-md-6 mb-5 ' style={{ position: 'relative' }}>
                  <label className='form-label' style={{ display: 'block' }}>
                    Logo<span className='text-danger'> *</span>
                  </label>

                  <img
                    alt=''
                    onError={(event) => {
                      event.target.src = ProfileDefault;
                      event.onerror = null;
                    }}
                    src={formik?.values?.logo ? assignBucketUrl(formik?.values?.logo) : assignBucketUrl(ProfileDefault)}
                    style={{
                      width: '80px',
                      height: '80px',
                      border: '1px solid rgb(25, 19, 19, 0.25)',
                      borderRadius: '50%',
                      boxShadow: '0px 7px 9px -4px rgba(0,0,0,0.1)',
                      marginRight: '10px',
                    }}
                  />

                  <div className='profileImgUpload d-inline-block  '>
                    <label htmlFor='profile'>
                      <div
                        className='changeFileBtn'
                        onClick={() => {
                          inputRef?.current?.click();
                        }}>
                        Choose File
                      </div>
                    </label>
                  </div>

                  {formik.values?.logo !== '' ? (
                    <div
                      className='removeFileBtn d-inline-block  '
                      onClick={() => {
                        formik.setFieldValue('logo', '');
                      }}>
                      Remove
                    </div>
                  ) : (
                    ''
                  )}
                  <input
                    onChange={handleFileUpload}
                    type='file'
                    title=' '
                    id='profile'
                    className='btn btn-sm btn_primary_black edit-pen2 d-none fileInput'
                    placeholder='Upload'
                    name='logo'
                  />
                  <InputErrorMessage error={formik.touched.logo && formik.errors.logo} marginBottom={-5} />
                </div>
                <div className='col-md-6 mb-5'>
                  <label className='form-label'>
                    Industry<span className='text-danger'> *</span>
                  </label>
                  <select
                    className={Boolean(formik.touched?.industry) && Boolean(formik.errors?.industry) ? 'form-select border-danger' : 'form-select'}
                    name='industry'
                    value={formik.values.industry}
                    onChange={handleChange}>
                    <option hidden>Select Industry</option>
                    {formSelects?.industry &&
                      formSelects?.industry?.map((el, i) => {
                        return (
                          <option key={i} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                  </select>
                  <InputErrorMessage error={formik.touched.industry && formik.errors.industry} marginBottom={-5} />
                </div>
                <div className='col-md-12 mb-5'>
                  <label className='form-label'>
                    Select Region<span className='text-danger'> *</span>
                  </label>
                  <select
                    className={Boolean(formik.touched?.region) && Boolean(formik.errors?.region) ? 'form-select border-danger' : 'form-select'}
                    name='region'
                    value={formik.values.region}
                    onChange={handleChange}>
                    <option hidden>Select Region</option>
                    {formSelects?.region &&
                      formSelects?.region?.map((el, i) => {
                        return (
                          <option key={i} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                  </select>
                  <InputErrorMessage error={formik.touched.region && formik.errors.region} marginBottom={-5} />
                </div>

                <div className='col-md-12 mb-5'>
                  <label className='form-label'>
                    Client Type<span className='text-danger'> *</span>
                  </label>
                  <select
                    className={
                      Boolean(formik.touched?.clientType) && Boolean(formik.errors?.clientType) ? 'form-select border-danger' : 'form-select'
                    }
                    name='clientType'
                    value={formik.values.clientType}
                    onChange={handleChange}>
                    <option hidden>Select Client type</option>
                    {formSelects?.client &&
                      formSelects?.client?.map((el, i) => {
                        return (
                          <option key={i} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                  </select>
                  <InputErrorMessage error={formik.touched.clientType && formik.errors.clientType} marginBottom={-5} />
                </div>

                <div className='col-md-12 mb-5'>
                  <label className='form-label'>
                    Category<span className='text-danger'> *</span>
                  </label>
                  <select
                    className={Boolean(formik.touched?.category) && Boolean(formik.errors?.category) ? 'form-select border-danger' : 'form-select'}
                    name='category'
                    value={formik.values.category}
                    onChange={handleChange}>
                    <option hidden>Select category</option>
                    {formSelects?.category &&
                      formSelects?.category?.map((el, i) => {
                        return (
                          <option key={i} value={el.id}>
                            {el.name}
                          </option>
                        );
                      })}
                  </select>
                  <InputErrorMessage error={formik.touched.category && formik.errors.category} marginBottom={-5} />
                </div>

                <div className='col-md-12 mb-5'>
                  <label className='form-label'>
                    Engagement Structure<span className='text-danger'> *</span>
                  </label>
                  <select
                    className={
                      Boolean(formik.touched?.engagementStructure) && Boolean(formik.errors?.engagementStructure)
                        ? 'form-select border-danger'
                        : 'form-select'
                    }
                    name='engagementStructure'
                    value={formik.values.engagementStructure}
                    onChange={handleChange}>
                    <option> Select Engagement Structure</option>
                    {formSelects?.engagementType &&
                      formSelects?.engagementType?.map((el, i) => {
                        return (
                          <option key={i} value={el?.id}>
                            {el?.name}
                          </option>
                        );
                      })}
                  </select>
                  <InputErrorMessage error={formik.touched.engagementStructure && formik.errors.engagementStructure} marginBottom={-5} />
                </div>

                <div className='col-md-12 mb-5 d-none'>
                  <label className='form-label'>
                    About Brief<span className='text-danger'> *</span>
                  </label>
                  <textarea
                    onKeyUp={(e) => autoHeightText(e)}
                    className='form-control min-h-200px'
                    placeholder='Description of each deliverable/milestone in the project, including with Masher is responsible, the format of deliverable, usage, and number of client reviews in scope'
                    rows={2}
                  />
                </div>
                <div className='col-md-12 mt-3 mb-5'>
                  <button type='submit' className='btn btn-sm btn_primary_black btn-width'>
                    Next
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <div className=' text-center mt-6 '>Please select a brief first</div>
          )}
        </div>
      )}
    </>
  );
}
