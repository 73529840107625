// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-group-item{
    width: 100%;
    word-wrap: break-word;
    white-space: break-spaces;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard/notify.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,qBAAqB;IACrB,yBAAyB;AAC7B","sourcesContent":[".list-group-item{\n    width: 100%;\n    word-wrap: break-word;\n    white-space: break-spaces;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
