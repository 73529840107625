
import { FileEarmark,Check,Download,ExclamationCircleFill,TrashFill } from 'react-bootstrap-icons';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './Filecomponent.css';
import trashIcon from '../../../../Icons/trachIcon.svg';
import { EditProfileLoader } from '../../../../components/common/loader';
export const FileUploadComponent = ({ name, filesize, percentage,isfileerror }) => {
  return (
    <div className={isfileerror ? 'mt-2 file-upload border-danger':'mt-2 file-upload'}>
      <div className='file-preview'>
        <FileEarmark color='#00A46F' size={12} />
      </div>
      <div className='file-name-container-pw'>
        <div className='file-name-pw'>{name}</div>
        <div className='file-size-pw'>{filesize}</div>
        {
          !isfileerror &&   <div className='progress-bar-div-pw'>
            <div className='progress-bar-pw'> 
              <ProgressBar now={percentage}  />
            </div>
            <div>{percentage} %</div>
          </div>
        }
      
      </div>
      {(percentage === 100 && !isfileerror) &&(
        <div className='success-upload'>
          <Check color='#fff' />
        </div>
      )}
      {
        isfileerror && (
          <div className='download-upload'>
            <ExclamationCircleFill color='red' />
          </div>
        )

      }
    </div>
  );
};
export const FilePreviewComponent = ({ name, filesize, url,id,Deletefun,index,filedeleteloader,setFileDeleteLoader }) => {
  return (
    <div className='mt-2 file-upload bg-[#fff]'>
      <div className='file-preview'>
        <FileEarmark color='#00A46F' size={12} />
      </div>
      <div className='file-name-container-pw'>
        <div className='file-name-pw'>{name}</div>
        <div className='file-size-pw'>{filesize}</div>
      </div>
      {url && (
        <div className='download-upload'>
          {
            filedeleteloader ? 
            <EditProfileLoader /> 
           : <img src={trashIcon} onClick={() => {
              Deletefun(id,index)
             }} className='cursor-pointer' />
          }
          
          <a href={url} target='_blank' rel="noreferrer">
            <Download color='#00A46F' size={14} />
          </a>
        </div>
          
        
      )}
        
      
    </div>
  );
};
