// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CollaborativeEditor_container__14eLL {
  display: flex;
  flex-direction: column;
  position: relative;
  background: rgba(255, 255, 255, 0.60);
  width: 100%;
  color: #111827;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}


.CollaborativeEditor_editorHeader__4NzE7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

.CollaborativeEditor_ql-container__QsbJd{
  min-height: 800px;
}

.CollaborativeEditor_editor__RNcnQ {
  border-radius: inherit;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 1rem;
  outline: none;
  z-index: 100;
}
.CollaborativeEditor_editor__RNcnQ .CollaborativeEditor_ql-container__QsbJd {
  background: transparent;
  border: none;
}
.CollaborativeEditor_editorContainer__1mIFt {
  position: relative;
}
.CollaborativeEditor_editor__RNcnQ > div > div:nth-child(3){
  left: 0 !important;
  background-color: #101828;
}
.CollaborativeEditor_editor__RNcnQ > div > div:nth-child(3) > span{
  left: 25px !important;
  color: #101828;
}
.CollaborativeEditor_editor__RNcnQ > .CollaborativeEditor_placeholder__B2CPQ {
  pointer-events: none;
  position: absolute;
  inset: 0;
  color:#101828;
  opacity: 0.3;
  padding: 0 1em;
}

.CollaborativeEditor_editor__RNcnQ p {
  margin: 1em 0;
}
.CollaborativeEditor_ql-editor__wrAks{
  outline: 0px;
}
.CollaborativeEditor_ql-editor__wrAks:focus-visible{
  outline: 0px;
}
.CollaborativeEditor_ql-clipboard__7Xljz{
  outline: 0px;
}
.CollaborativeEditor_ql-clipboard__7Xljz:focus-visible{
  outline: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Briefs/EditBrief/components/CollaborativeEditor.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,qCAAqC;EACrC,WAAW;EACX,cAAc;EACd,8FAA8F;AAChG;;;AAGA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,aAAa;EACb,aAAa;EACb,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,qBAAqB;EACrB,cAAc;AAChB;AACA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,QAAQ;EACR,aAAa;EACb,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  background: rgba(255, 255, 255, 0.60);\n  width: 100%;\n  color: #111827;\n  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);\n}\n\n\n.editorHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #fff;\n}\n\n.ql-container{\n  min-height: 800px;\n}\n\n.editor {\n  border-radius: inherit;\n  flex-grow: 1;\n  width: 100%;\n  height: 100%;\n  padding: 1rem;\n  outline: none;\n  z-index: 100;\n}\n.editor .ql-container {\n  background: transparent;\n  border: none;\n}\n.editorContainer {\n  position: relative;\n}\n.editor > div > div:nth-child(3){\n  left: 0 !important;\n  background-color: #101828;\n}\n.editor > div > div:nth-child(3) > span{\n  left: 25px !important;\n  color: #101828;\n}\n.editor > .placeholder {\n  pointer-events: none;\n  position: absolute;\n  inset: 0;\n  color:#101828;\n  opacity: 0.3;\n  padding: 0 1em;\n}\n\n.editor p {\n  margin: 1em 0;\n}\n.ql-editor{\n  outline: 0px;\n}\n.ql-editor:focus-visible{\n  outline: 0px;\n}\n.ql-clipboard{\n  outline: 0px;\n}\n.ql-clipboard:focus-visible{\n  outline: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CollaborativeEditor_container__14eLL`,
	"editorHeader": `CollaborativeEditor_editorHeader__4NzE7`,
	"ql-container": `CollaborativeEditor_ql-container__QsbJd`,
	"editor": `CollaborativeEditor_editor__RNcnQ`,
	"editorContainer": `CollaborativeEditor_editorContainer__1mIFt`,
	"placeholder": `CollaborativeEditor_placeholder__B2CPQ`,
	"ql-editor": `CollaborativeEditor_ql-editor__wrAks`,
	"ql-clipboard": `CollaborativeEditor_ql-clipboard__7Xljz`
};
export default ___CSS_LOADER_EXPORT___;
