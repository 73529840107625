import React, { useContext, useState, useEffect } from 'react';
import RecentActivity from './RecentActivity';
import { getUserApi } from '../../apis';


const AllDashboardTable = ({currentid,setcurrentid, currentUser}) => {
  // const [currentUser, setCurrentUser] = useState(currentUser);


  // const currentUserApi = async () => {
  //   const res = await getUserApi();
  //   setCurrentUser(res?.data?.user[0]._id);
  // }
  // useEffect(() => {
  //   currentUserApi();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <RecentActivity currentid={currentid} setcurrentid={setcurrentid} currentUser={currentUser}/>
    </>
  );
};

export default AllDashboardTable;
