import React, { useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
const countries = require('i18n-iso-countries');
import * as yup from 'yup';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import { useFormik } from 'formik';
import { Loader } from '../../../../components/common/loader';
import { InputErrorMessage } from '../../../../components/errorMessages';
import { addAudienceV2 } from '../../../../apis/index';
import { EditProfileLoader } from '../../../../components/common/loader';

export default function AddAudience({ briefId, closeModal }) {
  const [updating, setUpdating] = useState(false);
  const [roles, setRoles] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const countryOptions = useMemo(() => countryList().getData(), []);

  const addAudienceSchema = yup.object({
    audience: yup.string().required()
  });

  const formik = useFormik({
    initialValues: {
        audience: '',
    },
    validationSchema: addAudienceSchema,
    onSubmit: async (values) => {
      try {
        setUpdating(true);
        console.log('values', values);
        const res = await addAudienceV2({
          id: briefId,
          audience: {            
            countryId: values.audience ? countries.alpha2ToNumeric(values.audience) : '',
            countryName: values.audience ? countries.getName(values.audience, 'en', {select: 'all'})[0] : ''
          }
        });
        setUpdating(false);

        if (res.status === 200 || 201) {
          toast.success(res?.data?.message, { id: '001' });          
          closeModal();
        } else {
          toast.error(res?.data?.message);  
        }
      } catch(e: any) {
        toast.error(e.response.data.message, { id: '001' });
        closeModal();
      }
    },
  });
  
  const handleChange = (e) => {
    formik.handleChange(e);
  };

  return (
    <>    
    {loading ? (
        <div className='text-xs text-center'>
          <Loader />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div>
              <label className='form-label'>
                Audience Region
              </label>
              <Select
                menuPortalTarget={document.body}
                menuPosition={'fixed'}   
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                classNamePrefix="select"
                defaultValue={countryOptions[countryOptions.findIndex(element => element.value === formik.values.audience)]}
                options={countryOptions}
                name='country'
                isClearable={false}
                isSearchable={true}
                onChange={(value) => { 
                    formik.setFieldValue('audience', value.value);
                }}
                />
              <InputErrorMessage error={formik.touched.audience} marginBottom={-5} />
            </div>
            <div className='brief-button-container'>
              <button type='button' className='button-close' style={{width: '100%'}} onClick={closeModal}>
                Cancel
              </button>
              <button type='submit' className='button-primary' disabled={updating} style={{width: '100%'}}>
                {updating ? (
                  <div className='text-xs text-center'>
                    <EditProfileLoader />
                  </div>
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </div>
        </form>
      )
    }
    </>
  );
}
