"use client";
import * as React from 'react';
import Quill from "quill";
import ReactQuill from "react-quill";
import 'quill-paste-smart';
import QuillCursors from "quill-cursors";
import { QuillBinding } from "y-quill";
import { AppContext } from './../../../../appContext';
import * as Y from "yjs";
import LiveblocksProvider from "@liveblocks/yjs";
import { useRoom } from "./../liveblocks.config";
import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { Toolbar } from "./Toolbar";
import 'react-quill/dist/quill.bubble.css'
import styles from "./CollaborativeEditor.module.css";
// import { Avatars } from "./Avatars";
let userfname,profilepic;
export type QuillEditorType = ReturnType<ReactQuill["getEditor"]>;
Quill.register("modules/cursors", QuillCursors);

// Collaborative text editor with simple rich text, live cursors, and live avatars
export function CollaborativeEditor({ updateForm, startedTyping }) {

const appContext = useContext(AppContext);
//const { state } = appContext;
const state: any = appContext;
  const { loggedInUser } = state;
  userfname = loggedInUser?.data[0].firstName || '';
  profilepic = loggedInUser?.data[0].profilePic || '';
  const room = useRoom();
  const [text, setText] = useState<Y.Text>();
  const [provider, setProvider] = useState<any>();

  // Set up Liveblocks Yjs provider
  useEffect(() => {
    const yDoc = new Y.Doc();
    const yText = yDoc.getText("quill");
    const yProvider = new LiveblocksProvider(room, yDoc);
    setText(yText);
    setProvider(yProvider);

    return () => {
      yDoc?.destroy();
      yProvider?.destroy();
    };
  }, [room]);

  const updateHandler = (html) => {
    updateForm(html);
  }

  if (!text || !provider) {
    return null;
  }

  return <QuillEditor yText={text} room={room} provider={provider} startedTyping={startedTyping} updateHandler={updateHandler} />;
}

type EditorProps = {
  yText: Y.Text;
  room: any;
  provider: any;
  startedTyping: Function;
  updateHandler: Function;
};

function QuillEditor({ yText, room, provider, startedTyping, updateHandler }: EditorProps) {
  provider.awareness.setLocalStateField("user", {
      name: userfname,
      color: '#ff0000',
      picture: profilepic,
    });
  const reactQuillRef = useRef<ReactQuill>(null);
  const [connected, setConnected] = useState(false);

  // Function to get the current Quill editor
  const getQuill = useCallback(() => {
    if (!reactQuillRef.current) {
      return null;
    }

    return reactQuillRef.current.getEditor();
  }, []);

  // Set up Yjs and Quill
  useEffect(() => {
    let quill: QuillEditorType;
    let binding: QuillBinding;

    if (!reactQuillRef.current) {
      return;
    }

    quill = reactQuillRef.current.getEditor();
    
    const unprivilegedEditor = reactQuillRef?.current?.makeUnprivilegedEditor(quill);
    yText?.observe(async () => {
      updateHandler(unprivilegedEditor?.getHTML());
    });
    provider?.on('sync', (isSynced) => {
      if (isSynced) {
        updateHandler(unprivilegedEditor?.getHTML());
      }
    });

    room.subscribe('status', (status) => {
      if (status === 'connected') {
        setConnected(true);
      } else {
        setConnected(false);
      }
    });

    quill.on('text-change', (_delta, _oldDelta, source) => {
      if (source == 'user') {
        startedTyping();
      }
    });
    binding = new QuillBinding(yText, quill, provider.awareness);
    return () => {
      binding?.destroy?.();
    };
  }, [yText, provider]);

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  
    [{ 'color': [] }],          // dropdown with defaults from theme
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ];

  return (
    <div className={styles.container}>
      <div className={styles.editorHeader}>
        {/* <Toolbar getQuill={getQuill} /> */}
        {/* <Avatars /> */}
      </div>
      <div className={styles.editorContainer}>
        <div style={{ position: 'absolute', bottom: 0, right: 0, fontSize: '12px', fontWeight: 100, fontStyle: 'italic'}}>
          {connected ? null : 'Connecting...'}
        </div>
        <ReactQuill
          className={styles.editor}
          placeholder="Start typing here…"
          ref={reactQuillRef}
          theme="bubble"
          formats={['color', 'bold', 'italic', 'strike', 'underline', 'list', 'header', 'color', 'align']}
          modules={{
            cursors: true,
            toolbar: toolbarOptions,
            history: {
              // Local undo shouldn't undo changes from remote users
              userOnly: true,
            },
          }}
        />
      </div>
    </div>
  );
}
