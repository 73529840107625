// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_loading__QajtQ {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  place-content: center;
  place-items: center;
}

.Loading_loading__QajtQ img {
  width: 64px;
  height: 64px;
  opacity: 0.2;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Briefs/EditBrief/components/Loading.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;AACd","sourcesContent":[".loading {\n  position: absolute;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  place-content: center;\n  place-items: center;\n}\n\n.loading img {\n  width: 64px;\n  height: 64px;\n  opacity: 0.2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `Loading_loading__QajtQ`
};
export default ___CSS_LOADER_EXPORT___;
