import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { editClientApi, getClientByIdApi } from '../../apis';
import { default as ProfileDefault } from '../../Assets/img/covers/userDefault.png';
import AdminLayout from '../../components/admin-layout';
import { EditProfileLoader, Loader } from '../../components/common/loader';
import { InputErrorMessage } from '../../components/errorMessages';
import { addNoteForClientValidation } from '../../components/Validation';
import { assignBucketUrl, getLastElementId } from '../../utils/helperFunctions';
import CoverDefault from '../../Assets/img/covers/img-1.jpg';
import OtherContactsModal from './OtherContactsModal';

export const ClientProfile = () => {
  const [clientById, setClientById] = useState({ data: null, loading: true });
  const [note, setNote] = useState({ loading: false });
  const clientId = getLastElementId();
  const data = clientById?.data;
  let allBriefClient = [];
  let briefTableClient = allBriefClient.concat(data?.live, data?.archived, data?.converted);

  const getClient = async (id) => {
    setClientById({ loading: true });
    try {
      const res = await getClientByIdApi(id);
      if (res.status === 200 || 201) {
        setClientById({ data: res.data.client, loading: false });
      }
    } catch (error) {
      setClientById({ loading: false });
    }
  };

  const formik = useFormik({
    initialValues: {
      notes: '',
      id: clientId,
    },
    validationSchema: addNoteForClientValidation,
    onSubmit: async (values) => {
      setNote({ loading: true });
      const res = await editClientApi(values);
      try {
        if (res.status === 200 || 201) {
          toast.success(res?.data?.message);
          getClient(clientId);

          setNote({ loading: false });
          formik.resetForm();
        }
      } catch (error) {
        setNote({ loading: false });
      }
    },
  });

  useEffect(() => {
    getClient(clientId);
  }, [clientId]);

  return (
    <AdminLayout>
      {clientById?.loading ? (
        <div className='text-center p-4'>
          <Loader />
        </div>
      ) : (
        <div className='flex-lg-1 h-screen overflow-y-lg-auto-hidden client-profile'>
          <header className='bg'>
            {/* Cover */}
            <div className='bg-cover repeat-0 position-relative'>
              <div
                className='bg-cover'
                style={{
                  backgroundImage: `url(${assignBucketUrl(data?.coverImage) || CoverDefault})`,
                  height: 300,
                  backgroundPosition: 'center center',
                }}></div>
            </div>
            {/* Info */}
            <div className='container-fluid max-w-screen-xl position-relative'>
              <div className='row g-0'>
                <div className='col-auto'>
                  <div style={{ width: '170px', height: '170px', marginTop: 0 }} className='edit-avtar rounded-circle masherProfilePic'>
                    <div className='col-sm-4 bg-repeat-0 bg-position-center bg-size-cover' htmlFor='profileImage'>
                      <img
                        alt=''
                        onError={(event) => {
                          event.target.src = ProfileDefault;
                          event.onerror = null;
                        }}
                        src={assignBucketUrl(data?.logo)}
                        style={{
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          position: 'absolute',
                          backgroundColor: 'white',
                          top: '-18%',
                          width: '170px',
                          height: '170px',
                          border: '1px solid rgb(25, 19, 19, 0.15)',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          boxShadow: 'rgb(0 0 0 / 37%) 0px 14px 20px -5px',
                          backgroundImage: `${`url(${assignBucketUrl(data?.logo)})`}`,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-12 col-md-6 ps-4 pt-4'>
                  <h1 className='h2'>{data?.brandName}</h1>
                  <div className='d-flex gap-2 flex-wrap mt-3 d-none d-sm-block'>
                    <span className='bg-yellow2 border px-4 py-2 font-semibold text-heading text-xs me-1'>{data?.industry[0]?.label}</span>
                    <span className='bg-yellow2 border px-4 py-2 font-semibold text-heading text-xs me-1'>{data?.type[0]?.label}</span>
                    <span className='bg-yellow2 border px-4 py-2 font-semibold text-heading text-xs me-1'>{data?.region[0]?.label}</span>
                  </div>
                </div>
                <div className='col-2 col-md-auto mt-2 mt-md-0 mb-md-3 hstack gap-2 mt-4 mt-sm-0  '>
                  <Link to={`/brands/edit-brand/${data?._id}`} className='btn btn-sm btn-neutral border-base text-capitalize'>
                    <i className='bi bi-pencil'></i>
                  </Link>
                </div>
                <div className='col-4 col-md-auto mt-2 mt-md-0 mb-md-3 hstack gap-2 mt-4 mt-sm-0 ms-2'>
                  <a href={data?.websiteLink} target='_blank' rel='noreferrer' className='btn btn-sm btn-neutral border-base text-capitalize'>
                    <span>Website</span>
                  </a>
                </div>

                <div className='col-6 col-md-auto mt-2 mt-md-0 mb-md-3 hstack gap-2 mt-4 mt-sm-0 ms-2'>
                  <Link
                    to={'/briefs/create-brief'}
                    state={{ value: data?._id, label: data?.clientId?.brandName }}
                    className='btn btn-sm btn-neutral border-base text-capitalize'>
                    <span>Create Brief</span>
                  </Link>
                </div>
              </div>
            </div>
          </header>
          {/* Main */}
          <div className='custom-tab-content pb-8'>
            <main className='pt-6'>
              <div className='container-fluid max-w-screen-xl'>
                <div className='row g-6'>
                  <div className='col-xl-8'>
                    <div className='vstack gap-6'>
                      <div className='card'>
                        <div className='card-header border-bottom d-flex align-items-center'>
                          <h5 className='me-auto'>Briefs</h5>
                        </div>

                        <i className='bi bi-airplane'></i>
                        <div className='table-responsive' style={{ maxHeight: '40vh', minHeight: '40vh' }}>
                          <table className='table table-hover table-nowrap'>
                            <thead className='table_gray_light'>
                              <tr>
                                <th scope='col'>Brief Name</th>
                                <th scope='col'>Created</th>
                                <th scope='col'>last updated</th>
                                <th scope='col'>Status</th>

                                <th scope='col'>action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {briefTableClient &&
                                briefTableClient?.map((el, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{el?.briefName}</td>
                                      <td>
                                        <Moment format='DD/MM/YYYY' date={el?.createdAt} />
                                      </td>
                                      <td>
                                        <Moment format='DD/MM/YYYY' date={el?.updatedAt} />
                                      </td>
                                      {!el?.isProject && !el?.isArchived ? <td>Live</td> : ''}
                                      {el?.isProject && <td>Converted</td>}
                                      {el?.isArchived && <td>Archived</td>}
                                      <td>
                                        <Link to={`/briefs/brief-view/${el?._id}`} className='btn btn-sm btn-neutral'>
                                          View
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                          {briefTableClient?.length <= 0 && <div className='text-center p-4'>No data found</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-4'>
                    <div className='vstack gap-6'>
                      <div className='card shadow-none'>
                        <div className='card-body'>
                          <div className='row align-items-center mb-2'>
                            <div className='col-md-6'>
                              <h5 className='mb-0'>Total Briefs</h5>
                            </div>
                            <div className='col-md-6'>
                              <span className='text-sm text-heading'>{data?.live.length + data?.converted.length + data?.archived.length || 0}</span>
                            </div>
                          </div>
                          <div className='row align-items-center mb-2'>
                            <div className='col-md-6'>
                              <h5 className='mb-0'>Live</h5>
                            </div>
                            <div className='col-md-6'>
                              <span className='text-sm text-heading'>{data?.live.length === 0 ? 0 : data?.live.length}</span>
                            </div>
                          </div>
                          <div className='row align-items-center mb-2'>
                            <div className='col-md-6'>
                              <h5 className='mb-0'>Converted</h5>
                            </div>
                            <div className='col-md-6'>{data?.converted.length === 0 ? 0 : data?.converted.length}</div>
                          </div>
                          <div className='row align-items-center mb-2'>
                            <div className='col-md-6'>
                              <h5 className='mb-0'>Archived</h5>
                            </div>
                            <div className='col-md-6'>
                              <span className='text-sm text-heading'>{data?.archived.length === 0 ? 0 : data?.archived.length}</span>
                            </div>
                          </div>
                          <div className='row align-items-center mb-2'>
                            <div className='col-md-6'>
                              <h5 className='mb-0'>Client Contact Name</h5>
                            </div>
                            <div className='col-md-6'>
                              <span className='text-sm text-heading'>{data?.clientName}</span>
                            </div>
                          </div>
                          <div className='row align-items-center mb-2'>
                            <div className='col-md-6'>
                              <h5 className='mb-0'>Client Contact Email</h5>
                            </div>
                            <div className='col-md-6'>
                              <span className='text-sm text-heading'>{data?.clientEmail}</span>
                            </div>
                          </div>
                          <div className='row align-items-center mb-2'>
                            <div className='col-md-6'>
                              <h5 className='mb-0'>Client Designation</h5>
                            </div>
                            <div className='col-md-6'>
                              <span className='text-sm text-heading'>{data?.clientDesignation}</span>
                            </div>
                          </div>
                          <div className='row align-items-center mb-2'>
                            <div className='col-md-6'>
                              <h5 className='mb-0'>Phone No</h5>
                            </div>
                            <div className='col-md-6'>
                              <span className='text-sm text-heading'>{(data?.clientPhoneCode?.[0]?.value || '') + ' ' + (data?.clientPhoneNo || '--')}</span>
                            </div>
                          </div>
                          {(data?.clientContacts.length > 0 && data?.clientContacts.length !==  undefined) && <div className='row align-items-center mb-2'>
                            <div className='col-md-6'>
                              {/* <h5 className='mb-0'></h5> */}
                            </div>
                            <div className='col-md-6'>
                              <Link
                                to=''
                                // className='btn btn-sm btn_primary_black'
                                // onClick={() => setTime(el.validTill)}
                                data-bs-target={'#other_contacts'}
                                data-bs-toggle='modal'
                              >
                                more contacts {`${(data?.clientContacts.length > 0 || data?.clientContacts.length !==  undefined) ? data?.clientContacts.length + '+' : ''}`}
                              </Link>
                              <OtherContactsModal tableData={data?.clientContacts || []} />
                            </div>
                          </div>}
                          <div className='row align-items-center'>
                            <div className='col-md-6'>
                              <h5 className='mb-0'>Client Status</h5>
                            </div>
                            <div className='col-md-6'>
                              <span className='text-sm text-heading'>{(data?.clientStatus ? (typeof (data.clientStatus) == 'string' ? (data.clientStatus) : (data.clientStatus[0].label)) : ('--'))}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                                
                    <div className='mt-6 mb-6 card shadow-none'>
                      <div className='card-body'>
                        <h5 className='mb-4'>Mashers worked with</h5>
                        <div className='vstack gap-6'>
                          {data?.masherWorkWith?.length > 0 &&
                            data?.masherWorkWith?.map((el, i) => {
                              return (
                                <div className='d-flex align-items-center' key={i}>
                                  <Link
                                    to={`/mashers/mashers-profile/${el?.value}`}
                                    className='me-3 projectTeam avatar rounded-circle text-white border border-1 border-solid border-card'>
                                    {el?.label?.charAt(0)}
                                  </Link>
                                  <div className='flex-1'>
                                    <Link to={`/mashers/mashers-profile/${el?.value}`} className='d-block font-semibold text-sm text-heading'>
                                      {el?.label}
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    <div className='card'>
                      <div className=' '>
                        <div className='col-md-12'>
                          <div className='card'>
                            <div className='card-header'>
                              <div className='d-flex align-items-center'>
                                <h5 className='mb-0'>NOTES</h5>
                              </div>
                            </div>

                            <div className='' style={{ border: '1px solid #eceef3' }}>
                              <div className='card-body position-relative scrollable-y notesbox'>
                                {clientById?.loading ? (
                                  <div className=''>
                                    <Loader />
                                  </div>
                                ) : clientById?.data?.notes !== '' ? (
                                  <div className='preWrap'>{clientById?.data?.notes}</div>
                                ) : (
                                  <div className='preWrap'>No Notes Found</div>
                                )}
                              </div>

                              <div className=''>
                                <form onSubmit={formik.handleSubmit}>
                                  <div className='card-body'>
                                    <div className='row' style={{ textAlign: 'end' }}>
                                      <div className='col-md-9 p-0'>
                                        <input
                                          placeholder='Add notes here...'
                                          value={formik.values.notes}
                                          onChange={(e) => formik.handleChange(e)}
                                          name='notes'
                                          className={
                                            Boolean(formik.touched.notes) && Boolean(formik.errors.notes)
                                              ? 'form-control border-danger'
                                              : 'form-control'
                                          }
                                          style={{ padding: '0.4rem' }}
                                        />
                                        <InputErrorMessage
                                          error={formik.touched.notes && formik.errors.notes}
                                          marginBottom={-20}
                                          style={{ textAlign: 'start' }}
                                        />
                                      </div>
                                      <div className='col-md-3 pe-0'>
                                        <button className='btn btn-sm btn_primary_black btn-width' type='submit'>
                                          {!note?.loading ? (
                                            'Save'
                                          ) : (
                                            <div className='text-xs'>
                                              <EditProfileLoader />
                                            </div>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};
 