import React, {useEffect, useState} from 'react';
import { getMasherByIdApi } from '../../apis';
import { getMasherFromTypesense } from '../../apis/typesenseApis';

import './avatar.css';

function Avatar({name, url, borderColor, borderWidth, id}) {
  const [avatarColour, setAvatarColour] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');

  function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + (hash << 7) - hash;
      hash = hash & hash;
      hash = Math.abs(hash);
    }
    var h = hash % 360;
    h += (hash % 30); // Add a smaller, secondary rotation based on the hash

    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  const getUserDetails = async () => {
    if (url) {
      setAvatarUrl(url?.split(' ').join('%20'));
      return;
    } else if (id && !url){
      try {
        const user = await getMasherFromTypesense(id);
        
        if ( user?.data?.hits[0]?.document?.profilePic ) { 
          setAvatarUrl(user?.data?.hits[0]?.document?.profilePic);
          return;
        } 
        if ( !user?.data?.hits[0]?.document?.profilePic ) {
          setAvatarUrl('');
          setAvatarColour(stringToHslColor(id, 50, 65));
          return;
        }
      } catch(e) {
        if (e) {
          setAvatarUrl('');
          setAvatarColour(stringToHslColor(id, 50, 65));
          return;
        }
      }
    } else if (!id && !url) {
      setAvatarColour(stringToHslColor(name, 50, 65));
      setAvatarUrl('');
      return;
    }
  };

  function getInitials(str) {
    const firstLetters = str
      .split(' ')
      .map(word => word.charAt(0))
      .join('')
      .toUpperCase();
    return firstLetters;
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      {
        avatarUrl && 
          <div className='avatar-container' style={{border: `${borderWidth || '2px'} solid ${borderColor || 'white'}`, backgroundColor: 'white', minWidth: '40px'}}>
            <div className='avatar-url'>
              {/* <img src={avatarUrl}/> */}
              <p
                style={{backgroundImage: `url(${avatarUrl})`, backgroundSize: 'cover'}}
                className="search-card-image"
              ></p>
            </div>
          </div>
      }
      { !avatarUrl &&
          <div className='avatar-container' style={{color: '#FFF', backgroundColor: `${avatarColour}` || 'rgb(212 212 212)', minWidth:'40px', border: `2px solid ${borderColor || 'white'}`}}>
            <div className='avatar-no-url' >
              <span>{getInitials(name)}</span>
            </div>
          </div>
      } 
    </>
  );
}

export default Avatar;